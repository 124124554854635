<template>
  <div>
    <el-button
      icon="el-icon-download"
      plain
      style="margin:25px"
      @click="download"
    >Download</el-button>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'Downloa',

  components: {

  },

  data() {
    return {

    }
  },

  created() {

  },

  mounted() {
    xray.index()
  },

  methods: {
    download() {
      const isLogin = localStorage.getItem('isLogin')
      if (isLogin === undefined || isLogin == null || isLogin === 'false') {
        this.$message({
          message: 'You need to be logged in to download this.',
          type: 'warning'
        })
      } else {
        let downloadElement = document.createElement('a')
        downloadElement.href = '/training_data.zip'
        downloadElement.download = 'training_data.zip'
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
      }
    }
  }

}
</script>

<style  scoped>
</style>