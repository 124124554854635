<template>
  <div>
    <b-form-group>
      <b-table :fields="fields" :items="items">
        <template v-slot:cell(username)="username">
          <input
            v-model="modifyInput_new.username"
            v-show="ismodify && currentSelected === username.value.user_id"
          />
          <div
            v-show="!(ismodify && currentSelected === username.value.user_id)"
          >
            {{ username.value.username }}
          </div>
        </template>
        <template v-slot:cell(department)="department">
          <input
            v-model="modifyInput_new.department"
            v-show="ismodify && currentSelected === department.value.user_id"
          />
          <div
            v-show="!(ismodify && currentSelected === department.value.user_id)"
          >
            {{ department.value.department }}
          </div>
        </template>
        <template v-slot:cell(user_id)="user_id">
          <div>{{ user_id.value.user_id }}</div>
        </template>
        <template v-slot:cell(user_info)="user_info">
          <input
            v-model="modifyInput_new.user_info"
            v-show="ismodify && currentSelected === user_info.value.user_id"
          />
          <div
            v-show="!(ismodify && currentSelected === user_info.value.user_id)"
          >
            {{ user_info.value.user_info }}
          </div>
        </template>
        <template v-slot:cell(user_type)="user_type">
          <!-- <input
            v-model="modifyInput_new.user_type"
            v-show="ismodify && currentSelected === user_type.value.user_id"
          /> -->
          <div v-show="ismodify && currentSelected === user_type.value.user_id">
            <b-dropdown :text="modifyInput_new.user_type" variant="primary">
              <b-dropdown-item-button @click="usertypeSelect(0)"
                >普通医生</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="usertypeSelect(1)"
                >超级管理员</b-dropdown-item-button
              >
            </b-dropdown>
          </div>
          <div
            v-show="!(ismodify && currentSelected === user_type.value.user_id)"
          >
            {{ user_type.value.user_type }}
          </div>
        </template>
        <template v-slot:cell(password)="user_pwd">
          <input
            v-model="password"
            v-show="
              ismodify_pwd && currentSelected_pwd === user_pwd.value.user_id
            "
            type="password"
          />
          <b-button variant="primary" @click="modify_pwd(user_pwd.value)">{{
            modify_button_pwd(user_pwd.value)
          }}</b-button>
        </template>
        <template v-slot:cell(button)="data">
          <b-button-group>
            <b-button variant="success" @click="modify(data.value)">{{
              modify_button(data.value)
            }}</b-button>
            <b-button variant="danger" @click="deleteDoctor(data.value.user_id)"
              >删除</b-button
            >
          </b-button-group>
        </template>
      </b-table>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'FormTable',
  props: {
    fields: {
      type: Array,
      defalut() {
        return []
      }
    },
    items: {
      type: Array,
      defalut() {
        return []
      }
    },
    canChange: {
      type: Boolean,
      defalut: true
    }
  },
  data() {
    return {
      ismodify: false,
      ismodify_pwd: false,
      currentSelected: 0,
      currentSelected_pwd: 0,
      modifyInput_old: {
        user_id: 0,
        username: '',
        department: '',
        user_info: '',
        user_type: 0
      },
      modifyInput_new: {
        user_if: 0,
        username: '',
        department: '',
        user_info: '',
        user_type: 0
      },
      password: ''
    }
  },
  methods: {
    usertypeSelect(index) {
      switch (index) {
        case 0:
          this.modifyInput_new.user_type = '普通医生'
          break
        case 1:
          this.modifyInput_new.user_type = '超级管理员'
      }
    },
    modify(data) {
      if (!this.ismodify) {
        this.ismodify = true
        this.currentSelected = data.user_id
        this.modifyInput_new.user_id = data.user_id
        this.modifyInput_new.username = data.username
        this.modifyInput_new.department = data.department
        this.modifyInput_new.user_info = data.user_info
        this.modifyInput_new.user_type = data.user_type
        this.modifyInput_old.user_id = data.user_id
        this.modifyInput_old.username = data.username
        this.modifyInput_old.department = data.department
        this.modifyInput_old.user_info = data.user_info
        this.modifyInput_old.user_type = data.user_type
      } else {
        if (data.user_id !== this.currentSelected) {
          this.ismodify = false
          return
        }
        if (this.modifyInput_new.user_type !== this.modifyInput_old.user_type) {
          if (
            this.modifyInput_new.user_type === '超级管理员' ||
            this.modifyInput_new.user_type === '普通医生'
          ) {
            let index = {}
            index.user_id = this.modifyInput_new.user_id
            index.auth = this.modifyInput_new.user_type === '超级管理员' ? '1' : '0'
            this.$emit('modifyAuth', index)
          }

          // this.$emit('')
        }
        let index = {}
        index.user_id = this.modifyInput_new.user_id
        index.username = this.modifyInput_new.username
        index.department = this.modifyInput_new.department
        index.user_info = this.modifyInput_new.user_info
        this.$emit('modifyExPwd', index)
        this.ismodify = false
      }
    },
    deleteDoctor(index) {
      this.$emit('deleteClick', index)
    },
    modify_pwd(data) {
      if (!this.ismodify_pwd) {
        this.ismodify_pwd = true
        this.currentSelected_pwd = data.user_id
      } else {
        if (data.user_id !== this.currentSelected_pwd) {
          this.ismodify_pwd = false
          return
        }
        if (this.password === '') {
          this.ismodify_pwd = false
          return
        }
        let index = {}
        index.password = this.password
        index.user_id = data.user_id
        this.$emit('modifyPwd', index)
        this.ismodify_pwd = false
      }
    },
    modify_button(data) {
      if (this.ismodify && data.user_id === this.currentSelected) {
        return '确认'
      } else {
        return '修改'
      }
    },
    modify_button_pwd(data) {
      if (this.ismodify_pwd && data.user_id === this.currentSelected_pwd) {
        return '确认'
      } else {
        return '修改'
      }
    }
  }
}
</script>

<style scoped></style>
