export default {
    updateRecord (context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('updateRecord', payload)
            resolve()
        })
    },
    updateSelected (context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('updateSelected', payload)
            resolve()
        })
    },
    changeStatus (context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('changeStatus', payload)
            resolve()
        })
    }
}