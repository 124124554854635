<template>
  <div class="personalInfo">
    <iq-card>
      <b-tabs content-class="mt-3">
        <b-tab
          :title="$t('personal_info.user_name')"
          @click="tabJump"
          active
          style="padding:10px"
        >
          <p v-show="!ismodify" class="content">
            <b>{{ $t('personal_info.user_name') }}：</b>
            {{ user.username }}
          </p>
          <div>
            <alert-bar
              :dismissSecs="dismissSecs"
              :dismissCountDown="dismissCountDown"
              :alert_style="alert_style"
              :alert_content="alert_content"
            ></alert-bar>
          </div>
          <p stlye="margin:10px;">
            <b-input-group
              v-show="ismodify"
              :prepend="$t('personal_info.user_name')"
              class="mt-3"
            >
              <b-form-input v-model="user_input.username"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" @click="modifyExceptPwd"
                  >确认</b-button
                >
                <b-button variant="outline-success" @click="cancelModify">{{
                  $t('personal_info.cancel')
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" @click="Modify" v-show="!ismodify">{{
              $t('personal_info.change')
            }}</b-button>
          </p>
        </b-tab>

        <b-tab
          :title="$t('personal_info.password')"
          @click="tabJump"
          style="padding:10px"
        >
          <p>
            <b-input-group
              :prepend="$t('personal_info.original_password')"
              class="mt-3"
              id="pwd"
            >
              <b-form-input v-model="old_pwd" type="password"></b-form-input>
            </b-input-group>
            <b-input-group
              :prepend="$t('personal_info.new_password')"
              class="mt-3"
            >
              <b-form-input v-model="new_pwd" type="password"></b-form-input>
            </b-input-group>
          </p>
          <div>
            <alert-bar
              :dismissSecs="dismissSecs"
              :dismissCountDown="dismissCountDown"
              :alert_style="alert_style"
              :alert_content="alert_content"
            ></alert-bar>
          </div>
          <b-button variant="primary" @click="modifyPwd">{{
            $t('personal_info.change')
          }}</b-button>
        </b-tab>

        <b-tab
          :title="$t('personal_info.office')"
          @click="tabJump"
          style="padding:10px"
        >
          <p v-show="!ismodify" class="content">
            <b> {{ $t('personal_info.office') }}：</b>
            {{ user.department }}
          </p>
          <div>
            <alert-bar
              :dismissSecs="dismissSecs"
              :dismissCountDown="dismissCountDown"
              :alert_style="alert_style"
              :alert_content="alert_content"
            ></alert-bar>
          </div>
          <p>
            <b-input-group
              v-show="ismodify"
              :prepend="$t('personal_info.office')"
              class="mt-3"
            >
              <b-form-input v-model="user_input.department"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" @click="modifyExceptPwd"
                  >确认</b-button
                >
                <b-button variant="outline-success" @click="cancelModify">{{
                  $t('personal_info.cancel')
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" @click="Modify" v-show="!ismodify">{{
              $t('personal_info.change')
            }}</b-button>
          </p>
        </b-tab>

        <b-tab
          :title="$t('personal_info.personal_info')"
          @click="tabJump"
          style="padding:10px"
          ><p v-show="!ismodify" class="content">
            <b>{{ $t('personal_info.personal_info') }}:</b>
            {{ user.user_info }}
          </p>
          <div>
            <alert-bar
              :dismissSecs="dismissSecs"
              :dismissCountDown="dismissCountDown"
              :alert_style="alert_style"
              :alert_content="alert_content"
            ></alert-bar>
          </div>
          <p>
            <b-input-group
              v-show="ismodify"
              :prepend="$t('personal_info.personal_info')"
              class="mt-3"
            >
              <b-form-input v-model="user_input.user_info"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" @click="modifyExceptPwd"
                  >确认</b-button
                >
                <b-button variant="outline-success" @click="cancelModify">{{
                  $t('personal_info.cancel')
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" @click="Modify" v-show="!ismodify">{{
              $t('personal_info.change')
            }}</b-button>
          </p></b-tab
        >
      </b-tabs>
    </iq-card>
  </div>
</template>

<script>
import AlertBar from '../../components/Alert/AlertBar'
import iqCard from '../../components/xray/cards/iq-card.vue'
import { xray } from '../../config/pluginInit'
import {
  personalInfo,
  modifyPersonalInfo_1,
  modifyPersonalInfo_2
} from '../../network/personalInfo'
// import i18n from '../../i18n'
export default {
  name: 'PersonalInfo',
  components: {
    iqCard,
    AlertBar
  },
  mounted() {
    xray.index()
  },
  data() {
    return {
      ismodify: false,
      user: {
        username: '',
        password: '',
        department: '',
        user_info: ''
      },
      user_input: {
        username: '',
        password: '',
        department: '',
        user_info: ''
      },
      user_id: 0,
      // 警示框相关参数
      dismissSecs: 3, // 警示框倒计时
      dismissCountDown: 0,
      alert_style: 'danger',
      alert_content: '',
      old_pwd: '',
      new_pwd: ''
    }
  },
  methods: {
    countDownChanged1(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getPersonalInfo(user_id) {
      // let id = this.$store.state.Setting.loginUser.user_id
      personalInfo(user_id).then(res => {
        this.user.username = res.data.data.username
        this.user.password = res.data.data.password
        this.user.department = res.data.data.department
        this.user.user_info = res.data.data.user_info
        this.user_input.username = res.data.data.username
        this.user_input.password = res.data.data.password
        this.user_input.department = res.data.data.department
        this.user_input.user_info = res.data.data.user_info
      })
    },
    // 修改相关函数
    Modify() {
      this.ismodify = true
    },
    cancelModify() {
      this.dismissCountDown = 0
      this.ismodify = false
      this.user_input.username = this.user.username
      this.user_input.password = this.user.password
      this.user_input.department = this.user.department
      this.user_input.user_info = this.user.user_info
    },
    tabJump() {
      this.cancelModify()
      this.dismissCountDown = 0
    },
    modifyExceptPwd() {
      this.dismissCountDown = 0
      let form = new FormData()
      form.append('username', this.user_input.username)
      form.append('department', this.user_input.department)
      form.append('user_info', this.user_input.user_info)
      modifyPersonalInfo_1(this.user_id, form).then(res => {
        if (res.data.success === false) {
          this.dismissCountDown = this.dismissSecs
          this.alert_content = res.data.message
        } else {
          this.ismodify = false
          this.getPersonalInfo(this.user_id)
          var user1 = JSON.parse(localStorage.getItem('loginUser'))
          user1.username = this.user_input.username
          user1.department = this.user_input.department
          user1.user_info = this.user_input.user_info
          localStorage.setItem('loginUser', JSON.stringify(user1))
          this.$store.commit('Setting/updateUserInfo', this.user)
          // 此处直接调用父级组件，耦合度较高
          this.$parent.updateUserName(user1.username)
        }
      })
      this.ismodify = true
    },
    modifyPwd() {
      this.dismissCountDown = 0
      let form = new FormData()
      form.append('password_old', this.old_pwd)
      form.append('password_new', this.new_pwd)
      modifyPersonalInfo_2(this.user_id, form).then(res => {
        // console.log(res)
        if (res.data.success === false) {
          this.dismissCountDown = this.dismissSecs
          this.alert_content = res.data.message
        } else {
          this.dismissCountDown = this.dismissSecs
          this.alert_content = res.data.message
          this.alert_style = 'success'
          this.getPersonalInfo(this.user_id)
          this.$store.commit('Setting/updateUserInfo', this.user)
        }
      })
      this.old_pwd = ''
      this.new_pwd = ''
    }
  },
  created() {
    this.user_id = JSON.parse(localStorage.getItem('loginUser')).user_id
    this.getPersonalInfo(this.user_id)
  }
}
</script>

<style scoped>
.content {
  position: relative;
  left: 5px;
}
</style>
