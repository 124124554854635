import { render, staticRenderFns } from "./CovidPatientDataChart.vue?vue&type=template&id=5bae6ae7&scoped=true&"
import script from "./CovidPatientDataChart.vue?vue&type=script&lang=js&"
export * from "./CovidPatientDataChart.vue?vue&type=script&lang=js&"
import style0 from "./CovidPatientDataChart.vue?vue&type=style&index=0&id=5bae6ae7&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bae6ae7",
  null
  
)

export default component.exports