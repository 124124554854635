<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="username" name="User Name" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="name">Your User Name</label>
          <input type="text" v-model="user.username" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="name" placeholder="Your User Name">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="user.password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="password" placeholder="Password">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="user_info" name="User Info" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="user_info">User Info</label>
          <input type="text" v-model="user.user_info" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="user_info" placeholder="user_info">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="department" name="Department" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="department">Department</label>
          <select v-model="user.department" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                  id="department" placeholder="department" visible-change="false">
            <option
                  v-for="item in department_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
          </select>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="user_type" name="User Type" rules="required">
        <div class="form-group">
          <label>User Type</label><br>
          <el-radio-group v-model="user.user_type" class="mt-1">
            <el-radio :label="0">医生</el-radio>
            <el-radio :label="1">管理员</el-radio>
          </el-radio-group>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <!-- 隐私协议确认选择框 -->
        <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">I accept <a href="#">Terms and Conditions</a></label>
        </div> -->
        <button type="submit" class="btn btn-primary float-right">Sign Up</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Already Have Account ?
              <router-link to="/dark/auth/sign-in1" class="iq-waves-effect pr-4" v-if="$route.meta.dark">
                Sign in
              </router-link>
            <router-link to="/auth/sign-in1" class="iq-waves-effect pr-4" v-else>
                Sign in
              </router-link>
          </span>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import auth from '../../../../services/auth'
import firebase from 'firebase'
import { mapGetters } from 'vuex'

export default {
  name: 'SignUp1Form',
  props: ['formType'],
  computed: {
    ...mapGetters({
      users: 'Setting/usersState'
    })
  },
  data: () => ({
    user: {
      username: '',
      password: '',
      user_info: '',
      department: '',
      user_type: 0
    },
    department_options: [
      {
        value: '内科',
        label: '内科'
      },
      {
        value: '外科',
        label: '外科'
      }
    ]
  }),
  methods: {
    // 此处三个注册函数功能暂不需要，另外写了一个新的
    onSubmit () {
      // if (this.formType === 'passport') {
      //   this.passportRegister()
      // } else if (this.formType === 'jwt') {
      //   this.jwtRegister()
      // } else if (this.formType === 'firebase') {
      //   this.firebaseRegister()
      // }
      this.register()
    },
    jwtRegister () {
      this.$store.dispatch('Setting/addUserAction', this.user)
      this.$router.replace('/auth/sign-in1')
    },
    passportRegister () {
      auth.register(this.user).then(response => {
        if (response.status) {
          this.$router.push('/auth/sign-in1')
        } else if (response.data.errors.length > 0) {
          this.$refs.form.setErrors(response.data.errors)
        }
      }).finally(() => { this.loading = false })
    },
    firebaseRegister () {
      firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password).then((user) => {
        this.$router.replace('/auth/sign-in1')
        // eslint-disable-next-line handle-callback-err
      }).catch((err) => {
      })
    },
    async register () {
      let formData = new FormData()
      formData.append('username', this.user.username)
      formData.append('password', this.user.password)
      formData.append('user_info', this.user.user_info)
      formData.append('department', this.user.department)
      formData.append('user_type', this.user.user_type)
      await auth.register(formData)
        .then(response => {
          // console.log(response)
          alert(response.data.message)
          if (response.data.success) {
            this.$router.push('/auth/sign-in1')
          }
        })
        .catch(error => {
          alert(error.message)
        })
    }
  }
}
</script>
