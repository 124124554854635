import Axios from 'axios'

export default {
  login (userData) {
    return Axios({
      method: 'post',
      url: 'http://47.93.42.104:10408/v1/web/doctors/login',
      data: userData
    })
  },
  register (userData) {
    return Axios({
      method: 'post',
      url: 'http://47.93.42.104:10408/v1/web/doctors/register',
      data: userData
    })
  }
}
