<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="5">
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img v-if="patient.gender === 0" src="../../assets/images/user/11.png" alt="profile-img"
                    class="avatar-130 img-fluid">
                  <img v-else src="../../assets/images/user/19.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <div class="text-center mt-3">
                  <!--              <h3><b>编号：{{patient.pdid}}</b></h3>-->
                  <h6 class="text-primary">原发疾病</h6>
                  <h4><b>{{patient.originDisease}}</b></h4>
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">编号</h6>
                    <h4> - </h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">性别</h6>
                    <h4 v-if="patient.gender === 0">女</h4>
                    <h4 v-else>男</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">出生年份</h6>
                    <h4 v-if="patient.birthDate != null">{{patient.birthDate.substring(0,4)}}</h4>
                    <h4 v-else>暂无数据</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a data-icon="T" class="icon" v-if="display === false"
                    @click="display = !display; $store.commit('setShowLine', true)">
                    <span>更多</span>
                  </a>
                  <a data-icon="W" class="icon" v-else
                    @click="display = !display ; $store.commit('setShowLine', false)">
                    <span>收起</span>
                  </a>
                </div>
                <b-collapse id="collapse-0" class="mb-2" v-model="display">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">身高</p>
                        <h5 v-if="patient.height == undefined">暂无数据</h5>
                        <div v-else>
                          <h5>{{change(patient.height)}}&nbsp;<span>cm</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">体重</p>
                        <h5 v-if="patient.weight == undefined">暂无数据</h5>
                        <div v-else>
                          <h5>{{change(patient.weight)}}&nbsp;<span>kg</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">入院年龄</p>
                        <h5 v-if="patient.age == undefined">暂无数据</h5>
                        <div v-else>
                          <h5>{{patient.age}}&nbsp;<span>岁</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3" v-if="patient.death == 1">
                        <p class="mb-0">去世年份-月份</p>
                        <h5>{{patient.deathDate.substring(0,7)}}</h5>
                      </div>
                      <div class="data-block mt-3" v-else>
                        <p class="mb-0">现状</p>
                        <h5>正在治疗</h5>
                      </div>
                      <div class="data-block mt-3"
                        v-show="patient.amputation === 1 || patient.ch === 1 || patient.ci === 1 || patient.mi === 1 || patient.chd === 1 || patient.lung_infect === 1 || patient.heart_failure === 1 || patient.diabetes === 1">
                        <p class="mb-0">基础病症</p>
                        <h5>{{(patient.diabetes === 1 ? '糖尿病 ' : '') +
                          (patient.heart_failure === 1 ? '心脏衰竭 ' : '') +
                          (patient.lung_infect === 1 ? '肺部感染 ' : '') +
                          (patient.chd === 1 ? '冠心病 ' : '') +
                          (patient.mi === 1 ? '心梗 ' : '') +
                          (patient.ci === 1 ? '脑梗 ' : '') +
                          (patient.ch === 1 ? '脑出血 ' : '') +
                          (patient.amputation === 1 ? '截肢 ' : '')}}</h5>
                      </div>
                      <div class="data-block mt-3" v-show="patient.death == 1">
                        <p class="mb-0">死因</p>
                        <h5>{{patient.deathReasonText}}</h5>
                      </div>
                      <div class="data-block mt-3" v-show="analyze['cluster_id'] !== undefined">
                        <p class="mb-0">聚簇类别</p>
                        <h5><span>健康状态 </span>{{analyze.cluster_id}} <span> 号簇</span></h5>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- patient card end -->
        <b-col>
          <!--          <iq-card class-name="iq-card-block iq-card-stretch" body-class="iq-bg-primary rounded">-->
          <!--            <template v-slot:body>-->
          <!--              <div class="d-flex align-items-center" style="padding-top:10px;flex-direction:column;">-->
          <!--                <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-user-fill"></i></div>-->
          <!--                <div class="text-center">-->
          <!--                  <h2 class="mb-0"><span style="font-size:20px"> </span><span class="counter">医疗建议</span></h2>-->
          <!--                </div>-->
          <!--                <div class="media-body" style="margin:20px">-->
          <!--                  <h5 class="mt-0"></h5>-->
          <!--                  <p class="mb-0">-->
          <!--                    {{advice['info']}}-->
          <!--                    <br/>{{advice['first']}}-->
          <!--                    <br/>{{advice['second']}}-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </iq-card>-->
          <AIObservation :analyzeAdvice="analyzeAdvice" :adviceOnRiskThreshold="adviceOnRiskThreshold"
            :adviceOnRisk="adviceOnRisk" />
        </b-col>
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">指标</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>清空已选</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4 class="card-title text-primary" v-show="patient_data_selector.current_important.length">当前重要指标
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.current_important.length">
              <PatientDataSelector :item="patient_data_selector.current_important" :info="patient_data_selector.info">
              </PatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.history_important.length">
              <h4 class="card-title text-primary">历史重要指标（建议同时加强关注）</h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.history_important.length">
              <PatientDataSelector :item="patient_data_selector.history_important" :info="patient_data_selector.info">
              </PatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.other.length">
              <div style="padding-bottom:20px;text-align:center">
                <a data-icon="T" class="icon" v-if="display1 === false" @click="display1 = !display1">
                  <span>更多</span>
                </a>
                <a data-icon="W" class="icon" v-else @click="display1 = !display1">
                  <span>收起</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.other.length">
              <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                <h4 class="card-title text-primary">其他指标</h4>
                <PatientDataSelector :item="patient_data_selector.other"></PatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <PatientDataChart v-if=" analyze !==undefined && (selectedGraph.length>= 1 || analyze.predict !== undefined)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="bg-primary rounded pt-2 pb-2 pr-2">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0" style="font-size: 22px;margin-left: 10px;color: white;"> 相似病人信息（建议关注的潜在重要信息）<i
                  class="fa fa-id-card" style="margin-left: 5px;"></i>
              </h4>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <iq-card class-name='iq-card-block iq-card-stretch iq-card-height'>
          <template v-slot:headerTitle>
            <h4 class="card-title" style="display: inline">Top6 相似病人列表</h4>
            <Tooltip placement="top">
              <img src="../../assets/images/question.png" alt="guide" class="guide" />
              <span slot="content" style="white-space: normal">
                按照病情，从数据库中筛选出的与当前病人最相似的病人列表，按照相似度指数从大到小排列，选取最相似的6位病人
              </span>
            </Tooltip>
          </template>
          <template v-slot:body>
            <div class="table-responsive" style="margin-left: 5px;">
              <table class="table mb-0 table-borderless">
                <thead>
                  <tr>
                    <th scope="col">性别</th>
                    <th scope="col">原发疾病</th>
                    <th scope="col">基础病症</th>
                    <th scope="col">入院年龄</th>
                    <th scope="col">去世年龄</th>
                    <th scope="col">死因</th>
                    <th scope="col">相似就诊时间段</th>
                    <th scope="col">相似度指数
                      <Tooltip placement="top">
                        <img src="../../assets/images/question.png" alt="guide" class="guide"
                          style="height:20px; margin-top: 5px;" />
                        <span slot="content" style="white-space: normal; font-weight: normal">
                          表示病人之间的接近程度 (0 ≤ 相似度指数 ≤ 100)<br />--数值越大代表病人越相似
                        </span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(similarPatient, index) in similarPatients" :key="similarPatient.pdid"
                    :class="{'link-item-active': similarPatient.id == activeLinkId,'link-item-hover':similarPatient.pdid==hoverIndex}"
                    @click="toPatientPage(similarPatient)" @mouseover="hoverIndex = similarPatient.pdid"
                    @mouseout="hoverIndex = -1">
                    <td>{{similarPatient.gender === 1 ? '男': '女'}}</td>
                    <td>{{similarPatient.originDisease}}</td>
                    <td>
                      {{(similarPatient.diabetes === 1 ? '糖尿病 ' : '') +
                      (similarPatient.heart_failure === 1 ? '心脏衰竭 ' : '') +
                      (similarPatient.lung_infect === 1 ? '肺部感染 ' : '') +
                      (similarPatient.chd === 1 ? '冠心病 ' : '') +
                      (similarPatient.mi === 1 ? '心梗 ' : '') +
                      (similarPatient.ci === 1 ? '脑梗 ' : '') +
                      (similarPatient.ch === 1 ? '脑出血 ' : '') +
                      (similarPatient.amputation === 1 ? '截肢 ' : '') +
                      (similarPatient.amputation === 1 || similarPatient.ch === 1 || similarPatient.ci === 1 || similarPatient.mi === 1 || similarPatient.chd === 1 || similarPatient.lung_infect === 1 || similarPatient.heart_failure === 1 || similarPatient.diabetes === 1 ? '' : '无')
                    }}
                    </td>
                    <td>{{similarPatient.age}}</td>
                    <td>{{similarPatient.death === 0 ? '健在': similarPatient.deathAge}}</td>
                    <td>{{similarPatient.death === 0 ? '无': similarPatient.deathReasonText}}</td>
                    <td>{{similarPatient.focusTime}}</td>
                    <td>{{(100 - analyze.top_similarIdx[index]*100).toFixed(2)}}</td>
                    <!-- <td>{{(analyze.top_similarIdx[index]).toFixed(10)}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch" :key="genderRatio">
          <template v-slot:headerTitle>
            <h4 class="card-title">人口统计学信息统计<span style="font-size: 14px;">（共
                {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['amount']}}名病人）</span></h4>
          </template>
          <template v-slot:body>
            <div class="iq-details">
              <span class="title text-dark">男性占比</span>
              <div class="percentage float-right text-primary">
                {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['maleRatio']).toFixed(2)}}<span>%</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">女性占比</span>
              <div class="percentage float-right text-primary">
                {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['femaleRatio']).toFixed(2)}}<span>%</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">平均入院年龄</span>
              <div class="percentage float-right text-warning">
                {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['age'].toFixed(2)}}<span>
                  岁</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">平均死亡年龄</span>
              <div class="percentage float-right text-success">
                {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['death_age'].toFixed(2)}}<span>
                  岁</span>
              </div>
            </div>
            <!-- <div class="iq-details mt-4">
              <span class="title text-dark">死亡率</span>
              <div class="percentage float-right text-danger">
                {{analyze['cluster_info'] === undefined ? '':(100*analyze['cluster_info']['death']).toFixed(2)}}<span>%</span>
              </div>
            </div> -->
            <div style="margin-bottom: 100px;"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h4 class="card-title">重要生理指标统计<span style="font-size: 14px;">（潜在重要程度）</span></h4>
          </template>
          <template v-slot:body>
            <ul class="patient-progress m-0 p-0">
              <div>
                <h6 style="float: left; font-weight: bold;">生理指标</h6>
                <h6 style="float: right; margin-right: 20px; font-weight: bold;">重要指数
                  <Tooltip placement="top">
                    <img src="../../assets/images/question.png" alt="guide" class="guide" style="margin-top: 5px;" />
                    <span slot="content" style="white-space: normal; font-weight: normal">
                      表示相似病人某个生理指标的重要程度, 数值越大表示该指标越重要 (范围: 0-100)
                    </span>
                  </Tooltip>
                </h6>
              </div>
              <div style="margin-bottom: -5px; clear: both;"></div>
              <div class="table-responsive mt-4">
                <ul class="doctors-lists m-0 p-0">
                  <div v-for="attn in similarPatientsAttn" :key="attn[0]" style="clear: both;">
                    <li class="d-flex mb-3 align-items-center">
                      <div class="media-support-info">
                        <h6><span :style="{color: ch.get(attn[0]).midColor}"
                            style="font-weight: bold;">{{medicalCharChinese[attn[0]]}}</span>
                        </h6>
                      </div>
                      <span class="badge badge-primary"
                        style="margin-right: 20px;">{{(attn[1] * 100).toFixed(2)}}</span>
                    </li>
                  </div>
                </ul>
              </div>
            </ul>
            <div style="margin-bottom: 50px;"></div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card class-name="iq-card-block iq-card-stretch" body-class="pl-0 pr-0" :key="clusterInfoKey">
          <template v-slot:headerTitle>
            <h4 class="card-title">基础病症统计<span style="font-size: 14px;">（潜在基础病症）</span></h4>
          </template>
          <template v-slot:body>
            <AmChart element="home-chart-03" :type=originDiseaseChart.type :option=originDiseaseChart.data
              :height=originDiseaseChart.height />
          </template>
          <div style="margin-bottom: 50px;"></div>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">治疗结局统计</h4>
          </template>
          <template v-slot:body>
            <h2>
              <span class="percentage text-danger">
                {{analyze['cluster_info'] === undefined ? '':(100*analyze['cluster_info']['death']).toFixed(2)}}%
              </span>
              <small class="text-secondary"
                style="padding-top: 12px; float: right; font-size: 16px; color: black !important">相似病人死亡率
                <Tooltip placement="top-end">
                  <img src="../../assets/images/question.png" alt="guide" class="guide" style="margin-top: 5px;" />
                  <span slot="content" style="white-space: normal">
                    相似病人两年内死亡率统计
                  </span>
                </Tooltip>
              </small>
            </h2>
            <div class="progress mt-3">
              <div class="progress-bar" role="progressbar" v-for="reason in deathReason" :key="reason[0]"
                :aria-valuenow="(100 * reason[1] / analyze.cluster_info.amount).toFixed(0)" aria-valuemin="0"
                aria-valuemax="100"
                :style="{width:(100 * reason[1] / analyze.cluster_info.amount).toFixed(0) + '%',background:ch.get(reason[0]).midColor}">
              </div>
              <!-- <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="20" aria-valuemin="0"
                aria-valuemax="100" style="width:20%">
              </div>
              <div class="progress-bar bg-info" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                aria-valuemax="100" style="width:10%">
              </div>
              <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                aria-valuemax="100" style="width:40%">
              </div>
              <div class="progress-bar bg-success" role="progressbar" aria-valuenow="20" aria-valuemin="0"
                aria-valuemax="100" style="width:20%">
              </div>
              <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                aria-valuemax="100" style="width:10%">
              </div> -->
            </div>
            <div class="table-responsive mt-4">
              <ul class="doctors-lists m-0 p-0">
                <li class="d-flex mb-4 align-items-center" v-for="reason in deathReason" :key="reason[0]">
                  <div class="media-support-info ml-3">
                    <h5>{{reason[0]}}</h5>
                    <p class="mb-0 font-size-12">{{reason[1]}}<span> 人</span></p>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="percentage float-left text-danger" style="margin-right:20px;">
                      {{(100 * reason[1] / analyze.cluster_info.amount).toFixed(2)}}<span>%</span></div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import IqCard from '../../components/xray/cards/iq-card'
import { xray } from '@/config/pluginInit'
import SettingsHelper from '../../util/SettingsHelper'
import APIHelper, { getAnalyzeAdvice } from '@/util/APIHelper'
import { medicalCharChinese } from '@/util/info'
import SpecialPatientDataChart from '../Charts/SpecialPatientDataChart'
import SpecialPatientDataSelector from '../Charts/SpecialPatientDataSelector'
import ColorHelper from '../../util/ColorHelper'
import SpecialAIObservation from '../../components/Advice/SpecialAIObservation'

const body = document.getElementsByTagName('body')
  export default {
    name: 'Dashboard4',
    components: {
      IqCard,
      PatientDataChart: SpecialPatientDataChart,
      PatientDataSelector: SpecialPatientDataSelector,
      AIObservation: SpecialAIObservation
    },
    inject: ['reload'],
    data() {
      return {
        bg_color: [
          'bg-warning',
          'bg-primary',
          'bg-info',
          'bg-danger',
          'bg-success',
          'bg-secondary'
        ],
        hoverIndex: -1, // 菜单hover索引
        activeLinkId: 0, // 当前激活的菜单id
        message: '默认值',
        genderRatio: 0,
        display: false,
        display1: false,
        dialog: false,
        top2: undefined,
        urlAnalyzeApi: undefined,
        settings: undefined,
        getAnalyzeDataTimer: undefined,
        getAdviceTimer: undefined,
        similarPatientsAttn: [],
        medicalCharChinese: medicalCharChinese,
        patient_data_selector: {
          current_important: [],
          history_important: [],
          other: [],
          item: [],
          part: [0.2, 0.5, 1],
          info: {}
        },
        analyzeAdvice: undefined,
        originDiseaseChart: {
          type: 'radial-pie',
          height: 280,
          data: []
        },
        clusterInfoKey: 0
      }
    },
    created() {
      this.urlAnalyzeApi = (new URL(window.location.href)).searchParams.get('api')
      if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
      this.settings = SettingsHelper.load()
      this.$store.commit('setComputingAdvice', { pending: true })
      this.$store.commit('setSpecialModifiedLab', this.lab)
      Promise.all([APIHelper.getAdviceOnRisk({
        patient: this.patient,
        lab: this.lab
      }, 40), APIHelper.getAdviceOnRisk({
        patient: this.patient,
        lab: this.lab
      }, 0)]).then(([adviceOnRiskThreshold, adviceOnRisk]) => {
        // this.$store.commit('setAdviceOnRiskThresholdSub', adviceOnRiskThresholdSub)
        this.$store.commit('setAdviceOnRiskThreshold', adviceOnRiskThreshold)
        this.$store.commit('setAdviceOnRisk', adviceOnRisk)
        this.$store.commit('setComputingAdvice', { pending: false })
      })
      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }
    },
    mounted() {
      xray.index()
      body[0].classList.add('sidebar-main-menu')
    },
    destroyed() {
      body[0].classList.remove('sidebar-main-menu')
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.analyze
      },
      modifiedLab() {
        return this.$store.state.specialModifiedLab
      },
      lab() {
        return this.$store.state.specialLab
      },
      patient() {
        return this.$store.state.specialPatient
      },
      similarPatients() {
        return this.$store.state.similarPatients
      },
      analyzeData() {
        const { modifiedLab, patient } = this
        return {
          modifiedLab, patient
        }
      },
      advice() {
        return this.$store.state.advice
      },
      adviceOnRiskThreshold() {
        return this.$store.state.adviceOnRiskThreshold
      },
      adviceOnRiskThresholdSub() {
        return this.$store.state.adviceOnRiskThresholdSub
      },
      adviceOnRisk() {
        return this.$store.state.adviceOnRisk
      },
      adviceOnIdx() {
        return this.$store.state.adviceOnIdx
      },
      ch() {
        return this.$store.state.ch
      },
      showLine() {
        return this.$store.state.showLine
      },
      deathReason() {
        return this.$store.state.deathReason
      }
    },
    watch: {
      analyzeData() {
        this.getAnalyzeData()
      },
      '$route'(to, from) { // 监听路由是否变化
        if (to.name === 'dashboard.home-4') { // 跳转到哪个页面
          this.reload()
        }
      }
    },
    methods: {
      enter: function () {
        this.color = 'blue'
      },
      leave: function () {
        this.color = 'red'
      },
      getAnalyzeData() {
        clearTimeout(this.getAnalyzeDataTimer)
        this.getAnalyzeDataTimer = setTimeout(() => this.patient && this.modifiedLab && APIHelper.getAnalyze({
          patient: this.patient,
          lab: this.modifiedLab
        }, this.urlAnalyzeApi ? this.settings.analyzeApi.map(o => ({
          ...o,
          enabled: this.urlAnalyzeApi.includes(o.alias)
        })) : this.settings.analyzeApi).then(result => {
          this.$store.commit('setAnalyze', result)
          if (this.top2 === undefined) {
            this.$store.commit('setOriginalAnalyze', result)
            this.getRank()
          }
          this.originDiseaseChart.data = []
          let diseases = result.cluster_info.origin_disease
          for (let key in diseases) {
            if (diseases[key] > 0) {
              this.originDiseaseChart.data.push({ 'country': medicalCharChinese[key], 'value': diseases[key] })
            }
          }
          this.genderRatio = 100 / (1 / result['cluster_info']['genderRatio'] + 1)
          ++this.clusterInfoKey

          APIHelper.getAllPatientById(result['top_visit'], result['cluster_top6_pdid']).then(similarPatients => this.$store.commit('setSimilarPatients', similarPatients))

          let dict = result['cluster_info']['attention']
          this.similarPatientsAttn = Object.keys(dict).map(function (key) {
            return [key, dict[key]]
          })
          // Sort the array based on the second element
          this.similarPatientsAttn.sort(function (first, second) {
            return second[1] - first[1]
          })
          dict = result['cluster_info']['death_reason']
          let deathReason = Object.keys(dict).map(function (key) {
            return [key, dict[key]]
          })
          // Sort the array based on the second element
          deathReason.sort(function (first, second) {
            return second[1] - first[1]
          })
          let tmp = deathReason[0]
          if (tmp[0] === '存活') {
            deathReason.splice(0, 1)
            deathReason.push(tmp)
          }
          this.$store.commit('setDeathReason', deathReason)
        }), 500)
      },
      change(a) {
        const b = parseFloat(a)
        return b.toFixed(1)
      },
      getRank() {
        if (this.analyze.attention) {
          const lastAttention = this.analyze.attention.slice(-1)[0]
          this.patient_data_selector.item = Object.keys(lastAttention)
          let arr = []
          for (let [key, value] of Object.entries(lastAttention)) {
            if (value >= 0.22) {
              arr.push(key)
            }
          }
          let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
            return b[1] - a[1]
          })
          this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
          let obj = {}
          let top1 = []
          let arr1 = []
          const length = this.analyze.attention.length
          this.analyze.attention.forEach((item, i) => {
            let max = 0
            Object.entries(item).forEach(([key, value]) => {
              if (value > max) {
                max = value
                top1[i] = key
              }
            })
            // let k = 0
            Object.getOwnPropertyNames(item).forEach(key => {
              obj[key] ? (obj[key] += item[key]) : (obj[key] = item[key])
              if (item[key] >= 0.5 || key === top1[i]) {
                if (arr1.indexOf(key) === -1) {
                  arr1.push(key)
                  if (this.getLocale()) {
                    this.patient_data_selector.info[key] = '<b>AI强烈关注此项指标时间</b>'
                  } else {
                    this.patient_data_selector.info[key] = '<b>This indicator is focused by AI</b>'
                  }
                }
                if (i === 0 || key !== top1[i - 1]) {
                  this.patient_data_selector.info[key] += '<br/>' + this.modifiedLab[i].date
                  // k = i
                }
              } else {
                if (i >= 1 && key === top1[i - 1]) {
                  if (i >= 2 && key === top1[i - 2]) {
                    this.patient_data_selector.info[key] += '~' + this.modifiedLab[i - 1].date
                    /* if (k - 1 >= 0) {
                      let trend = this.lab[i][key] / this.lab[k - 1][key] - 1
                      if (trend !== 0) {
                        this.patient_data_selector.info[key] +=
                          '（' + (trend > 0 ? '升高了' + trend : '降低了' + (-trend)) + '%）'
                      }
                    } */
                  }
                }
              }
            })
          })
          if (length >= 2) {
            this.patient_data_selector.item.forEach(key => {
              if (key === top1[length - 1]) {
                if (key === top1[length - 2]) {
                  this.patient_data_selector.info[key] += '~' + this.modifiedLab[length - 1].date
                }
              }
            })
          }
          Object.entries(obj).forEach(item => {
            if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
              arr1.push(item[0])
            }
          })
          this.patient_data_selector.current_important = arr
          this.patient_data_selector.history_important = arr1.filter(item => arr.indexOf(item) === -1)
          this.patient_data_selector.other = this.patient_data_selector.item.filter(
            item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
          )
          if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length >= 2) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.current_important[1]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1 &&
            this.patient_data_selector.history_important && this.patient_data_selector.history_important.length >= 1) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.history_important[0]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1) {
            this.$store.commit('setSelectedGraph', [this.patient_data_selector.current_important[0]])
          }
          this.patient_data_selector.other.push('总蛋白')
          this.patient_data_selector.other.push('球蛋白')
          this.patient_data_selector.other.push('白球比例')
          this.patient_data_selector.other.push('总胆红素')
          this.patient_data_selector.other.push('直接胆红素')
          this.patient_data_selector.other.push('间接胆红素')
          this.patient_data_selector.other.push('丙氨酸氨基转移酶')
          this.patient_data_selector.other.push('天冬氨酸氨基转移酶')
          this.patient_data_selector.other.push('总胆汁酸')
          this.patient_data_selector.other.push('谷氨酰转移酶')
          this.patient_data_selector.other.push('碱性磷酸酶')
          this.patient_data_selector.other.push('胆碱酯酶')
          this.patient_data_selector.other.push('尿酸')
          this.patient_data_selector.other.push('镁')
          this.patient_data_selector.other.push('估算的肾小球滤过率')
          this.patient_data_selector.other.push('总胆固醇')
          this.patient_data_selector.other.push('甘油三酯')
          this.patient_data_selector.other.push('高密度脂蛋白胆固醇')
          this.patient_data_selector.other.push('低密度脂蛋白胆固醇')
          this.patient_data_selector.other.push('血清胱抑素c')
          this.patient_data_selector.other.push('β2微球蛋白')
          this.patient_data_selector.other.push('补体c1q')
          this.patient_data_selector.other.push('糖化血红蛋白')
          this.patient_data_selector.other.push('阴离子隙')
          this.patient_data_selector.other.push('乳酸测定')
          this.patient_data_selector.other.push('尿素氮/肌酐')
          this.$store.commit('setSelectedDate', [])
          this.analyzeAdvice = getAnalyzeAdvice(this.lab, this.analyze, this.patient_data_selector.item)
        }
      },
      toPatientPage(a) {
        this.$router.push('/' + a.pdid)
      }
    }
  }
</script>

<style scoped>
  .link-item-hover {
    background-color: #f5f7fa;
  }

  .link-item-active {
    color: #fff;
    background-color: #b3cae4;
  }

  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }
</style>
