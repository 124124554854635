var render = function render(){var _vm=this,_c=_vm._self._c;return _c('iq-card',{attrs:{"class-name":"iq-card-block iq-card-stretch"},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title",staticStyle:{"display":"inline"}},[_vm._v(" Risk Curve ")]),_c('Tooltip',{attrs:{"placement":"top"}},[_c('img',{staticClass:"guide",attrs:{"src":require("../../assets/images/question.png"),"alt":"guide"}}),_c('span',{staticStyle:{"white-space":"normal"},attrs:{"slot":"content"},slot:"content"},[_vm._v("Shows AI's prediction of the death risk index for each patient visit.")])])]},proxy:true},{key:"headerAction",fn:function(){return [_c('div',{staticStyle:{"padding-right":"20px"}},[_c('a',{staticStyle:{"padding-right":"30px"},on:{"click":function($event){return _vm.showTruth(!_vm.showGroundTruth)}}},[_c('span',[_vm._v(_vm._s(_vm.showGroundTruth ? 'Hide' : 'Display')+" ground truth")])]),_c('a',{staticStyle:{"padding-right":"30px"},on:{"click":function($event){return _vm.showAll(!_vm.showValue)}}},[_c('span',[_vm._v(_vm._s(_vm.showValue ? 'Hide' : 'Display')+" values")])]),_c('a',{on:{"click":_vm.reset}},[_c('span',[_vm._v("Reset")])])])]},proxy:true},{key:"body",fn:function(){return [_c('div',{attrs:{"id":"patient-chart","onContextMenu":e => e.preventDefault()}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],style:(_vm.inputPos
            ? { top: _vm.inputPos[1] + 'px', left: _vm.inputPos[0] + 'px' }
            : { display: 'none' }),attrs:{"id":"patient-chart-input"},domProps:{"value":(_vm.inputVal)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.inputPos = undefined},function($event){if(!$event.shiftKey)return null;_vm.keyModifier = 0.1},function($event){if(!$event.altKey)return null;_vm.keyModifier = 0.01}],"keyup":[function($event){if(!$event.shiftKey)return null;_vm.keyModifier = 1},function($event){if(!$event.altKey)return null;_vm.keyModifier = 1}],"input":function($event){if($event.target.composing)return;_vm.inputVal=$event.target.value}}}),_c('div',{style:({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: 'unset'
        }),attrs:{"id":"patientDataChart"},on:{"contextmenu":function($event){$event.preventDefault();}}})])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }