<template>
  <div>
    <span v-for="value in item" :key="value">
      <span v-if="info[value]">
        <Tooltip placement="top">
          <b-button pill :pressed="selectedGraph.indexOf(value) !== -1" :ref="value" @click="click($event, value)"
            @mouseenter="mouseEnter($event, value)" @mouseleave="mouseLeave($event, value)"
            :style="{color: ch.get(value).midColor, borderColor: ch.get(value).midColor}" variant="outline-primary"
            class="mb-3 mr-1" id="selector-button">{{medicalCharChallenge[value]}}
          </b-button>
          <span slot="content" v-html="info[value]"></span>
        </Tooltip>
      </span>
      <span v-else>
        <b-button pill :pressed="selectedGraph.indexOf(value) !== -1" :ref="value" @click="click($event, value)"
          @mouseenter="mouseEnter($event, value)" @mouseleave="mouseLeave($event, value)"
          :style="{color: ch.get(value).midColor, borderColor: ch.get(value).midColor}" variant="outline-primary"
          class="mb-3 mr-1" id="selector-button">{{medicalCharChallenge[value]}}
        </b-button>
      </span>
    </span>
  </div>
</template>

<script>
  import { medicalCharChallenge } from '@/util/info'

  export default {
    name: 'patient-data-selector',
    props: {
      item: Array,
      info: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        type: [
          'outline-primary',
          'outline-secondary',
          'outline-success',
          'outline-danger',
          'outline-warning',
          'outline-info',
          'outline-dark'
        ],
        medicalCharChallenge: medicalCharChallenge
      }
    },
    mounted() {
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.challengeAnalyze
      },
      lab() {
        return this.$store.state.challengeLab
      },
      ch() {
        return this.$store.state.ch
      }
    },
    updated() {
      this.item.forEach((key) => {
        if (this.$refs[key]) {
          if (this.selectedGraph.indexOf(key) !== -1) {
            this.$refs[key][0].style.color = '#ffffff'
            this.$refs[key][0].style.background = this.ch.get(key).midColor
          } else {
            this.$refs[key][0].style.color = this.ch.get(key).midColor
            this.$refs[key][0].style.background = '#ffffff'
          }
        }
      })
    },
    methods: {
      mouseEnter(e, value) {
        if (this.selectedGraph.indexOf(value) === -1) {
          e.target.style.color = '#ffffff'
          e.target.style.background = this.ch.get(value).midColor
        }
      },
      mouseLeave(e, value) {
        if (this.selectedGraph.indexOf(value) === -1) {
          e.target.style.color = this.ch.get(value).midColor
          e.target.style.background = '#ffffff'
        }
      },
      click(e, value) {
        if (this.selectedGraph.indexOf(value) === -1) {
          e.target.style.color = '#ffffff'
          e.target.style.background = this.ch.get(value).midColor
          this.$store.commit('setSelectedGraph', [...this.selectedGraph, value])
        } else {
          e.target.style.color = this.ch.get(value).midColor
          e.target.style.background = '#ffffff'
          this.$store.commit('setSelectedGraph', this.selectedGraph.filter(a => a !== value))
        }
      }
    }
  }
</script>

<style scoped lang="less">
  #selector-button {
    color: #089bab;
    border-color: #089bab;

    &:hover {
      color: #ffffff;
      background: #089bab;
      border-color: #089bab;
    }

    &:active {
      color: #ffffff;
      background: #089bab;
      border-color: #089bab;
    }
  }
</style>
