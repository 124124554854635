export default {
    updateRecord (state, data) {
        state.record = data
    },
    updateSelected (state, data) {
        state.selected = data
    },
    changeStatus (state, data) {
        state.isReady = data
    }
}