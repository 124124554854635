import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Chat from './Chat/index'
import Analysis from './Analysis/index'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Chat,
    Analysis
  },
  state: {
    specialPatient: {
      pdid: 999,
      birthDate: null,
      age: null,
      gender: 1,
      death: 0,
      deathDate: null,
      deathAge: null,
      deathReason: null,
      deathReasonText: null,
      height: null,
      weight: null,
      originDisease: null,
      diabetes: 0,
      outcome: null,
      duplicate: 0,
      scr: null,
      heart_failure: null,
      lung_infect: null,
      chd: null,
      mi: null,
      ci: null,
      ch: null,
      amputation: null,
      urea: null,
      albumin: null,
      hgb: null,
      ca: null,
      p: null,
      pth: null,
      bmi: null,
      epi: null,
      sbp: null,
      dbp: null,
      death_number: null
    },
    specialLab: [
      {
        date: '2013-02-21',
        k: '4.5',
        na: '140.1',
        cl: '103.7',
        总蛋白: '71.9',
        alb: '41.9',
        球蛋白: '30.0',
        白球比例: '1.4',
        总胆红素: '19.4',
        直接胆红素: '3.0',
        间接胆红素: '16.4',
        丙氨酸氨基转移酶: '12.0',
        天冬氨酸氨基转移酶: '19.0',
        总胆汁酸: '5.4',
        谷氨酰转移酶: '14.0',
        碱性磷酸酶: '46.0',
        胆碱酯酶: '5619.0',
        urea: '4.6',
        cre: '79',
        尿酸: '344',
        ca: '2.33',
        p: '1.21',
        镁: '0.91',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.31',
        甘油三酯: '0.53',
        高密度脂蛋白胆固醇: '1.35',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.62',
        crp: '0.05',
        co2: '27.6',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '13.3',
        乳酸测定: '0.87',
        '尿素氮/肌酐': '58.2',
        pdid: 19940226,
        wbc: '6.04',
        hgb: '157',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '55'
      },
      {
        date: '2013-08-12',
        k: '4.2',
        na: '137.3',
        cl: '104.5',
        总蛋白: '67.2',
        alb: '39.5',
        球蛋白: '27.7',
        白球比例: '1.4',
        总胆红素: '11.0',
        直接胆红素: '4.8',
        间接胆红素: '6.2',
        丙氨酸氨基转移酶: '20.0',
        天冬氨酸氨基转移酶: '19.0',
        总胆汁酸: '5.4',
        谷氨酰转移酶: '14.0',
        碱性磷酸酶: '46.0',
        胆碱酯酶: '5619.0',
        urea: '4.69',
        cre: '80',
        尿酸: '421',
        ca: '2.3',
        p: '1.34',
        镁: '0.86',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.37',
        甘油三酯: '0.61',
        高密度脂蛋白胆固醇: '1.35',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.95',
        crp: '0.05',
        co2: '26.5',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '10.5',
        乳酸测定: '0.87',
        '尿素氮/肌酐': '58.6',
        pdid: 19940226,
        wbc: '6.04',
        hgb: '157',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '55.3333'
      },
      {
        date: '2014-02-19',
        k: '4.0',
        na: '139.8',
        cl: '104.3',
        总蛋白: '72.6',
        alb: '45.2',
        球蛋白: '27.4',
        白球比例: '1.6',
        总胆红素: '17.1',
        直接胆红素: '7.4',
        间接胆红素: '9.7',
        丙氨酸氨基转移酶: '19.0',
        天冬氨酸氨基转移酶: '19.0',
        总胆汁酸: '4.4',
        谷氨酰转移酶: '13.0',
        碱性磷酸酶: '41.0',
        胆碱酯酶: '5619.0',
        urea: '4.63',
        cre: '87',
        尿酸: '289',
        ca: '2.42',
        p: '1.32',
        镁: '0.94',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.21',
        甘油三酯: '0.47',
        高密度脂蛋白胆固醇: '1.22',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.83',
        crp: '0.05',
        co2: '27.7',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.8',
        乳酸测定: '2.5',
        '尿素氮/肌酐': '53.2',
        pdid: 19940226,
        wbc: '6.04',
        hgb: '157',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '55.6667'
      },
      {
        date: '2015-08-30',
        k: '4.55',
        na: '140.0',
        cl: '104.6',
        总蛋白: '73.4',
        alb: '47.1',
        球蛋白: '26.3',
        白球比例: '1.8',
        总胆红素: '20.9',
        直接胆红素: '7.1',
        间接胆红素: '13.8',
        丙氨酸氨基转移酶: '12.0',
        天冬氨酸氨基转移酶: '14.0',
        总胆汁酸: '1.7',
        谷氨酰转移酶: '12.0',
        碱性磷酸酶: '43.0',
        胆碱酯酶: '5619.0',
        urea: '5.08',
        cre: '92',
        尿酸: '363',
        ca: '2.38',
        p: '1.3',
        镁: '0.89',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.63',
        甘油三酯: '0.63',
        高密度脂蛋白胆固醇: '1.1775',
        低密度脂蛋白胆固醇: '1.87',
        glu: '5.05',
        crp: '0.05',
        co2: '28.4',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '6.04',
        hgb: '157',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '56'
      },
      {
        date: '2018-10-17',
        k: '4.64',
        na: '137.9',
        cl: '99.9',
        总蛋白: '69.4',
        alb: '43.5',
        球蛋白: '25.9',
        白球比例: '1.68',
        总胆红素: '33.9',
        直接胆红素: '5.5',
        间接胆红素: '28.4',
        丙氨酸氨基转移酶: '18.0',
        天冬氨酸氨基转移酶: '17.0',
        总胆汁酸: '2.1',
        谷氨酰转移酶: '13.0',
        碱性磷酸酶: '44.0',
        胆碱酯酶: '5498.9',
        urea: '6.35',
        cre: '99',
        尿酸: '372',
        ca: '2.39',
        p: '1.16',
        镁: '0.9',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.4433',
        甘油三酯: '0.62',
        高密度脂蛋白胆固醇: '1.135',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.52',
        crp: '0.05',
        co2: '26.3',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '6.04',
        hgb: '157',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '57.5'
      },
      {
        date: '2018-10-23',
        k: '4.05',
        na: '139.0',
        cl: '106.0',
        总蛋白: '73.25',
        alb: '44.45',
        球蛋白: '28.95',
        白球比例: '1.6467',
        总胆红素: '23.05',
        直接胆红素: '3.6',
        间接胆红素: '19.45',
        丙氨酸氨基转移酶: '15.5',
        天冬氨酸氨基转移酶: '17.5',
        总胆汁酸: '2.0',
        谷氨酰转移酶: '12.25',
        碱性磷酸酶: '43.5',
        胆碱酯酶: '5594.55',
        urea: '4.8',
        cre: '98',
        尿酸: '371',
        ca: '2.31',
        p: '1.37',
        镁: '0.8625',
        估算的肾小球滤过率: '93.0',
        总胆固醇: '3.2567',
        甘油三酯: '0.61',
        高密度脂蛋白胆固醇: '1.0925',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.56',
        crp: '0.05',
        co2: '24.2',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '6.705',
        hgb: '156',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '56'
      },
      {
        date: '2020-01-16',
        k: '4.55',
        na: '140.0',
        cl: '106.0',
        总蛋白: '77.1',
        alb: '45.4',
        球蛋白: '32.0',
        白球比例: '1.6133',
        总胆红素: '12.2',
        直接胆红素: '1.7',
        间接胆红素: '10.5',
        丙氨酸氨基转移酶: '13.0',
        天冬氨酸氨基转移酶: '18.0',
        总胆汁酸: '1.9',
        谷氨酰转移酶: '11.5',
        碱性磷酸酶: '43.0',
        胆碱酯酶: '5690.2',
        urea: '3.4',
        cre: '99',
        尿酸: '419',
        ca: '2.3',
        p: '0.99',
        镁: '0.825',
        估算的肾小球滤过率: '91.0',
        总胆固醇: '3.07',
        甘油三酯: '0.6',
        高密度脂蛋白胆固醇: '1.05',
        低密度脂蛋白胆固醇: '1.87',
        glu: '4.6',
        crp: '0.05',
        co2: '22.3',
        血清胱抑素c: '0.76',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '7.37',
        hgb: '155',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '58'
      },
      {
        date: '2020-06-10',
        k: '4.2',
        na: '136.0',
        cl: '100.0',
        总蛋白: '75.3',
        alb: '45.9',
        球蛋白: '29.0',
        白球比例: '1.58',
        总胆红素: '23.3',
        直接胆红素: '2.1',
        间接胆红素: '21.2',
        丙氨酸氨基转移酶: '14.0',
        天冬氨酸氨基转移酶: '16.0',
        总胆汁酸: '1.8',
        谷氨酰转移酶: '10.75',
        碱性磷酸酶: '42.5',
        胆碱酯酶: '5785.85',
        urea: '5.2',
        cre: '103',
        尿酸: '416',
        ca: '2.29',
        p: '1.31',
        镁: '0.7875',
        估算的肾小球滤过率: '87.0',
        总胆固醇: '3.73',
        甘油三酯: '0.78',
        高密度脂蛋白胆固醇: '1.37',
        低密度脂蛋白胆固醇: '2.11',
        glu: '4.8',
        crp: '0.05',
        co2: '25.3',
        血清胱抑素c: '0.85',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '5.39',
        hgb: '167',
        sys: '105',
        dia: '70',
        amount: '3435.23214518674882',
        weight: '59'
      },
      {
        date: '2020-07-06',
        k: '3.87',
        na: '139.6',
        cl: '103.2',
        总蛋白: '71.2',
        alb: '44.5',
        球蛋白: '26.7',
        白球比例: '1.67',
        总胆红素: '15.8',
        直接胆红素: '1.9',
        间接胆红素: '13.9',
        丙氨酸氨基转移酶: '19.0',
        天冬氨酸氨基转移酶: '21.0',
        总胆汁酸: '1.7',
        谷氨酰转移酶: '10.0',
        碱性磷酸酶: '42.0',
        胆碱酯酶: '5881.5',
        urea: '4.13',
        cre: '103',
        尿酸: '377',
        ca: '2.14',
        p: '1.3',
        镁: '0.85',
        估算的肾小球滤过率: '86.5',
        总胆固醇: '3.455',
        甘油三酯: '0.84',
        高密度脂蛋白胆固醇: '1.3',
        低密度脂蛋白胆固醇: '1.905',
        glu: '4.0',
        crp: '0.05',
        co2: '24.7',
        血清胱抑素c: '0.82',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '5.69',
        hgb: '161',
        sys: '119',
        dia: '67',
        amount: '3435.23214518674882',
        weight: '59'
      },
      {
        date: '2020-08-24',
        k: '3.87',
        na: '140.0',
        cl: '105.0',
        总蛋白: '72.3',
        alb: '42.9',
        球蛋白: '39.0',
        白球比例: '1.67',
        总胆红素: '32.4',
        直接胆红素: '2.6',
        间接胆红素: '13.9',
        丙氨酸氨基转移酶: '21.0',
        天冬氨酸氨基转移酶: '21.0',
        总胆汁酸: '1.7',
        谷氨酰转移酶: '10.0',
        碱性磷酸酶: '42.0',
        胆碱酯酶: '5881.5',
        urea: '3.8',
        cre: '104',
        尿酸: '471',
        ca: '2.19',
        p: '1.16',
        镁: '0.85',
        估算的肾小球滤过率: '86.0',
        总胆固醇: '3.18',
        甘油三酯: '0.9',
        高密度脂蛋白胆固醇: '1.23',
        低密度脂蛋白胆固醇: '1.7',
        glu: '3.2',
        crp: '0.05',
        co2: '24.1',
        血清胱抑素c: '0.79',
        β2微球蛋白: '1.5',
        补体c1q: '105',
        糖化血红蛋白: '5.3',
        阴离子隙: '11.6',
        乳酸测定: '1.29',
        '尿素氮/肌酐': '55.2',
        pdid: 19940226,
        wbc: '5.69',
        hgb: '161',
        sys: '119',
        dia: '73',
        amount: '3435.23214518674882',
        weight: '59'
      }
    ],
    yearMinus: 0,
    specialModifiedLab: [],
    lab: [],
    patient: {},
    events: [],
    covidLab: [],
    covidFixedLab: [],
    covidPatient: {},
    covidTime: [],
    covidGroundTruthT12: [],
    covidAnalyze: {},
    challengeLab: [],
    challengePatient: {},
    challengeX: [],
    challengeY: [],
    challengeDemo: {},
    modifiedLab: [],
    analyze: {},
    analyzeRisk: {},
    analyze1: {},
    analyze2: {},
    analyze3: {},
    analyze4: {},
    complicationsAnalyze: {},
    challengeAnalyze: {},
    originalAnalyze: {},
    adviceOnRiskThreshold: {},
    adviceOnRiskThresholdSub: {},
    adviceOnRisk: {},
    adviceOnIdx: [],
    selectedDate: [],
    selectedGraph: [],
    ch: {},
    advice: {},
    clusterTopPdid: [],
    similarPatients: [],
    computingAdvice: false,
    showValue: false,
    showGroundTruth: false,
    showEvents: false,
    top2: [],
    showLine: false,
    showRisk: true,
    deathReason: [],
    patientsBasicDetails: [],
    quesId: 1
  },
  mutations: {
    setQuesId(state) {
      if (state.quesId === 20) {
        state.quesId = 1
      } else {
        state.quesId = state.quesId + 1
      }
    },
    setLab(state, msg) {
      if (parseInt(msg[0].date.slice(0, 4)) > 1900) {
        state.lab = msg
      }
    },
    setShowEvents(state, msg) {
      state.showEvents = msg
    },
    setEvents(state, msg) {
      state.events = msg
    },
    setCovidLab(state, msg) {
      state.covidLab = msg
    },
    setCovidGroundTruthT12(state, msg) {
      state.covidGroundTruthT12 = msg
    },
    setCovidFixedLab(state, msg) {
      state.covidFixedLab = msg
    },
    setCovidPatient(state, msg) {
      state.covidPatient = msg
    },
    setCovidTime(state, msg) {
      state.covidTime = msg
    },
    setCovidAnalyze(state, msg) {
      state.covidAnalyze = msg
    },
    setChallengeLab(state, msg) {
      state.challengeLab = msg
    },
    setChallengePatient(state, msg) {
      state.challengePatient = msg
    },
    setChallengeX(state, msg) {
      state.challengeX = msg
    },
    setChallengeY(state, msg) {
      state.challengeY = msg
    },
    setChallengeDemo(state, msg) {
      state.challengeDemo = msg
    },
    setAdviceOnIdx(state, msg) {
      state.adviceOnIdx = msg
    },
    setAdviceOnRisk(state, msg) {
      state.adviceOnRisk = msg
    },
    setAdviceOnRiskThreshold(state, msg) {
      state.adviceOnRiskThreshold = msg
    },
    setAdviceOnRiskThresholdSub(state, msg) {
      state.adviceOnRiskThresholdSub = msg
    },
    setSimilarPatients(state, msg) {
      state.similarPatients = msg
    },
    setClusterTopPdid(state, msg) {
      state.clusterTopPdid = msg
    },
    setPatient(state, msg) {
      state.patient = msg
    },
    setModifiedLab(state, msg) {
      if (parseInt(msg[0].date.slice(0, 4)) > 1900) {
        state.modifiedLab = msg
      }
    },
    setYearMinus(state, msg) {
      state.yearMinus = msg
    },
    setAnalyze(state, msg) {
      state.analyze = msg
    },
    setAnalyzeRisk(state, msg) {
      state.analyzeRisk = msg
    },
    setComplicationsAnalyze(state, msg) {
      state.complicationsAnalyze = msg
    },
    setAnalyze1(state, msg) {
      state.analyze1 = msg
    },
    setAnalyze2(state, msg) {
      state.analyze2 = msg
    },
    setAnalyze3(state, msg) {
      state.analyze3 = msg
    },
    setAnalyze4(state, msg) {
      state.analyze4 = msg
    },
    setChallengeAnalyze(state, msg) {
      state.challengeAnalyze = msg
    },
    setSelectedDate(state, msg) {
      state.selectedDate = msg
    },
    setSelectedGraph(state, msg) {
      state.selectedGraph = msg
    },
    setCh(state, msg) {
      state.ch = msg
    },
    setAdvice(state, msg) {
      state.advice = msg
    },
    setComputingAdvice(state, msg) {
      state.computingAdvice = Object.assign({}, msg)
    },
    setShowValue(state, msg) {
      state.showValue = msg
    },
    setShowGroundTruth(state, msg) {
      state.showGroundTruth = msg
    },
    setOriginalAnalyze(state, msg) {
      state.originalAnalyze = msg
    },
    setTop2(state, msg) {
      state.top2 = msg
    },
    setShowLine(state, msg) {
      state.showLine = msg
    },
    setShowRisk(state, msg) {
      state.showRisk = msg
    },
    setDeathReason(state, msg) {
      state.deathReason = msg
    },
    setSpecialModifiedLab(state, msg) {
      state.specialModifiedLab = msg
    },
    setPatientsBasicDetails(state, load) {
      state.patientsBasicDetails = load
    }
  },
  actions: {},
  getters: {},
  strict: debug
})
