<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="5">
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img v-if="patient.Gender === '0.0'" src="../../assets/images/user/11.png" alt="profile-img"
                    class="avatar-130 img-fluid">
                  <img v-else src="../../assets/images/user/19.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">ID</h6>
                    <h4>{{$route.params.patientId}}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">Gender</h6>
                    <h4 v-if="patient.Gender === '0.0'">female</h4>
                    <h4 v-else>male</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">Age</h6>
                    <h4 v-if="patient.Age === undefined || patient.Age === '1.0' || patient.Age === '0.0'">No data
                    </h4>
                    <h4 v-else>{{patient.Age}}</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a data-icon="T" class="icon" v-if="display === false"
                    @click="display = !display; $store.commit('setShowLine', true)">
                    <span>more</span>
                  </a>
                  <a data-icon="W" class="icon" v-else
                    @click="display = !display ; $store.commit('setShowLine', false)">
                    <span>hide</span>
                  </a>
                </div>
                <b-collapse id="collapse-0" class="mb-2" v-model="display">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">ICULOS</p>
                        <h5 v-if="patient.iculos === undefined">No data</h5>
                        <div v-else>
                          <h5>{{patient.iculos}}</h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">Unit1</p>
                        <h5 v-if="patient.Unit1 === undefined">No data</h5>
                        <div v-else>
                          <h5>{{patient.Unit1}}</h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">Unit2</p>
                        <h5 v-if="patient.Unit2 === undefined">No data</h5>
                        <div v-else>
                          <h5>{{patient.Unit2}}</h5>
                        </div>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- patient card end -->
        <!-- <b-col>
          <AIObservation :analyzeAdvice="analyzeAdvice" :adviceOnRiskThreshold="adviceOnRiskThreshold"
            :adviceOnRisk="adviceOnRisk" />
        </b-col> -->
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Indicators</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>Clear selected</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4 class="card-title text-primary" v-show="patient_data_selector.current_important.length">Current
                Important Indicators
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.current_important.length">
              <ChallengePatientDataSelector :item="patient_data_selector.current_important"
                :info="patient_data_selector.info">
              </ChallengePatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.history_important.length">
              <h4 class="card-title text-primary">Historically Important Indicators (pay attention at the same time)
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.history_important.length">
              <ChallengePatientDataSelector :item="patient_data_selector.history_important"
                :info="patient_data_selector.info">
              </ChallengePatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.other.length">
              <div style="padding-bottom:20px;text-align:center">
                <a data-icon="T" class="icon" v-if="display1 === false" @click="display1 = !display1">
                  <span> more</span>
                </a>
                <a data-icon="W" class="icon" v-else @click="display1 = !display1">
                  <span> hide</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.other.length">
              <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                <h4 class="card-title text-primary">Other Indicators</h4>
                <ChallengePatientDataSelector :item="patient_data_selector.other"></ChallengePatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <ChallengePatientDataChart
          v-if=" analyze !==undefined && (selectedGraph.length>= 1 || analyze.predict !== undefined)" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import IqCard from '../../components/xray/cards/iq-card'
  import { xray } from '@/config/pluginInit'
  import SettingsHelper from '../../util/SettingsHelper'
  import APIHelper from '@/util/APIHelper'
  import { medicalCharChallenge } from '@/util/info'
  import ChallengePatientDataChart from '../Charts/ChallengePatientDataChart'
  import ChallengePatientDataSelector from '../Charts/ChallengePatientDataSelector'
  import ColorHelper from '../../util/ColorHelper'
  // import AIObservation from '../../components/Advice/AIObservation'

  const body = document.getElementsByTagName('body')
  export default {
    name: 'Dashboard4',
    components: {
      IqCard,
      ChallengePatientDataChart: ChallengePatientDataChart,
      ChallengePatientDataSelector
      // AIObservation
    },
    inject: ['reload'],
    data() {
      return {
        bg_color: [
          'bg-warning',
          'bg-primary',
          'bg-info',
          'bg-danger',
          'bg-success',
          'bg-secondary'
        ],
        hoverIndex: -1, // 菜单hover索引
        activeLinkId: 0, // 当前激活的菜单id
        message: '默认值',
        genderRatio: 0,
        display: false,
        display1: false,
        dialog: false,
        top2: undefined,
        urlAnalyzeApi: undefined,
        settings: undefined,
        getAnalyzeDataTimer: undefined,
        getAdviceTimer: undefined,
        similarPatientsAttn: [],
        deathReason: [],
        medicalCharChallenge: medicalCharChallenge,
        patient_data_selector: {
          current_important: [],
          history_important: [],
          other: [],
          item: [],
          part: [0.2, 0.5, 1],
          info: {}
        },
        analyzeAdvice: undefined,
        originDiseaseChart: {
          type: 'radial-pie',
          height: 280,
          data: []
        },
        clusterInfoKey: 0
      }
    },
    created() {
      if (!this.$route.params.patientId) {
        this.$router.replace('/107035')
      }
      this.urlAnalyzeApi = (new URL(window.location.href)).searchParams.get('api')
      if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
      this.settings = SettingsHelper.load()
      this.$store.commit('setComputingAdvice', { pending: true })
      Promise.all([APIHelper.getChallengePatientById(this.$route.params.patientId), APIHelper.getChallengeLabById(this.$route.params.patientId), APIHelper.getChallengeXById(this.$route.params.patientId), APIHelper.getChallengeYById(this.$route.params.patientId)])
        .then(([patient, lab, x, y]) => {
          this.$store.commit('setChallengeLab', lab)
          this.$store.commit('setChallengeX', x)
          this.$store.commit('setChallengeY', y)
          this.$store.commit('setChallengePatient', patient)
        })
      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }
    },
    mounted() {
      xray.index()
      body[0].classList.add('sidebar-main-menu')
    },
    destroyed() {
      body[0].classList.remove('sidebar-main-menu')
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.challengeAnalyze
      },
      challengeX() {
        return this.$store.state.challengeX
      },
      challengeY() {
        return this.$store.state.challengeY
      },
      lab() {
        return this.$store.state.challengeLab
      },
      patient() {
        return this.$store.state.challengePatient
      },
      analyzeData() {
        const { challengeX } = this
        return {
          challengeX
        }
      },
      ch() {
        return this.$store.state.ch
      },
      showLine() {
        return this.$store.state.showLine
      }
    },
    watch: {
      analyzeData() {
        this.getAnalyzeData()
      },
      '$route'(to, from) { // 监听路由是否变化
        if (to.name === 'dashboard.home-challenge') { // 跳转到哪个页面
          this.reload()
        }
      }
    },
    methods: {
      enter: function () {
        this.color = 'blue'
      },
      leave: function () {
        this.color = 'red'
      },
      getAnalyzeData() {
        clearTimeout(this.getAnalyzeDataTimer)
        this.getAnalyzeDataTimer = setTimeout(() => this.challengeX && APIHelper.getChallengeAnalyze(
          this.challengeX
        ).then(result => {
          this.$store.commit('setChallengeAnalyze', result)
          if (this.top2 === undefined) {
            this.$store.commit('setOriginalAnalyze', result)
            this.getRank()
          }
        }), 500)
      },
      change(a) {
        const b = parseFloat(a)
        return b.toFixed(1)
      },
      getRank() {
        if (this.analyze.attention) {
          const lastAttention = this.analyze.attention.slice(-1)[0]
          this.patient_data_selector.item = Object.keys(lastAttention)
          let arr = []
          for (let [key, value] of Object.entries(lastAttention)) {
            if (value >= 0.22) {
              arr.push(key)
            }
          }
          let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
            return b[1] - a[1]
          })
          this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
          let obj = {}
          let top1 = []
          let arr1 = []
          const length = this.analyze.attention.length
          this.analyze.attention.forEach((item, i) => {
            let max = 0
            Object.entries(item).forEach(([key, value]) => {
              if (value > max) {
                max = value
                top1[i] = key
              }
            })
          })
          Object.entries(obj).forEach(item => {
            if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
              arr1.push(item[0])
            }
          })
          this.patient_data_selector.current_important = arr
          this.patient_data_selector.history_important = arr1.filter(item => arr.indexOf(item) === -1)
          this.patient_data_selector.other = this.patient_data_selector.item.filter(
            item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
          )
          if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length >= 2) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.current_important[1]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1 &&
            this.patient_data_selector.history_important && this.patient_data_selector.history_important.length >= 1) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.history_important[0]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1) {
            this.$store.commit('setSelectedGraph', [this.patient_data_selector.current_important[0]])
          }
          this.$store.commit('setSelectedDate', [])
        }
      },
      toPatientPage(a) {
        this.$router.push('/' + a.pdid)
      }
    }
  }
</script>

<style scoped>
  .link-item-hover {
    background-color: #f5f7fa;
  }

  .link-item-active {
    color: #fff;
    background-color: #b3cae4;
  }

  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }
</style>
