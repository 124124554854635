<template>
  <iq-card class-name="iq-card-block iq-card-stretch">
    <template v-slot:headerTitle>
      <h4 class="card-title" style="display: inline">
        Risk Curve
        <!--                  <i class="las la-exclamation-circle" style="margin:10px" @click.stop=" dialog = true"></i>-->
      </h4>
      <Tooltip placement="top">
        <img src="../../assets/images/question.png" alt="guide" class="guide" />
        <span slot="content" style="white-space: normal"
          >Shows AI's prediction of the death risk index for each patient
          visit.</span
        >
      </Tooltip>
      <!--            <template>-->
      <!--              <v-row justify="center">-->
      <!--                <v-dialog v-model="dialog" width="600px">-->
      <!--                  <v-card>-->
      <!--                    <v-card-title>-->
      <!--                      <span class="headline">如何使用</span>-->
      <!--                    </v-card-title>-->
      <!--                    <v-card-text></v-card-text>-->
      <!--                    <v-card-actions>-->
      <!--                      <v-spacer></v-spacer>-->
      <!--                      <v-btn color="green darken-1" text @click="dialog = false">关闭</v-btn>-->
      <!--                    </v-card-actions>-->
      <!--                  </v-card>-->
      <!--                </v-dialog>-->
      <!--              </v-row>-->
      <!--            </template>-->
    </template>
    <template v-slot:headerAction>
      <div style="padding-right: 20px">
        <a @click="showTruth(!showGroundTruth)" style="padding-right: 30px">
          <span>{{ showGroundTruth ? 'Hide' : 'Display' }} ground truth</span>
        </a>
        <a @click="showAll(!showValue)" style="padding-right: 30px">
          <span>{{ showValue ? 'Hide' : 'Display' }} values</span>
        </a>
        <a @click="reset">
          <span>Reset</span>
        </a>
      </div>
    </template>
    <template v-slot:body>
      <div id="patient-chart" :onContextMenu="e => e.preventDefault()">
        <input
          id="patient-chart-input"
          v-model="inputVal"
          @keydown.esc="inputPos = undefined"
          @keydown.shift="keyModifier = 0.1"
          @keydown.alt="keyModifier = 0.01"
          @keyup.shift="keyModifier = 1"
          @keyup.alt="keyModifier = 1"
          :style="
            inputPos
              ? { top: inputPos[1] + 'px', left: inputPos[0] + 'px' }
              : { display: 'none' }
          "
        />
        <div
          id="patientDataChart"
          @contextmenu.prevent
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 'unset'
          }"
        ></div>
      </div>
    </template>
  </iq-card>
</template>

<script>
import { medicalCharChallenge, medicalCharChallengeShort } from '@/util/info'

export default {
  name: 'PatientDataChart',
  data() {
    return {
      echart: undefined,
      inputPos: undefined,
      inputVal: undefined,
      keyModifier: 1
    }
  },
  computed: {
    data() {
      const { analyze, lab, challengeY, showGroundTruth } = this
      return {
        analyze,
        lab,
        challengeY,
        showGroundTruth
      }
    },
    selected() {
      const { selectedGraph } = this
      return {
        selectedGraph
      }
    },
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    patient() {
      return this.$store.state.challengePatient
    },
    challengeY() {
      return this.$store.state.challengeY
    },
    analyze() {
      return this.$store.state.challengeAnalyze
    },
    lab() {
      return this.$store.state.challengeLab
    },
    ch() {
      return this.$store.state.ch
    },
    showValue() {
      return this.$store.state.showValue
    },
    showGroundTruth() {
      return this.$store.state.showGroundTruth
    },
    showLine() {
      return this.$store.state.showLine
    },
    predict() {
      let obj = {
        type: 'line',
        name: 'Risk',
        data: this.lab.map((event, i) => [
          event.id,
          this.analyze.predict[i] * 100
        ]),
        yAxisIndex: 0,
        lineStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: '#ff0844'
              },
              {
                offset: 1,
                color: '#ffb199'
              }
            ],
            global: false
          },
          opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: '#ff0844'
              },
              {
                offset: 1,
                color: '#ffb199'
              }
            ],
            global: false
          },
          opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
        },
        symbol: 'none',
        smooth: 0.2
      }
      if (1 || !this.selectedGraph.length) {
        if (obj.markLine) {
          obj.markLine.data.push({
            silent: true, // 鼠标悬停事件  true没有，false有
            lineStyle: {
              // 警戒线的样式  ，虚实  颜色
              type: 'dashed',
              color: '#FFB199',
              width: 2
            },
            label: {
              show: false
            },
            yAxis: 50
          })
        } else {
          obj.markLine = {
            symbol: 'none', // 去掉警戒线最后面的箭头
            silent: true,
            data: [
              {
                silent: true, // 鼠标悬停事件  true没有，false有
                lineStyle: {
                  // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: '#FFB199',
                  width: 2
                },
                label: {
                  show: false
                },
                yAxis: 50
              }
            ]
          }
        }
      }
      return obj
    },
    series() {
      return {
        series: [
          this.analyze.predict && this.predict,
          this.showGroundTruth && {
            type: 'line',
            name: 'groundTurth',
            data: this.challengeY.map((value, i) => [
              i + this.lab[0].id,
              value.label * 100
            ]),
            symbol: 'circle',
            symbolSize: 8
          },
          ...this.selectedGraph.map((key, i) => ({
            type: 'line',
            name: medicalCharChallenge[key],
            data: this.lab.map(event => [event.id, event[key]]),
            yAxisIndex: i + 1,
            symbol: (value, params) =>
              this.analyze.attention &&
              this.analyze.attention[params.dataIndex][key] > 0
                ? 'circle'
                : 'emptyCircle',
            symbolSize: (value, params) =>
              this.analyze.attention
                ? this.analyze.attention[params.dataIndex][key] * 12 + 4
                : 8,
            itemStyle: {
              color: this.ch.get(key).midColor
            },
            lineStyle: {
              color: this.ch.get(key)
            },
            emphasis: {
              lineStyle: {
                width: 2
              }
            }
            // markPoint: {
            //     data:modifiedLab.map((event,index)=>({
            //         coord:[event.date,event[key]],
            //         value:analyze.attention[index][key].toFixed(2)
            //     })).filter(o=>o.value>0),
            //     //data:[{coord:['2007-08-21',0],value:300}]
            // },
          })),
          this.analyze.stage && {
            type: 'line',
            name: 'Stage Variation',
            data: this.modifiedLab.map((event, i) => [
              event.date,
              this.analyze.stage[i]
            ]),
            yAxisIndex: this.selectedGraph.length + 2,
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#1e3c72'
                  },
                  {
                    offset: 1,
                    color: '#2a5298'
                  }
                ],
                global: false
              },
              opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#1e3c72'
                  },
                  {
                    offset: 1,
                    color: '#2a5298'
                  }
                ],
                global: false
              },
              opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
            },
            symbol: 'none',
            smooth: 0.2
          }
        ]
      }
    }
  },
  watch: {
    data() {
      this.echart.setOption(this.series)
      this.setChart()
    },
    selected() {
      this.setChart()
    },
    showLine() {
      this.echart.setOption(this.series)
    },
    lab() {
      this.$store.commit('setModifiedLab', this.lab)
    }
  },
  created() {},
  mounted() {
    this.echart = this.$echarts.init(
      document.getElementById('patientDataChart')
    )
    this.echart.getZr().on('click', e => {
      if (!e.target) this.inputPos = undefined
    })
    this.setChart()
    window.onresize = () => {
      this.echart.resize()
    }
  },
  destroyed() {
    window.onresize = null
  },
  methods: {
    showAll(value) {
      this.$store.commit('setShowValue', value)
      // this.$store.commit('setSelectedGraph', [])
    },
    showTruth(value) {
      this.$store.commit('setShowGroundTruth', value)
    },
    reset() {
      // this.$store.commit('setSelectedDate', [])
      this.$store.commit('setSelectedGraph', [])
    },
    setChart() {
      this.echart.setOption(
        {
          animation: true,
          xAxis: [
            {
              type: 'time',
              show: false,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowBlur: 2
            }
          ],
          yAxis: [
            {
              name: 'Risk',
              type: 'value',
              show: this.analyze.predict,
              min: 0,
              max: 100,
              offset: 0,
              axisLine: {
                show: true
              }
            },
            ...this.selectedGraph.map((key, i) => ({
              name: medicalCharChallengeShort[this.selectedGraph[i]],
              type: 'value',
              min: key === 'Temp' ? 30 : 0,
              axisLine: {
                show: true,
                lineStyle: {
                  color: this.ch.get(key).midColor
                }
              },
              axisLabel: {
                color: '#000'
              },
              nameTextStyle: {
                color: '#000'
              },
              offset: i <= 0 ? 0 : i * 48,
              position: i === -1 ? 'left' : 'right',
              scale: true // 设置y轴最小值自适应
            })),
            {
              name: 'Indicators',
              type: 'value',
              show: this.selectedGraph.length === 0 && this.analyze.stage,
              offset: 0,
              position: 'right'
            }
          ],
          grid: {
            show: false,
            borderWidth: 0,
            backgroundColor: '#fff',
            top: 64,
            left: 48,
            right:
              96 +
              (this.selectedGraph.length > 2
                ? this.selectedGraph.length * 48 - 96
                : 0),
            bottom: 32
          },
          tooltip: {
            trigger: 'axis',
            position: function(point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              let x = 0 // x坐标位置
              let y = 0 // y坐标位置
              // 当前鼠标位置
              let pointX = point[0]
              let pointY = point[1]
              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];
              // 提示框大小
              let boxWidth = size.contentSize[0]
              let boxHeight = size.contentSize[1]
              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = pointX + 10
              } else {
                // 左边放的下
                x = pointX - boxWidth - 10
              }
              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5
              } else {
                // 上边放得下
                y = pointY - boxHeight
              }
              return [x, y]
            },
            formatter: params => {
              // console.log(params)
              const getCircle = echartColor => `<span style="
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: ${echartColor.midColor};
"> </span>`
              const index = params[0].dataIndex
              const startId = this.lab[0].id
              let i = params[0].value[0] - startId + 1
              const unit = ['st', 'nd', 'rd']
              const valueContent = params
                .map(
                  o =>
                    `<div>${o.marker}${o.seriesName}：${Number(
                      o.value[1]
                    ).toFixed(2)}</div>`
                )
                .join('')
              let attentionContent
              if (this.showValue) {
                attentionContent = this.analyze.attention
                  ? Object.entries(this.analyze.attention[index])
                      .filter(o => o[1] >= 0)
                      .sort((a, b) => -a[1] + b[1])
                      .map(
                        ([k, v]) =>
                          `<div>${getCircle(this.ch.get(k))}${
                            medicalCharChallenge[k]
                          }：${(v * 100).toFixed(1)}% (${Number(
                            this.lab[index][k]
                          ).toFixed(1)})</div>`
                      )
                      .join('')
                  : undefined
              } else {
                attentionContent = this.analyze.attention
                  ? Object.entries(this.analyze.attention[index])
                      .filter(o => o[1] > 0)
                      .sort((a, b) => -a[1] + b[1])
                      .map(
                        ([k, v]) =>
                          `<div>${getCircle(this.ch.get(k))}${
                            medicalCharChallenge[k]
                          }：${(v * 100).toFixed(1)}%</div>`
                      )
                      .join('')
                  : undefined
              }
              return `<div><div><b>${
                i <= 3 ? i + unit[i - 1] : i + 'th'
              } visit</b></div>${valueContent}<div>${
                attentionContent
                  ? `<b>Attention</b></div>${attentionContent}</div>`
                  : ''
              }`
            }
          }
        },
        true
      )
      this.echart.setOption(this.series)
      this.echart.setOption({
        graphic: this.echart
          ? this.selectedGraph
              .map((key, selectedIndex) =>
                this.lab.map((event, labIndex) => ({
                  type: 'circle',
                  shape: { r: 8 },
                  position: [
                    this.echart.convertToPixel({ xAxisIndex: 0 }, event.id),
                    this.echart.convertToPixel(
                      { yAxisIndex: selectedIndex + 1 },
                      event[key]
                    )
                  ],
                  draggable: true,
                  invisible: true,
                  z: 100,
                  cursor: 'n-resize',
                  onmouseup: e => {
                    if (e.event.button === 2) {
                      this.inputPos = [e.offsetX + 10, e.offsetY - 40]
                      this.inputVal = parseFloat(this.lab[labIndex][key])
                      this.event = event
                      this.labIndex = labIndex
                    }
                  }
                }))
              )
              .reduce((obj, cur) => [...obj, ...cur], [])
          : undefined
      })
    }
  }
}
</script>

<style scoped lang="less">
.guide {
  width: 16px;
  padding-left: 2px;
  padding-bottom: 5px;
  cursor: pointer;
}

#patient-chart {
  /*width: 100%;*/
  /*height: 0;*/
  position: relative;
  padding-top: 40%;

  &-input {
    position: absolute;
    width: 100px;
    height: 32px;
    border: 1px solid #00000026;
    border-radius: 4px;
    z-index: 100001;
    padding: 0 8px;
  }

  &-input:focus {
    outline: none;
  }
}
</style>
