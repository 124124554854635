<template>
  <div class="iq-sidebar">
    <div
      class="iq-sidebar-logo d-flex justify-content-between"
      style="padding-left: 10px"
    >
      <router-link :to="homeURL">
        <img :src="webLogo" class="img-fluid" alt="logo" style="height:62px" />
        <span style="margin-top:8px">{{ $t('sidebar.title') }}</span>
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="iq-sidebar-logo d-flex justify-content-between" style="padding-left: 15px">
      <a href="https://www.pku.edu.cn/">
        <img :src="logo" class="img-fluid" alt="logo" style="height:48px">
        <span style="font-size: 18px">北京大学</span>
      </a>
    </div>
    <div class="iq-sidebar-logo d-flex justify-content-between" style="padding-top: 10px;padding-left: 15px">
      <a href="https://www.puh3.net.cn/">
        <img :src="logo2" class="img-fluid" alt="logo" style="height:40px">
        <span style="font-size: 18px">北医三院</span>
      </a>
    </div> -->
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import List from '../menus/ListStyle1'

export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
    items: { type: Array },
    logo: {
      type: String,
      default: require('../../../assets/images/pku-2.png')
    },
    logo2: {
      type: String,
      default: require('../../../assets/images/pkuHospital-2.png')
    },
    webLogo: {
      type: String,
      default: require('../../../assets/images/newLogo.png')
    },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  methods: {
    miniSidebar() {
      this.$emit('toggle')
    }
  },
  data() {
    return {}
  }
}
</script>
