<template>
  <div>
    <div class="body">
      <div class="result">
        <div class="pic">
          <PatientDataChart
            v-if="
              rePatientChart &&
                analyze !== undefined &&
                (selectedGraph.length >= 1 || analyze.predict !== undefined)
            "
            :isShowAll="isShowAll"
            :showDeath="showDeath"
            isQues="true"
          />
        </div>
        <div class="indicators">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title" style="font-size:28px">
                {{ $t('main.general.indicators') }}
                <span v-show="formOrder === 0" style="font-size:20px"
                  >（请点击您认为重要的、需要查看变化细节的指标，系统会将其时序图展开。括号中为最后一次测量数据。）</span
                >
              </h4>
            </template>
            <template v-slot:headerAction>
              <div style="padding-right: 20px " v-show="formOrder !== 0">
                <a @click="$store.commit('setSelectedGraph', [])">
                  <span>{{ $t('main.general.clearSelected') }}</span>
                </a>
              </div>
            </template>
            <!-- 'alb',
                  'crp',
                  'dia',
                  'cl',
                  'cre',
                  'amount',
                  'ca',
                  'k',
                  'sys',
                  'co2',
                  'na',
                  'urea',
                  'wbc',
                  'glu',
                  'p',
                  'weight',
                  'hgb' -->
            <template v-slot:body>
              <div v-if="formOrder === 0">
                <div
                  class="iq-card-body pl-0 pr-0 pb-0"
                  v-if="patient_data_selector.current_important.length"
                >
                  <PatientDataSelector
                    :item="selectoritem"
                    :info="patient_data_selector.info"
                    :langType="getLocale()"
                    :getGraphSelf="false"
                    @record="clickrecord"
                  >
                  </PatientDataSelector>
                  <!-- {{ patient_data_selector.current_important }}
              {{ patient_data_selector.info }} -->
                </div>
              </div>
              <div
                v-else-if="
                  formOrder === 1 || formOrder === 2 || formOrder === 3
                "
              >
                <div class="iq-header-title">
                  <h4
                    class="card-title text-primary"
                    v-show="patient_data_selector.current_important.length"
                  >
                    AI认为这位患者最后一次门诊随访时，对评价其健康状况最有指示意义的特征是：
                  </h4>
                </div>
                <div
                  class="iq-card-body pl-0 pr-0 pb-0"
                  v-show="patient_data_selector.current_important.length"
                >
                  <PatientDataSelector
                    @record="clickrecord"
                    :item="patient_data_selector.current_important"
                    :info="patient_data_selector.info"
                    :langType="getLocale()"
                  >
                  </PatientDataSelector>
                </div>
                <div
                  class="iq-header-title"
                  v-show="patient_data_selector.history_important.length"
                >
                  <h4 class="card-title text-primary">
                    AI认为这位患者的历史门诊随访序列中，还起到重要作用的特征是：
                  </h4>
                </div>
                <div
                  class="iq-card-body pl-0 pr-0 pb-0"
                  v-show="patient_data_selector.history_important.length"
                >
                  <PatientDataSelector
                    :item="patient_data_selector.history_important"
                    :info="patient_data_selector.info"
                    :langType="getLocale()"
                    @record="clickrecord"
                  >
                  </PatientDataSelector>
                </div>
                <div
                  class="iq-header-title"
                  v-show="patient_data_selector.other.length"
                >
                  <div
                    style="padding-bottom:20px;text-align:center;font-size:20px"
                  >
                    <a
                      data-icon="T"
                      class="icon"
                      v-if="display1 === false"
                      @click="display1 = !display1"
                    >
                      <span>{{ $t('main.general.more') }}</span>
                    </a>
                    <a
                      data-icon="W"
                      class="icon"
                      v-else
                      @click="display1 = !display1"
                    >
                      <span>{{ $t('main.general.hide') }}</span>
                    </a>
                  </div>
                  <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
                </div>
                <div
                  class="iq-card-body pl-0 pr-0 pb-0"
                  v-show="patient_data_selector.other.length"
                >
                  <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                    <h4 class="card-title text-primary">
                      {{ $t('main.general.otherIndicators') }}
                    </h4>
                    <PatientDataSelector
                      :item="patient_data_selector.other"
                      :langType="getLocale()"
                      @record="clickrecord"
                    ></PatientDataSelector>
                  </b-collapse>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
      <div class="questionnaire" style="margin-top:-65px;">
        <div class="personalInfo">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <!-- <div class="user-profile text-center">
                <img
                  v-if="patient.gender !== null && patient.gender === 0"
                  src="../../assets/images/user/11.png"
                  alt="profile-img"
                  class="avatar-130 img-fluid"
                />
                <img
                  v-else
                  src="../../assets/images/user/19.png"
                  alt="profile-img"
                  class="avatar-130 img-fluid"
                />
              </div> -->
                <div class="text-center mt-3">
                  <!--              <h3><b>编号：{{patient.pdid}}</b></h3>-->
                  <h6 class="text-primary" style="font-size:20px">
                    {{ $t('main.DashboardCkd.patientCard.originDisease') }}
                  </h6>
                  <h4 style="font-size:28px">
                    <b>{{
                      getLocale()
                        ? patient.originDisease
                        : originMedicalChartEn[patient.originDisease]
                    }}</b>
                  </h4>
                </div>
                <ul
                  class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0 "
                  style="font-size:20px"
                >
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.id') }}
                    </h6>
                    <h4 v-if="$route.params.patientId.charAt(0) == 'a'">
                      {{ $route.params.patientId }}
                    </h4>
                    <h4 v-else>-</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.gender') }}
                    </h6>
                    <h4 v-if="patient.gender !== null && patient.gender === 0">
                      {{ $t('main.general.female') }}
                    </h4>
                    <h4 v-else>{{ $t('main.general.male') }}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.birth') }}
                    </h6>
                    <h4 v-if="patient.birthDate != null">
                      {{ patient.birthDate.substring(0, 4) - $store.state.yearMinus }}
                    </h4>
                    <h4 v-else>{{ $t('main.general.noData') }}</h4>
                  </li>
                </ul>
                <br />
                <!-- <div style="padding-bottom:20px;text-align:center">
                  <a
                    data-icon="T"
                    class="icon"
                    v-if="display === false"
                    @click="
                      display = !display
                      $store.commit('setShowLine', true)
                    "
                  >
                    <span style="font-size:20px">{{
                      $t('main.general.more')
                    }}</span>
                  </a>
                  <a
                    data-icon="W"
                    class="icon"
                    v-else
                    @click="
                      display = !display
                      $store.commit('setShowLine', false)
                    "
                  >
                    <span style="font-size:20px">{{
                      $t('main.general.hide')
                    }}</span>
                  </a>
                </div> -->
                <b-collapse
                  id="collapse-0"
                  class="mb-2"
                  v-model="display"
                  style="padding:0 36px 0 36px;font-size:20px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="mt-3">
                      <p class="mb-0">
                        {{ $t('main.DashboardCkd.patientCard.height') }}
                      </p>
                      <h5 v-if="patient.height == undefined">
                        {{ $t('main.general.noData') }}
                      </h5>
                      <div v-else>
                        <h5>
                          {{ change(patient.height) }}&nbsp;<span>cm</span>
                        </h5>
                      </div>
                    </span>
                    <span class="mt-3">
                      <p class="mb-0">
                        {{ $t('main.DashboardCkd.patientCard.weight') }}
                      </p>
                      <h5 v-if="patient.weight == undefined">
                        {{ $t('main.general.noData') }}
                      </h5>
                      <div v-else>
                        <h5>
                          {{ change(patient.weight) }}&nbsp;<span>kg</span>
                        </h5>
                      </div>
                    </span>
                    <span class="data-block mt-3">
                      <p class="mb-0">
                        {{ $t('main.DashboardCkd.patientCard.admissionAge') }}
                      </p>
                      <h5 v-if="patient.age == undefined">
                        {{ $t('main.general.noData') }}
                      </h5>
                      <div v-else>
                        <h5>
                          {{ patient.age }}&nbsp;<span>{{
                            $t('main.general.yearsOld')
                          }}</span>
                        </h5>
                      </div>
                    </span>
                    <span
                      class="data-block mt-3"
                      v-show="
                        patient.amputation === 1 ||
                          patient.ch === 1 ||
                          patient.ci === 1 ||
                          patient.mi === 1 ||
                          patient.chd === 1 ||
                          patient.lung_infect === 1 ||
                          patient.heart_failure === 1 ||
                          patient.diabetes === 1
                      "
                    >
                      <p class="mb-0">
                        {{ $t('main.DashboardCkd.patientCard.basicDisease') }}
                      </p>
                      <h5>
                        {{
                          (patient.diabetes === 1 ? '糖尿病 ' : '') +
                            (patient.heart_failure === 1 ? '心脏衰竭 ' : '') +
                            (patient.lung_infect === 1 ? '肺部感染 ' : '') +
                            (patient.chd === 1 ? '冠心病 ' : '') +
                            (patient.mi === 1 ? '心梗 ' : '') +
                            (patient.ci === 1 ? '脑梗 ' : '') +
                            (patient.ch === 1 ? '脑出血 ' : '') +
                            (patient.amputation === 1 ? '截肢 ' : '')
                        }}
                      </h5>
                    </span>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </div>
        <iq-card class="card3">
          <template v-slot:headerTitle>
            <h4 style="font-size:28px;">问卷</h4>
          </template>
          <template v-slot:body>
            <div v-if="formOrder === 0" style="font-size:22px">
              <div class="single">
                <div>
                  1.您认为该患者一年内死亡的风险为？<span style="color:orange;"
                    >(单选题)</span
                  >
                </div>
                <div>
                  <template
                    v-for="(item, index) in state11"
                    style="padding:5px 0;"
                  >
                    <b-form-radio
                      v-model="inactive"
                      :name="item.name"
                      :key="index"
                      :value="item.value"
                      @change="radiochange1(index)"
                      >{{ item.label }}</b-form-radio
                    >
                    <!-- @change="form1.single = index" -->
                  </template>
                </div>
              </div>
              <div class="multiple" style="margin-top:20px">
                <div>
                  2.您做出如上判断，所主要关注的特征为哪些？<span
                    style="color:orange;"
                    >(多选题)</span
                  >
                </div>
                <div
                  class="checkbox d-inline-block mr-2"
                  v-for="(item, index) in state12"
                  :key="index"
                  style="padding:0 5px;width:220px"
                >
                  <input
                    name="multiple"
                    type="checkbox"
                    class="checkbox-input"
                    style="margin-bottom:0; "
                    @change="mutiple(index)"
                    :id="index"
                  />
                  <label class="ml-2" style="margin-bottom:0;" :for="index">{{
                    item.label
                  }}</label>
                </div>
              </div>
              <div style="margin:20px 0;margin-left:120px;font-size:20px">
                <b-button
                  variant="primary"
                  @click="submitform1"
                  style="font-size:20px;"
                  >提交以查看AI预测结果</b-button
                >
              </div>
            </div>
            <div v-else-if="formOrder === 1" style="font-size:22px">
              <AIObservation
                v-if="reAiObservation && formOrder === 1"
                :analyzeAdvice="analyzeAdvice"
                :adviceOnRiskThreshold="adviceOnRiskThreshold"
                :adviceOnRisk="adviceOnRisk"
                :isQuestionnaire="true"
              />
              <p style="color:red;padding-top:24px;">
                请您根据顶部的AI观察结果再次作答以下问卷
              </p>
              <div class="single">
                <div>
                  1.您认为该患者一年内死亡的风险为？<span style="color:orange;"
                    >(单选题)</span
                  >
                </div>
                <div style="padding:0 5px;">
                  <template
                    v-for="(item, index) in state11"
                    style="padding:5px 0;"
                  >
                    <b-form-radio
                      v-model="inactive"
                      :name="item.name"
                      :key="index"
                      :value="item.value"
                      @change="radiochange1(index)"
                      >{{ item.label }}</b-form-radio
                    >
                  </template>
                </div>
              </div>
              <div class="multiple" style="margin-top:20px">
                <div>
                  2.您做出如上判断，所主要关注的特征为哪些？<span
                    style="color:orange;"
                    >(多选题)</span
                  >
                </div>
                <div
                  class="checkbox d-inline-block mr-2"
                  v-for="(item, index) in state12"
                  :key="index"
                  style="padding:0 5px;width:220px"
                >
                  <input
                    name="multiple"
                    type="checkbox"
                    class="checkbox-input"
                    style="margin-bottom:0; "
                    @change="mutiple(index)"
                    :id="index"
                  />
                  <label class="ml-2" style="margin-bottom:0;" :for="index">{{
                    item.label
                  }}</label>
                </div>
              </div>
              <div style="margin:20px 0;margin-left:120px">
                <b-button
                  variant="primary"
                  @click="submitform1"
                  style="font-size:20px;"
                  >再次提交以查看患者结局</b-button
                >
              </div>
            </div>
            <div v-else-if="formOrder === 2" style="font-size:22px">
              <div style="color:red;">患者结局</div>
              <div
                class="user-details-block"
                style="margin:-20px 0px 24px -16px;"
              >
                <b-collapse id="collapse-0" class="mb-2" v-model="display2">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-col>
                      <div class="data-block mt-3" v-if="patient.death == 1">
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.deadTime') }}
                        </p>
                        <h5 style="font-size:22px">
                          {{ patient.deathDate.substring(0, 7) }}
                        </h5>
                      </div>
                      <div class="data-block mt-3" v-else>
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.status') }}
                        </p>
                        <h5>{{ $t('main.DashboardCkd.patientCard.cure') }}</h5>
                      </div>
                      <div class="data-block mt-3" v-show="patient.death == 1">
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.deadCause') }}
                        </p>
                        <h5 style="font-size:22px">
                          {{
                            getLocale()
                              ? patient.deathReasonText
                              : deathReasonEn[patient.deathReasonText]
                          }}
                        </h5>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
              <p style="color:red     ">请您根据顶部的患者结局作答以下问卷</p>
              <div class="single">
                <div>
                  1.您认为AI模型的死亡风险预测结果：<span style="color:orange;"
                    >(单选题)</span
                  >
                </div>
                <div style="padding:0 5px;">
                  <template
                    v-for="(item, index) in state31"
                    style="padding:5px 0;"
                  >
                    <b-form-radio
                      v-model="inactive"
                      :name="item.name"
                      :key="index"
                      :value="item.value"
                      @change="radiochange31(index)"
                      >{{ item.label }}</b-form-radio
                    >
                  </template>
                </div>
              </div>
              <div class="single" style="margin-top:20px">
                <div>
                  2.您认为针对该患者的情况，AI进行预测时所鉴别的关键特征是否合理：<span
                    style="color:orange;"
                    >(单选题)</span
                  >
                </div>
                <div style="padding:0 5px;">
                  <template
                    v-for="(item, index) in state32"
                    style="padding:5px 0;"
                  >
                    <b-form-radio
                      v-model="inactive"
                      :name="item.name"
                      :key="index"
                      :value="item.value"
                      @change="radiochange32(index)"
                      >{{ item.label }}</b-form-radio
                    >
                  </template>
                </div>
              </div>
              <div style="margin:20px 0;margin-left:120px">
                <b-button
                  variant="primary"
                  @click="submitform3"
                  style="font-size:20px;"
                  >提交您对AI模型的评价</b-button
                >
              </div>
            </div>
            <div v-else style="font-size:22px;">
              <b-form-group
                label="您对该患者的情况有何补充描述？例如还需要提供哪些信息才能更好地辅助您判断患者的健康风险（如透析充分性、饮食记录等）。或者您有何建议？"
                label-for="exampleFormControlTextarea1"
              >
                <b-form-textarea
                  id="exampleFormControlTextarea1"
                  rows="5"
                  v-model="suggestion"
                ></b-form-textarea>
              </b-form-group>
              <div style="margin:20px 0;margin-left:150px">
                <b-button
                  variant="primary"
                  @click="submittext"
                  style="font-size:20px;"
                  >提交您的建议</b-button
                >
              </div>
              <!-- <a class="search-link" @click="submittext">提交您的建议</a> -->
            </div>
          </template>
        </iq-card>
      </div>
      <!-- <iq-card class="body">
      <template v-slot:body> </template>
    </iq-card>
    <iq-card class="body">
      <template v-slot:body>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
        <div>10</div>
      </template>
    </iq-card> -->
      <div style="clear:both"></div>
    </div>
    <div class="tail">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 style="font-size:28px;">引导说明</h4>
        </template>
        <template v-slot:headerAction> </template>
        <template v-slot:body>
          <div style="font-size:20px;">
            <div>
              您好，感谢您参与人工智能ESRD预后系统AICare的性能评估实测问卷调查。我们将记录并分析您作为资深的专业医护人员对患者健康情况的分析结果，以及您对AI所判断的结果的评价。下面对本系统预测问题以及输出内容、您即将填写的问卷项目进行介绍。
            </div>
            <div>
              为动态、实时评估腹膜透析患者的健康状况并辅助改善预后，北京大学团队研发人工智能模型AICare为每一位患者预测每次门诊随访时一年内死亡风险。0%-100%为低风险到高风险，如10%则AI认为该患者情况平稳、良好，1年内几乎不会死亡，而90%则表明AI认为该患者极度危险，1年内较大概率死亡。
            </div>
            <div>
              AI所使用的特征为临床中记录较为频繁的17项动态特征（血白蛋白、血红蛋白、舒张压、收缩压、血氯、血钙、体重、血肌酐、血糖、含水食物摄入量等），与4项在初次就诊时记录的静态基线特征（年龄、性别、身高、是否患有糖尿病）。
            </div>
            <div>
              该AI模型不仅可以以较高准确率进行预测，还可以针对每一位患者在每次就诊时个性化判断不同特征的重要程度，即AI依据什么特征的情况来判断该患者的死亡率。在每次预测中，所有特征的权重总和为100%。
            </div>
            <div>
              在时序图中，横轴为一位患者的就诊时间，纵轴有多项，包括风险值、特征检验结果。每一个记录点为一次就诊。AI所评估的患者风险指数，以<span
                style="color:pink"
                >背景浅粉色的曲线</span
              >的曲线示意，越高则越危险。当您用鼠标点选不同特征时，该特征的时序折线图就会展示。特征折线图上标记有大小不一的点，<span
                style="font-weight:700"
                >点越大</span
              ><span style="font-size:20px">◉</span
              >则表明AI在此刻越关注该特征，<span style="font-weight:700"
                >点很小</span
              ><span style="font-size:12px;">◉</span
              >表明AI不关注该特征。当您把鼠标悬停在其上时，会有悬浮窗口显示该患者此次就诊的时间、预测风险值、特征重要性权重分配、特征检验检查值。
            </div>
            <div>
              在问卷中，我们会首先模拟临床真实场景，展示患者的基本信息（原发疾病、性别、年龄、身高体重）、所有动态特征检验结果（按钮括号内为该患者该特征的最后一次检验检查值），您基于该患者的基本情况，按照您关注的重点，分别点选您希望查看的特征。随后，对该患者的一年内死亡概率进行评估。
            </div>
            <div>
              在您提交结果后，问卷会显示AI对同一位患者的评估结果，您可以查看AI所评估的死亡概率以及所做出该判断的依据（即特征重要性权重）。您在获得AI的辅助性输出后，可以二次评估该患者的情况。
            </div>
            <div>
              最终，请您对AI的整体表现进行评价。 谢谢！
            </div>
          </div>
        </template>
      </iq-card>
    </div>
    <div class="introduction">
      <el-dialog
        title="问卷引导说明"
        :visible.sync="dialogVisible"
        width="75%"
        :before-close="handleClose"
        top="15vh"
      >
        <div style="text-indent:2em;font-size:20px;">
          <div>
            您好，感谢您参与人工智能ESRD预后系统AICare的性能评估实测问卷调查。我们将记录并分析您作为资深的专业医护人员对患者健康情况的分析结果，以及您对AI所判断的结果的评价。下面对本系统预测问题以及输出内容、您即将填写的问卷项目进行介绍。
          </div>
          <div>
            为动态、实时评估腹膜透析患者的健康状况并辅助改善预后，北京大学团队研发人工智能模型AICare为每一位患者预测每次门诊随访时一年内死亡风险。0%-100%为低风险到高风险，如10%则AI认为该患者情况平稳、良好，1年内几乎不会死亡，而90%则表明AI认为该患者极度危险，1年内较大概率死亡。
          </div>
          <div>
            AI所使用的特征为临床中记录较为频繁的17项动态特征（血白蛋白、血红蛋白、舒张压、收缩压、血氯、血钙、体重、血肌酐、血糖、含水食物摄入量等），与4项在初次就诊时记录的静态基线特征（年龄、性别、身高、是否患有糖尿病）。
          </div>
          <div>
            该AI模型不仅可以以较高准确率进行预测，还可以针对每一位患者在每次就诊时个性化判断不同特征的重要程度，即AI依据什么特征的情况来判断该患者的死亡率。在每次预测中，所有特征的权重总和为100%。
          </div>
          <div>
            在时序图中，横轴为一位患者的就诊时间，纵轴有多项，包括风险值、特征检验结果。每一个记录点为一次就诊。AI所评估的患者风险指数，以<span
              style="color:pink"
              >背景浅粉色的曲线</span
            >的曲线示意，越高则越危险。当您用鼠标点选不同特征时，该特征的时序折线图就会展示。特征折线图上标记有大小不一的点，<span
              style="font-weight:700"
              >点越大</span
            ><span style="font-size:16px">◉</span
            >则表明AI在此刻越关注该特征，<span style="font-weight:700"
              >点很小</span
            ><span style="font-size:12px;">◉</span
            >表明AI不关注该特征。当您把鼠标悬停在其上时，会有悬浮窗口显示该患者此次就诊的时间、预测风险值、特征重要性权重分配、特征检验检查值。
          </div>
          <div>
            在问卷中，我们会首先模拟临床真实场景，展示患者的基本信息（原发疾病、性别、年龄、身高体重）、所有动态特征检验结果（按钮括号内为该患者该特征的最后一次检验检查值），您基于该患者的基本情况，按照您关注的重点，分别点选您希望查看的特征。随后，对该患者的一年内死亡概率进行评估。
          </div>
          <div>
            在您提交结果后，问卷会显示AI对同一位患者的评估结果，您可以查看AI所评估的死亡概率以及所做出该判断的依据（即特征重要性权重）。您在获得AI的辅助性输出后，可以二次评估该患者的情况。
          </div>
          <div>
            最终，请您对AI的整体表现进行评价。 谢谢！
          </div>
        </div>
        <!-- <span-- slot="footer" class="dialog-footer"> </span-->
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import PatientLabDataSelector from '../Charts/PatientLabDataSelector'
import PatientDataSelector from '../Charts/PatientDataSelector'
import IqCard from '../../components/xray/cards/iq-card'
import { xray } from '@/config/pluginInit'
import SettingsHelper from '../../util/SettingsHelper'
import APIHelper, {
  getAnalyzeAdvice,
  getTxtTest,
  getTime
} from '@/util/APIHelper'
import {
  medicalChar,
  medicalCharChinese,
  diseasesChartChi,
  diseasesChartEn,
  originMedicalChartEn,
  deathReasonEn,
  patientMap
} from '@/util/info'
import PatientDataChart from '../Charts/PatientDataChart'
import ColorHelper from '../../util/ColorHelper'
import AIObservation from '../../components/Advice/AIObservation'
import { answerAll, getLatestbyId } from '../../network/questionnaire'

export default {
  name: 'Questionnaire',
  components: {
    IqCard,
    PatientDataChart: PatientDataChart,
    AIObservation,
    PatientDataSelector
  },
  inject: ['reload'],
  data() {
    return {
      dialogVisible: false,
      finalAnswer: {
        answers: [],
        doctor_id: 0,
        patient_id: 0,
        sequences: []
      },
      suggestion: '',
      isShowAll: false,
      showDeath: true,
      state11: [
        {
          name: 'single',
          label: 'A. 0-20%，低风险，基本确定1年内不会死亡',
          value: 'inactive'
        },
        {
          name: 'single',
          label: 'B. 20-40%，中低风险，一般不会死亡，但有一些健康风险',
          value: 'inactive'
        },
        {
          name: 'single',
          label: 'C. 40%-60%，中风险，需要注意一些潜在风险',
          value: 'inactive'
        },
        {
          name: 'single',
          label:
            'D. 60%-80%，中高风险，有一些重要的危害健康的表现，较大概率1年内死亡',
          value: 'inactive'
        },
        {
          name: 'single',
          label: 'E. 80%-100%，高风险，有重大健康风险很可能于1年内死亡',
          value: 'inactive'
        }
      ],
      state31: [
        {
          name: 'single',
          label:
            'A. AI在患者整个随访序列中的每一次就诊均不能正确评估患者的情况',
          value: 'inactive'
        },
        {
          name: 'single',
          label:
            'B. AI在患者状态还很不错的时候就做出了过高危险预测，形成了误报（假阳性）',
          value: 'inactive'
        },
        {
          name: 'single',
          label:
            'C. AI在患者已经处于危险时仍无动于衷，到最后非常临近死亡时才做出了危险预测，为时已晚（假阴性）',
          value: 'inactive'
        },
        {
          name: 'single',
          label: 'D. AI在大部分就诊中都做出了较为正确的判断',
          value: 'inactive'
        },
        {
          name: 'single',
          label: 'E. AI在患者整个随访序列中一直保持非常合理、准确的预测性能',
          value: 'inactive'
        }
      ],

      state32: [
        {
          name: 'single32',
          label:
            'A. AI在患者整个随访序列中的每一次就诊均完全没有发现能预测患者死亡率的特征（不论是预测健康还是预测危险）',
          value: 'inactive'
        },
        {
          name: 'single32',
          label:
            'B. AI能针对患者健康状况较为良好的时段鉴别出能表现良好预后的特征（阴性部分分析合理）',
          value: 'inactive'
        },
        {
          name: 'single32',
          label:
            'C. AI能针对患者健康状况较为危险的时段鉴别出能预警不良预后的特征（阳性部分分析合理）',
          value: 'inactive'
        },
        {
          name: 'single32',
          label: 'D. AI能针对大部分就诊合理发现能指示出患者结局的特征',
          value: 'inactive'
        }
      ],
      state12: [
        {
          label: '白蛋白',
          value: 0
        },
        {
          label: '舒张压',
          value: 0
        },
        {
          label: '收缩压',
          value: 0
        },
        {
          label: '尿素',
          value: 0
        },
        {
          label: '体重',
          value: 0
        },
        {
          label: '血钠',
          value: 0
        },
        {
          label: '血氯',
          value: 0
        },
        {
          label: '血红蛋白',
          value: 0
        },
        {
          label: '肌酐',
          value: 0
        },
        {
          label: '血磷',
          value: 0
        },
        {
          label: '血钾',
          value: 0
        },
        {
          label: '食物摄入(含水)',
          value: 0
        },
        {
          label: '血钙',
          value: 0
        },
        {
          label: '二氧化碳结合力',
          value: 0
        },
        {
          label: '超敏C-反应蛋白',
          value: 0
        },
        {
          label: '血糖',
          value: 0
        },
        {
          label: '白细胞',
          value: 0
        }
      ],
      form3: {
        single1: -1,
        single2: -1
      },
      form1: {
        single: -1,
        multiple: []
      },
      formOrder: 0,
      bg_color: [
        'bg-warning',
        'bg-primary',
        'bg-info',
        'bg-danger',
        'bg-success',
        'bg-secondary'
      ],
      reAiObservation: true,
      rePatientChart: true,
      reAmchart: true,
      hoverIndex: -1, // 菜单hover索引
      activeLinkId: 0, // 当前激活的菜单id
      message: '默认值',
      genderRatio: 0,
      display: true,
      display1: false,
      display2: true,
      dialog: false,
      top2: undefined,
      urlAnalyzeApi: undefined,
      settings: undefined,
      getAnalyzeDataTimer: undefined,
      getAdviceTimer: undefined,
      similarPatientsAttn: [],
      medicalCharChinese: medicalCharChinese,
      medicalChar: medicalChar,
      deathReasonEn: deathReasonEn,
      originMedicalChartEn: originMedicalChartEn,
      patient_data_selector: {
        current_important: [],
        history_important: [],
        other: [],
        item: [],
        part: [0.2, 0.5, 1],
        info: {}
      },
      analyzeAdvice: undefined,
      originDiseaseChart: {
        type: 'radial-pie',
        height: 350,
        data: []
      },
      clusterInfoKey: 0,
      txtTest: '默认值',
      getGraphSelf: false,
      storeImportIndex: [],
      recordList: [],
      selectoritem: [
        'alb',
        'dia',
        'sys',
        'urea',
        'weight',
        'na',
        'cl',
        'hgb',
        'cre',
        'p',
        'k',
        'amount',
        'ca',
        'co2',
        'crp',
        'glu',
        'wbc'
      ],
      curpatientId: 0
    }
  },
  beforeCreate() {},
  created() {
    this.init()
  },
  mounted() {
    xray.index()
  },
  destroyed() {
    this.$store.state.selectedGraph = this.storeImportIndex
  },
  computed: {
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    events() {
      return this.$store.state.events
    },
    analyze() {
      return this.$store.state.analyze
    },
    modifiedLab() {
      return this.$store.state.modifiedLab
    },
    lab() {
      return this.$store.state.lab
    },
    patient() {
      return this.$store.state.patient
    },
    similarPatients() {
      return this.$store.state.similarPatients
    },
    analyzeData() {
      const { modifiedLab, patient } = this
      return {
        modifiedLab,
        patient
      }
    },
    advice() {
      return this.$store.state.advice
    },
    adviceOnRiskThreshold() {
      return this.$store.state.adviceOnRiskThreshold
    },
    adviceOnRiskThresholdSub() {
      return this.$store.state.adviceOnRiskThresholdSub
    },
    adviceOnRisk() {
      return this.$store.state.adviceOnRisk
    },
    adviceOnIdx() {
      return this.$store.state.adviceOnIdx
    },
    ch() {
      return this.$store.state.ch
    },
    showLine() {
      return this.$store.state.showLine
    },
    deathReason() {
      return this.$store.state.deathReason
    }
  },
  activated() {
    xray.index()
    this.init()
  },
  watch: {
    $route(to, from) {
      // console.log(to)
      // console.log(from)
      // setTimeout(this.$router.go(0), 100)
      this.$router.go(0)
    },
    selectedGraph(newVal, oldVal) {
      // console.log(newVal)
      if (!this.getGraphSelf && this.formOrder === 0) {
        this.storeImportIndex = this.$store.state.selectedGraph
        this.$store.state.selectedGraph = []
        this.getGraphSelf = true
      }
    },
    analyzeData() {
      this.getAnalyzeData()
      this.getComplicationsAnalyzeData()
    },
    // '$route'(to, from) { // 监听路由是否变化
    //   if (to.name === 'dashboard.home-4') { // 跳转到哪个页面
    //     this.reload()
    //   }
    // },
    '$i18n.locale'(newValue) {
      this.reloadChild()
    }
  },
  methods: {
    radiochange1(index) {
      this.form1.single = index
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 2
      this.recordList.push(record1)
    },
    radiochange31(index) {
      this.form3.single1 = index
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 2
      this.recordList.push(record1)
    },
    radiochange32(index) {
      this.form3.single2 = index
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 2
      this.recordList.push(record1)
    },
    handleGoUp() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    handleClose(done) {
      done()
      this.dialogVisible = false
    },
    clickrecord(record) {
      let record1 = JSON.parse(record)
      // console.log(record1.index)
      this.recordList.push(record1)
      // console.log(this.recordList)
      // console.log(this.recordList)
      // console.log(record)
    },
    init() {
      // console.log(this.$route.path.split('/')[this.$route.path.split('/').length - 1].split('a')[1])
      if (window.localStorage.getItem('loginUser') === null) {
        if (this.$route.path === '/table/questionnaire/a1') {
          this.dialogVisible = true
        }
        // console.log(this.$route.path)
        for (let i = 0, len = this.selectoritem.length; i < len; i++) {
          let a = parseInt(Math.random() * len)
          let temp = this.selectoritem[a]
          this.selectoritem[a] = this.selectoritem[i]
          this.selectoritem[i] = temp
        }
        // console.log(this.selectoritem)
        this.urlAnalyzeApi = new URL(window.location.href).searchParams.get(
          'api'
        )
        // console.log(this.urlAnalyzeApi)
        if (this.urlAnalyzeApi) {
          this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
        }
        this.settings = SettingsHelper.load()
        // 额外参数为 pedning = true
        this.$store.commit('setComputingAdvice', { pending: true })
        // 将多个Promise实例包装成一个新的Promise实例 处理多个异步处理
        // lab为每一次的就诊记录，patient是基本的病人信息，
        Promise.all([
          APIHelper.getPatientById(this.$route.params.patientId),
          APIHelper.getLabById(this.$route.params.patientId),
          APIHelper.getMockData(this.$route.params.patientId)
        ]).then(([patient, lab, events]) => {
          // console.log(events)
          this.$store.commit('setLab', lab)
          // this.$store.commit('setEvents', events)
          this.$store.commit('setModifiedLab', lab)
          this.$store.commit('setPatient', patient)
          Promise.all([
            APIHelper.getAdviceOnRisk(
              {
                patient: this.patient,
                lab: this.modifiedLab
              },
              40
            ),
            APIHelper.getAdviceOnRisk(
              {
                patient: this.patient,
                lab: this.modifiedLab
              },
              0
            )
          ]).then(([adviceOnRiskThreshold, adviceOnRisk]) => {
            // this.$store.commit('setAdviceOnRiskThresholdSub', adviceOnRiskThresholdSub)
            // console.log(adviceOnRisk)
            // console.log(adviceOnRiskThreshold)
            this.$store.commit(
              'setAdviceOnRiskThreshold',
              adviceOnRiskThreshold
            )
            this.$store.commit('setAdviceOnRisk', adviceOnRisk)
            this.$store.commit('setComputingAdvice', { pending: false })
          })
        })
        if (this.$store.state.ch) {
          this.$store.commit('setCh', new ColorHelper())
        }
        return
      }
      let form = new FormData()
      // console.log(JSON.parse(window.localStorage.getItem('loginUser')).username)
      form.append(
        'doctor_id',
        parseInt(JSON.parse(window.localStorage.getItem('loginUser')).user_id)
      )
      let curpatientId = -1
      getLatestbyId(form).then(res => {
        // console.log(res)
        if (res.data.success) {
          if (res.data.data !== -1) {
            curpatientId = res.data.data
            // console.log(123)
          } else {
            curpatientId = -1
          }
          let keylist = []
          for (let item in patientMap) {
            keylist.push(item)
          }
          // console.log(patientMap)
          if (curpatientId === -1) {
            curpatientId = 'a1'
          } else {
            let i = 0
            for (let item in patientMap) {
              i++
              if (patientMap[item] === curpatientId) {
                // curpatientId = item
                break
              }
            }
            curpatientId = keylist[i]
          }
          // let patientId = window.localStorage.getItem('patientId')
          // if (!patientId) {
          //   window.localStorage.setItem('patientId', 1)
          //   patientId = window.localStorage.getItem('patientId')
          // }
          // console.log(parseInt(patientId))
          // this.$router.replace('/table/questionnaire/a' + patientId)
          // if (parseInt(patientId) === 1) {
          //   this.dialogVisible = true
          // }
          this.$router.replace('/table/questionnaire/' + curpatientId)
          if (curpatientId === 'a1') {
            this.dialogVisible = true
          }
          for (let i = 0, len = this.selectoritem.length; i < len; i++) {
            let a = parseInt(Math.random() * len)
            let temp = this.selectoritem[a]
            this.selectoritem[a] = this.selectoritem[i]
            this.selectoritem[i] = temp
          }
          // console.log(this.selectoritem)
          this.urlAnalyzeApi = new URL(window.location.href).searchParams.get(
            'api'
          )
          // console.log(this.urlAnalyzeApi)
          if (this.urlAnalyzeApi) {
            this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
          }
          this.settings = SettingsHelper.load()
          // 额外参数为 pedning = true
          this.$store.commit('setComputingAdvice', { pending: true })
          // 将多个Promise实例包装成一个新的Promise实例 处理多个异步处理
          // lab为每一次的就诊记录，patient是基本的病人信息，
          Promise.all([
            APIHelper.getPatientById(this.$route.params.patientId),
            APIHelper.getLabById(this.$route.params.patientId),
            APIHelper.getMockData(this.$route.params.patientId)
          ]).then(([patient, lab, events]) => {
            // console.log(events)
            this.$store.commit('setLab', lab)
            // this.$store.commit('setEvents', events)
            this.$store.commit('setModifiedLab', lab)
            this.$store.commit('setPatient', patient)
            Promise.all([
              APIHelper.getAdviceOnRisk(
                {
                  patient: this.patient,
                  lab: this.modifiedLab
                },
                40
              ),
              APIHelper.getAdviceOnRisk(
                {
                  patient: this.patient,
                  lab: this.modifiedLab
                },
                0
              )
            ]).then(([adviceOnRiskThreshold, adviceOnRisk]) => {
              // this.$store.commit('setAdviceOnRiskThresholdSub', adviceOnRiskThresholdSub)
              // console.log(adviceOnRisk)
              // console.log(adviceOnRiskThreshold)
              this.$store.commit(
                'setAdviceOnRiskThreshold',
                adviceOnRiskThreshold
              )
              this.$store.commit('setAdviceOnRisk', adviceOnRisk)
              this.$store.commit('setComputingAdvice', { pending: false })
            })
          })
          if (this.$store.state.ch) {
            this.$store.commit('setCh', new ColorHelper())
          }
        }
      })
    },
    enter: function() {
      this.color = 'blue'
    },
    leave: function() {
      this.color = 'red'
    },
    submittext() {
      if (window.localStorage.getItem('loginUser') === null) {
        let x = (Number(this.$route.path.split('/')[this.$route.path.split('/').length - 1].split('a')[1]) + 1) % 20
        // console.log(x)
        this.$router.replace('/table/questionnaire/a' + x.toString())
        return
      }
      // console.log(this.$route.path.split('/'))
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 3
      this.recordList.push(record1)
      let suggest = {
        answer: this.suggestion,
        question_id: 10
      }
      this.finalAnswer.answers.push(suggest)
      this.finalAnswer.doctor_id = JSON.parse(
        window.localStorage.getItem('loginUser')
      ).user_id
      this.finalAnswer.patient_id = patientMap[this.$route.params.patientId]
      // console.log(this.finalAnswer)
      // console.log(JSON.parse(window.localStorage.getItem('loginUser')))
      // console.log(this.recordList)
      this.finalAnswer.sequences = this.recordList
      // console.log(this.recordList)
      answerAll(this.finalAnswer).then(res => {
        // console.log(res)
        if (res.data.success) {
          alert('问卷提交成功，即将显示下一位患者！')
          this.$router.go(0)
          // let patientId = parseInt(window.localStorage.getItem('patientId'))
          // patientId = (patientId % 40) + 1

          // window.localStorage.setItem('patientId', patientId)
          // let inputVal = 'a' + patientId
          // if (this.$route.meta.name === 'questionnaire') {
          //   this.$router.push(inputVal)
          // }
        } else {
          alert('问卷提交失败!')
        }
      })
      // let patientId = parseInt(window.localStorage.getItem('patientId'))
      // patientId = (patientId % 20) + 1

      // window.localStorage.setItem('patientId', patientId)
      // let inputVal = 'a' + patientId
      // if (this.$route.meta.name === 'questionnaire') {
      //   this.$router.push(inputVal)
      // }
    },
    reset() {
      this.form1.single = -1
      this.form1.multiple = []
      this.form3.single1 = -1
      this.form3.single2 = -1
      this.state12.forEach(item => {
        item.value = 0
      })
      let radio = document.getElementsByName('single')
      for (let i = 0; i < radio.length; i++) {
        radio[i].checked = false
      }
      radio = document.getElementsByName('single32')
      for (let i = 0; i < radio.length; i++) {
        radio[i].checked = false
      }
      let multiple = document.getElementsByName('multiple')
      for (let i = 0; i < multiple.length; i++) {
        multiple[i].checked = false
      }
    },
    mutiple(index) {
      this.state12[index].value === 0
        ? (this.state12[index].value = 1)
        : (this.state12[index].value = 0)
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 2
      this.recordList.push(record1)
    },
    submitform1() {
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 3
      this.recordList.push(record1)
      // console.log(this.recordList)
      let mlist = []
      for (let i = 0; i < 17; i++) {
        if (this.state12[i].value === 1) {
          mlist.push(i)
        }
      }
      this.form1.mutiple = mlist
      if (this.form1.mutiple.length === 0 || this.form1.single === -1) {
        alert('您还有未完成的题目!')
      } else {
        // console.log(this.form1)
        //   todo:将值传给后端
        // this.form1.single = -1
        // this.form1.mutiple = []
        this.isShowAll = true
        if (this.formOrder === 0) {
          this.formOrder = 1
          this.$store.state.selectedGraph = this.storeImportIndex
          this.showDeath = false
          // console.log(this.form1)
          let single = {
            answer: this.form1.single.toString(),
            question_id: 4
          }
          this.finalAnswer.answers.push(single)
          let multiple = {
            answer: this.form1.mutiple.toString(),
            question_id: 5
          }
          this.finalAnswer.answers.push(multiple)
          this.handleGoUp()
          // console.log(this.finalAnswer)
          setTimeout(this.reset, 100)
        } else if (this.formOrder === 1) {
          // console.log(this.form1)
          let single = {
            answer: this.form1.single.toString(),
            question_id: 8
          }
          this.finalAnswer.answers.push(single)
          let multiple = {
            answer: this.form1.mutiple.toString(),
            question_id: 9
          }
          this.finalAnswer.answers.push(multiple)
          // console.log(this.finalAnswer)
          this.showDeath = true
          this.formOrder = 2
          this.handleGoUp()
          setTimeout(this.reset, 100)
        }
      }
    },
    submitform3() {
      let record1 = {}
      record1.index = ''
      record1.time = getTime()
      record1.type = 3
      this.recordList.push(record1)
      if (this.form3.single1 === -1 || this.form3.single2 === -1) {
        alert('您还有未完成的题目!')
      } else {
        // console.log(this.form3)
        let single1 = {
          answer: this.form3.single1.toString(),
          question_id: 6
        }
        this.finalAnswer.answers.push(single1)
        let single2 = {
          answer: this.form3.single2.toString(),
          question_id: 7
        }
        this.finalAnswer.answers.push(single2)
        // console.log(this.finalAnswer)
        this.formOrder = 3
        // this.getGraphSelf = false
        // this.$store.state.selectedGraph = []
        // this.isShowAll = false
        setTimeout(this.reset, 100)
      }
    },
    reloadChild() {
      this.reAiObservation = false
      this.rePatientChart = false
      this.reAmchart = false
      this.$nextTick(() => {
        this.reAiObservation = true
        this.rePatientChart = true
        this.reAmchart = true
      })
    },
    // 分析数据
    getAnalyzeData() {
      // var txtarr = ['co2cp', 'Systolic pressure']
      // console.log('getAnalyzeData')
      clearTimeout(this.getAnalyzeDataTimer)
      // console.log(this.urlAnalyzeApi)
      // console.log(this.settings.analyzeApi)
      // 延迟 500ms 保证最后执行
      this.getAnalyzeDataTimer = setTimeout(
        () =>
          APIHelper.getAnalyze(
            {
              patient: this.patient,
              lab: this.modifiedLab
            },
            this.urlAnalyzeApi
              ? this.settings.analyzeApi.map(o => ({
                  ...o,
                  enabled: this.urlAnalyzeApi.includes(o.alias)
                }))
              : this.settings.analyzeApi
          ).then(result => {
            this.$store.commit('setAnalyze', result)
            this.$store.commit('setAnalyzeRisk', result)
            // console.log(this.top2)
            if (this.top2 === undefined) {
              this.$store.commit('setOriginalAnalyze', result)
              this.getRank()
            }
            this.originDiseaseChart.data = []
            let diseases = result.cluster_info.origin_disease
            for (let key in diseases) {
              if (diseases[key] > 0) {
                this.originDiseaseChart.data.push({
                  country: key,
                  value: diseases[key]
                })
              }
            }
            this.genderRatio =
              100 / (1 / result['cluster_info']['genderRatio'] + 1)
            ++this.clusterInfoKey

            APIHelper.getAllPatientById(
              result['top_visit'],
              result['cluster_top6_pdid']
            ).then(similarPatients =>
              this.$store.commit('setSimilarPatients', similarPatients)
            )

            let dict = result['cluster_info']['attention']
            this.similarPatientsAttn = Object.keys(dict).map(function(key) {
              return [key, dict[key]]
            })
            // Sort the array based on the second element
            // console.log(this.similarPatients)
            // console.log(this.similarPatientsAttn)
            this.similarPatientsAttn.sort(function(first, second) {
              return second[1] - first[1]
            })
            dict = result['cluster_info']['death_reason']
            let deathReason = Object.keys(dict).map(function(key) {
              return [key, dict[key]]
            })
            // deathReason.forEach(item => {
            // if (this.getLocale()) {
            // item[0] = diseasesChartChi[item[0]]
            // } else {
            // item[0] = diseasesChartEn[item[0]]
            // }
            // })
            // Sort the array based on the second element
            deathReason.sort(function(first, second) {
              return second[1] - first[1]
            })
            let tmp = deathReason[0]
            if (tmp[0] === '003') {
              deathReason.splice(0, 1)
              deathReason.push(tmp)
            }
            this.$store.commit('setDeathReason', deathReason)
          }),
        500
      )
    },
    getComplicationsAnalyzeData() {
      // clearTimeout(this.getAnalyzeDataTimer)
      this.getAnalyzeDataTimer = setTimeout(
        () =>
          APIHelper.getComplicationsAnalyze({
            patient: this.patient,
            lab: this.modifiedLab
          }).then(res => {
            // console.log('com: ', res)
            this.$store.commit('setComplicationsAnalyze', res)
            let obj1 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj2 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj3 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj4 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let events = res.events
            this.$store.commit('setEvents', events)
            let len = res.predict.length
            for (let i = 0; i < len; i++) {
              obj1.predict[i] = res.predict[i][0]
              obj2.predict[i] = res.predict[i][1]
              obj3.predict[i] = res.predict[i][2]
              obj4.predict[i] = res.predict[i][3]
            }
            obj1.attention = res.attention[0]
            obj2.attention = res.attention[1]
            obj3.attention = res.attention[2]
            obj4.attention = res.attention[3]
            // console.log(obj1.predict)
            // console.log(obj2.predict)
            this.$store.commit('setAnalyze1', obj1)
            this.$store.commit('setAnalyze2', obj2)
            this.$store.commit('setAnalyze3', obj3)
            this.$store.commit('setAnalyze4', obj4)
          }),
        2000
      )
    },
    getDiseaseName(value) {
      return this.getLocale() ? diseasesChartChi[value] : diseasesChartEn[value]
    },
    change(a) {
      const b = parseFloat(a)
      return b.toFixed(1)
    },
    getRank() {
      if (this.analyze.attention) {
        const lastAttention = this.analyze.attention.slice(-1)[0]
        this.patient_data_selector.item = Object.keys(lastAttention)
        let arr = []
        for (let [key, value] of Object.entries(lastAttention)) {
          if (value >= 0.22) {
            arr.push(key)
          }
        }
        // 排序
        let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
          return b[1] - a[1]
        })
        this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
        this.top2.forEach(o => {
          if (arr.indexOf(o) === -1) {
            arr.push(o)
          }
        })
        this.$store.commit('setTop2', this.top2)
        let obj = {}
        let top1 = []
        let arr1 = []
        const length = this.analyze.attention.length
        this.analyze.attention.forEach((item, i) => {
          let max = 0
          Object.entries(item).forEach(([key, value]) => {
            if (value > max) {
              max = value
              top1[i] = key
            }
          })
          // let k = 0
          Object.getOwnPropertyNames(item).forEach(key => {
            obj[key] ? (obj[key] += item[key]) : (obj[key] = item[key])
            if (item[key] >= 0.5 || key === top1[i]) {
              if (arr1.indexOf(key) === -1) {
                arr1.push(key)
                if (this.getLocale()) {
                  this.patient_data_selector.info[key] =
                    '<b>AI强烈关注此项指标时间</b>'
                } else {
                  this.patient_data_selector.info[key] =
                    '<b>This indicator is focused by AI</b>'
                }
              }
              if (i === 0 || key !== top1[i - 1]) {
                this.patient_data_selector.info[key] +=
                  '<br/>' + this.modifiedLab[i].date
                // k = i
              }
            } else {
              if (i >= 1 && key === top1[i - 1]) {
                if (i >= 2 && key === top1[i - 2]) {
                  this.patient_data_selector.info[key] +=
                    '~' + this.modifiedLab[i - 1].date
                  /* if (k - 1 >= 0) {
                      let trend = this.lab[i][key] / this.lab[k - 1][key] - 1
                      if (trend !== 0) {
                        this.patient_data_selector.info[key] +=
                          '（' + (trend > 0 ? '升高了' + trend : '降低了' + (-trend)) + '%）'
                      }
                    } */
                }
              }
            }
          })
        })
        if (length >= 2) {
          this.patient_data_selector.item.forEach(key => {
            if (key === top1[length - 1]) {
              if (key === top1[length - 2]) {
                this.patient_data_selector.info[key] +=
                  '~' + this.modifiedLab[length - 1].date
              }
            }
          })
        }
        Object.entries(obj).forEach(item => {
          if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
            arr1.push(item[0])
          }
        })
        this.patient_data_selector.current_important = arr
        this.patient_data_selector.history_important = arr1.filter(
          item => arr.indexOf(item) === -1
        )
        this.patient_data_selector.other = this.patient_data_selector.item.filter(
          item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
        )
        if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length >= 2
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.current_important[1]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1 &&
          this.patient_data_selector.history_important &&
          this.patient_data_selector.history_important.length >= 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.history_important[0]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0]
          ])
        }
        this.$store.commit('setSelectedDate', [])
        this.analyzeAdvice = getAnalyzeAdvice(
          this.lab,
          this.analyze,
          this.patient_data_selector.item
        )

        let arrstr = ''
        let i
        for (i = 0; i < arr.length; i++) {
          arrstr += '*'
          arrstr += medicalChar[arr[i]]
        }
        getTxtTest(arrstr).then(res => {
          this.txtTest = res.data
        })
      }
    },
    toPatientPage(a) {
      this.$router.push('/' + a.pdid /* + '?secret=yuyuyzl' */)
    },
    getLocale() {
      return this.$i18n.locale === 'chi'
    }
  }
}
</script>

<style scoped>
.body {
  width: 1600px;
  margin: 20px auto;
  padding: 0px 25px;
}

.tail {
  width: 1600px;
  margin: 20px auto;
  padding: 0px 25px;
}
.result {
  float: left;
  width: 1000px;
}
.questionnaire {
  float: right;
  width: 520px;
}

.el-button--small {
  padding: 5px !important;
  font-size: 16px !important;
}

.el-button--small,
.el-button--small.is-round {
  padding: 5px !important;
  font-size: 16px !important;
}

/* .body {
  width: 1600px;
  margin-left: 15px;
} */
</style>
