<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <!-- errors字符串组不知在哪儿 -->
      <ValidationProvider
        vid="username"
        name="Username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group ">
          <label for="usernameInput">Username</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="usernameInput"
            aria-describedby="usernameHelp"
            v-model="user.username"
            placeholder="Enter username"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/auth/password-reset1" class="float-right">
            Forgot password?
          </router-link>
          <input
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            v-model="user.password"
            placeholder="Password"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType" />
          <label class="custom-control-label" :for="formType"
            >Remember Me</label
          >
        </div>
        <button type="submit" class="btn btn-primary float-right">
          Sign in
        </button>
      </div>
      <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link to="/dark/auth/sign-up1" class="iq-waves-effect pr-4" v-if="$route.meta.dark">
              Sign up
            </router-link>
            <router-link to="/auth/sign-up1" class="iq-waves-effect pr-4" v-else>
              Sign up
            </router-link>
          </span>
      </div> -->
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import auth from '../../../../services/auth'

export default {
  name: 'SignIn1Form',
  props: ['formType', 'username', 'password'],
  data: () => ({
    user: {
      username: '',
      password: ''
    }
  }),
  mounted() {
    this.user.username = this.$props.username
    this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    onSubmit() {
      this.login()
    },
    async login() {
      let formData = new FormData()
      formData.append('username', this.user.username)
      formData.append('password', this.user.password)
      await auth
        .login(formData)
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('isLogin', 'true')
            localStorage.setItem(
              'loginUser',
              JSON.stringify(response.data.detail)
            )
            this.$store.commit(
              'Setting/initUserInfo',
              JSON.parse(localStorage.getItem('loginUser'))
            )
            // console.log(JSON.parse(localStorage.getItem('loginUser')))
            // 跳转到医生主页面
            this.$router.push('/a1')
          }
          alert(response.data.message)
        })
        .catch(error => {
          alert(error.message)
        })
    }
  }
}
</script>
