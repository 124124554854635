import axios from 'axios'
import config from '../config'
import {
  medicalStandard,
  patientMap,
  medicalUnit,
  medicalCharChinese
} from './info'

// 去掉末尾的斜杠
config.apiUrl = config.apiUrl + (config.apiUrl.endsWith('/') ? '' : '/')

const getApi = async (api, params) => {
  return (
    await axios.get(config.apiUrl + api + window.location.search, {
      params: {
        data: JSON.stringify(params)
      }
    })
  ).data.data
}

const flipObjectAsArray = obj => {
  const arr = []
  for (let [key, value] of Object.entries(obj)) {
    value.forEach((o, i) => {
      if (!arr[i]) arr[i] = {}
      arr[i][key] = o
    })
  }
  return arr
}

const flipArrayAsObject = array => {
  const obj = {}
  array.forEach(item => {
    for (let [key, value] of Object.entries(item)) {
      if (!obj[key]) {
        obj[key] = [value]
      } else {
        obj[key].push(value)
      }
    }
  })
  return obj
}

export const getAnalyzeAdvice = (lab, analyze, itemList) => {
  let flag = false
  let flag1 = false
  let index = []
  let index1 = []
  let advice = []
  // let variation = []
  const rawLab = flipArrayAsObject(lab)
  const rawAttention = flipArrayAsObject(analyze.attention)
  /* for (let i = 1; i < analyze.predict.length; ++i) {
    variation.push(analyze.predict[i] / analyze.predict[i - 1] - 1)
  }
  let cnt = 0
  for (let i = 0; i < variation.length; ++i) {
    if (variation >= 0.1) {
      k = 1
    }
  } */
  for (let i = 2; i < analyze.predict.length; ++i) {
    if (
      i < analyze.predict.length - 2 &&
      analyze.predict[i - 1] >= analyze.predict[i - 2] * 1.2 &&
      analyze.predict[i] >= analyze.predict[i - 1] * 1.1 &&
      analyze.predict[i] >= 0.15
    ) {
      flag1 = true
      index1.push([i - 2, i])
    } else if (
      (!flag1 || index1.slice(-1)[0][1] !== i - 1) &&
      analyze.predict[i - 1] - analyze.predict[i - 2] >= 0.1
    ) {
      flag = true
      index.push(i - 1)
    }
  }
  if (
    (!flag1 || index1.slice(-1)[0][1] !== analyze.predict.length - 1) &&
    analyze.predict[analyze.predict.length - 1] -
      analyze.predict[analyze.predict.length - 2] >=
      0.1
  ) {
    flag = true
    index.push(analyze.predict.length - 1)
  }
  if (flag) {
    let index_item = {}
    itemList.forEach(item => {
      for (let i = 0; i < index.length; ++i) {
        let lab_variation =
          Number(rawLab[item][index[i]]) / Number(rawLab[item][index[i] - 1]) -
          1
        let lab_attention_variation =
          rawAttention[item][index[i]] / rawAttention[item][index[i] - 1] - 1
        if (
          lab_variation &&
          ((rawAttention[item][index[i]] - rawAttention[item][index[i] - 1] >=
            0.05 &&
            rawAttention[item][index[i]] >= 0.1) ||
            rawAttention[item][index[i]] >= 0.25 ||
            (Math.abs(lab_variation) >= 2 &&
              (rawLab[item][index[i]] > medicalStandard[item][1] * 1.2 ||
                rawLab[item][index[i]] < medicalStandard[item][0] * 0.8)))
        ) {
          if (index_item[rawLab.date[index[i]]]) {
            index_item[rawLab.date[index[i]]].push({
              item: item,
              date_index: [index[i] - 1 > 0 ? index[i] - 1 : 0, index[i]],
              risk: analyze.predict[index[i]],
              risk_variation_absolute:
                analyze.predict[index[i]] - analyze.predict[index[i] - 1],
              risk_variation:
                analyze.predict[index[i]] / analyze.predict[index[i] - 1] - 1,
              lab: Number(rawLab[item][index[i]]),
              lab_variation: lab_variation,
              lab_variation_absolute:
                Number(rawLab[item][index[i]]) -
                Number(rawLab[item][index[i] - 1]),
              lab_attention_variation: lab_attention_variation
            })
          } else {
            index_item[rawLab.date[index[i]]] = [
              {
                item: item,
                date_index: [index[i] > 0 ? index[i] : 0, index[i]],
                risk: analyze.predict[index[i]],
                risk_variation_absolute:
                  analyze.predict[index[i]] - analyze.predict[index[i] - 1],
                risk_variation:
                  analyze.predict[index[i]] / analyze.predict[index[i] - 1] - 1,
                lab: Number(rawLab[item][index[i]]),
                lab_variation: lab_variation,
                lab_variation_absolute:
                  Number(rawLab[item][index[i]]) -
                  Number(rawLab[item][index[i] - 1]),
                lab_attention_variation: lab_attention_variation
              }
            ]
          }
        }
      }
    })
    for (let [date, itemList] of Object.entries(index_item)) {
      advice.push({
        type: 0,
        date: date,
        date_index: itemList[0].date_index,
        merge: itemList[0].date_index[0] === analyze.predict.length - 1,
        item: itemList.map(o => o.item),
        risk: itemList[0].risk,
        risk_variation_absolute: itemList[0].risk_variation_absolute,
        risk_variation: itemList[0].risk_variation,
        lab: itemList.map(o => o.lab),
        lab_variation: itemList.map(o => o.lab_variation),
        lab_variation_absolute: itemList.map(o => o.lab_variation_absolute),
        lab_attention_variation: itemList.map(o => o.lab_attention_variation)
      })
    }
  }
  if (flag1) {
    let index1_merge = []
    let left = -1
    let right = -1
    for (let i = 0; i < index1.length; ++i) {
      if (index1[i][0] > right) {
        if (left >= 0) {
          index1_merge.push([left, right])
        }
        left = index1[i][0]
      }
      right = index1[i][1]
    }
    index1_merge.push([left, right])
    let index_item = {}
    itemList.forEach(item => {
      for (let i = 0; i < index1_merge.length; ++i) {
        let lab_attention_variation =
          rawAttention[item][index1_merge[i][1]] /
            rawAttention[item][index1_merge[i][0]] -
          1
        let lab_variation =
          Number(rawLab[item][index1_merge[i][1]]) /
            Number(rawLab[item][index1_merge[i][0]]) -
          1
        if (
          ((lab_attention_variation >= 0.2 &&
            rawAttention[item][index1_merge[i][1]] >= 0.05) ||
            rawAttention[item][index1_merge[i][1]] >= 0.15 ||
            (Math.abs(lab_variation) >= 2 &&
              (rawLab[item][index1_merge[i][1]] >
                medicalStandard[item][1] * 1.2 ||
                rawLab[item][index1_merge[i][1]] <
                  medicalStandard[item][0] * 0.8))) &&
          lab_variation
        ) {
          let date =
            rawLab.date[index1_merge[i][0]].slice(0, 10) +
            ' ~ ' +
            rawLab.date[index1_merge[i][1]].slice(0, 10)
          if (index_item[date]) {
            index_item[date].push({
              item: item,
              date_index: [
                index1_merge[i][0] > 0 ? index1_merge[i][0] : 0,
                index1_merge[i][1] < rawLab[item].length - 1
                  ? index1_merge[i][1]
                  : rawLab[item].length - 1
              ],
              risk: analyze.predict[index1_merge[i][1]],
              risk_variation:
                analyze.predict[index1_merge[i][1]] /
                  analyze.predict[index1_merge[i][0]] -
                1,
              risk_variation_absolute:
                analyze.predict[index1_merge[i][1]] -
                analyze.predict[index1_merge[i][0]],
              lab: Number(rawLab[item][index1_merge[i][1]]),
              lab_variation: lab_variation,
              lab_variation_absolute:
                Number(rawLab[item][index1_merge[i][1]]) -
                Number(rawLab[item][index1_merge[i][0]]),
              lab_attention_variation: lab_attention_variation
            })
          } else {
            index_item[date] = [
              {
                item: item,
                date_index: [
                  index1_merge[i][0] > 0 ? index1_merge[i][0] : 0,
                  index1_merge[i][1] < rawLab[item].length - 1
                    ? index1_merge[i][1]
                    : rawLab[item].length - 1
                ],
                risk: analyze.predict[index1_merge[i][1]],
                risk_variation:
                  analyze.predict[index1_merge[i][1]] /
                    analyze.predict[index1_merge[i][0]] -
                  1,
                risk_variation_absolute:
                  analyze.predict[index1_merge[i][1]] -
                  analyze.predict[index1_merge[i][0]],
                lab: Number(rawLab[item][index1_merge[i][1]]),
                lab_variation: lab_variation,
                lab_variation_absolute:
                  Number(rawLab[item][index1_merge[i][1]]) -
                  Number(rawLab[item][index1_merge[i][0]]),
                lab_attention_variation: lab_attention_variation
              }
            ]
          }
        }
      }
    })
    for (let [date, itemList] of Object.entries(index_item)) {
      advice.push({
        type: 1,
        date: date,
        date_index: itemList[0].date_index,
        item: itemList.map(o => o.item),
        risk: itemList[0].risk,
        risk_variation: itemList[0].risk_variation,
        risk_variation_absolute: itemList[0].risk_variation_absolute,
        lab: itemList.map(o => o.lab),
        lab_variation: itemList.map(o => o.lab_variation),
        lab_variation_absolute: itemList.map(o => o.lab_variation_absolute),
        lab_attention_variation: itemList.map(o => o.lab_attention_variation)
      })
    }
  }
  let items = []
  let trends = []
  itemList.forEach(item => {
    let flag2 = true
    rawAttention[item].forEach(value => {
      if (value < 0.1) {
        flag2 = false
      }
    })
    if (flag2) {
      items.push(item)
      const avg =
        rawLab[item].reduce((acc, val) => acc + Number(val), 0) /
        rawLab[item].length
      if (medicalStandard[item]) {
        if (avg < medicalStandard[item][0]) {
          trends.push(-1)
        } else if (avg > medicalStandard[item][1]) {
          trends.push(1)
        } else {
          trends.push(0)
        }
      } else if (item === 'weight') {
        if (avg < 50) {
          trends.push(-1)
        } else if (avg > 80) {
          trends.push(1)
        } else {
          trends.push(0)
        }
      } else if (item === 'amount') {
        if (avg < 2000) {
          trends.push(-1)
        } else if (avg > 6000) {
          trends.push(1)
        } else {
          trends.push(0)
        }
      }
    }
  })
  if (items.length) {
    advice.push({
      date:
        rawLab.date[0].slice(0, 10) +
        ' ~ ' +
        rawLab.date.slice(-1)[0].slice(0, 10),
      date_index: [0, rawLab.date.length - 1],
      type: 2,
      item: items,
      trend: trends
    })
  }
  let left = -1
  let continued_high_risk_index = []
  for (let i = 0; i < analyze.predict.length; ++i) {
    if (analyze.predict[i] >= 0.5) {
      if (left === -1) {
        left = i
      }
    } else {
      if (left !== -1 && i - left > 5) {
        continued_high_risk_index.push([left, i - 1])
      }
      left = -1
    }
  }
  if (left !== -1 && analyze.predict.length - left >= 5) {
    continued_high_risk_index.push([left, analyze.predict.length - 1])
  }
  if (continued_high_risk_index.length) {
    continued_high_risk_index.forEach(index => {
      let items = []
      let length = index[1] - index[0] + 1
      itemList.forEach(item => {
        let sum = 0
        for (let i = index[0]; i <= index[1]; ++i) {
          sum += rawAttention[item][i]
        }
        if (sum >= 0.2 * length) {
          items.push(item)
        }
      })
      if (items.length) {
        advice.push({
          type: 3,
          date:
            rawLab.date[index[0]].slice(0, 10) +
            ' ~ ' +
            rawLab.date[index[1]].slice(0, 10),
          date_index: [index[0], index[1]],
          item: items
        })
      }
    })
  }
  return advice.sort((a, b) =>
    a.date && b.date ? (a.date < b.date ? 1 : -1) : a.date ? -1 : 1
  )
}
export const getTime = () => {
  var myDate = new Date()
  var myYear = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
  var myMonth = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
  var myToday = myDate.getDate() // 获取当前日(1-31)
  // var myDay = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
  var myHour = myDate.getHours() // 获取当前小时数(0-23)
  var myMinute = myDate.getMinutes() // 获取当前分钟数(0-59)
  var mySecond = myDate.getSeconds() // 获取当前秒数(0-59)
  // var week = [
  //   '星期日',
  //   '星期一',
  //   '星期二',
  //   '星期三',
  //   '星期四',
  //   '星期五',
  //   '星期六'
  // ]
  let nowTime

  nowTime =
    myYear +
    '-' +
    myMonth +
    '-' +
    myToday +
    ' ' +
    myHour +
    ':' +
    myMinute +
    ':' +
    mySecond
  // console.log(nowTime);
  return nowTime
}
const APIHelper = {
  // 新增一条病人基本信息
  addPatientBasicDetails: async patient => {
    const res = await axios({
      url: 'http://47.93.42.104:10408/v1/app/patients/basics',
      method: 'post',
      data: patient,
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }
      ],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return res
  },
  // 通过id获取病人基本信息
  getPatientBasicDetailsById: async id => {
    // const res = await axios({
    //   url: 'http://47.93.42.104:5000/patient',
    //   method: 'get',
    //   params: { data: { pdid: id } },
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // })
    const res = await axios.get(
      'http://47.93.42.104:10408/v1/app/patients/basics/' + id
    )
    return res.data.data
  },
  // 通过id列表获取若干病人基本信息
  getPatientBasicDetailsByIdList: async ids => {
    return (
      await axios.get(
        'http://47.93.42.104:10408/v1/app/patients/basics/list/' + ids
      )
    ).data.data
  },
  // 通过id列表获取若干病人的历史就诊记录
  getPatientLabtestByIdList: async ids => {
    const res = await axios.get(
      'http://47.93.42.104:10408/v1/app/patients/labtest/list/' + ids
    )
    return res.data.data
  },
  // 通过id更新病人基本信息
  // 一定要用FormData格式 要不然更新失败
  updatePatientBasicDetailsById: async (id, patient) => {
    const res = await axios({
      url: 'http://47.93.42.104:10408/v1/app/patients/basics/' + id,
      method: 'put',
      data: patient,
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }
      ],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return res

    // const res = await axios.put('http://47.93.42.104:10408/v1/app/patients/basics/94', {
    //   pdid: '94',
    //   name: '1',
    //   gender: '0',
    //   birthDate: '20060102',
    //   age: '1',
    //   originDisease: '1',
    //   diabetes: '1',
    //   death: '1',
    //   deathDate: '20060102',
    //   deathAge: '1',
    //   deathReason: '1',
    //   deathReasonText: '1',
    //   outcome: '1',
    //   duplicate: '1',
    //   height: '1',
    //   weight: '1',
    //   scr: '1',
    //   heart_failure: '1',
    //   lung_infect: '1',
    //   chd: '1',
    //   mi: '1',
    //   ci: '1',
    //   ch: '1',
    //   amputation: '1',
    //   urea: '1',
    //   albumin: '1',
    //   ca: '1',
    //   p: '1',
    //   pth: '1',
    //   bmi: '1',
    //   epi: '1',
    //   sbp: '1',
    //   dbp: '1',
    //   death_number: '1'
    // })
    // var para = JSON.stringify({
    //   pdid: '20060102',
    //   name: '1',
    //   gender: '20060102',
    //   birthDate: '20060102',
    //   age: '20060102',
    //   originDisease: '1',
    //   diabetes: '20060102',
    //   death: '20060102',
    //   deathDate: '20060102',
    //   deathAge: '20060102',
    //   deathReason: '20060102',
    //   deathReasonText: '1',
    //   outcome: '20060102',
    //   duplicate: '20060102',
    //   height: '20060102',
    //   weight: '20060102',
    //   scr: '20060102',
    //   heart_failure: '20060102',
    //   lung_infect: '20060102',
    //   chd: '20060102',
    //   mi: '20060102',
    //   ci: '20060102',
    //   ch: '20060102',
    //   amputation: '20060102',
    //   urea: '20060102',
    //   albumin: '20060102',
    //   ca: '20060102',
    //   p: '20060102',
    //   pth: '20060102',
    //   bmi: '20060102',
    //   epi: '20060102',
    //   sbp: '20060102',
    //   dbp: '20060102',
    //   death_number: '20060102' })
    // var para = JSON.stringify(patient)
    // console.log(para)
    // const res = await axios.put('http://47.93.42.104:10408/v1/app/patients/basics/' + id, para)
  },
  // 通过id删除病人基本信息
  deletePatientBasicDetailsById: async id => {
    const res = await axios.delete(
      'http://47.93.42.104:10408/v1/app/patients/basics/' + id
    )
    return res.data
  },
  // 分页获取所有病人基本信息
  getAllPatientsBasicDetailByPage: async (pageIndex, pageSize) => {
    const res = await axios.get(
      'http://47.93.42.104:10408/v1/app/patients/basics/page_index/' +
        pageIndex +
        '/page_size/' +
        pageSize
    )
    return res.data.data
  },
  // 获取所有病人就诊信息
  getAllPatient: async () => {
    const res = await axios.get('http://47.93.42.104:10408/v1/app/patients/labtest')
    // console.log(res) 13146个array 太大了
    return res.data.data
  },
  // 通过id获取病人就诊信息
  getPatientsLabTestById: async id => {
    const res = await axios.get(
      'http://47.93.42.104:10408/v1/app/patients/labtest/' + id
    )
    return res.data.data
  },
  // 通过id和日期增加病人就诊信息
  addPatientsLabTestById: async form => {
    const res = await axios({
      url: 'http://47.93.42.104:10408/v1/app/patients/labtest',
      method: 'post',
      data: form,
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }
      ],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return res
  },
  // 通过id和日期删除病人就诊信息
  deletePatientsLabTestById: async (id, date) => {
    // console.log(`http://47.93.42.104:10408/v1/app/patients/labtest/${id}/${date}`)
    const res = await axios.delete(
      `http://47.93.42.104:10408/v1/app/patients/labtest/${id}/${date}`
    )
    return res
  },
  // 通过id和日期修改病人就诊信息
  updatePatientsLabTestById: async (id, form) => {
    const res = await axios({
      url: 'http://47.93.42.104:10408/v1/app/patients/labtest/' + id,
      method: 'put',
      data: form,
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }
      ],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return res
  },

  // 获取所有病人基本信息
  getAllPatientsBasicDetails: async () => {
    const res = await axios.get('http://47.93.42.104:10408/v1/app/patients/basics')
    return res.data.data
  },
  // 从ES分页获取所有病人就诊信息
  getAllPatientsFromES: async () => {
    const res = await axios.get('http://47.93.42.104:9200/patient/_search/')
    return res
  },
  // 从ES分页获取所有病人就诊信息
  getAllPatientsFromESByPage: async (pageIndex, pageSize) => {
    const res = await axios({
      url:
        'http://47.93.42.104:9200/patient/_search?source_content_type=application/json&source={"query": {"match_all":{}},"size": ' +
        pageSize +
        ',"from": ' +
        pageIndex +
        '}'
    })
    return res
  },
  // 从ES根据编号搜索患者
  searchPatientsByPdidFromES: async pdid => {
    const res = await axios({
      url:
        'http://47.93.42.104:9200/patient/_search?source_content_type=application/json&source={"query": {"match": {"data__pdid": "' +
        pdid +
        '"}}}'
    })
    return res
  },
  // 从ES根据输入的条件筛选患者
  searchPatientsFromESByPage: async (
    age,
    gender,
    origin_disease,
    pageIndex,
    pageSize
  ) => {
    let url =
      'http://47.93.42.104:9200/patient/_search?source_content_type=application/json&source={"query": {"bool": {"must": ['
    let cnt = 0
    if (age !== '') {
      url += '{ "match": { "data__age":  "' + age + '" }}'
      cnt++
    }
    if (gender !== '') {
      if (cnt !== 0) url += ','
      url += '{ "match": { "data__gender":  "' + gender + '" }}'
      cnt++
    }
    if (origin_disease !== '') {
      if (cnt !== 0) url += ','
      url +=
        '{"wildcard": {"data__origin_disease": "*' + origin_disease + '*"}}'
      cnt++
    }
    url += ']}},"size": ' + pageSize + ',"from": ' + pageIndex + '}'
    const res = await axios({
      url: url
    })
    return res
  },
  getCovidAllPatientById: async (top_visit, ids) => {
    const lab = await getApi('covidLab/?secret=yuyuyzl', {
      all: true,
      pdid: ids
    })
    // console.log('covidlab', lab)
    let labs = {}
    for (var i in lab) {
      if (!labs.hasOwnProperty(lab[i]['pdid'])) {
        labs[lab[i]['pdid']] = []
      }
      labs[lab[i]['pdid']].push(lab[i]['date'])
    }
    // console.log('labs: ', labs)
    let patients = await getApi('covidPatient/?secret=yuyuyzl', {
      all: true,
      pdid: ids
    })
    // console.log('covidPatient', patients)
    // console.log('labs[pdid]: ', labs[patients[0]['pdid']][top_visit[patients[0]['pdid']]])
    for (var j in patients) {
      patients[j]['focusTime'] =
        labs[patients[j]['pdid']][0].substring(0, 10) +
        ' : ' +
        labs[patients[j]['pdid']][top_visit[patients[j]['pdid']]].substring(
          0,
          10
        )
      if (patients[j]['Admission time'] != null) {
        patients[j]['Admission time'] = patients[j]['Admission time']
          .replace('T', ' ')
          .replace('.000Z', '')
      }
      if (patients[j]['Discharge time'] != null) {
        patients[j]['Discharge time'] = patients[j]['Discharge time']
          .replace('T', ' ')
          .replace('.000Z', '')
      }
      // 隐私替换
      let ft = patients[j]['focusTime']
      let at = patients[j]['Admission time']
      let dt = patients[j]['Discharge time']
      patients[j]['focusTime'] =
        ft.split('-')[0] + '-**-' + ft.split('-')[2] + '-**-' + ft.split('-')[4]
      if (at != null) {
        patients[j]['Admission time'] =
          at.split('-')[0] + '-**-' + at.split('-')[2]
      }
      if (dt != null) {
        patients[j]['Discharge time'] =
          dt.split('-')[0] + '-**-' + dt.split('-')[2]
      }
    }
    // console.log('patients: ', patients)
    // console.log('ids: ', ids)
    return patients
  },

  getCovidPatientById: async id => {
    let patient = await getApi('covidPatient/?secret=yuyuyzl', { pdid: +id })
    if (patient['Admission time'] != null) {
      patient['Admission time'] = patient['Admission time']
        .replace('T', ' ')
        .replace('.000Z', '')
    }
    if (patient['Discharge time'] != null) {
      patient['Discharge time'] = patient['Discharge time']
        .replace('T', ' ')
        .replace('.000Z', '')
    }
    // 隐私替换
    let at = patient['Admission time']
    let dt = patient['Discharge time']
    // let date = patient['date']
    if (at != null) {
      patient['Admission time'] = at.split('-')[0] + '-**-' + at.split('-')[2]
    }
    if (dt != null) {
      patient['Discharge time'] = dt.split('-')[0] + '-**-' + dt.split('-')[2]
    }
    // patient['date'] = date.split('-')[0] + '-**-' + dt.split('-')[2]
    // console.log('!!!!!!!!!!!!!')
    // console.log(patient)
    return patient
  },
  getCovidLabById: async id => {
    let lab = await getApi('covidLab/?secret=yuyuyzl', { pdid: +id })
    for (let i in lab) {
      lab[i]['date'] = lab[i]['date'].replace('T', ' ').replace('.000Z', '')
    }
    return lab
  },
  getMockData: async id => {
    const res = await axios.post('/api/data', { pdid: id })
    return res.data
  },
  getCovidTimeById: async id => {
    let time = await getApi('covidTime/?secret=yuyuyzl', { pdid: +id })
    return time
  },
  getCovidAnalyze: async patient => {
    // const res = (await axios.post('http://47.93.42.104:10411/', patient)).data
    const res = (await axios.post('http://47.93.42.104:10418', patient)).data
    // console.log('res: ', res)
    return res
  },
  getChallengePatientById: async id => {
    let patient = await getApi('challengePatient/?secret=yuyuyzl', {
      name: +id
    })
    return patient
  },
  getChallengeLabById: async id => {
    let lab = await getApi('challengeLab/?secret=yuyuyzl', { name: +id })
    return lab
  },
  getChallengeXById: async id => {
    let x = await getApi('challengeX/?secret=yuyuyzl', { name: +id })
    return x
  },
  getChallengeYById: async id => {
    let y = await getApi('challengeY/?secret=yuyuyzl', { name: +id })
    return y
  },
  getChallengeDemoById: async id => {
    let demo = await getApi('challengeDemo/?secret=yuyuyzl', { name: +id })
    return demo
  },
  getChallengeAnalyze: async x => {
    let analyze = (await axios.post('http://47.93.42.104:10417/', x)).data
    return analyze
  },
  getPatientById: async id => {
    if (id.substr(0, 1) === 'A') {
      id = 'a' + id.substr(1)
    }
    if (patientMap[id]) {
      id = patientMap[id]
    }
    // return getApi('patient', { pdid: +id })
    return APIHelper.getPatientBasicDetailsById(id)
  },
  getAllPatientById: async (top_visit, ids) => {
    // const lab = await getApi('lab/?secret=yuyuyzl', { all: true, pdid: ids })
    const lab = await (
      await axios.get(
        'http://47.93.42.104:10408/v1/app/patients/labtest/list/' +
          JSON.stringify(ids)
      )
    ).data.data
    // console.log('lab: ', lab[0]['date'])
    let labs = {}
    for (var i in lab) {
      if (!labs.hasOwnProperty(lab[i]['pdid'])) {
        labs[lab[i]['pdid']] = []
      }
      labs[lab[i]['pdid']].push(lab[i]['date'])
    }
    // console.log('labs: ', labs)
    // let patients = await getApi('patient/?secret=yuyuyzl', { all: true, pdid: ids })
    let patients = (
      await axios.get(
        'http://47.93.42.104:10408/v1/app/patients/basics/list/' +
          JSON.stringify(ids)
      )
    ).data.data
    // console.log('labs[pdid]: ', labs[patients[0]['pdid']][top_visit[patients[0]['pdid']]])
    for (var j in patients) {
      patients[j]['focusTime'] =
        labs[patients[j]['pdid']][0].substring(0, 7) +
        ' : ' +
        labs[patients[j]['pdid']][top_visit[patients[j]['pdid']]].substring(
          0,
          7
        )
    }
    // console.log('patients: ', patients)
    // console.log('ids: ', ids)
    return patients
  },
  searchPatient: async key => {
    return getApi('search', { key: key })
  },
  getLabById: async id => {
    if (id.substr(0, 1) === 'A') {
      id = 'a' + id.substr(1)
    }
    if (patientMap[id]) {
      id = patientMap[id]
    }
    const res = APIHelper.getPatientsLabTestById(id)
    return res
    // return getApi('lab', { pdid: +id })
  },
  getAdvice: async patient => {
    let res = (await axios.post('http://47.93.42.104:10410/', patient)).data
    // var res = (await axios.post('http://localhost:10408/', patient)).data

    let valid = res.valid
    res = res.advice

    res.advice_st.curContent = parseFloat(res.advice_st.curContent)
    res.advice_st.oriContent = parseFloat(res.advice_st.oriContent)
    res.advice_nd.curContent_st = parseFloat(res.advice_nd.curContent_st)
    res.advice_nd.oriContent_st = parseFloat(res.advice_nd.oriContent_st)
    res.advice_nd.curContent_nd = parseFloat(res.advice_nd.curContent_nd)
    res.advice_nd.oriContent_nd = parseFloat(res.advice_nd.oriContent_nd)

    const contentRate = Math.abs(
      ((res.advice_st.curContent - res.advice_st.oriContent) /
        res.advice_st.oriContent) *
        100
    ).toFixed(2)
    const ristRate = Math.abs(
      ((res.advice_st.curRisk - res.advice_st.oriRisk) /
        res.advice_st.oriRisk) *
        100
    ).toFixed(2)

    const contentRate_st = Math.abs(
      ((res.advice_nd.curContent_st - res.advice_nd.oriContent_st) /
        res.advice_nd.oriContent_st) *
        100
    ).toFixed(2)
    const contentRate_nd = Math.abs(
      ((res.advice_nd.curContent_nd - res.advice_nd.oriContent_nd) /
        res.advice_nd.oriContent_nd) *
        100
    ).toFixed(2)
    const ristRate_nd = Math.abs(
      ((res.advice_nd.curRisk - res.advice_nd.oriRisk) /
        res.advice_nd.oriRisk) *
        100
    ).toFixed(2)

    return {
      valid: valid,
      mainkey: res.advice_nd.key_st,
      subkey: res.advice_nd.key_nd,
      curRisk: (res.advice_nd.oriRisk * 100).toFixed(2),
      mainKey: medicalCharChinese[res.advice_nd.key_st],
      mainKeyValue:
        parseFloat(res.advice_nd.oriContent_st).toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_st],
      subKey: medicalCharChinese[res.advice_nd.key_nd],
      subKeyValue:
        parseFloat(res.advice_nd.oriContent_nd).toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_nd],
      ad1_mainKeyDirection:
        res.advice_st.curContent > res.advice_st.oriContent ? 1 : 0,
      ad1_mainKeyValue:
        res.advice_st.curContent.toFixed(2) +
        ' ' +
        medicalUnit[res.advice_st.key],
      ad1_mainKeyValueDiff:
        Math.abs(
          res.advice_st.curContent - parseFloat(res.advice_nd.oriContent_st)
        ).toFixed(2) +
        ' ' +
        medicalUnit[res.advice_st.key],
      ad1_mainKeyProp: contentRate,
      ad1_risk: (res.advice_st.curRisk * 100).toFixed(2),
      ad1_riskDiff: (
        -(res.advice_st.curRisk - res.advice_st.oriRisk) * 100
      ).toFixed(2),
      ad1_riskProp: ristRate,

      ad2_mainKeyDirection:
        res.advice_nd.curContent_st > res.advice_nd.oriContent_st ? 1 : 0,
      ad2_mainKeyValueDiff:
        Math.abs(
          res.advice_nd.curContent_st - parseFloat(res.advice_nd.oriContent_st)
        ).toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_st],
      ad2_mainKeyValue:
        res.advice_nd.curContent_st.toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_st],
      ad2_mainKeyProp: contentRate_st,
      ad2_subKeyDirection:
        res.advice_nd.curContent_nd > res.advice_nd.oriContent_nd ? 1 : 0,
      ad2_subKeyValue:
        res.advice_nd.curContent_nd.toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_nd],
      ad2_subKeyValueDiff:
        Math.abs(
          res.advice_nd.curContent_nd - parseFloat(res.advice_nd.oriContent_nd)
        ).toFixed(2) +
        ' ' +
        medicalUnit[res.advice_nd.key_nd],
      ad2_subKeyProp: contentRate_nd,
      ad2_risk: (res.advice_nd.curRisk * 100).toFixed(2),
      ad2_riskDiff: (
        -(res.advice_nd.curRisk - res.advice_nd.oriRisk) * 100
      ).toFixed(2),
      ad2_riskProp: ristRate_nd
    }
  },
  getAdviceOnRisk: async (patient, riskThreshold) => {
    let res = (
      await axios.post('http://47.93.42.104:10415/', {
        patient: patient,
        riskThreshold: riskThreshold
      })
    ).data
    // console.log('patient:415:', patient)
    // let res = (await axios.post('http://localhost:10415/', { 'patient': patient, 'riskThreshold': riskThreshold })).data
    // var res = (await axios.post('http://localhost:10408/', patient)).data
    let valid = res.valid
    res = res.advice
    var idx = res.idx
    var advice = {}
    advice.idx = []
    advice.oriRisk = (parseFloat(res.oriRisk) * 100).toFixed(1)
    advice.curRisk = (parseFloat(res.curRisk) * 100).toFixed(1)
    advice.riskProp = Math.abs(
      ((advice.curRisk - advice.oriRisk) / advice.oriRisk) * 100
    ).toFixed(1)
    advice.riskDiff = (advice.oriRisk - advice.curRisk).toFixed(1)
    for (var key in idx) {
      let tmp = {}
      tmp.key = key
      tmp.oriValue =
        parseFloat(idx[key].oriContent).toFixed(1) + medicalUnit[key]
      tmp.curValue =
        parseFloat(idx[key].curContent).toFixed(1) + medicalUnit[key]
      tmp.valueDiff = Math.abs(
        parseFloat(idx[key].curContent) - parseFloat(idx[key].oriContent)
      ).toFixed(1)
      tmp.direction =
        parseFloat(idx[key].curContent) > parseFloat(idx[key].oriContent)
          ? 1
          : 0
      tmp.prop = Math.abs(
        ((parseFloat(idx[key].curContent) - parseFloat(idx[key].oriContent)) /
          parseFloat(idx[key].oriContent)) *
          100
      )
      advice.idx.push(tmp)
    }
    return {
      valid: valid,
      advice: advice
    }
  },
  getAdviceOnIdx: async (patient, riskThreshold) => {
    let res = (
      await axios.post('http://47.93.42.104:10416/', {
        patient: patient,
        riskThreshold: riskThreshold
      })
    ).data
    // let res = (await axios.post('http://localhost:10416/', { 'patient': patient, 'riskThreshold': riskThreshold })).data
    // var res = (await axios.post('http://localhost:10408/', patient)).data
    for (let i in res) {
      res[i].idxChinese = medicalCharChinese[res[i].idx]
    }
    return res
  },
  getAnalyze: async (patient, apiList) => {
    // let patient= {
    //     patient: {
    //         'pdid': patientData.patient.pdid,
    //         'name': patientData.patient.name,
    //         'birthDate': patientData.patient.birthDate,
    //         'age': patientData.patient.age,
    //         'gender': patientData.patient.gender,
    //         'death': patientData.patient.death,
    //         'deathDate': patientData.patient.deathDate,
    //         'deathAge': patientData.patient.deathAge,
    //         'deathReason': patientData.patient.deathReason,
    //         'height': patientData.patient.height,
    //         'weight': patientData.patient.weight,
    //     },
    // lab:patientData.lab,
    // };

    // const res = (await Promise.all(apiList.filter(api => api.enabled)
    //   .map(api => axios.post(api.url, patient).then(res => ({ data: res.data, api })))))
    //   .reduce((p, c) => ({ ...c.data, ...p }), {})
    const res = (await axios.post('http://47.93.42.104:10407', patient)).data
    if (res && res.attention) res.attention = flipObjectAsArray(res.attention)
    if (res && res.predict_next_value) {
      res.predict_next_value = flipObjectAsArray(res.predict_next_value)
    }
    return res
  },
  getComplicationsAnalyze: async patient => {
    const res = (await axios.post('http://47.93.42.104:10412', patient)).data
    // const res = (await axios.post('http://localhost:10412', patient)).data

    if (res && res.attention) {
      res.attention.forEach((data, index) => {
        res.attention[index] = flipObjectAsArray(data)
      })
    }
    if (res && res.predict_next_value) {
      res.predict_next_value = flipObjectAsArray(res.predict_next_value)
    }
    return res
  },
  getEsrdProtoCareAnalyze: async patient => {
    // const res = (await Promise.all(apiList.filter(api => api.enabled)
    //   .map(api => axios.post(api.url, patient).then(res => ({ data: res.data, api })))))
    //   .reduce((p, c) => ({ ...c.data, ...p }), {})
    // if (res && res.attention) res.attention = flipObjectAsArray(res.attention)
    // if (res && res.predict_next_value) res.predict_next_value = flipObjectAsArray(res.predict_next_value)
    // console.log(res)
    const res = (await axios.post('http://47.93.42.104:10410', patient)).data
    res.attention = flipObjectAsArray(res.attention)
    // console.log(res)
    return res
  }
}
export const getTxtTest = param => {
  let url =
    'http://47.93.42.104:8088/searchSimplePage?page=1&size=3&keyword=' + param
  return axios.get(url)
}
export default APIHelper
