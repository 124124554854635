// import { request } from './request'
import axios from 'axios'
// export function uploadFile(formData) {
//   return request({
//     url: '/web/files/upload',
//     // headers: {
//     //   'Content-Type': 'multipart/form-data'
//     // },
//     method: 'post',
//     data: formData
//   })
// }

// export function downloadFile(file) {
//   return axios({
//     method: 'get',
//     url: 'http://47.93.42.104:10408/v1/web/files/download/' + file
//   })
// }

export function answerAll(obj) {
  return axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: 'http://47.93.42.104:10408/v1/web/questions/answer',
    data: obj
  })
}

export function getLatestbyId(formData) {
  return axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: 'http://47.93.42.104:10408/v1/web/questions/latest',
    data: formData
  })
}

// export function registerFile(formData) {
//   return axios({
//     method: 'post',
//     url: 'http://47.93.42.104:10408/v1/web/excels/doctors/bulk-register',
//     data: formData
//   })
// }

// export function getFileList() {
//   return request({
//     url: '/web/files',
//     method: 'get'
//   })
// }
