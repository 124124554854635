<template>
    <div :id="element"></div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { mapGetters } from 'vuex'
export default {
    name: 'OriginDiseaseChart',
    props: ['element', 'patients'],
    mounted () {
        let _this = this
        let selector = '#' + _this.element
        let x = []
        let y = []
        for (const item of this.patients) {
            if (this.selected.indexOf(item.originDisease) !== -1) {
                const loc = x.indexOf(item.originDisease)
                if (loc === -1) {
                    x.push(item.originDisease)
                    y.push(1)
                } else {
                    y[loc] += 1
                }
            }
        }
        let option = {
            chart: {
                type: 'bar'
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            series: [{
                name: 'amount',
                data: y
            }],
            xaxis: {
                categories: x
            }
        }
        let chart = new ApexCharts(document.querySelector(selector), option)
        chart.render()
        this.instance = chart
    },
    computed: {
        ...mapGetters('Analysis', [
            'selected'
        ])
    },
    data () {
        return {
            lastDate: 0,
            data: [],
            TICKINTERVAL: 86400000,
            XAXISRANGE: 777600000,
            instance: undefined
        }
    },
    methods: {
        getNewSeries (baseval, yrange) {
            let newDate = baseval + this.TICKINTERVAL
            this.lastDate = newDate
            for (var i = 0; i < this.data.length - 10; i++) {
                this.data[i].x = newDate - this.XAXISRANGE - this.TICKINTERVAL
                this.data[i].y = 0
            }
            this.data.push({
                x: newDate,
                y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
            })
        },
        updateChart () {
            let x = []
            let y = []
            for (const item of this.patients) {
                if (this.selected.indexOf(item.originDisease) !== -1) {
                    const loc = x.indexOf(item.originDisease)
                    if (loc === -1) {
                        x.push(item.originDisease)
                        y.push(1)
                    } else {
                        y[loc] += 1
                    }
                }
            }
            let option = {
                chart: {
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                series: [{
                    name: 'amount',
                    data: y
                }],
                xaxis: {
                    categories: x
                }
            }
            this.instance.updateOptions(option)
        }
    },
    watch: {
        selected() {
            this.updateChart()
        }
    }
}
</script>