<template>
  <div class="superManager">
    <iq-card>
      <template v-slot:headerTitle>
        <h2 class="card-title">{{ $t('super_manager.head') }}</h2>
        <b-input-group v-show="!doctor_department_select">
          <b-form-input v-model="input"></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="search">{{
              $t('super_manager.search')
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-dropdown
          :text="selected_department"
          variant="primary"
          v-show="doctor_department_select"
        >
          <b-dropdown-item-button
            v-for="(item, index) in department_to_select"
            :key="(item, index)"
            @click="department_select(index)"
            >{{ item }}</b-dropdown-item-button
          >
        </b-dropdown>
        <b-form-radio-group>
          <b-form-radio v-model="option" value="1" @change="changeRadio(1)">{{
            $t('super_manager.all_doctors')
          }}</b-form-radio>
          <b-form-radio v-model="option" value="2" @change="changeRadio(2)">{{
            $t('super_manager.all_offices')
          }}</b-form-radio>
          <b-form-radio v-model="option" value="3" @change="changeRadio(3)">{{
            $t('super_manager.doctorsOfOffice')
          }}</b-form-radio>
        </b-form-radio-group>
        <alert-bar
          :dismissSecs="dismissSecs1"
          :dismissCountDown="dismissCountDown1"
          :alert_style="alert_style1"
          :alert_content="alert_content1"
        ></alert-bar>
      </template>
      <template v-slot:body>
        <b-form v-show="option === '1'">
          <form-table
            :fields="doctorFields"
            :items="allDoctor"
            @deleteClick="deleteDoctor"
            @modifyAuth="modifyAuth"
            @modifyExPwd="modifyExPwd"
            @modifyPwd="modifyPwd"
          />
        </b-form>
        <b-form v-show="option === '2'">
          <form-table
            :fields="departmentFields"
            :items="allDepartment"
            :canChange="false"
          />
        </b-form>
        <b-form v-show="option === '3'">
          <form-table
            :fields="doctorFields"
            :items="allDoctor"
            @deleteClick="deleteDoctor"
            @modifyAuth="modifyAuth"
            @modifyExPwd="modifyExPwd"
            @modifyPwd="modifyPwd"
          />
        </b-form>
      </template>
    </iq-card>
  </div>
</template>

<script>
import iqCard from '../../components/xray/cards/iq-card.vue'
import { xray } from '../../config/pluginInit'
import {
  getAllDoctor,
  getDepartmentDoctor,
  getAllDepartment,
  deleteDoctor,
  modifyAuth,
  modifyPwd_force
} from '../../network/superManager'
import { modifyPersonalInfo_1 } from '../../network/personalInfo'
import FormTable from '../../components/Table/FormTable'
import AlertBar from '../../components/Alert/AlertBar'
// import i18n from '../../i18n'
export default {
  name: 'SuperManager',
  components: {
    iqCard,
    FormTable,
    AlertBar
  },
  mounted() {
    xray.index()
  },
  data() {
    return {
      // 医生列表相关参数
      doctorFields: [
        {
          key: 'user_id',
          label: this.$t('super_manager.doctor_id'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'username',
          label: this.$t('super_manager.doctor_name'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'department',
          label: this.$t('super_manager.department'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'user_info',
          label: this.$t('super_manager.user_info'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'user_type',
          label: this.$t('super_manager.user_type'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'password',
          label: this.$t('super_manager.password'),
          formatter: value => {
            return value
          }
        },
        {
          key: 'button',
          label: this.$t('super_manager.action'),
          formatter: value => {
            return value
          }
        }
      ],
      departmentFields: [
        {
          key: 'department_name',
          label: this.$t('super_manager.department_name'),
          formatter: value => {
            return value
          }
        }
      ],
      allDoctor: [],
      allDepartment: [],

      input: '',
      option: '',

      dismissSecs1: 2, // 警示框倒计时
      dismissCountDown1: 0,
      alert_style1: 'danger',
      alert_content1: '',
      doctor_department_select: false,
      department_to_select: [],
      selected_department: '请选择您要查询的科室'
    }
  },
  methods: {
    department_select(index) {
      this.selected_department = this.department_to_select[index]
      this.getDepartmentDoctor(this.department_to_select[index])
    },
    changeRadio(index) {
      if (index === 3) {
        this.allDoctor = []
        this.doctor_department_select = true
        getAllDepartment().then(res => {
          // console.log(res.data.data)
          this.department_to_select = res.data.data
        })
      } else {
        this.doctor_department_select = false
        this.selected_department = '请选择您要查询的科室'
      }
    },
    countDownChanged1(dismissCountDown) {
      this.dismissCountDown1 = dismissCountDown
    },
    getAllDoctor() {
      this.allDoctor = []
      this.input = ''
      getAllDoctor().then(res => {
        var arr = res.data.data
        for (var i = 0; i < arr.length; i++) {
          var tag = 0
          for (var j = 0; j < this.allDoctor.length; j++) {
            if (arr[i].user_id === this.allDoctor[j].user_id) {
              tag = 1
              break
            }
          }
          if (tag === 0) {
            arr[i].user_type =
              arr[i].user_type === 1
                ? this.$t('super_manager.super_manager')
                : this.$t('super_manager.doctor')
            var item = {
              username: arr[i],
              user_id: arr[i],
              department: arr[i],
              user_info: arr[i],
              user_type: arr[i],
              password: arr[i],
              button: arr[i]
            }
            this.allDoctor.push(item)
          }
        }
      })
    },
    getDepartmentDoctor(department) {
      this.allDoctor = []
      getDepartmentDoctor(department).then(res => {
        var arr = res.data.data
        for (var i = 0; i < arr.length; i++) {
          var tag = 0
          for (var j = 0; j < this.allDoctor.length; j++) {
            if (arr[i].user_id === this.allDoctor[j].user_id) {
              tag = 1
              break
            }
          }
          if (tag === 0) {
            arr[i].user_type =
              arr[i].user_type === 1
                ? this.$t('super_manager.super_manager')
                : this.$t('super_manager.doctor')
            var item = {
              username: arr[i],
              user_id: arr[i],
              department: arr[i],
              user_info: arr[i],
              user_type: arr[i],
              password: arr[i],
              button: arr[i]
            }
            this.allDoctor.push(item)
          }
        }
      })
    },
    getAllDepartment() {
      this.input = ''
      this.allDepartment = []
      getAllDepartment().then(res => {
        // console.log(res)
        var arr = res.data.data
        for (var i = 0; i < arr.length; i++) {
          var tag = 0
          for (var j = 0; j < this.allDepartment.length; j++) {
            if (arr[i] === this.allDepartment[j].department_name) {
              tag = 1
              break
            }
          }
          if (tag === 0) {
            var item = {
              department_name: arr[i]
            }
            this.allDepartment.push(item)
          }
        }
      })
    },
    search() {
      switch (this.option) {
        case '1':
          this.getAllDoctor()
          break
        case '2':
          this.getAllDepartment()
          break
        case '3':
          this.getDepartmentDoctor()
          break
      }
    },
    deleteDoctor(index) {
      deleteDoctor(index).then(res => {
        // console.log(res)
      })
      if (this.option === '1') {
        this.getAllDoctor()
      } else {
        this.getDepartmentDoctor()
      }
    },
    modifyAuth(index) {
      let form = new FormData()
      // console.log(index)
      form.append('user_type', index.auth)
      modifyAuth(index.user_id, form).then(res => {
        // console.log(res)
      })
    },
    modifyExPwd(index) {
      let form = new FormData()
      form.append('username', index.username)
      form.append('department', index.department)
      form.append('user_info', index.user_info)
      modifyPersonalInfo_1(index.user_id, form).then(res => {
        this.getAllDoctor()
      })
    },
    modifyPwd(index) {
      let form = new FormData()
      form.append('password_new', index.password)
      modifyPwd_force(index.user_id, form).then(res => {
        // console.log(res)
      })
    }
  },
  created() {}
}
</script>

<style scoped></style>
