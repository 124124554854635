<template>
  <b-container fluid>
    <b-row>
      <b-col
        xs="12"
        sm="12"
        md="6"
        lg="5"
      >
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img
                    v-if="patient.gender !== null && patient.gender === 0"
                    src="../../assets/images/user/11.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                  <img
                    v-else
                    src="../../assets/images/user/19.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3">
                  <!--              <h3><b>编号：{{patient.pdid}}</b></h3>-->
                  <h6 class="text-primary">
                    {{ $t('main.DashboardCkd.patientCard.originDisease') }}
                  </h6>
                  <h4 v-if="islogin === 1">
                    <b>{{
                      getLocale()
                        ? patient.originDisease
                        : originMedicalChartEn[patient.originDisease]
                    }}</b>
                  </h4>
                  <h4 v-else>-</h4>
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.id') }}
                    </h6>
                    <h4 v-if="$route.params.patientId.charAt(0) == 'a'">
                      {{ $route.params.patientId }}
                    </h4>
                    <h4 v-else>-</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.gender') }}
                    </h6>
                    <h4 v-if="islogin === 0">-</h4>
                    <h4 v-else-if="patient.gender !== null && patient.gender === 0">
                      {{ $t('main.general.female') }}
                    </h4>
                    <h4 v-else>{{ $t('main.general.male') }}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">
                      {{ $t('main.DashboardCkd.patientCard.birth') }}
                    </h6>
                    <h4 v-if="patient.birthDate != null">
                      {{ patient.birthDate.substring(0, 4) - $store.state.yearMinus }}
                    </h4>
                    <h4 v-else>{{ $t('main.general.noData') }}</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a
                    data-icon="T"
                    class="icon"
                    v-if="display === false"
                    @click="
                      display = !display
                      $store.commit('setShowLine', true)
                    "
                  >
                    <span>{{ $t('main.general.more') }}</span>
                  </a>
                  <a
                    data-icon="W"
                    class="icon"
                    v-else
                    @click="
                      display = !display
                      $store.commit('setShowLine', false)
                    "
                  >
                    <span>{{ $t('main.general.hide') }}</span>
                  </a>
                </div>
                <b-collapse
                  id="collapse-0"
                  class="mb-2"
                  v-model="display"
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.height') }}
                        </p>
                        <h5 v-if="islogin === 0">-</h5>
                        <h5 v-else-if="patient.height == undefined">
                          {{ $t('main.general.noData') }}
                        </h5>
                        <div v-else>
                          <h5>
                            {{ change(patient.height) }}&nbsp;<span>cm</span>
                          </h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.weight') }}
                        </p>
                        <h5 v-if="islogin === 0">-</h5>
                        <h5 v-else-if="patient.weight == undefined">
                          {{ $t('main.general.noData') }}
                        </h5>
                        <div v-else>
                          <h5>
                            {{ change(patient.weight) }}&nbsp;<span>kg</span>
                          </h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.admissionAge') }}
                        </p>
                        <h5 v-if="islogin === 0">-</h5>
                        <h5 v-else-if="patient.age == undefined">
                          {{ $t('main.general.noData') }}
                        </h5>
                        <div v-else>
                          <h5>
                            {{ patient.age }}&nbsp;<span>{{
                              $t('main.general.yearsOld')
                            }}</span>
                          </h5>
                        </div>
                      </div>
                      <div
                        class="data-block mt-3"
                        v-if="patient.death == 1"
                      >
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.deadTime') }}
                        </p>
                        <h5>{{ Number(patient.deathDate.substring(0, 4)) - $store.state.yearMinus +  patient.deathDate.substring(4, 7)}} </h5>
                      </div>
                      <div
                        class="data-block mt-3"
                        v-else
                      >
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.status') }}
                        </p>
                        <h5>{{ $t('main.DashboardCkd.patientCard.cure') }}</h5>
                      </div>
                      <div
                        class="data-block mt-3"
                        v-show="
                          patient.amputation === 1 ||
                            patient.ch === 1 ||
                            patient.ci === 1 ||
                            patient.mi === 1 ||
                            patient.chd === 1 ||
                            patient.lung_infect === 1 ||
                            patient.heart_failure === 1 ||
                            patient.diabetes === 1
                        "
                      >
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.basicDisease') }}
                        </p>
                        <h5>
                          {{
                            (patient.diabetes === 1 ? '糖尿病 ' : '') +
                              (patient.heart_failure === 1 ? '心脏衰竭 ' : '') +
                              (patient.lung_infect === 1 ? '肺部感染 ' : '') +
                              (patient.chd === 1 ? '冠心病 ' : '') +
                              (patient.mi === 1 ? '心梗 ' : '') +
                              (patient.ci === 1 ? '脑梗 ' : '') +
                              (patient.ch === 1 ? '脑出血 ' : '') +
                              (patient.amputation === 1 ? '截肢 ' : '')
                          }}
                        </h5>
                      </div>
                      <div
                        class="data-block mt-3"
                        v-show="patient.death == 1"
                      >
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.deadCause') }}
                        </p>
                        <h5>
                          {{
                            getLocale()
                              ? patient.deathReasonText
                              : deathReasonEn[patient.deathReasonText]
                          }}
                        </h5>
                      </div>
                      <div
                        class="data-block mt-3"
                        v-show="analyze['cluster_id'] !== undefined"
                      >
                        <p class="mb-0">
                          {{ $t('main.DashboardCkd.patientCard.cluster') }}
                        </p>
                        <h5>
                          <span>{{
                              $t('main.DashboardCkd.patientCard.clusterNo1')
                            }} </span>{{ analyze.cluster_id }}
                          <span>
                            {{
                              $t('main.DashboardCkd.patientCard.clusterNo2')
                            }}</span>
                        </h5>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- patient card end -->
        <b-col>
          <!--          <iq-card class-name="iq-card-block iq-card-stretch" body-class="iq-bg-primary rounded">-->
          <!--            <template v-slot:body>-->
          <!--              <div class="d-flex align-items-center" style="padding-top:10px;flex-direction:column;">-->
          <!--                <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-user-fill"></i></div>-->
          <!--                <div class="text-center">-->
          <!--                  <h2 class="mb-0"><span style="font-size:20px"> </span><span class="counter">医疗建议</span></h2>-->
          <!--                </div>-->
          <!--                <div class="media-body" style="margin:20px">-->
          <!--                  <h5 class="mt-0"></h5>-->
          <!--                  <p class="mb-0">-->
          <!--                    {{advice['info']}}-->
          <!--                    <br/>{{advice['first']}}-->
          <!--                    <br/>{{advice['second']}}-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </iq-card>-->
          <AIObservation
            v-if="reAiObservation"
            :analyzeAdvice="analyzeAdvice"
            :adviceOnRiskThreshold="adviceOnRiskThreshold"
            :adviceOnRisk="adviceOnRisk"
          />
        </b-col>
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('main.general.indicators') }}</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>{{ $t('main.general.clearSelected') }}</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4
                class="card-title text-primary"
                v-show="patient_data_selector.current_important.length"
              >
                {{ $t('main.general.importantIndicators') }}
              </h4>
            </div>
            <div
              class="iq-card-body pl-0 pr-0 pb-0"
              v-show="patient_data_selector.current_important.length"
            >
              <PatientDataSelector
                :item="patient_data_selector.current_important"
                :info="patient_data_selector.info"
                :langType="getLocale()"
              >
              </PatientDataSelector>
            </div>
            <div
              class="iq-header-title"
              v-show="patient_data_selector.history_important.length"
            >
              <h4 class="card-title text-primary">
                {{ $t('main.general.historicallyImportantIndicators') }}
              </h4>
            </div>
            <div
              class="iq-card-body pl-0 pr-0 pb-0"
              v-show="patient_data_selector.history_important.length"
            >
              <PatientDataSelector
                :item="patient_data_selector.history_important"
                :info="patient_data_selector.info"
                :langType="getLocale()"
              >
              </PatientDataSelector>
            </div>
            <div
              class="iq-header-title"
              v-show="patient_data_selector.other.length"
            >
              <div style="padding-bottom:20px;text-align:center">
                <a
                  data-icon="T"
                  class="icon"
                  v-if="display1 === false"
                  @click="display1 = !display1"
                >
                  <span>{{ $t('main.general.more') }}</span>
                </a>
                <a
                  data-icon="W"
                  class="icon"
                  v-else
                  @click="display1 = !display1"
                >
                  <span>{{ $t('main.general.hide') }}</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div
              class="iq-card-body pl-0 pr-0 pb-0"
              v-show="patient_data_selector.other.length"
            >
              <b-collapse
                id="collapse-1"
                class="mb-2"
                v-model="display1"
              >
                <h4 class="card-title text-primary">
                  {{ $t('main.general.otherIndicators') }}
                </h4>
                <PatientDataSelector
                  :item="patient_data_selector.other"
                  :langType="getLocale()"
                ></PatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <PatientDataChart v-if="
            rePatientChart &&
              analyze !== undefined &&
              (selectedGraph.length >= 1 || analyze.predict !== undefined)
          " />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="bg-primary rounded pt-2 pb-2 pr-2">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h4
                class="mb-0"
                style="font-size: 22px;margin-left: 10px;color: white;"
              >
                {{ $t('main.general.similarPatientInformation')
                }}<i
                  class="fa fa-id-card"
                  style="margin-left: 5px;"
                ></i>
              </h4>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4
              class="card-title"
              style="display: inline"
            >
              {{ $t('main.general.top6SimilarPatientList') }}
            </h4>
            <Tooltip placement="top">
              <img
                src="../../assets/images/question.png"
                alt="guide"
                class="guide"
              />
              <span
                slot="content"
                style="white-space: normal"
              >
                {{ $t('main.general.top6SimilarPatientList()') }}
              </span>
            </Tooltip>
          </template>
          <template v-slot:body>
            <div
              class="table-responsive"
              style="margin-left: 5px;"
            >
              <table class="table mb-0 table-borderless">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('main.general.top6SimilarPatientList') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.DashboardCkd.patientCard.originDisease') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.DashboardCkd.patientCard.basicDisease') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.DashboardCkd.patientCard.admissionAge') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.DashboardCkd.patientCard.deathAge') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.DashboardCkd.patientCard.deadCause') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.general.recordsInSimilarStatus') }}
                    </th>
                    <th scope="col">
                      {{ $t('main.general.similarityScore') }}
                      <Tooltip placement="top">
                        <img
                          src="../../assets/images/question.png"
                          alt="guide"
                          class="guide"
                          style="height:20px; margin-top: 5px;"
                        />
                        <span
                          slot="content"
                          style="white-space: normal; font-weight: normal"
                        >
                          {{ $t('main.general.similarityScore()') }}
                        </span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(similarPatient, index) in similarPatients"
                    :key="similarPatient.pdid"
                    :class="{
                      'link-item-active': similarPatient.id == activeLinkId,
                      'link-item-hover': similarPatient.pdid == hoverIndex
                    }"
                    @click="toPatientPage(similarPatient)"
                    @mouseover="hoverIndex = similarPatient.pdid"
                    @mouseout="hoverIndex = -1"
                  >
                    <td>
                      {{
                        similarPatient.gender === 1
                          ? $t('main.general.male')
                          : $t('main.general.female')
                      }}
                    </td>
                    <td>
                      {{
                        getLocale()
                          ? similarPatient.originDisease
                          : originMedicalChartEn[similarPatient.originDisease]
                      }}
                    </td>
                    <td>
                      {{
                        (similarPatient.diabetes === 1
                          ? $t('main.medicalChar.diabetes') + '、'
                          : '') +
                          (similarPatient.heart_failure === 1
                            ? $t('main.medicalChar.heart_failure') + '、'
                            : '') +
                          (similarPatient.lung_infect === 1
                            ? $t('main.medicalChar.lung_infect') + '、'
                            : '') +
                          (similarPatient.chd === 1
                            ? $t('main.medicalChar.chd') + '、'
                            : '') +
                          (similarPatient.mi === 1
                            ? $t('main.medicalChar.mi') + '、'
                            : '') +
                          (similarPatient.ci === 1
                            ? $t('main.medicalChar.ci') + '、'
                            : '') +
                          (similarPatient.ch === 1
                            ? $t('main.medicalChar.ch') + '、'
                            : '') +
                          (similarPatient.amputation === 1
                            ? $t('main.medicalChar.amputation')
                            : '') +
                          (similarPatient.amputation === 1 ||
                          similarPatient.ch === 1 ||
                          similarPatient.ci === 1 ||
                          similarPatient.mi === 1 ||
                          similarPatient.chd === 1 ||
                          similarPatient.lung_infect === 1 ||
                          similarPatient.heart_failure === 1 ||
                          similarPatient.diabetes === 1
                            ? ''
                            : $t('main.general.none'))
                      }}
                    </td>
                    <td>{{ similarPatient.age }}</td>
                    <td>
                      {{
                        similarPatient.death === 0
                          ? $t('main.general.alive')
                          : similarPatient.deathAge
                      }}
                    </td>
                    <td>
                      {{
                        getLocale()
                          ? similarPatient.deathReasonText
                          : deathReasonEn[similarPatient.deathReasonText]
                      }}
                    </td>
                    <td>{{ similarPatient.focusTime }}</td>
                    <td>
                      {{
                        (100 - analyze.top_similarIdx[index] * 100).toFixed(2)
                      }}
                    </td>
                    <!-- <td>{{(analyze.top_similarIdx[index]).toFixed(10)}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card
          class-name="iq-card-block iq-card-stretch"
          :key="genderRatio"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('main.general.clusterInformationStatistics')
              }}<span style="font-size: 14px;">{{ $t('main.general.total') }}
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : analyze['cluster_info']['amount']
                }}{{ $t('main.general.patients') }}</span>
            </h4>
          </template>
          <template v-slot:body>
            <div class="iq-details">
              <span class="title text-dark">{{
                $t('main.general.percentageOfMale')
              }}</span>
              <div class="percentage float-right text-primary">
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : (100 * analyze['cluster_info']['maleRatio']).toFixed(2)
                }}<span>%</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">{{
                $t('main.general.percentageOfFemale')
              }}</span>
              <div class="percentage float-right text-primary">
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : (100 * analyze['cluster_info']['femaleRatio']).toFixed(2)
                }}<span>%</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">{{
                $t('main.DashboardCkd.patientCard.aveAdmissionAge')
              }}</span>
              <div class="percentage float-right text-warning">
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : analyze['cluster_info']['age'].toFixed(2)
                }}<span> {{ $t('main.general.yearsOld') }}</span>
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">{{
                $t('main.DashboardCkd.patientCard.aveDeathAge')
              }}</span>
              <div class="percentage float-right text-success">
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : analyze['cluster_info']['death_age'].toFixed(2)
                }}<span> {{ $t('main.general.yearsOld') }}</span>
              </div>
            </div>
            <!-- <div class="iq-details mt-4">
              <span class="title text-dark">死亡率</span>
              <div class="percentage float-right text-danger">
                {{analyze['cluster_info'] === undefined ? '':(100*analyze['cluster_info']['death']).toFixed(2)}}<span>%</span>
              </div>
            </div> -->
            <div style="margin-bottom: 100px;"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('main.general.statisticsOfImportantIndicators')
              }}<span style="font-size: 14px;">{{
                $t('main.general.statisticsOfImportantIndicators()')
              }}</span>
            </h4>
          </template>
          <template v-slot:body>
            <ul class="patient-progress m-0 p-0">
              <div>
                <h6 style="float: left; font-weight: bold;">
                  {{ $t('main.general.physiologicalFeature') }}
                </h6>
                <h6 style="float: right; margin-right: 20px; font-weight: bold;">
                  {{ $t('main.general.importanceScore') }}
                  <Tooltip placement="top">
                    <img
                      src="../../assets/images/question.png"
                      alt="guide"
                      class="guide"
                      style="margin-top: 5px;"
                    />
                    <span
                      slot="content"
                      style="white-space: normal; font-weight: normal"
                    >
                      {{ $t('main.general.importanceScore()') }}
                    </span>
                  </Tooltip>
                </h6>
              </div>
              <div style="margin-bottom: -5px; clear: both;"></div>
              <div class="table-responsive mt-4">
                <ul class="doctors-lists m-0 p-0">
                  <div
                    v-for="attn in similarPatientsAttn"
                    :key="attn[0]"
                    style="clear: both;"
                  >
                    <li class="d-flex mb-3 align-items-center">
                      <div class="media-support-info">
                        <h6>
                          <span
                            v-if="getLocale()"
                            :style="{ color: ch.get(attn[0]).midColor }"
                            style="font-weight: bold;"
                          >{{ medicalCharChinese[attn[0]] }}</span>
                          <span
                            v-else
                            :style="{ color: ch.get(attn[0]).midColor }"
                            style="font-weight: bold;"
                          >{{ medicalChar[attn[0]] }}</span>
                        </h6>
                      </div>
                      <span
                        class="badge badge-primary"
                        style="margin-right: 20px;"
                      >{{ (attn[1] * 100).toFixed(2) }}</span>
                    </li>
                  </div>
                </ul>
              </div>
            </ul>
            <div style="margin-bottom: 50px;"></div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card
          class-name="iq-card-block iq-card-stretch"
          body-class="pl-0 pr-0"
          :key="clusterInfoKey"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('main.DashboardCkd.basicDiseaseCount')
              }}<span style="font-size: 14px;">{{
                $t('main.DashboardCkd.basicDiseaseCount()')
              }}</span>
            </h4>
          </template>
          <template v-slot:body>
            <AmChart
              v-if="reAmchart"
              element="home-chart-03"
              :type="originDiseaseChart.type"
              :option="originDiseaseChart.data"
              :height="originDiseaseChart.height"
              :myLocale="getLocale()"
            />
          </template>
          <div style="margin-bottom: 50px;"></div>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('main.DashboardCkd.treatmentOutcome') }}
            </h4>
          </template>
          <template v-slot:body>
            <h2>
              <span class="percentage text-danger">
                {{
                  analyze['cluster_info'] === undefined
                    ? ''
                    : (100 * analyze['cluster_info']['death']).toFixed(2)
                }}%
              </span>
              <small
                class="text-secondary"
                style="padding-top: 12px; float: right; font-size: 16px; color: black !important"
              >{{ $t('main.DashboardCkd.similarPatientDeath') }}
                <Tooltip placement="top-end">
                  <img
                    src="../../assets/images/question.png"
                    alt="guide"
                    class="guide"
                    style="margin-top: 5px;"
                  />
                  <span
                    slot="content"
                    style="white-space: normal"
                  >
                    {{ $t('main.DashboardCkd.similarPatientDeath()') }}
                  </span>
                </Tooltip>
              </small>
            </h2>
            <div class="progress mt-3">
              <div
                class="progress-bar"
                role="progressbar"
                v-for="reason in deathReason"
                :key="reason[0]"
                :aria-valuenow="
                  ((100 * reason[1]) / analyze.cluster_info.amount).toFixed(0)
                "
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{
                  width:
                    ((100 * reason[1]) / analyze.cluster_info.amount).toFixed(
                      0
                    ) + '%',
                  background: ch.get(reason[0]).midColor
                }"
              ></div>
              <!-- <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="20" aria-valuemin="0"
                aria-valuemax="100" style="width:20%">
              </div>
              <div class="progress-bar bg-info" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                aria-valuemax="100" style="width:10%">
              </div>
              <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                aria-valuemax="100" style="width:40%">
              </div>
              <div class="progress-bar bg-success" role="progressbar" aria-valuenow="20" aria-valuemin="0"
                aria-valuemax="100" style="width:20%">
              </div>
              <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                aria-valuemax="100" style="width:10%">
              </div> -->
            </div>
            <div class="table-responsive mt-4">
              <ul class="doctors-lists m-0 p-0">
                <li
                  class="d-flex mb-4 align-items-center"
                  v-for="reason in deathReason"
                  :key="reason[0]"
                >
                  <div class="media-support-info ml-3">
                    <h5>{{ getDiseaseName(reason[0]) }}</h5>
                    <p class="mb-0 font-size-12">
                      {{ reason[1]
                      }}<span> {{ $t('main.general.person') }}</span>
                    </p>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div
                      class="percentage float-left text-danger"
                      style="margin-right:20px;"
                    >
                      {{
                        (
                          (100 * reason[1]) /
                          analyze.cluster_info.amount
                        ).toFixed(2)
                      }}<span>%</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!--    <b-row>-->
    <!--      <b-col lg="4">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="smaill-calender-home">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">Nearest Treatment</h4>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--            <flat-pickr :config="config" value="" class="d-none"/>-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <!--    <b-row>-->
    <!--      <b-col lg="3">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">Patient Progress</h4>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--              <li class="d-flex mb-3 align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Eric Shun</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-danger">15%</span>-->
    <!--              </li>-->
    <!--              <li class="d-flex mb-3 align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Rick Shaw</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-warning">55%</span>-->
    <!--              </li>-->
    <!--              <li class="d-flex mb-3 align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Ben Effit</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-info">45%</span>-->
    <!--              </li>-->
    <!--              <li class="d-flex mb-3 align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Rick Shaw</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-warning">55%</span>-->
    <!--              </li>-->
    <!--              <li class="d-flex mb-3 align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Marge Arita</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-primary">65%</span>-->
    <!--              </li>-->
    <!--              <li class="d-flex align-items-center">-->
    <!--                <div class="media-support-info">-->
    <!--                  <h6>Barry Cudat</h6>-->
    <!--                </div>-->
    <!--                <span class="badge badge-danger">15%</span>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--      <b-col lg="6">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="pl-0 pr-0">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">Patient overview</h4>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--            <AmChart element="home-chart-03" :type=originDiseaseChart.type :option=originDiseaseChart.data :height=originDiseaseChart.height />-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--      <b-col lg="3">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">Visits From Countries </h4>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--            <div class="iq-details">-->
    <!--              <span class="title text-dark">United States</span>-->
    <!--              <div class="percentage float-right text-primary">95 <span>%</span></div>-->
    <!--              <div class="iq-progress-bar-linear d-inline-block w-100">-->
    <!--                <div class="iq-progress-bar">-->
    <!--                  <span class="bg-primary" data-percent="95"></span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="iq-details mt-4">-->
    <!--              <span class="title text-dark">India</span>-->
    <!--              <div class="percentage float-right text-warning">75 <span>%</span></div>-->
    <!--              <div class="iq-progress-bar-linear d-inline-block w-100">-->
    <!--                <div class="iq-progress-bar">-->
    <!--                  <span class="bg-warning" data-percent="75"></span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="iq-details mt-4">-->
    <!--              <span class="title text-dark">Australia</span>-->
    <!--              <div class="percentage float-right text-success">55 <span>%</span></div>-->
    <!--              <div class="iq-progress-bar-linear d-inline-block w-100">-->
    <!--                <div class="iq-progress-bar">-->
    <!--                  <span class="bg-success" data-percent="55"></span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="iq-details mt-4">-->
    <!--              <span class="title text-dark">Brazil</span>-->
    <!--              <div class="percentage float-right text-danger">25 <span>%</span></div>-->
    <!--              <div class="iq-progress-bar-linear d-inline-block w-100">-->
    <!--                <div class="iq-progress-bar">-->
    <!--                  <span class="bg-danger" data-percent="25"></span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <!--    <b-row>-->
    <!--      <b-col lg="8">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">New Appointments </h4>-->
    <!--          </template>-->
    <!--          <template v-slot:headerAction>-->
    <!--            <b-dropdown size="lg p-0" variant="link" toggle-class="text-decoration-none" no-caret>-->
    <!--              <template v-slot:button-content>-->
    <!--                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">-->
    <!--                      <i class="ri-more-fill m-0 text-primary"></i>-->
    <!--                    </span>-->
    <!--              </template>-->
    <!--              <b-dropdown-item href="#"><i class="ri-eye-fill mr-2"></i>View</b-dropdown-item>-->
    <!--              <b-dropdown-item href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</b-dropdown-item>-->
    <!--              <b-dropdown-item href="#"><i class="ri-pencil-fill mr-2"></i>Edit</b-dropdown-item>-->
    <!--              <b-dropdown-item href="#"><i class="ri-printer-fill mr-2"></i>Print</b-dropdown-item>-->
    <!--              <b-dropdown-item href="#"><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item>-->
    <!--            </b-dropdown>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--            <div class="table-responsive">-->
    <!--              <table class="table mb-0 table-borderless">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th scope="col">Patient</th>-->
    <!--                  <th scope="col">Doctor</th>-->
    <!--                  <th scope="col">Date</th>-->
    <!--                  <th scope="col">Timing</th>-->
    <!--                  <th scope="col">Contact</th>-->

    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody v-for="appointment in appointments" :key="appointment.id">-->
    <!--                <tr>-->
    <!--                  <td>{{appointment.patient}}</td>-->
    <!--                  <td>{{appointment.doctor}}</td>-->
    <!--                  <td>{{appointment.date}}</td>-->
    <!--                  <td>{{appointment.timing}}</td>-->
    <!--                  <td>{{appointment.contact}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--      <b-col lg="4">-->
    <!--        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">-->
    <!--          <template v-slot:headerTitle>-->
    <!--            <h4 class="card-title">Doctors Lists</h4>-->
    <!--          </template>-->
    <!--          <template v-slot:body>-->
    <!--            <ul class="doctors-lists m-0 p-0">-->
    <!--              <li class="d-flex mb-4 align-items-center" v-for="doctor in doctors" :key="doctor.name">-->
    <!--                <div class="user-img img-fluid"><img :src="doctor.img" alt="story-img" class="rounded-circle avatar-40">-->
    <!--                </div>-->
    <!--                <div class="media-support-info ml-3">-->
    <!--                  <h6>{{doctor.name}}</h6>-->
    <!--                  <p class="mb-0 font-size-12">{{doctor.degree}}</p>-->
    <!--                </div>-->
    <!--                <div class="iq-card-header-toolbar d-flex align-items-center">-->
    <!--                  <b-dropdown size="lg" variant="link" toggle-class="p-0 text-decoration-none" no-caret>-->
    <!--                    <template v-slot:button-content class="p-0">-->
    <!--                    <span class="dropdown-toggle p-0" id="dropdownMenuButton6" data-toggle="dropdown">-->
    <!--                      <i class="ri-more-2-line"></i>-->
    <!--                    </span>-->
    <!--                    </template>-->
    <!--                    <b-dropdown-item href="#"><i class="ri-eye-line mr-2"></i>View</b-dropdown-item>-->
    <!--                    <b-dropdown-item href="#"><i class="ri-bookmark-line mr-2"></i>Appointment</b-dropdown-item>-->
    <!--                  </b-dropdown>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--            <a href="javascript:void(0);" class="btn btn-primary d-block mt-3"><i class="ri-add-line"></i> View All-->
    <!--              Doctors </a>-->
    <!--          </template>-->
    <!--        </iq-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <b-row>
      <b-col>
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('main.DashboardCkd.paperCard.relatedPaper')
              }}<span style="font-size: 14px;">{{ $t('main.DashboardCkd.paperCard.keyword')
                }}<span
                  class="testspan"
                  v-for="item in patient_data_selector.current_important"
                  v-bind:key="item"
                >{{ medicalChar[item] }}</span>）</span>
            </h4>
          </template>
          <template v-slot:body>
            <ul class="patient-progress m-0 p-0">
              <div>
                <h6 style="float: left; font-weight: bold;">
                  {{ $t('main.DashboardCkd.paperCard.paperInf') }}
                </h6>
                <h6 style="float: right; margin-right: 20px; font-weight: bold;">
                  {{ $t('main.DashboardCkd.paperCard.id') }}
                  <Tooltip placement="top">
                    <img
                      src="../../assets/images/question.png"
                      alt="guide"
                      class="guide"
                      style="margin-top: 5px;"
                    />
                    <span
                      slot="content"
                      style="white-space: normal; font-weight: normal"
                    >
                      {{ $t('main.DashboardCkd.paperCard.tips') }}
                    </span>
                  </Tooltip>
                </h6>
              </div>
              <div style="margin-bottom: -5px; clear: both;"></div>
              <div class="table-responsive mt-4">
                <ul class="doctors-lists m-0 p-0">
                  <pre>{{ txtTest }}</pre>
                </ul>
              </div>
            </ul>
            <div style="margin-bottom: 50px;"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import IqCard from '../../components/xray/cards/iq-card'
import { xray } from '@/config/pluginInit'
import SettingsHelper from '../../util/SettingsHelper'
import APIHelper, { getAnalyzeAdvice, getTxtTest } from '@/util/APIHelper'
import {
  medicalChar,
  medicalCharChinese,
  diseasesChartChi,
  diseasesChartEn,
  originMedicalChartEn,
  deathReasonEn
} from '@/util/info'
import PatientDataChart from '../Charts/PatientDataChart'
import PatientDataSelector from '../Charts/PatientDataSelector'
import ColorHelper from '../../util/ColorHelper'
import AIObservation from '../../components/Advice/AIObservation'

const body = document.getElementsByTagName('body')
export default {
  name: 'Dashboard4',
  components: {
    IqCard,
    PatientDataChart: PatientDataChart,
    PatientDataSelector,
    AIObservation
  },
  inject: ['reload'],
  data() {
    return {
      bg_color: [
        'bg-warning',
        'bg-primary',
        'bg-info',
        'bg-danger',
        'bg-success',
        'bg-secondary'
      ],
      reAiObservation: true,
      rePatientChart: true,
      reAmchart: true,
      hoverIndex: -1, // 菜单hover索引
      activeLinkId: 0, // 当前激活的菜单id
      message: '默认值',
      genderRatio: 0,
      display: false,
      display1: false,
      dialog: false,
      top2: undefined,
      urlAnalyzeApi: undefined,
      settings: undefined,
      getAnalyzeDataTimer: undefined,
      getAdviceTimer: undefined,
      similarPatientsAttn: [],
      medicalCharChinese: medicalCharChinese,
      medicalChar: medicalChar,
      deathReasonEn: deathReasonEn,
      originMedicalChartEn: originMedicalChartEn,
      patient_data_selector: {
        current_important: [],
        history_important: [],
        other: [],
        item: [],
        part: [0.2, 0.5, 1],
        info: {}
      },
      analyzeAdvice: undefined,
      originDiseaseChart: {
        type: 'radial-pie',
        height: 350,
        data: []
      },
      clusterInfoKey: 0,
      txtTest: '默认值',
      islogin: (localStorage.getItem('isLogin') === undefined || localStorage.getItem('isLogin') == null || localStorage.getItem('isLogin') === 'false') ? 0 : 1
    }
  },
  created() {
    let guestlists = [
      'a1',
      'a2',
      'a3',
      'a4',
      'a5',
      'a6',
      'a7',
      'a8',
      'a9',
      'a10'
    ]
    if (window.localStorage.getItem('loginUser') !== null) {
      let usertype = parseInt(
        JSON.parse(window.localStorage.getItem('loginUser')).user_type
      )
      if (
        !this.$route.params.patientId ||
        (usertype === 2 &&
          guestlists.indexOf(this.$route.params.patientId) === -1)
      ) {
        if (
          usertype === 2 &&
          guestlists.indexOf(this.$route.params.patientId) === -1 &&
          this.$route.params.patientId
        ) {
          alert('您没有权限！')
        }
        this.$router.replace('/a1')
      }
    }
    this.urlAnalyzeApi = new URL(window.location.href).searchParams.get('api')
    // console.log(this.urlAnalyzeApi)
    if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
    this.settings = SettingsHelper.load()
    // console.log(this.settings)
    // 额外参数为 pending = true
    this.$store.commit('setComputingAdvice', { pending: true })
    // 将多个Promise实例包装成一个新的Promise实例 处理多个异步处理
    // lab为每一次的就诊记录，patient是基本的病人信息，
    Promise.all([
      APIHelper.getPatientById(this.$route.params.patientId),
      APIHelper.getLabById(this.$route.params.patientId),
      APIHelper.getMockData(this.$route.params.patientId)
    ]).then(([patient, lab, events]) => {
      // console.log(events)
      this.$store.commit('setLab', lab)
      // this.$store.commit('setEvents', events)
      this.$store.commit('setModifiedLab', lab)
      this.$store.commit('setPatient', patient)
      Promise.all([
        APIHelper.getAdviceOnRisk(
          {
            patient: this.patient,
            lab: this.modifiedLab
          },
          40
        ),
        APIHelper.getAdviceOnRisk(
          {
            patient: this.patient,
            lab: this.modifiedLab
          },
          0
        )
      ]).then(([adviceOnRiskThreshold, adviceOnRisk]) => {
        // this.$store.commit('setAdviceOnRiskThresholdSub', adviceOnRiskThresholdSub)
        // console.log(adviceOnRisk)
        // console.log(adviceOnRiskThreshold)
        this.$store.commit('setAdviceOnRiskThreshold', adviceOnRiskThreshold)
        this.$store.commit('setAdviceOnRisk', adviceOnRisk)
        this.$store.commit('setComputingAdvice', { pending: false })
      })
    })
    if (this.$store.state.ch) {
      this.$store.commit('setCh', new ColorHelper())
    }
  },
  mounted() {
    xray.index()
    body[0].classList.add('sidebar-main-menu')
  },
  destroyed() {
    body[0].classList.remove('sidebar-main-menu')
  },
  computed: {
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    events() {
      return this.$store.state.events
    },
    analyze() {
      return this.$store.state.analyze
    },
    modifiedLab() {
      return this.$store.state.modifiedLab
    },
    lab() {
      return this.$store.state.lab
    },
    patient() {
      return this.$store.state.patient
    },
    similarPatients() {
      return this.$store.state.similarPatients
    },
    analyzeData() {
      const { modifiedLab, patient } = this
      return {
        modifiedLab,
        patient
      }
    },
    advice() {
      return this.$store.state.advice
    },
    adviceOnRiskThreshold() {
      return this.$store.state.adviceOnRiskThreshold
    },
    adviceOnRiskThresholdSub() {
      return this.$store.state.adviceOnRiskThresholdSub
    },
    adviceOnRisk() {
      return this.$store.state.adviceOnRisk
    },
    adviceOnIdx() {
      return this.$store.state.adviceOnIdx
    },
    ch() {
      return this.$store.state.ch
    },
    showLine() {
      return this.$store.state.showLine
    },
    deathReason() {
      return this.$store.state.deathReason
    }
  },
  watch: {
    analyzeData() {
      this.getAnalyzeData()
      this.getComplicationsAnalyzeData()
    },
    // '$route'(to, from) { // 监听路由是否变化
    //   if (to.name === 'dashboard.home-4') { // 跳转到哪个页面
    //     this.reload()
    //   }
    // },
    '$i18n.locale'(newValue) {
      this.reloadChild()
    }
  },
  methods: {
    enter: function () {
      this.color = 'blue'
    },
    leave: function () {
      this.color = 'red'
    },
    reloadChild() {
      this.reAiObservation = false
      this.rePatientChart = false
      this.reAmchart = false
      this.$nextTick(() => {
        this.reAiObservation = true
        this.rePatientChart = true
        this.reAmchart = true
      })
    },
    // 分析数据
    getAnalyzeData() {
      // var txtarr = ['co2cp', 'Systolic pressure']
      // console.log('getAnalyzeData')
      clearTimeout(this.getAnalyzeDataTimer)
      // console.log(this.urlAnalyzeApi)
      // console.log(this.settings.analyzeApi)
      // 延迟 500ms 保证最后执行
      this.getAnalyzeDataTimer = setTimeout(
        () =>
          APIHelper.getAnalyze(
            {
              patient: this.patient,
              lab: this.modifiedLab
            },
            this.urlAnalyzeApi
              ? this.settings.analyzeApi.map(o => ({
                ...o,
                enabled: this.urlAnalyzeApi.includes(o.alias)
              }))
              : this.settings.analyzeApi
          ).then(result => {
            this.$store.commit('setAnalyze', result)
            this.$store.commit('setAnalyzeRisk', result)
            // console.log(this.top2)
            if (this.top2 === undefined) {
              this.$store.commit('setOriginalAnalyze', result)
              this.getRank()
            }
            this.originDiseaseChart.data = []
            let diseases = result.cluster_info.origin_disease
            for (let key in diseases) {
              if (diseases[key] > 0) {
                this.originDiseaseChart.data.push({
                  country: key,
                  value: diseases[key]
                })
              }
            }
            this.genderRatio =
              100 / (1 / result['cluster_info']['genderRatio'] + 1)
            ++this.clusterInfoKey

            APIHelper.getAllPatientById(
              result['top_visit'],
              result['cluster_top6_pdid']
            ).then(similarPatients =>
              this.$store.commit('setSimilarPatients', similarPatients)
            )

            let dict = result['cluster_info']['attention']
            this.similarPatientsAttn = Object.keys(dict).map(function (key) {
              return [key, dict[key]]
            })
            // Sort the array based on the second element
            // console.log(this.similarPatients)
            // console.log(this.similarPatientsAttn)
            this.similarPatientsAttn.sort(function (first, second) {
              return second[1] - first[1]
            })
            dict = result['cluster_info']['death_reason']
            let deathReason = Object.keys(dict).map(function (key) {
              return [key, dict[key]]
            })
            // deathReason.forEach(item => {
            // if (this.getLocale()) {
            // item[0] = diseasesChartChi[item[0]]
            // } else {
            // item[0] = diseasesChartEn[item[0]]
            // }
            // })
            // Sort the array based on the second element
            deathReason.sort(function (first, second) {
              return second[1] - first[1]
            })
            let tmp = deathReason[0]
            if (tmp[0] === '003') {
              deathReason.splice(0, 1)
              deathReason.push(tmp)
            }
            this.$store.commit('setDeathReason', deathReason)
          }),
        500
      )
    },
    getComplicationsAnalyzeData() {
      // clearTimeout(this.getAnalyzeDataTimer)
      this.getAnalyzeDataTimer = setTimeout(
        () =>
          APIHelper.getComplicationsAnalyze({
            patient: this.patient,
            lab: this.modifiedLab
          }).then(res => {
            // console.log('com: ', res)
            this.$store.commit('setComplicationsAnalyze', res)
            let obj1 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj2 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj3 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let obj4 = JSON.parse(JSON.stringify(this.$store.state.analyze))
            let events = res.events
            this.$store.commit('setEvents', events)
            let len = res.predict.length
            for (let i = 0; i < len; i++) {
              obj1.predict[i] = res.predict[i][0]
              obj2.predict[i] = res.predict[i][1]
              obj3.predict[i] = res.predict[i][2]
              obj4.predict[i] = res.predict[i][3]
            }
            obj1.attention = res.attention[0]
            obj2.attention = res.attention[1]
            obj3.attention = res.attention[2]
            obj4.attention = res.attention[3]
            this.$store.commit('setAnalyze1', obj1)
            this.$store.commit('setAnalyze2', obj2)
            this.$store.commit('setAnalyze3', obj3)
            this.$store.commit('setAnalyze4', obj4)
          }),
        2000
      )
    },
    getDiseaseName(value) {
      return this.getLocale() ? diseasesChartChi[value] : diseasesChartEn[value]
    },
    change(a) {
      const b = parseFloat(a)
      return b.toFixed(1)
    },
    getRank() {
      if (this.analyze.attention) {
        const lastAttention = this.analyze.attention.slice(-1)[0]
        this.patient_data_selector.item = Object.keys(lastAttention)
        this.patient_data_selector.item.splice(13, 1)
        let arr = []
        for (let [key, value] of Object.entries(lastAttention)) {
          if (value >= 0.22) {
            arr.push(key)
          }
        }
        // 排序
        let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
          return b[1] - a[1]
        })
        this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
        this.top2.forEach(o => {
          if (arr.indexOf(o) === -1) {
            arr.push(o)
          }
        })
        this.$store.commit('setTop2', this.top2)
        let obj = {}
        let top1 = []
        let arr1 = []
        const length = this.analyze.attention.length
        this.analyze.attention.forEach((item, i) => {
          let max = 0
          Object.entries(item).forEach(([key, value]) => {
            if (value > max) {
              max = value
              top1[i] = key
            }
          })
          // let k = 0
          Object.getOwnPropertyNames(item).forEach(key => {
            obj[key] ? (obj[key] += item[key]) : (obj[key] = item[key])
            if (item[key] >= 0.5 || key === top1[i]) {
              if (arr1.indexOf(key) === -1) {
                arr1.push(key)
                if (this.getLocale()) {
                  this.patient_data_selector.info[key] =
                    '<b>AI强烈关注此项指标时间</b>'
                } else {
                  this.patient_data_selector.info[key] =
                    '<b>This indicator is focused by AI</b>'
                }
              }
              if (i === 0 || key !== top1[i - 1]) {
                this.patient_data_selector.info[key] +=
                  '<br/>' + this.modifiedLab[i].date
                // k = i
              }
            } else {
              if (i >= 1 && key === top1[i - 1]) {
                if (i >= 2 && key === top1[i - 2]) {
                  this.patient_data_selector.info[key] +=
                    '~' + this.modifiedLab[i - 1].date
                  /* if (k - 1 >= 0) {
                      let trend = this.lab[i][key] / this.lab[k - 1][key] - 1
                      if (trend !== 0) {
                        this.patient_data_selector.info[key] +=
                          '（' + (trend > 0 ? '升高了' + trend : '降低了' + (-trend)) + '%）'
                      }
                    } */
                }
              }
            }
          })
        })
        if (length >= 2) {
          this.patient_data_selector.item.forEach(key => {
            if (key === top1[length - 1]) {
              if (key === top1[length - 2]) {
                this.patient_data_selector.info[key] +=
                  '~' + this.modifiedLab[length - 1].date
              }
            }
          })
        }
        Object.entries(obj).forEach(item => {
          if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
            arr1.push(item[0])
          }
        })
        this.patient_data_selector.current_important = arr
        this.patient_data_selector.history_important = arr1.filter(
          item => arr.indexOf(item) === -1
        )
        this.patient_data_selector.other = this.patient_data_selector.item.filter(
          item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
        )
        if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length >= 2
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.current_important[1]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1 &&
          this.patient_data_selector.history_important &&
          this.patient_data_selector.history_important.length >= 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.history_important[0]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0]
          ])
        }
        this.$store.commit('setSelectedDate', [])
        this.analyzeAdvice = getAnalyzeAdvice(
          this.lab,
          this.analyze,
          this.patient_data_selector.item
        )

        let arrstr = ''
        let i
        for (i = 0; i < arr.length; i++) {
          arrstr += '*'
          arrstr += medicalChar[arr[i]]
        }
        getTxtTest(arrstr).then(res => {
          this.txtTest = res.data
        })
      }
    },
    toPatientPage(a) {
      this.$router.push('/' + a.pdid /* + '?secret=yuyuyzl' */)
    },
    getLocale() {
      return this.$i18n.locale === 'chi'
    }
  }
}
</script>

<style scoped>
.link-item-hover {
  background-color: #f5f7fa;
}

.link-item-active {
  color: #fff;
  background-color: #b3cae4;
}

.guide {
  width: 16px;
  padding-left: 2px;
  padding-bottom: 5px;
  cursor: pointer;
}

.testspan {
  display: inline-block;
  padding-left: 10px;
}
</style>
