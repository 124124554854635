<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="5">
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img v-if="patient.gender === 2" src="../../assets/images/user/11.png" alt="profile-img"
                    class="avatar-130 img-fluid">
                  <img v-else src="../../assets/images/user/19.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.general.id')}}</h6>
                    <h4>{{$route.params.patientId}}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.general.gender')}}</h6>
                    <h4 v-if="patient.gender === 2">{{$t('main.general.female')}}</h4>
                    <h4 v-else>{{$t('main.general.male')}}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.general.age')}}</h6>
                    <h4 v-if="patient.age != null">{{patient.age}}</h4>
                    <h4 v-else>{{$t('main.general.noData')}}</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a data-icon="T" class="icon" v-if="display === false"
                    @click="display = !display; $store.commit('setShowLine', true)">
                    <span>{{$t('main.general.more')}}</span>
                  </a>
                  <a data-icon="W" class="icon" v-else
                    @click="display = !display ; $store.commit('setShowLine', false)">
                    <span>{{$t('main.general.hide')}}</span>
                  </a>
                </div>
                <b-collapse id="collapse-0" class="mb-2" v-model="display">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">{{$t('main.general.admissionTime')}}</p>
                        <h5 v-if="patient['Admission time'] == undefined">No data</h5>
                        <div v-else>
                          <h5>{{patient['Admission time']}}</h5>
                        </div>
                      </div>
                      <div class="data-block mt-3" v-if="patient['Discharge time'] !== undefined">
                        <p class="mb-0">{{$t('main.general.dischargeTime')}}</p>
                        <h5 v-if="patient['Discharge time'] == undefined">{{$t('main.general.noData')}}</h5>
                        <h5 v-else>{{patient['Discharge time']}}</h5>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">{{$t('main.general.status')}}</p>
                        <h5 v-if="patient.outcome == undefined">{{$t('main.general.noData')}}</h5>
                        <h5 v-else>{{patient.outcome ? $t('main.general.dead') : $t('main.general.alive')}}</h5>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
          <!-- start case study -->
          <!-- case study pdid = 63 -->
          <iq-card class-name="iq-user-profile-block1" v-if = "$route.params.patientId == 63">
            <template v-slot:headerTitle>
              <h4 class="card-title" style="display: inline">AI Recommendations</h4>
               ── Generated at visit 2
            </template>
            <template v-slot:body>
              <div class="d-inline-block w-100">
                The patient’s current mortality risk is {{analyze['predict'] === undefined ? '' : (analyze['predict'][analyze['predict'].length-1]).toFixed(2) }}. If you want to minimize the risk, you can: <br />
                ● raise <span :style="{color: ch.get('Platelets').midColor}" style="font-weight:bold">Platelets</span> from 202.000 to 242.400 (<span style="color: red"> 20.00% ↑ </span>)<br />
                ● raise <span :style="{color: ch.get('LIN%').midColor}" style="font-weight:bold">LIN%</span> from 19.400 to 43.446 (<span style="color: red"> 123.95% ↑ </span>)<br />
                ● decrease <span :style="{color: ch.get('Monocytes(%)').midColor}" style="font-weight:bold">Monocytes(%)</span> from 8.400 to 7.584 ( <span style="color: limegreen"> 9.71% ↓ </span>)<br />
                ● raise <span :style="{color: ch.get('MCV').midColor}" style="font-weight:bold">MCV</span> from 95.900 to 98.178 (<span style="color: red"> 2.38% ↑ </span>)<br />
              </div>
            </template>
          </iq-card>
          <!-- case study pdid = 240 -->
          <iq-card class-name="iq-user-profile-block1" v-if = "$route.params.patientId == 240">
            <template v-slot:headerTitle >
              <h4 class="card-title" style="display: inline;">AI Recommendations</h4>
               ── Generated at visit 20
            </template>
            <template v-slot:body>
              <div class="d-inline-block w-100">
                The patient’s current mortality risk is {{analyze['predict'] === undefined ? '' : (analyze['predict'][analyze['predict'].length-1]).toFixed(2) }}. If you want to minimize the risk, you can: <br />
                ● decrease <span :style="{color: ch.get('Interleukin 10').midColor}" style="font-weight:bold">Interleukin 10</span> from 9.800 to 8.820 (<span style="color: limegreen">73.10% ↓</span>)<br />
                ● raise <span :style="{color: ch.get('lymphocyte count').midColor}" style="font-weight:bold">lymphocyte count</span> from 0.230 to 0.331 (<span style="color: red"> 44.00% ↑ </span>)<br />
                ● raise <span :style="{color: ch.get('thrombocytocrit').midColor}" style="font-weight:bold">thrombocytocrit</span> from 0.060 to 0.104 (<span style="color: red"> 72.80% ↑ </span>)<br />
                ● raise <span :style="{color: ch.get('ESR').midColor}" style="font-weight:bold">ESR</span> from 5.000 to 9.979 (<span style="color: red"> 99.58% ↑ </span>)<br />
              </div>
            </template>
          </iq-card>
          <!-- end case study -->
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="add-file-right-img" style="margin-left:50px;">{{$t('main.general.uploadFile')}}：</div>
                <input type="file" ref="clearFile" @change="getFile($event)" multiple="multiplt"  style="margin-left:50px;" accept=".xlsx">
                <!-- <el-button type="primary" @click="submitAddFile" size="small">开始上传</el-button> -->
                <div style="margin-left:50px;margin-top:5px">
                  <button style="padding-left:6px;padding-right:6px;border-radius:2px;border-width:1px" @click="submitAddFile">{{$t('main.general.upload')}}</button>
                </div>
                <div class="add-file-right-img" style="margin-left:50px;"><a href="http://47.93.42.104:8080/UploadTest/DownloadExcel">{{$t('main.general.downloadExample')}}</a></div>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- patient card end -->
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('main.general.indicators')}}</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>{{$t('main.general.clearSelected')}}</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4 class="card-title text-primary" v-show="patient_data_selector.current_important.length">{{$t('main.general.importantIndicators')}}
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.current_important.length">
              <CovidPatientDataSelector :item="patient_data_selector.current_important"
                :info="patient_data_selector.info">
              </CovidPatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.history_important.length">
              <h4 class="card-title text-primary">{{$t('main.general.historicallyImportantIndicators')}}
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.history_important.length">
              <CovidPatientDataSelector :item="patient_data_selector.history_important"
                :info="patient_data_selector.info">
              </CovidPatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.other.length">
              <div style="padding-bottom:20px;text-align:center">
                <a data-icon="T" class="icon" v-if="display1 === false" @click="display1 = !display1">
                  <span> {{$t('main.general.more')}}</span>
                </a>
                <a data-icon="W" class="icon" v-else @click="display1 = !display1">
                  <span> {{$t('main.general.hide')}}</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.other.length">
              <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                <h4 class="card-title text-primary">{{$t('main.general.otherIndicators')}}</h4>
                <CovidPatientDataSelector :item="patient_data_selector.other"></CovidPatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <CovidPatientDataChart
          v-if=" analyze !== undefined && (selectedGraph.length>= 1 || analyze.predict !== undefined)" />
      </b-col>
    </b-row>

    <!-- 相似簇内患者的统计信息 -->
    <!-- <div v-if="whiteList.includes(this.$route.params.patientId)"> -->
    <div>
      <b-row>
        <b-col lg="12">
          <iq-card body-class="bg-primary rounded pt-2 pb-2 pr-2">
            <template v-slot:body>
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0" style="font-size: 22px;margin-left: 10px;color: white;">{{$t('main.general.similarPatientInformation')}}<i
                    class="fa fa-id-card" style="margin-left: 5px;"></i>
                </h4>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <iq-card class-name='iq-card-block iq-card-stretch iq-card-height'>
            <template v-slot:headerTitle>
              <h4 class="card-title" style="display: inline">{{$t('main.general.top6SimilarPatientList')}}</h4>
              <Tooltip placement="top">
                <img src="../../assets/images/question.png" alt="guide" class="guide" />
                <span slot="content" style="white-space: normal">
                  {{$t('main.general.top6SimilarPatientList()')}}
                </span>
              </Tooltip>
            </template>
            <template v-slot:body>
              <div class="table-responsive" style="margin-left: 5px;">
                <table class="table mb-0 table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">{{$t('main.general.id')}}</th>
                      <th scope="col">{{$t('main.general.age')}}</th>
                      <th scope="col">{{$t('main.general.gender')}}</th>
                      <th scope="col">{{$t('main.general.admissionTime')}}</th>
                      <th scope="col">{{$t('main.general.dischargeTime')}}</th>
                      <th scope="col">{{$t('main.general.status')}}</th>
                      <th scope="col">{{$t('main.general.recordsInSimilarStatus')}}</th>
                      <th scope="col">{{$t('main.general.similarityScore')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(similarPatient, index) in similarPatients" :key="similarPatient.pdid"
                      :class="{'link-item-active': similarPatient.id == activeLinkId,'link-item-hover':similarPatient.pdid==hoverIndex}"
                      @click="toPatientPage(similarPatient)" @mouseover="hoverIndex = similarPatient.pdid"
                      @mouseout="hoverIndex = -1">
                      <td>{{similarPatient.pdid}}</td>
                      <td>{{similarPatient.age}}</td>
                      <td>{{similarPatient.gender === 1 ? $t('main.general.male'): $t('main.general.female')}}</td>
                      <td>{{similarPatient['Admission time']}}</td>
                      <td>{{similarPatient['Discharge time']}}</td>
                      <td>{{similarPatient.outcome ? $t('main.general.dead') : $t('main.general.alive')}}</td>
                      <td>{{similarPatient.focusTime}}</td>
                      <td>{{(100 - analyze.top_similarIdx[index]*100).toFixed(2)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch" :key="genderRatio">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('main.general.clusterInformationStatistics')}}<span style="font-size: 14px;">
                  </span>
              </h4>
            </template>
            <template v-slot:body>
              <div class="iq-details">
                <span class="title text-dark">{{$t('main.general.percentageOfMale')}}</span>
                <div class="percentage float-right text-primary">
                   {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['maleRatio']).toFixed(2)}}<span>%</span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.general.percentageOfFemale')}}</span>
                <div class="percentage float-right text-primary">
                   {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['femaleRatio']).toFixed(2)}}<span>%</span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.DashboardCovid.mortalityRate')}}</span>
                <div class="percentage float-right text-danger">
                  {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['deathRatio']).toFixed(2)}}<span>%</span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.DashboardCovid.averageRiskScore')}}</span>
                <div class="percentage float-right text-warning">
                   {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['age'].toFixed(2)}}<span></span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.DashboardCovid.averagePrediction')}}</span>
                <div class="percentage float-right text-warning">
                   {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['prediction'].toFixed(2)}}<span></span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.DashboardCovid.recordsInSpain')}}</span>
                <div class="percentage float-right text-success">
                  {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['SpainN']}}<span></span>
                </div>
              </div>
              <div class="iq-details mt-4">
                <span class="title text-dark">{{$t('main.DashboardCovid.recordsInChina')}}</span>
                <div class="percentage float-right text-success">
                  {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['CovidN']}}<span></span>
                </div>
              </div>
              <div style="margin-bottom: 50px;"></div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('main.general.statisticsOfImportantIndicators')}}<span style="font-size: 14px;">{{$t('main.general.statisticsOfImportantIndicators()')}}</span></h4>
            </template>
            <template v-slot:body>
              <ul class="patient-progress m-0 p-0">
                <div>
                  <h6 style="float: left; font-weight: bold;">{{$t('main.general.physiologicalFeature')}}</h6>
                  <h6 style="float: right; margin-right: 20px; font-weight: bold;">{{$t('main.general.importanceScore')}}
                    <Tooltip placement="top">
                      <img src="../../assets/images/question.png" alt="guide" class="guide" style="margin-top: 5px;" />
                      <span slot="content" style="white-space: normal; font-weight: normal">
                        {{$t('main.general.importanceScore()')}}
                      </span>
                    </Tooltip>
                  </h6>
                </div>
                <div style="margin-bottom: -5px; clear: both;"></div>
                <div class="table-responsive mt-4">
                  <ul class="doctors-lists m-0 p-0">
                    <div v-for="attn in similarPatientsAttn" :key="attn[0]" style="clear: both;">
                      <li class="d-flex mb-3 align-items-center">
                        <div class="media-support-info">
                          <h6><span :style="{color: ch.get(attn[0]).midColor}"
                              style="font-weight: bold;">{{attn[0]}}</span>
                          </h6>
                        </div>
                        <span class="badge badge-primary"
                          style="margin-right: 20px;">{{(attn[1] * 100).toFixed(2)}}</span>
                      </li>
                    </div>
                  </ul>
                </div>
              </ul>
              <div style="margin-bottom: 50px;"></div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
  import IqCard from '../../components/xray/cards/iq-card'
  import { xray } from '@/config/pluginInit'
  import SettingsHelper from '../../util/SettingsHelper'
  import APIHelper from '@/util/APIHelper'
  import { medicalCharCovid } from '@/util/info'
  import CovidPatientDataChart from '../Charts/CovidPatientDataChart'
  import CovidPatientDataSelector from '../Charts/CovidPatientDataSelector'
  import ColorHelper from '../../util/ColorHelper'
  import axios from 'axios'

  const whiteList = ['64', '6', '62', '79', '89', '98', '172', '218', '207', '232', '273', '302', '308']
  const body = document.getElementsByTagName('body')
  export default {
    name: 'DashboardCovid',
    components: {
      IqCard,
      CovidPatientDataChart: CovidPatientDataChart,
      CovidPatientDataSelector
    },
    inject: ['reload'],
    data() {
      return {
        bg_color: [
          'bg-warning',
          'bg-primary',
          'bg-info',
          'bg-danger',
          'bg-success',
          'bg-secondary'
        ],
        hoverIndex: -1, // 菜单hover索引
        activeLinkId: 0, // 当前激活的菜单id
        whiteList: whiteList,
        message: '默认值',
        genderRatio: 0,
        display: false,
        display1: false,
        dialog: false,
        top2: undefined,
        urlAnalyzeApi: undefined,
        settings: undefined,
        getAnalyzeDataTimer: undefined,
        getAdviceTimer: undefined,
        similarPatientsAttn: [],
        deathReason: [],
        medicalCharCovid: medicalCharCovid,
        patient_data_selector: {
          current_important: [],
          history_important: [],
          other: [],
          item: [],
          part: [0.2, 0.5, 1],
          info: {}
        },
        analyzeAdvice: undefined,
        originDiseaseChart: {
          type: 'radial-pie',
          height: 280,
          data: []
        },
        clusterInfoKey: 0,
        addArr: []
      }
    },
    created() {
      if (!this.$route.params.patientId) {
        this.$router.replace('/covid/64')
      }
      this.urlAnalyzeApi = (new URL(window.location.href)).searchParams.get('api')
      if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
      this.settings = SettingsHelper.load()
      this.$store.commit('setComputingAdvice', { pending: true })
      Promise.all([APIHelper.getCovidPatientById(this.$route.params.patientId), APIHelper.getCovidLabById(this.$route.params.patientId), APIHelper.getCovidTimeById(this.$route.params.patientId)])
        .then(([patient, lab, time]) => {
          let groundTruthT12 = time.map(value => this.convertGroundTruth(value.label))
          this.$store.commit('setCovidGroundTruthT12', groundTruthT12)
          this.$store.commit('setCovidTime', time)
          this.$store.commit('setCovidPatient', patient)
          this.$store.commit('setCovidLab', lab)
          let covidLab = lab
          let fixlab = []
          let tmp = {}
          var i
          for (i in covidLab) {
            let tmplab = {}
            for (let idx in covidLab[i]) {
              if (covidLab[i][idx] !== 0 || !tmp.hasOwnProperty(idx)) {
                if (covidLab[i][idx] !== 0 && tmp[idx] === 0) {
                  for (let j = 0; j < i; j++) {
                    fixlab[j][idx] = covidLab[i][idx]
                  }
                }
                tmp[idx] = covidLab[i][idx]
              }
              tmplab[idx] = tmp[idx]
            }
            fixlab.push(tmplab)
          }
          for (let i = 0; i < covidLab.length; ++i) {
            covidLab[i].date = i
          }
          this.$store.commit('setCovidFixedLab', fixlab)
        })
      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }
    },
    mounted() {
      xray.index()
      body[0].classList.add('sidebar-main-menu')
    },
    destroyed() {
      body[0].classList.remove('sidebar-main-menu')
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.covidAnalyze
      },
      covidGroundTruthT12() {
        return this.$store.state.covidGroundTruthT12
      },
      lab() {
        return this.$store.state.covidLab
      },
      patient() {
        return this.$store.state.covidPatient
      },
      analyzeData() {
        const { lab, patient } = this
        return {
          lab, patient
        }
      },
      ch() {
        return this.$store.state.ch
      },
      showLine() {
        return this.$store.state.showLine
      },
      similarPatients() {
        return this.$store.state.similarPatients
      }
    },
    watch: {
      analyzeData() {
        this.getAnalyzeData()
      },
      '$route'(to, from) { // 监听路由是否变化
        if (to.name === 'dashboard.home-covid') { // 跳转到哪个页面
          // this.reload()
          this.$router.go(0)
        }
      }
    },
    methods: {
      convertGroundTruth(value) {
        let truth = value
        if (truth <= 35) return 'Discharged in '.concat(Math.ceil(truth)).concat(truth <= 1 ? ' day' : ' days')
        else {
          truth = Math.ceil(70 - truth)
          return 'Died in '.concat(truth).concat(truth <= 1 ? ' day' : ' days')
        }
      },
      enter: function () {
        this.color = 'blue'
      },
      leave: function () {
        this.color = 'red'
      },
      getAnalyzeData() {
        clearTimeout(this.getAnalyzeDataTimer)
        this.getAnalyzeDataTimer = setTimeout(() => this.lab && APIHelper.getCovidAnalyze(
          this.lab
        ).then(result => {
          result.fixedPredict = result.predict.map((value, i) => this.patient.outcome ? 70 - value : value)
          this.$store.commit('setCovidAnalyze', result)
          // console.log('CovidAnalyze', result)
          if (this.top2 === undefined) {
            this.$store.commit('setOriginalAnalyze', result)
            this.getRank()
          }
          APIHelper.getCovidAllPatientById(result['top_visit'], result['cluster_top6_pdid']).then(similarPatients => this.$store.commit('setSimilarPatients', similarPatients))
          let dict = result['cluster_info']['attention']
          // console.log('dict', dict)
          this.similarPatientsAttn = Object.keys(dict).map(function (key) {
            return [key, dict[key]]
          })
          // Sort the array based on the second element
          this.similarPatientsAttn.sort(function (first, second) {
            return second[1] - first[1]
          })
        }), 500)
      },
      change(a) {
        const b = parseFloat(a)
        return b.toFixed(1)
      },
      getRank() {
        if (this.analyze.attention) {
          const lastAttention = this.analyze.attention.slice(-1)[0]
          this.patient_data_selector.item = Object.keys(lastAttention)
          let arr = []
          for (let [key, value] of Object.entries(lastAttention)) {
            if (value >= 0.22) {
              arr.push(key)
            }
          }
          let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
            return b[1] - a[1]
          })
          this.top2 = sortedLastAttention.slice(0, 5).map(e => e[0])
          this.top2.forEach(o => {
            if (arr.indexOf(o) === -1) {
              arr.push(o)
            }
          })
          this.$store.commit('setTop2', this.top2)
          let obj = {}
          let top1 = []
          let arr1 = []
          const length = this.analyze.attention.length
          this.analyze.attention.forEach((item, i) => {
            let max = 0
            Object.entries(item).forEach(([key, value]) => {
              if (value > max) {
                max = value
                top1[i] = key
              }
            })
          })
          Object.entries(obj).forEach(item => {
            if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
              arr1.push(item[0])
            }
          })
          this.patient_data_selector.current_important = arr
          this.patient_data_selector.history_important = arr1.filter(item => arr.indexOf(item) === -1)
          this.patient_data_selector.other = this.patient_data_selector.item.filter(
            item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
          )
          if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length >= 5) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.current_important[1],
              this.patient_data_selector.current_important[2],
              this.patient_data_selector.current_important[3],
              this.patient_data_selector.current_important[4]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1 &&
            this.patient_data_selector.history_important && this.patient_data_selector.history_important.length >= 1) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.history_important[0]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1) {
            this.$store.commit('setSelectedGraph', [this.patient_data_selector.current_important[0]])
          }
          this.$store.commit('setSelectedDate', [])
        }
      },
      toPatientPage(a) {
        this.$router.push('/covid/' + a.pdid)
      },
      getFile(event) {
        var file = event.target.files
        for (var i = 0; i < file.length; i++) {
        // 上传类型判断
          var imgName = file[i].name
          var idx = imgName.lastIndexOf('.')
          if (idx !== -1) {
            var ext = imgName.substr(idx + 1).toUpperCase()
            ext = ext.toLowerCase()
            if (ext !== 'xlsx') {
            } else {
              this.addArr.push(file[i])
            }
          } else {
          }
        }
      },
      submitAddFile() {
        if (this.addArr.length === 0) {
          // this.$message({
          //   type: 'info',
          //   message: '请选择要上传的文件'
          // })
          return
        }
        var formData = new FormData()
        for (var i = 0; i < this.addArr.length; i++) {
          formData.append('fileUpload', this.addArr[i])
        }
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.post('http://47.93.42.104:8080/UploadTest/UploadExcel', formData, config)
        .then((response) => {
          // console.log(response.data)
          this.$router.push('/covid/' + response.data[0])
        })
      }
    }
  }
</script>

<style scoped>
  .link-item-hover {
    background-color: #f5f7fa;
  }

  .link-item-active {
    color: #fff;
    background-color: #b3cae4;
  }

  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }
</style>
