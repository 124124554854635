<template>
  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height"
    v-if="analyzeAdvice.length || adviceOnRisk.valid || adviceOnRiskThreshold.valid">
    <template v-slot:headerTitle>
      <h4 class="card-title" style="display: inline">AI观察与建议</h4>
      <Tooltip placement="top">
        <img src="../../assets/images/question.png" alt="guide" class="guide" />
        <span slot="content" style="white-space: normal">点击日期可以自动选中指标和时间</span>
      </Tooltip>
    </template>
    <template v-slot:headerAction>
      <div style="padding-right: 20px">
        <a v-if="display === false" @click="display = !display">
          <span>展开</span>
        </a>
        <a v-else @click="display = !display">
          <span>收起</span>
        </a>
      </div>
    </template>
    <template v-slot:body>
      <b-collapse id="collapse-0" class="mb-2" v-model="display">
        <p v-show="computingAdvice.pending">计算中...</p>
        <ul v-show="!computingAdvice.pending" class="iq-timeline">
          <li>
            <div class="timeline-dots border-primary"></div>
            <a class="text-primary float-left mb-1" id="date"
              @click="show([modifiedLab.length - 1, modifiedLab.length - 1], top2)">
              {{modifiedLab.slice(-1)[0].date}}
            </a>
            <div class="d-inline-block w-100">
              <p style="margin-bottom: 0" v-if="originalAnalyze && lab">
                患者当前
                <Tooltip placement="top">
                  <b>死亡风险指数</b>
                  <img src="../../assets/images/question.png" alt="guide" class="guide" />
                  <span slot="content" style="white-space: normal"
                    v-html="`<b>死亡风险指数</b> 是AI用来评估病人未来一年内死亡风险的指标（范围0 - 100），大于 50 表示该病人病情比较严重`">\
                  </span>
                </Tooltip>
                为 {{currentRisk}}，判断依据的主关键指标为
                <span :style="{color: ch.get(top2[0]).midColor}"
                  style="font-weight:bold;">{{medicalCharChinese[top2[0]]}}</span>
                ({{Number(lab.slice(-1)[0][top2[0]]).toFixed(1) + medicalUnit[top2[0]]}})，次关键指标为
                <span :style="{color: ch.get(top2[1]).midColor}"
                  style="font-weight:bold;">{{medicalCharChinese[top2[1]]}}</span>
                ({{Number(lab.slice(-1)[0][top2[1]]).toFixed(1) + medicalUnit[top2[1]]}})
                <span v-if="currentRisk >= 40 && prognosis.length">
                  ，应谨慎预防的不良预后为：
                  <span v-for="(value, i) in prognosis" :key="value">
                    {{value}}
                    <span v-if="i !== prognosis.length - 1">
                      、
                    </span>
                  </span>
                </span>
              </p>
              <div v-if="analyzeAdvice.length && analyzeAdvice[0].merge">
                死亡风险指数出现波动，短时间内上升了 {{getVariation(100 * analyzeAdvice[0].risk_variation_absolute)}} 至
                {{getVariation(100 * analyzeAdvice[0].risk)}} (
                <span v-if="analyzeAdvice[0].risk_variation>0" style="color: red">
                  {{getVariation(100 * analyzeAdvice[0].risk_variation)}}%↑
                </span>
                <span v-else style="color: limegreen">
                  {{getVariation(100 * analyzeAdvice[0].risk_variation)}}%↓
                </span>)，建议关注：
                <p v-for="(value, j) in analyzeAdvice[0].item" :key="value+'0'" style="margin-bottom: 0">◎&nbsp;
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                  从
                  {{(analyzeAdvice[0].lab[j]-analyzeAdvice[0].lab_variation_absolute[j]).toFixed(1)}}{{medicalUnit[value]}}
                  快速{{getTrendByVariation(analyzeAdvice[0].lab_variation[j])}}了
                  {{getVariation(analyzeAdvice[0].lab_variation_absolute[j], 1)}} 至
                  {{analyzeAdvice[0].lab[j].toFixed(1)}}{{medicalUnit[value]}} (
                  <span v-if="analyzeAdvice[0].lab_variation[j]>0" style="color: red">
                    {{getVariation(100 * analyzeAdvice[0].lab_variation[j])}}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{getVariation(100 * analyzeAdvice[0].lab_variation[j])}}%↓
                  </span>)
                  <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '且' : ''}}-->
                </p>
              </div>
              <!-- <b>建议 1：</b><br />
              <span v-for="(adOnIdx, index) in adviceOnIdx" :key="index">
                <div v-if="adOnIdx.valid">
                  ◎&nbsp;
                  <span :style="{color: ch.get(adOnIdx.idx).midColor}" style="font-weight:bold">
                    {{adOnIdx.idxChinese}}
                  </span>
                  <span>{{adOnIdx.advice[0].direction == 1? '升高 ': '降低 '}}</span>
                  <span v-if="adOnIdx.advice[0].valid">{{adOnIdx.advice[0].modifyValue.toFixed(2)}}<span
                      v-if="adOnIdx.advice[1].valid">&nbsp;|&nbsp;</span></span>
                  <span v-if="adOnIdx.advice[1].valid">{{adOnIdx.advice[1].modifyValue.toFixed(2)}}<span
                      v-if="adOnIdx.advice[2].valid">&nbsp;|&nbsp;</span></span>
                  <span v-if="adOnIdx.advice[2].valid">{{adOnIdx.advice[2].modifyValue.toFixed(2)}}<span
                      v-if="adOnIdx.advice[3].valid">&nbsp;|&nbsp;</span></span>
                  <span v-if="adOnIdx.advice[3].valid">{{adOnIdx.advice[3].modifyValue.toFixed(2)}}<span
                      v-if="adOnIdx.advice[4].valid">&nbsp;|&nbsp;</span></span>
                  <span v-if="adOnIdx.advice[4].valid">{{adOnIdx.advice[4].modifyValue.toFixed(2)}}<span
                      v-if="adOnIdx.advice[5].valid">&nbsp;|&nbsp;</span></span>
                  <span v-if="adOnIdx.advice[5].valid">{{adOnIdx.advice[5].modifyValue.toFixed(2)}}</span>
                  <span>&nbsp;{{medicalUnit[adOnIdx.idx]}}&nbsp;</span>
                  <br />
                  <span>⇨&nbsp;死亡风险指数可以下降&nbsp;</span>
                  <span
                    v-if="adOnIdx.advice[0].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[0].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[0].curRisk * 100).toFixed(2)}}）<span
                      v-if="adOnIdx.advice[1].valid">&nbsp;|&nbsp;</span></span>
                  <span
                    v-if="adOnIdx.advice[1].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[1].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[1].curRisk * 100).toFixed(2)}}）<span
                      v-if="adOnIdx.advice[2].valid">&nbsp;|&nbsp;</span></span>
                  <span
                    v-if="adOnIdx.advice[2].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[2].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[2].curRisk * 100).toFixed(2)}}）<span
                      v-if="adOnIdx.advice[3].valid">&nbsp;|&nbsp;</span></span>
                  <span
                    v-if="adOnIdx.advice[3].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[3].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[3].curRisk * 100).toFixed(2)}}）<span
                      v-if="adOnIdx.advice[4].valid">&nbsp;|&nbsp;</span></span>
                  <span
                    v-if="adOnIdx.advice[4].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[4].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[4].curRisk * 100).toFixed(2)}}）<span
                      v-if="adOnIdx.advice[5].valid">&nbsp;|&nbsp;</span></span>
                  <span
                    v-if="adOnIdx.advice[5].valid">{{((originalAnalyze.predict.slice(-1)[0] - adOnIdx.advice[5].curRisk) * 100).toFixed(2)}}（至{{(adOnIdx.advice[5].curRisk * 100).toFixed(2)}}）</span>
                  <br />
                </div>
              </span> -->
              <!-- <p style="margin-bottom: 0; margin-top: 5px;"
                v-if="adviceOnRiskThresholdSub.valid && adviceOnRiskThresholdSub.advice.curRisk < adviceOnRiskThresholdSub.advice.oriRisk">
                <b>AI建议（如预期将死亡风险指数降低至 60.0 以下）可考虑将：</b><br />
                <span
                  v-for="(advice_item, i) in adviceOnRiskThresholdSub.advice == undefined? null : adviceOnRiskThresholdSub.advice.idx"
                  :key="i">
                  ◎&nbsp;
                  <span :style="{color: ch.get(advice_item.key).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[advice_item.key]}}
                  </span>
                  从 {{advice_item.oriValue}} {{advice_item.direction ? '升高': '降低'}}
                  {{advice_item.valueDiff}} 至 {{advice_item.curValue}} (
                  <span v-if="advice_item.direction" style="color: red">
                    {{advice_item.prop.toFixed(1)}}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{advice_item.prop.toFixed(1)}}%↓
                  </span>)
                  <br />
                </span>
                ⇨死亡风险指数可下降 {{adviceOnRiskThreshold.advice.riskDiff}}
                至 {{adviceOnRiskThreshold.advice.curRisk}} (
                <span style="color: limegreen">
                  {{adviceOnRiskThreshold.advice.riskProp}}%↓
                </span>)
              </p> -->
              <p style="margin-bottom: 0; margin-top: 5px;"
                v-if="adviceOnRiskThreshold.valid && adviceOnRiskThreshold.advice.curRisk < adviceOnRiskThreshold.advice.oriRisk && adviceOnRiskThreshold.advice.curRisk < 40">
                <b>AI建议
                  <!-- {{(adviceOnRiskThresholdSub.valid == true && (adviceOnRiskThresholdSub.advice.curRisk &lt; adviceOnRiskThresholdSub.advice.oriRisk)) ? 2:1}} -->
                  （如预期将死亡风险指数降低至 40.0 以下）可考虑将：</b><br />
                <span
                  v-for="(advice_item, i) in adviceOnRiskThreshold.advice == undefined? null : adviceOnRiskThreshold.advice.idx"
                  :key="i">
                  ◎&nbsp;
                  <span :style="{color: ch.get(advice_item.key).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[advice_item.key]}}
                  </span>
                  <span v-if="advice_item.key == 'amount'">
                    <span v-if="advice_item.direction">增加摄入量</span>
                    <span v-else>减少摄入量</span>
                  </span>
                  <span v-else>
                    从 {{advice_item.oriValue}} {{advice_item.direction ? '升高': '降低'}}
                    {{advice_item.valueDiff}} 至 {{advice_item.curValue}} (
                    <span v-if="advice_item.direction" style="color: red">
                      {{advice_item.prop.toFixed(1)}}%↑
                    </span>
                    <span v-else style="color: limegreen">
                      {{advice_item.prop.toFixed(1)}}%↓
                    </span>)
                  </span>
                  <br />
                </span>
                ⇨死亡风险指数可下降 {{adviceOnRiskThreshold.advice.riskDiff}}
                至 {{adviceOnRiskThreshold.advice.curRisk}} (
                <span style="color: limegreen">
                  {{adviceOnRiskThreshold.advice.riskProp}}%↓
                </span>)
              </p>
              <p style="margin-bottom: 0; margin-top: 5px;"
                v-if="adviceOnRisk.valid && adviceOnRiskThreshold.advice.oriRisk > 20">
                <b>AI建议
                  <!-- {{(adviceOnRiskThreshold.valid == true && (adviceOnRiskThreshold.advice.curRisk &lt; adviceOnRiskThreshold.advice.oriRisk)) ? 3:2}} -->
                  （如预期最大程度降低死亡风险指数）可考虑将：
                </b>
                <br />
                <span v-for="(advice_item, i) in adviceOnRisk.advice == undefined? null : adviceOnRisk.advice.idx"
                  :key="i">
                  ◎&nbsp;
                  <span :style="{color: ch.get(advice_item.key).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[advice_item.key]}}
                  </span>
                  <span v-if="advice_item.key == 'amount'">
                    <span v-if="advice_item.direction">增加摄入量</span>
                    <span v-else>减少摄入量</span>
                  </span>
                  <span v-else>
                    从 {{advice_item.oriValue}}{{advice_item.direction ? '升高': '降低'}}
                    {{advice_item.valueDiff}} 至 {{advice_item.curValue}} (
                    <span v-if="advice_item.direction" style="color: red">
                      {{advice_item.prop.toFixed(1)}}%↑
                    </span>
                    <span v-else style="color: limegreen">
                      {{advice_item.prop.toFixed(1)}}%↓
                    </span>)
                  </span>
                  <br />
                </span>
                ⇨死亡风险指数可下降 {{adviceOnRisk.advice.riskDiff}}
                至 {{adviceOnRisk.advice.curRisk}} (
                <span style="color: limegreen">
                  {{adviceOnRisk.advice.riskProp}}%↓
                </span>)
              </p>
            </div>
          </li>
          <li v-for="(advice_item, i) in analyzeAdvice" :key="i">
            <div :class="getTimelineDot(advice_item.type)" v-if="!advice_item.merge"></div>
            <a class="text-primary float-left mb-1" id="date" v-if="!advice_item.merge"
              @click="show(advice_item.date_index, advice_item.item)">
              {{advice_item.date}}
            </a>
            <div class="d-inline-block w-100" v-if="!advice_item.merge">
              <div v-if="advice_item.type === 0">
                死亡风险指数出现波动，短时间内上升了 {{getVariation(100 * advice_item.risk_variation_absolute)}} 至
                {{getVariation(100 * advice_item.risk)}} (
                <span v-if="advice_item.risk_variation>0" style="color: red">
                  {{getVariation(100 * advice_item.risk_variation)}}%↑
                </span>
                <span v-else style="color: limegreen">
                  {{getVariation(100 * advice_item.risk_variation)}}%↓
                </span>)，建议关注：
                <p v-for="(value, j) in advice_item.item" :key="value+'0'" style="margin-bottom: 0">◎&nbsp;
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                  从 {{(advice_item.lab[j]-advice_item.lab_variation_absolute[j]).toFixed(1)}}{{medicalUnit[value]}}
                  快速{{getTrendByVariation(advice_item.lab_variation[j])}}了
                  {{getVariation(advice_item.lab_variation_absolute[j], 1)}} 至
                  {{advice_item.lab[j].toFixed(1)}}{{medicalUnit[value]}} (
                  <span v-if="advice_item.lab_variation[j]>0" style="color: red">
                    {{getVariation(100 * advice_item.lab_variation[j])}}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{getVariation(100 * advice_item.lab_variation[j])}}%↓
                  </span>)
                  <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '且' : ''}}-->
                </p>
              </div>
              <div v-if="advice_item.type === 1">
                死亡风险指数持续升高 {{getVariation(100 * advice_item.risk_variation_absolute)}} 至
                {{getVariation(100 * advice_item.risk)}} (
                <span v-if="advice_item.risk_variation>0" style="color: red">
                  {{getVariation(100 * advice_item.risk_variation)}}%↑
                </span>
                <span v-else style="color: limegreen">
                  {{getVariation(100 * advice_item.risk_variation)}}%↓
                </span>)，建议关注：
                <p v-for="(value, j) in advice_item.item" :key="value+'1'" style="margin-bottom: 0">◎&nbsp;
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                  从 {{(advice_item.lab[j]-advice_item.lab_variation_absolute[j]).toFixed(1)}}{{medicalUnit[value]}}
                  持续{{getTrendByVariation(advice_item.lab_variation[j])}}了
                  {{getVariation(advice_item.lab_variation_absolute[j], 1)}} 至
                  {{advice_item.lab[j].toFixed(1)}}{{medicalUnit[value]}} (
                  <span v-if="advice_item.lab_variation[j]>0" style="color: red; word-spacing:0;">
                    {{getVariation(100 * advice_item.lab_variation[j])}}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{getVariation(100 * advice_item.lab_variation[j])}}%↓
                  </span>)
                  <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '和' : ''}}-->
                </p>
              </div>
              <div v-if="advice_item.type === 2">
                AI持续给予了
                <span v-for="(value, j) in advice_item.item" :key="value+'2'">
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                  {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '和' : ''}}
                </span>
                较高关注，建议：
                <p v-for="(value, j) in advice_item.item" :key="value+'2_1'" style="margin-bottom: 0">◎&nbsp;
                  {{getTrend(advice_item.trend[j])}}
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                </p>
              </div>
              <div v-if="advice_item.type === 3">
                在这段时间内，死亡风险指数持续保持高位，建议关注
                <span v-for="(value, j) in advice_item.item" :key="value+'3'">
                  <span :style="{color: ch.get(value).midColor}" style="font-weight:bold">
                    {{medicalCharChinese[value]}}
                  </span>
                  {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '和' : ''}}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </b-collapse>
    </template>
  </iq-card>
</template>

<script>
import { medicalCharChinese, medicalUnit } from '@/util/info'

export default {
    name: 'AIObservation',
    props: {
      adviceOnRiskThreshold: { type: Object },
      adviceOnRisk: { type: Object },
      analyzeAdvice: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        display: true,
        medicalCharChinese: medicalCharChinese,
        medicalUnit: medicalUnit,
        scrollTime: 20,
        id: 0,
        scrollSpeed: 0
      }
    },
    computed: {
      ch() {
        return this.$store.state.ch
      },
      lab() {
        return this.$store.state.specialLab
      },
      modifiedLab() {
        return this.$store.state.specialModifiedLab
      },
      computingAdvice() {
        return this.$store.state.computingAdvice
      },
      originalAnalyze() {
        return this.$store.state.originalAnalyze
      },
      top2() {
        return this.$store.state.top2
      },
      adviceOnIdx() {
        return this.$store.state.adviceOnIdx
      },
      adviceOnRiskThresholdSub() {
        return this.$store.state.adviceOnRiskThresholdSub
      },
      currentRisk() {
        return (this.$store.state.originalAnalyze.predict.slice(-1)[0] * 100).toFixed(1)
      },
      prognosis() {
        let prognosis = []
        this.$store.state.deathReason.slice(0, -1).forEach(reason => {
          if (reason[1] / this.originalAnalyze.cluster_info.amount >= 0.065) {
            prognosis.push(reason[0])
          }
        })
        if (this.$store.state.deathReason.length && !prognosis.length) {
          prognosis.push(this.$store.state.deathReason[0][0])
        }
        return prognosis
      }
    },
    methods: {
      getTimelineDot(type) {
        if (type === 0 || type === 3) {
          return 'timeline-dots border-danger'
        } else if (type === 1) {
          return 'timeline-dots border-warning'
        } else if (type === 2) {
          return 'timeline-dots border-primary'
        }
      },
      getVariation(variation, i = 1) {
        if (variation > 0) {
          return variation.toFixed(i)
        } else if (variation < 0) {
          return (-variation).toFixed(i)
        } else return '未变化'
      },
      getVariationMore(variation) {
        if (variation > 0) {
          return (100 * variation).toFixed(1) + '%'
        } else if (variation < 0) {
          return (-100 * variation).toFixed(1) + '%'
        } else return '未变化'
      },
      getTrendByVariation(variation) {
        if (variation > 0) {
          return '上升'
        } else if (variation < 0) {
          return '下降'
        } else return '未变化' // 不应该出现
      },
      getTrend(trend) {
        if (trend === 1) {
          return '降低'
        } else if (trend === -1) {
          return '提高'
        } else return '关注'
      },
      show(date_index, item) {
        this.$store.commit('setSelectedDate', date_index)
        this.$store.commit('setSelectedGraph', item)
        // this.scrollSpeed = this.getCurHeight() / this.scrollTime
        // this.id = setInterval(this.toStepTop, 1)
      },
      toStepTop() {
        if (document.body.scrollTop > 0) {
          document.body.scrollTop -= this.scrollSpeed
        } else if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= this.scrollSpeed
        }
        if (this.getCurHeight() <= 0) {
          clearInterval(this.id)
        }
      },
      getCurHeight() {
        return document.documentElement.scrollTop + document.body.scrollTop
      }
    }
  }
</script>

<style scoped lang="less">
  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  #date:hover {
    color: #089bab !important;
  }
</style>
