<template>
  <div class="personalInfo">
    <div>
      <b-alert
        :show="dismissCountDown"
        dismissible
        :variant="alert_style"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>
          {{ alert_content }}
          <!-- {{ dismissCountDown1 }}{{$t('bulk_register.second')}}倒计时 -->
        </p>
        <b-progress
          :variant="alert_style"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'AlertBar',
  props: {
    dismissSecs: {
      type: Number,
      default: 0
    },
    dismissCountDown: {
      type: Number,
      default: 0
    },
    alert_style: {
      type: String,
      default: 'danger'
    },
    alert_content: {
      type: String,
      default: 'alert tab'
    }
  },
  mounted() {
    xray.index()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
    //   this.dismissCountDown = this.dismissSecs
  }
}
</script>

<style scoped></style>
