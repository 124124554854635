<template>
  <div class="bulkRegister">
    <iq-card>
      <template v-slot:body>
        <b-form>
          <b-form-group label-for="customFile">
            <b-form-file
              :placeholder="$t('bulk_register.choose_a_file')"
              id="customFile"
              v-model="file"
            ></b-form-file>
          </b-form-group>
          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="alert_style"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            <p>
              {{ alert_content }}
              <!-- {{ dismissCountDown }}{{$t('bulk_register.second')}}倒计时 -->
            </p>
            <b-progress
              :variant="alert_style"
              :max="dismissSecs"
              :value="dismissCountDown"
              height="4px"
            ></b-progress>
          </b-alert>
          <b-button-group>
            <b-button variant="primary" @click="submitFile">{{
              $t('bulk_register.submit')
            }}</b-button>
            <b-button variant="danger" @click="cancle">{{
              $t('bulk_register.cancel')
            }}</b-button>
          </b-button-group>
        </b-form>
      </template>
    </iq-card>
    <iq-card>
      <template v-slot:body>
        <b-form>
          <b-form-group>
            <b-table :fields="fields" :items="items">
              <template v-slot:cell(fileName)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <div>{{ data.value }}</div>
                <div>
                  <b-alert
                    :show="
                      current_selected_file === data.value && dismissCountDown1
                    "
                    dismissible
                    :variant="alert_style1"
                    @dismissed="dismissCountDown1 = 0"
                    @dismiss-count-down="countDownChanged1"
                  >
                    <p>
                      {{ alert_content1 }}
                      <!-- {{ dismissCountDown1 }}{{$t('bulk_register.second')}}倒计时 -->
                    </p>
                    <b-progress
                      :variant="alert_style1"
                      :max="dismissSecs1"
                      :value="dismissCountDown1"
                      height="4px"
                    ></b-progress>
                  </b-alert>
                </div>
                <b-button-group>
                  <b-button variant="primary" @click="downloadFile(data)">{{
                    $t('bulk_register.download')
                  }}</b-button>
                  <b-button variant="danger" @click="deleteFile(data)">{{
                    $t('bulk_register.delete')
                  }}</b-button>
                  <b-button variant="warning" @click="bulkRegister(data)">{{
                    $t('bulk_register.bulk_register')
                  }}</b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-button variant="primary" @click="getFileList">{{
              $t('bulk_register.get_file_list')
            }}</b-button>
          </b-form-group>
        </b-form>
      </template>
    </iq-card>
  </div>
</template>

<script>
import iqCard from '../../components/xray/cards/iq-card.vue'
import { xray } from '../../config/pluginInit'
import {
  uploadFile,
  getFileList,
  // downloadFile,
  deleteFile,
  registerFile
} from '../../network/bulkRegister'
import i18n from '../../i18n'
export default {
  components: { iqCard },
  name: 'BulkRegister',
  mounted() {
    xray.index()
  },
  data() {
    return {
      file: null,
      fields: [
        {
          // A column that needs custom formatting,
          // calling formatter 'fullName' in this app
          key: 'fileName',
          label: i18n.t('bulk_register.file'),
          formatter: value => {
            return value
          }
        }
      ],
      items: [],
      dismissSecs: 3, // 警示框倒计时
      dismissCountDown: 0,
      alert_style: 'danger',
      alert_content: i18n.t('bulk_register.choose_file_alert'),
      dismissSecs1: 5, // 警示框倒计时
      dismissCountDown1: 0,
      alert_style1: 'danger',
      alert_content1: i18n.t('bulk_register.file_error'),
      current_selected_file: '',
      exist_user: []
    }
  },
  methods: {
    // 警示框相关函数
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    countDownChanged1(dismissCountDown) {
      this.dismissCountDown1 = dismissCountDown
    },
    // 文件上传相关函数
    submitFile() {
      if (this.file) {
        let formData = new FormData()
        formData.append('upload_file', this.file)
        uploadFile(formData)
          .then(res => {
            // console.log(res)
            if (res.message === '请上传Excel文件') {
              this.dismissCountDown = this.dismissSecs
              this.alert_style = 'danger'
              this.alert_content = i18n.t('bulk_register.upload_excel')
            } else {
              this.dismissCountDown = this.dismissSecs
              this.alert_style = 'success'
              this.alert_content = i18n.t('bulk_register.upload_success')
              this.getFileList()
            }
          })
          .catch(error => {
            throw error
          })
      } else {
        this.alert_style = 'danger'
        this.alert_content = i18n.t('bulk_register.choose_file_alert')
        this.dismissCountDown = this.dismissSecs
      }
    },
    cancel() {
      this.file = null
    },
    // 获取文件列表函数
    getFileList() {
      getFileList().then(res => {
        var arr = res.data
        for (var i = 0; i < arr.length; i++) {
          var tag = 0
          for (var j = 0; j < this.items.length; j++) {
            if (arr[i] === this.items[j].fileName) {
              tag = 1
            }
          }
          if (tag === 0) {
            var item = {
              fileName: arr[i]
            }
            this.items.push(item)
            // console.log(item)
          }
        }
      })
    },
    // 下载文件相关函数
    downloadFile(data) {
      let downloadElement = document.createElement('a')
      let href = 'http://47.93.42.104:10408/v1/web/files/download/' + data.value
      downloadElement.href = href
      downloadElement.download = data.value // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
      // downloadFile(data.value).then(res => {
      //   console.log(res)
      //   // this.fileChange(res.data, data.value)
      // })
    },
    // 二进制文件下载
    // fileChange(data, fileName) {
    //   let blob = new Blob([data], { type: 'application/octet-stream' }) // 转化为blob对象
    //   let filename = fileName || 'filename.xlsx' // 判断是否使用默认文件名
    //   if (typeof window.navigator.msSaveBlob !== 'undefined') {
    //     window.navigator.msSaveBlob(blob, filename)
    //   } else {
    //     var blobURL = window.URL.createObjectURL(blob) // 将blob对象转为一个URL
    //     var tempLink = document.createElement('a') // 创建一个a标签
    //     tempLink.style.display = 'none'
    //     tempLink.href = blobURL
    //     tempLink.setAttribute('download', filename) // 给a标签添加下载属性
    //     if (typeof tempLink.download === 'undefined') {
    //       tempLink.setAttribute('target', '_blank')
    //     }
    //     document.body.appendChild(tempLink) // 将a标签添加到body当中
    //     tempLink.click() // 启动下载
    //     document.body.removeChild(tempLink) // 下载完毕删除a标签
    //     window.URL.revokeObjectURL(blobURL)
    //   }
    // },
    fileChange(data, fileName) {
      let blob = new Blob([data], { type: 'application/octet-stream' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName) // 处理IE下载的兼容性
      } else {
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } // 释放掉blob对象
    },
    // 删除文件
    deleteFile(data) {
      let formData = new FormData()
      formData.append('file_name', data.value)
      deleteFile(formData).then(res => {
        this.items = []
        this.getFileList()
      })
    },
    // 批量注册
    bulkRegister(data) {
      this.current_selected_file = data.value
      let formData = new FormData()
      formData.append('file_name', data.value)
      registerFile(formData).then(res => {
        this.exist_user = []
        if (res.data.success) {
          this.alert_style1 = 'success'
          this.alert_content1 = i18n.t('bulk_register.success')
          this.alert_content1 += ' '
          let len = res.data.message.length
          for (let i = 0; i < len; i++) {
            let index = res.data.message[i].indexOf(': 用户已存在')
            if (index !== -1) {
              this.alert_content1 += i18n.t('bulk_register.user')
              this.alert_content1 += res.data.message[i].slice(0, index)
              this.alert_content1 += i18n.t('bulk_register.user_exist')
            }
          }
          this.dismissCountDown1 = this.dismissSecs1
        } else {
          this.alert_style1 = 'danger'
          this.alert_content1 = i18n.t('bulk_register.file_error')
          this.dismissCountDown1 = this.dismissSecs1
        }
      })
    }
  }
}
</script>

<style scoped></style>
