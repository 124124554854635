import axios from 'axios'

export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: 'http://47.93.42.104:10408/v1',
    timeout: 5000
  })
  // 相应拦截及其作用
  instance.interceptors.response.use(
    res => {
      // console.log(res)
      // 一般使用 res.data
      return res.data // 拦截之后需要返回,不然函数调用之后拿不到参数
    },
    err => {
      // console.log(err)
      throw (err)
    }
  )
  config.url = config.url + (config.url.endsWith('/') ? '' : '')
  // 3.发送真正的网络请求
  return instance(config) // 本来就是个promise
}
