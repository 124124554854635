<template>
  <iq-card class-name="iq-card-block iq-card-stretch">
    <template v-slot:headerTitle>
      <h4 class="card-title" style="display: inline">{{$t('main.esrdProtoCare.similarPrototype')}}</h4>
    </template>
    <template v-slot:body>
      <div id="lineChart" style="height: 312px"></div>
    </template>
  </iq-card>
</template>

<script>
export default {
  name: 'EsrdProtoCareLineChart',
  props: {
    order: Array
  },
  data() {
    return {
      echart: undefined,
      data: []
    }
  },
  computed: {
    lab() {
      return this.$store.state.lab
    },
    analyze() {
      return this.$store.state.analyze
    },
    language() {
      return this.$i18n.locale
    }
  },
  watch: {
    analyze() {
      this.setChart()
    },
    language() {
      this.setChart()
    }
  },
  mounted() {
    this.echart = this.$echarts.init(document.getElementById('lineChart'))
    window.onresize = () => {
      this.echart.resize()
    }
  },
  methods: {
    setChart() {
      this.echart.setOption({
        animation: true,
        xAxis: {
          type: 'time',
          show: true,
          name: this.$t('main.general.time'),
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          shadowBlur: 2,
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'category',
          name: this.$t('main.esrdProtoCare.prototypeId'),
          data: [0, 1, 2, 6, 4, 7, 3, 5]
        },
        tooltip: {
        },
        series: {
          data: this.analyze.similarityIndex.map((s, i) => [this.lab[i].date, this.order[s]]),
          type: 'line',
          tooltip: {
            borderWidth: 0,
            formatter: params => {
              const attentionContent = this.analyze.similarity[params.dataIndex].map((v, i) => [i, v]).sort((a, b) => -a[1] + b[1])
                .map(e => `<div>${this.$t('main.esrdProtoCare.prototype')} ${e[0]}: ${e[1].toFixed(3)}</div>`).join('')
              return `<div>
<b>${this.$t('main.general.AiAttention')}</b>
<div>${attentionContent}</div>
</div>`
            }
          }
        }
      })
    }
  }
}
</script>
