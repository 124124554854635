<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div
      class="iq-navbar-custom"
      :class="
        horizontal ? 'd-flex align-items-center justify-content-between' : ''
      "
    >
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="logo" class="img-fluid" alt="logo" />
            <span>XRay</span>
          </router-link>
        </div>
      </div>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <List :items="items" class="d-flex"></List>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-search-bar">
          <form class="searchbox">
            <!-- <input type="text" class="text search-input" placeholder="Search" -->
            <input
              type="text"
              class="text search-input"
              :placeholder="$t('main.general.search')"
              v-model="inputVal"
              @keydown.enter="search"
            />
            <a class="search-link" @click="search"
              ><i class="ri-search-line"></i
            ></a>
          </form>
        </div>
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse>
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import List from '../menus/ListStyle1'
import SideBarItems from '../../../FackApi/json/SideBar'

export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'layout1.dashboard' }) },
    title: { type: String, default: 'Dashboard' },
    logo: {
      type: String,
      default: require('../../../assets/images/pkuHospital-2.png')
    },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  mounted() {
    document.addEventListener('click', this.closeSearch, true)
  },
  components: {
    List
  },
  computed: {},
  data() {
    return {
      sidebar: SideBarItems,
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      inputVal: undefined
    }
  },
  methods: {
    miniSidebar() {
      this.$emit('toggle')
    },
    search() {
      if (this.inputVal) {
        if (this.$route.meta.name === 'ehr') {
          this.$router.push('/' + this.inputVal /* + '?secret=yuyuyzl' */)
        } else if (this.$route.meta.name === 'covid') {
          this.$router.push('/covid/' + this.inputVal /* + '?secret=yuyuyzl' */)
        } else if (this.$route.meta.name === 'anonymous') {
          this.$router.push(
            '/anonymous/covid/' + this.inputVal /* + '?secret=yuyuyzl' */
          )
        } else if (this.$route.meta.name === 'challenge') {
          this.$router.push(
            '/challenge/' + this.inputVal /* + '?secret=yuyuyzl' */
          )
        } else if (this.$route.meta.name === 'ESRD-ProtoCare') {
          this.$router.push(
            '/esrd/protocare/' + this.inputVal /* + '?secret=yuyuyzl' */
          )
        } else if (this.$route.meta.name === 'ESRD-ProMANet') {
          this.$router.push(
            '/esrd/promanet/' + this.inputVal /* + '?secret=yuyuyzl' */
          )
        }
        // else if (this.$route.meta.name === 'questionnaire') {
        //   this.$router.push(this.inputVal)
        //   // this.$router.push('/' + this.inputVal)
        // }
      }
    }
  }
}
</script>
