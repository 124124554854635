import axios from 'axios'

export function getAllDoctor() {
  return axios({
    method: 'get',
    url: 'http://47.93.42.104:10408/v1/web/doctors'
  })
}

export function getDepartmentDoctor(department) {
  return axios({
    method: 'get',
    url: 'http://47.93.42.104:10408/v1/web/doctors/department/' + department
  })
}

export function getAllDepartment() {
  return axios({
    method: 'get',
    url: 'http://47.93.42.104:10408/v1/web/doctors/departments'
  })
}

export function deleteDoctor(user_id) {
  return axios({
    method: 'delete',
    url: 'http://47.93.42.104:10408/v1/web/doctors/' + user_id
  })
}

export function modifyAuth(userId, form) {
  return axios({
    method: 'put',
    url: 'http://47.93.42.104:10408/v1/web/doctors/auth/' + userId,
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function modifyPwd_force(userId, form) {
  return axios({
    method: 'put',
    url: 'http://47.93.42.104:10408/v1/web/doctors/password/non-check/' + userId,
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
