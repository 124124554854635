<template>
  <b-container fluid>

    <b-row>
      <b-col md="12">
        <iq-card>
<!--          这里可以准备加一个数据列表供用户选择-->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('lab_test.Search Lab Test By PDID')}}</h4>
          </template>
          <template v-slot:body >
            <b-form-input list="my-list-id" v-model="pdid" type="number" size="lg"></b-form-input>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="getLabtest(pdid)">{{$t('lab_test.Search')}}</b-button>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('lab_test.Editable Table')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="add" :disabled="btnDisable">{{$t('lab_test.Add New')}}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" foot-clone>
                  <template v-slot:cell(date)="data">
                    <span v-if="!data.item.editable">{{ data.item.date }}</span>
                    <input type="text" v-model="data.item.date" v-else class="form-control">
                  </template>
                  <template v-slot:cell(amount)="data">
                    <span v-if="!data.item.editable">{{ data.item.amount }}</span>
                    <input type="text" v-model="data.item.amount" v-else class="form-control">
                  </template>
                  <template v-slot:cell(weight)="data">
                    <span v-if="!data.item.editable">{{ data.item.weight }}</span>
                    <input type="text" v-model="data.item.weight" v-else class="form-control">
                  </template>
                  <template v-slot:cell(wbc)="data">
                    <span v-if="!data.item.editable">{{ data.item.wbc }}</span>
                    <input type="text" v-model="data.item.wbc" v-else class="form-control">
                  </template>
                  <template v-slot:cell(hgb)="data">
                    <span v-if="!data.item.editable">{{ data.item.hgb }}</span>
                    <input type="text" v-model="data.item.hgb" v-else class="form-control">
                  </template>
                  <template v-slot:cell(k)="data">
                    <span v-if="!data.item.editable">{{ data.item.k }}</span>
                    <input type="text" v-model="data.item.k" v-else class="form-control">
                  </template>
                  <template v-slot:cell(na)="data">
                    <span v-if="!data.item.editable">{{ data.item.na }}</span>
                    <input type="text" v-model="data.item.na" v-else class="form-control">
                  </template>
                  <template v-slot:cell(cl)="data">
                    <span v-if="!data.item.editable">{{ data.item.cl }}</span>
                    <input type="text" v-model="data.item.cl" v-else class="form-control">
                  </template>
                  <template v-slot:cell(co2)="data">
                    <span v-if="!data.item.editable">{{ data.item.co2 }}</span>
                    <input type="text" v-model="data.item.co2" v-else class="form-control">
                  </template>
                  <template v-slot:cell(glu)="data">
                    <span v-if="!data.item.editable">{{ data.item.glu }}</span>
                    <input type="text" v-model="data.item.glu" v-else class="form-control">
                  </template>
                  <template v-slot:cell(alb)="data">
                    <span v-if="!data.item.editable">{{ data.item.alb }}</span>
                    <input type="text" v-model="data.item.alb" v-else class="form-control">
                  </template>
                  <template v-slot:cell(urea)="data">
                    <span v-if="!data.item.editable">{{ data.item.urea }}</span>
                    <input type="text" v-model="data.item.urea" v-else class="form-control">
                  </template>
                  <template v-slot:cell(cre)="data">
                    <span v-if="!data.item.editable">{{ data.item.cre }}</span>
                    <input type="text" v-model="data.item.cre" v-else class="form-control">
                  </template>
                  <template v-slot:cell(ca)="data">
                    <span v-if="!data.item.editable">{{ data.item.ca }}</span>
                    <input type="text" v-model="data.item.ca" v-else class="form-control">
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { xray } from '../../config/pluginInit'
// eslint-disable-next-line no-unused-vars
import APIHelper from '../../util/APIHelper'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
export default {
  name: 'LabTestTable',
  async created() {
    await this.getLabtest(94)
  },
  mounted () {
    xray.index()
  },
  methods: {
    getLabtest(id) {
      this.rows = []
      APIHelper.getPatientsLabTestById(id).then(labtest => {
        for (let i = 0; i < labtest.length; i++) {
          let obj = this.default()
          obj.date = moment(labtest[i].date).format('YYYY-MM-DD')
          obj.p = labtest[i].p
          obj.wbc = labtest[i].wbc
          obj.hgb = labtest[i].hgb
          obj.k = labtest[i].k
          obj.na = labtest[i].na
          obj.cl = labtest[i].cl
          obj.co2 = labtest[i].co2
          obj.glu = labtest[i].glu
          obj.alb = labtest[i].alb
          obj.urea = labtest[i].urea
          obj.cre = labtest[i].cre
          obj.ca = labtest[i].ca
          obj.crp = labtest[i].crp
          obj.dia = labtest[i].dia
          obj.amount = labtest[i].amount
          obj.weight = labtest[i].weight
          obj.pdid = labtest[i].pdid
          this.rows.push(obj)
        }
      })
    },
    add() {
      let obj = this.default()
      obj.pdid = this.pdid
      this.rows.push(obj)
      let form = this.default()
      form.pdid = this.pdid
      form.date = moment(form.date).format('YYYYMMDD')
      APIHelper.addPatientsLabTestById(form).then(res => {
        // console.log(res)
      })
    },
    default () {
      return {
        date: '1970-01-01',
        wbc: 0,
        hgb: 0,
        k: 0,
        na: 0,
        cl: 0,
        co2: 0,
        glu: 0,
        alb: 0,
        urea: 0,
        cre: 0,
        ca: 0,
        p: 0,
        crp: 0,
        sys: 0,
        dia: 0,
        amount: 0,
        weight: 0,
        editable: false,
        pdid: 0
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
      var form = JSON.parse(JSON.stringify(item))
      form.date = moment(form.date).format('YYYYMMDD')
      // console.log(form.date)
      // console.log(item.date)
      APIHelper.updatePatientsLabTestById(item.pdid, form).then(res => {
        // console.log(res)
      })
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
      APIHelper.deletePatientsLabTestById(item.pdid, moment(item.date).format('YYYYMMDD')).then(res => {
        // console.log(res)
      })
    }
  },
  data () {
    return {
      columns: [
        { label: 'Date', key: 'date', class: 'text-left' },
        { label: 'Amount', key: 'amount', class: 'text-left' },
        { label: 'Weight', key: 'weight', class: 'text-left' },
        { label: 'Wbc', key: 'wbc', class: 'text-left' },
        { label: 'Hgb', key: 'hgb', class: 'text-left' },
        { label: 'K', key: 'k', class: 'text-left' },
        { label: 'Na', key: 'na', class: 'text-left' },
        { label: 'Cl', key: 'na', class: 'text-left' },
        { label: 'CO2', key: 'co2', class: 'text-left' },
        { label: 'glu', key: 'glu', class: 'text-left' },
        { label: 'alb', key: 'alb', class: 'text-left' },
        { label: 'urea', key: 'urea', class: 'text-left' },
        { label: 'cre', key: 'cre', class: 'text-left' },
        { label: 'ca', key: 'ca', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      rows: [],
      pdid: '94'
    }
  },
  computed: {
    btnDisable() {
      return this.pdid === ''
    }
  }
}
</script>
