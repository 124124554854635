<template>
  <iq-card class-name="iq-card-block iq-card-stretch">
    <template v-slot:headerTitle>
      <h4 class="card-title" style="display: inline">{{$t('main.general.riskCurve')}}
        <!--                  <i class="las la-exclamation-circle" style="margin:10px" @click.stop=" dialog = true"></i>-->
      </h4>
      <Tooltip placement="top">
        <img src="@/assets/images/question.png" alt="guide" class="guide" />
        <span slot="content" style="white-space: normal">{{$t('main.general.riskCurve()')}}</span>
      </Tooltip>
      <!--            <template>-->
      <!--              <v-row justify="center">-->
      <!--                <v-dialog v-model="dialog" width="600px">-->
      <!--                  <v-card>-->
      <!--                    <v-card-title>-->
      <!--                      <span class="headline">如何使用</span>-->
      <!--                    </v-card-title>-->
      <!--                    <v-card-text></v-card-text>-->
      <!--                    <v-card-actions>-->
      <!--                      <v-spacer></v-spacer>-->
      <!--                      <v-btn color="green darken-1" text @click="dialog = false">关闭</v-btn>-->
      <!--                    </v-card-actions>-->
      <!--                  </v-card>-->
      <!--                </v-dialog>-->
      <!--              </v-row>-->
      <!--            </template>-->
    </template>
    <template v-slot:headerAction>
      <div style="padding-right: 20px">
<!--        <a @click="setShowEvents(!showEvents)" style="padding-right: 30px">-->
<!--          <span>{{showEvents ? '隐藏' : '显示'}}事件</span>-->
<!--        </a>-->
        <a @click="setShowRisk(!showRisk)" style="padding-right: 30px">
          <span>{{showRisk ? $t('main.general.hideCurve') : $t('main.general.displayCurve')}}</span>
        </a>
        <a @click="showAll(!showValue)" style="padding-right: 30px">
          <span>{{showValue ? $t('main.general.hideInd') : $t('main.general.displayInd')}}</span>
        </a>
        <a @click="reset">
          <span>{{$t('main.general.reset')}}</span>
        </a>
      </div>
    </template>
    <template v-slot:body>
      <div id="patient-chart" :onContextMenu="e => e.preventDefault()">
        <input id="patient-chart-input" v-model="inputVal" @keydown.enter="enter" @keydown.esc="inputPos = undefined"
          @keydown.shift="keyModifier=0.1" @keydown.alt="keyModifier=0.01" @keyup.shift="keyModifier=1"
          @keyup.alt="keyModifier=1" @wheel="wheel"
          :style="inputPos?{top: inputPos[1] + 'px', left: inputPos[0] + 'px'}:{display: 'none'}" />
        <div id="patientDataChart" @contextmenu.prevent
          :style="{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: 'unset'}"></div>
      </div>
    </template>
  </iq-card>
</template>

<script>
import { medicalCharChinese, medicalChar, medicalCharChineseShort, medicalCharShort, medicalStandard, medicalUnit } from '@/util/info'

  export default {
    name: 'PatientDataChart',
    data() {
      return {
        echart: undefined,
        inputPos: undefined,
        inputVal: undefined,
        keyModifier: 1
      }
    },
    computed: {
      data() {
        const { analyze, modifiedLab, lab, events } = this
        return {
          analyze, modifiedLab, lab, events
        }
      },
      selected() {
        const { selectedGraph, selectedDate } = this
        return {
          selectedGraph, selectedDate
        }
      },
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      selectedDate() {
        return this.$store.state.selectedDate
      },
      events() {
        return this.$store.state.events
      },
      patient() {
        return this.$store.state.patient
      },
      analyze() {
        return this.$store.state.analyze
      },
      modifiedLab() {
        return this.$store.state.modifiedLab
      },
      lab() {
        return this.$store.state.lab
      },
      ch() {
        return this.$store.state.ch
      },
      showValue() {
        return this.$store.state.showValue
      },
      // showEvents() {
      //   return this.$store.state.showEvents
      // },
      showLine() {
        return this.$store.state.showLine
      },
      showRisk() {
        return this.$store.state.showRisk
      },
      predict() {
        let obj = {
          type: 'line',
          name: this.$i18n.locale === 'chi' ? '死亡风险指数' : 'Death Risk Index',
          data: this.modifiedLab.map((event, i) => [event.date, this.analyze.predict[i] * 100]),
          yAxisIndex: 0,
          lineStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#ff0844'
              }, {
                offset: 1, color: '#ffb199'
              }],
              global: false
            },
            opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#ff0844'
              }, {
                offset: 1, color: '#ffb199'
              }],
              global: false
            },
            opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
          },
          symbol: 'none',
          smooth: 0.2
        }
        if (this.patient.deathDate && this.showLine) {
          let times = this.patient.deathDate.split('-')
          obj.markLine = {
            symbol: 'none', // 去掉警戒线最后面的箭头
            silent: false,
            data: [{
              silent: false, // 鼠标悬停事件  true没有，false有
              lineStyle: { // 警戒线的样式  ，虚实  颜色
                type: 'dashed',
                color: '#FF6347',
                width: 2
              },
              label: {
                position: 'end',
                formatter: this.$i18n.locale === 'chi' ? '死亡前一年' : 'year before death'
              },
              xAxis: (Number(times[0]) - 1) + '-' + times[1] + '-' + times[2]
            }]
          }
        }
        if (1 || !this.selectedGraph.length) {
          if (obj.markLine) {
            obj.markLine.data.push({
              silent: true, // 鼠标悬停事件  true没有，false有
              lineStyle: { // 警戒线的样式  ，虚实  颜色
                type: 'dashed',
                color: '#FFB199',
                width: 2
              },
              label: {
                show: false
              },
              yAxis: 50
            })
          } else {
            obj.markLine = {
              symbol: 'none', // 去掉警戒线最后面的箭头
              silent: true,
              data: [{
                silent: true, // 鼠标悬停事件  true没有，false有
                lineStyle: { // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: '#FFB199',
                  width: 2
                },
                label: {
                  show: false
                },
                yAxis: 50
              }]
            }
          }
        }
        return obj
      },
      series() {
        return {
          series: [
            this.showRisk && this.analyze.predict && this.predict,
            ...this.selectedGraph.map((key, i) => ({
              type: 'line',
              name: this.$i18n.locale === 'chi' ? medicalCharChinese[key] : medicalChar[key],
              data: this.modifiedLab.map(event => [event.date, event[key]]),
              yAxisIndex: i + 1,
              symbol: (value, params) => (this.analyze.attention && this.analyze.attention[params.dataIndex][key] > 0) ? 'circle' : 'emptyCircle',
              symbolSize: (value, params) => (this.analyze.attention ? (this.analyze.attention[params.dataIndex][key]) * 12 + 4 : 8),
              itemStyle: {
                color: this.ch.get(key).midColor
              },
              lineStyle: {
                color: this.ch.get(key)
              },
              emphasis: {
                lineStyle: {
                  width: 2
                }
              },
              markLine: this.selectedGraph.length === 1 && medicalStandard[this.selectedGraph[0]] && { // 添加警戒线
                symbol: 'none', // 去掉警戒线最后面的箭头
                // silent: true,
                data: [{
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: { // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: this.ch.get(this.selectedGraph[0]).midColor
                  },
                  label: {
                    position: 'end',
                    formatter: this.$i18n.locale === 'chi' ? '正常值下限' : 'Lower limit',
                    padding: 20
                  },
                  yAxis: medicalStandard[this.selectedGraph[0]][0]
                }, {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: { // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: this.ch.get(this.selectedGraph[0]).midColor
                  },
                  label: {
                    position: 'end',
                    formatter: this.$i18n.locale === 'chi' ? '正常值上限' : 'Upper limit',
                    padding: 20
                  },
                  yAxis: medicalStandard[this.selectedGraph[0]][1]
                }]
              }
              // markPoint: {
              //     data:modifiedLab.map((event,index)=>({
              //         coord:[event.date,event[key]],
              //         value:analyze.attention[index][key].toFixed(2)
              //     })).filter(o=>o.value>0),
              //     //data:[{coord:['2007-08-21',0],value:300}]
              // },
            })),
            // this.showEvents && this.events.length && {
            //   type: 'line',
            //   name: 'eventLine',
            //   markLine: {
            //     symbol: 'none', // 去掉警戒线最后面的箭头
            //     silent: false,
            //     data: this.events.map((data, i) => ({
            //       silent: true, // 鼠标悬停事件  true没有，false有
            //       lineStyle: { // 警戒线的样式  ，虚实  颜色
            //         type: 'dashed',
            //         color: this.ch.get(data.event).midColor,
            //         width: 3
            //       },
            //       itemStyle: {
            //         color: this.ch.get(data.event).midColor
            //       },
            //       label: {
            //         position: 'end',
            //         formatter: data.event
            //       },
            //       xAxis: data.date
            //     }))
            //   }
            // },
            this.selectedDate.length && {
              type: 'line',
              name: 'markLine',
              markLine: { // 添加警戒线
                symbol: 'none', // 去掉警戒线最后面的箭头
                silent: true,
                data: [{
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: { // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: '#FF4500',
                    width: 3
                  },
                  label: {
                    position: 'end',
                    formatter: this.getAiFocusArea((this.selectedDate[1] - this.selectedDate[0]) / this.modifiedLab.length >= 0.25)
                  },
                  xAxis: this.modifiedLab[this.selectedDate[0]].date
                }, {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: { // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: '#FF4500',
                    width: 3
                  },
                  label: (this.selectedDate[1] - this.selectedDate[0]) / this.modifiedLab.length >= 0.25 ? {
                    position: 'end',
                    formatter: this.$i18n.locale === 'chi' ? '←AI关注区域' : '←AI Focus Area'
                  } : {
                      show: false
                    },
                  xAxis: this.modifiedLab[this.selectedDate[1]].date
                }]
              }
            },
            ...this.selectedGraph.map((key, i) => ({
              type: 'line',
              name: key + ' predict',
              data: this.analyze.predict_next_value && this.analyze.predict_next_value.map((event, i) => {
                const date = this.modifiedLab[i].date.split('-')
                const predictDate = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
                predictDate.setMonth(predictDate.getMonth() + 3)
                const month = predictDate.getMonth() + 1
                const day = predictDate.getDate()
                const newDate = predictDate.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' +
                  (day < 10 ? '0' + day : day)
                return [newDate, event[key]]
              }),
              yAxisIndex: i + 1,
              symbol: 'emptyCircle',
              symbolSize: 4,
              itemStyle: {
                color: this.ch.get(key + ' predict').midColor
              },
              lineStyle: {
                color: this.ch.get(key + ' predict')
              }
            })),
            this.analyze.stage && {
              type: 'line',
              name: 'Stage Variation',
              data: this.modifiedLab.map((event, i) => [event.date, this.analyze.stage[i]]),
              yAxisIndex: this.selectedGraph.length + 2,
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0, color: '#1e3c72'
                  }, {
                    offset: 1, color: '#2a5298'
                  }],
                  global: false
                },
                opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0, color: '#1e3c72'
                  }, {
                    offset: 1, color: '#2a5298'
                  }],
                  global: false
                },
                opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
              },
              symbol: 'none',
              smooth: 0.2
            }
          ]
        }
      }
    },
    watch: {
      data() {
        this.echart.setOption(this.series)
        // this.setChart()
      },
      selected() {
        this.setChart()
      },
      showLine() {
        this.echart.setOption(this.series)
      },
      // showEvents() {
      //   this.setChart()
      // },
      showRisk() {
        this.setChart()
      },
      lab() {
        this.$store.commit('setModifiedLab', this.lab)
      }
    },
    created() {
    },
    mounted() {
      this.echart = this.$echarts.init(document.getElementById('patientDataChart'))
      this.echart.getZr().on('click', e => {
        if (!e.target) this.inputPos = undefined
      })
      this.setChart()
      window.onresize = () => {
        this.echart.resize()
      }
    },
    destroyed() {
      window.onresize = null
    },
    methods: {
      showAll(value) {
        this.$store.commit('setShowValue', value)
        // this.$store.commit('setSelectedGraph', [])
      },
      setShowRisk(value) {
        this.$store.commit('setShowRisk', value)
        // this.$store.commit('setSelectedGraph', [])
      },
      // setShowEvents(value) {
      //   this.$store.commit('setShowEvents', value)
      //   // this.$store.commit('setSelectedGraph', [])
      // },
      reset() {
        this.$store.commit('setSelectedDate', [])
        this.$store.commit('setSelectedGraph', [])
      },
      enter() {
        this.selectedGraph.map((key) => {
          if (this.inputVal !== '' && !isNaN(+this.inputVal)) {
            this.$store.commit('setModifiedLab', this.modifiedLab.map(o => o.date === this.event.date ? {
              ...o,
              [key]: this.inputVal
            } : o))
          } else if (this.inputVal === '') {
            this.$store.commit('setModifiedLab', this.modifiedLab.map(o => o.date === this.event.date ? {
              ...o,
              [key]: this.lab[this.labIndex][key]
            } : o))
          }
          this.inputPos = undefined
        })
      },
      getAiFocusArea(value) {
        if (value) {
          return this.$i18n.locale === 'chi' ? 'AI关注区域→' : 'AI focus area→'
        } else {
          return this.$i18n.locale === 'chi' ? 'AI关注区域' : 'AI focus area'
        }
      },
      wheel(e) {
        this.selectedGraph.map((key) => {
          const delta = Math.round(e.deltaY) * 0.01 * this.keyModifier
          this.inputVal = parseFloat((parseFloat(this.inputVal) + delta).toPrecision(12))
          if (!isNaN(this.inputVal)) {
            this.$store.commit('setModifiedLab',
              this.modifiedLab.map(o => o.date === this.event.date ? {
                ...o,
                [key]: this.inputVal
              } : o))
          }
        })
      },
      setChart() {
        this.echart.setOption({
          animation: true,
          xAxis: [{
            type: 'time',
            show: true,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            shadowBlur: 2
          }],
          yAxis: [
            {
              name: this.$i18n.locale === 'chi' ? '死亡风险指数' : 'Death Risk Index',
              type: 'value',
              show: this.showRisk && this.analyze.predict,
              min: 0,
              max: 100,
              offset: 0,
              position: 'left',
              axisLine: {
                show: true
              }
            },
            ...this.selectedGraph.map((key, i) => ({
              name: this.$i18n.locale === 'chi' ? medicalCharChineseShort[this.selectedGraph[i]] : medicalCharShort[this.selectedGraph[i]] + '\n' + medicalUnit[this.selectedGraph[i]],
              type: 'value',
              axisLine: {
                show: true,
                lineStyle: {
                  color: this.ch.get(key).midColor
                }
              },
              axisLabel: {
                color: '#000'
              },
              nameTextStyle: {
                color: '#000'
              },
              // 最值由正常值和指标值取最值得出
              min: medicalStandard[this.selectedGraph[i]]
                ? Math.min(medicalStandard[this.selectedGraph[i]][0], ...this.modifiedLab.map(event => event[key]))
                : Math.min(...this.modifiedLab.map(event => event[key])),
              max: medicalStandard[this.selectedGraph[i]]
                ? Math.max(medicalStandard[this.selectedGraph[i]][1], ...this.modifiedLab.map(event => event[key]))
                : Math.max(...this.modifiedLab.map(event => event[key])),
              offset: this.showRisk ? i * 48 : (i <= 0 ? 0 : (i * 48 - 48)),
              position: this.showRisk ? 'right' : (i === 0 ? 'left' : 'right')
            })),
            {
              name: '指标',
              type: 'value',
              show: this.selectedGraph.length === 0 && this.analyze.stage,
              offset: 0,
              position: 'right'
            }
          ],
          grid: {
            show: false,
            borderWidth: 0,
            backgroundColor: '#fff',
            top: 64,
            left: 48,
            right: 96 + (this.selectedGraph.length > 2 ? this.selectedGraph.length * 48 - 96 : 0),
            bottom: 32
          },
          tooltip: {
            trigger: 'axis',
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              let x = 0 // x坐标位置
              let y = 0 // y坐标位置
              // 当前鼠标位置
              let pointX = point[0]
              let pointY = point[1]
              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];
              // 提示框大小
              let boxWidth = size.contentSize[0]
              let boxHeight = size.contentSize[1]
              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = pointX + 10
              } else { // 左边放的下
                x = pointX - boxWidth - 10
              }
              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5
              } else { // 上边放得下
                y = pointY - boxHeight
              }
              return [x, y]
            },
            formatter: params => {
              const getCircle = (echartColor) => `<span style="
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: ${echartColor.midColor};
"> </span>`
              const index = params[0].dataIndex
              const valueContent = params.map(o => `<div>${o.marker}${o.seriesName}：${Number(o.value[1])
                .toFixed(2)}</div>`).join('')
              let attentionContent
              if (this.showValue) {
                attentionContent = this.analyze.attention ? Object.entries(this.analyze.attention[index])
                  .filter(o => o[1] >= 0).sort((a, b) => -a[1] + b[1])
                  .map(([k, v]) => `<div>${getCircle(this.ch.get(k))}${this.$i18n.locale === 'chi' ? medicalCharChinese[k] : medicalChar[k]}：${(v * 100)
                    .toFixed(1)}% (${Number(this.lab[index][k]).toFixed(1)})</div>`).join('') : undefined
              } else {
                attentionContent = this.analyze.attention ? Object.entries(this.analyze.attention[index])
                  .filter(o => o[1] > 0.0005).sort((a, b) => -a[1] + b[1])
                  .map(([k, v]) => `<div>${getCircle(this.ch.get(k))}${this.$i18n.locale === 'chi' ? medicalCharChinese[k] : medicalChar[k]}：${(v * 100)
                    .toFixed(1)}%</div>`).join('') : undefined
              }
              const attn = this.$i18n.locale === 'chi' ? 'AI关注度' : 'AI Attention'
              return `<div><div><b>${params[0].value[0]}</b></div>${valueContent}<div>${attentionContent
                ? `<b>${attn}</b></div>${attentionContent}</div>` : ''}`
            }
          }
        }, true)
        this.echart.setOption(this.series)
        this.echart.setOption({
          graphic: this.echart ? this.selectedGraph.map((key, selectedIndex) => this.modifiedLab.map((event, labIndex) => ({
            type: 'circle',
            shape: { r: 8 },
            position: [this.echart.convertToPixel({ xAxisIndex: 0 }, event.date), this.echart.convertToPixel({ yAxisIndex: selectedIndex + 1 }, event[key])],
            draggable: true,
            invisible: true,
            z: 100,
            cursor: 'n-resize',
            onmouseup: (e) => {
              if (e.event.button === 2) {
                this.inputPos = [e.offsetX + 10, e.offsetY - 40]
                this.inputVal = parseFloat(this.lab[labIndex][key])
                this.event = event
                this.labIndex = labIndex
              }
            },
            ondrag: (e) => {
              if (this.inputPos) this.inputPos = undefined
              this.$store.commit('setModifiedLab', this.modifiedLab.map(o => o.date === event.date ? {
                ...o,
                [key]: this.echart.convertFromPixel({ yAxisIndex: selectedIndex + 1 }, e.offsetY)
              } : o))
            }
          }))).reduce((obj, cur) => [...obj, ...cur], []) : undefined
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  #patient-chart {
    /*width: 100%;*/
    /*height: 0;*/
    position: relative;
    padding-top: 40%;

    &-input {
      position: absolute;
      width: 100px;
      height: 32px;
      border: 1px solid #00000026;
      border-radius: 4px;
      z-index: 100001;
      padding: 0 8px;
    }

    &-input:focus {
      outline: none;
    }
  }
</style>
