<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col md="5">
                    <b-col>
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{$t('analysis.patientsGender')}}</h4>
                            </template>
                            <template v-slot:body>
                                <ApexChart v-if="isloaded.genderLoaded" element="genderAnalysis" :chartOption="genderChart"/>
                                <b-spinner v-else variant="primary" label="Loading..." type="grow"/>
                            </template>
                        </iq-card>
                    </b-col>
                    <b-col>
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{$t('analysis.patientsAge')}}</h4>
                            </template>
                            <template v-slot:body>
                                <ApexChart v-if="isloaded.ageLoaded" element="ageAnalysis" :chartOption="ageChart"/>
                                <b-spinner v-else variant="primary" label="Loading..." type="grow"/>
                            </template>
                        </iq-card>
                    </b-col>
                </b-col>
                <b-col md="7">
                    <iq-card>
                        <template v-slot:headerTitle>
                            <h4 class="card-title">{{$t('analysis.diseaseSelect')}}</h4>
                        </template>
                        <template v-slot:body>
                            <!-- 后续更新可以向其传出科室参数 -->
                            <origin-disease-selector/>
                        </template>
                    </iq-card>
                    <iq-card>
                        <template v-slot:headerTitle>
                            <h4 class="card-title">{{$t('analysis.patientsOriginDisease')}}</h4>
                        </template>
                        <template v-slot:body>
                            <origin-disease-chart element="origin_disease_chart" :patients="patients"/>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import OriginDiseaseChart from '../../components/Analysis/OriginDiseaseChart.vue'
import OriginDiseaseSelector from '../../components/Analysis/OriginDiseaseSelector.vue'
import iqCard from '../../components/xray/cards/iq-card.vue'
import { xray } from '../../config/pluginInit'
import APIHelper from '../../util/APIHelper'
import ColorHelper from '../../util/ColorHelper'
export default {
    components: { iqCard, OriginDiseaseChart, OriginDiseaseSelector },
    name: 'all-patients',
    created() {
        if (this.$store.state.ch) {
            this.$store.commit('setCh', new ColorHelper())
        }
    },
    mounted() {
        xray.index()
        if (this.$store.state.patientsBasicDetails.length === 0) {
            APIHelper.getAllPatientsBasicDetails()
                .then(patients => {
                    this.$store.commit('setPatientsBasicDetails', patients)
                    this.ageAnalysis(patients)
                    this.genderAnalysis(patients)
                })
        } else {
            const patients = this.patients
            this.ageAnalysis(patients)
            this.genderAnalysis(patients)
        }
    },
    computed: {
        patients() {
            return this.$store.state.patientsBasicDetails
        }
    },
    methods: {
        ageDivide(age) {
            let res
            if (age <= 10) {
                res = 0
            } else if (age <= 20) {
                res = 1
            } else if (age <= 30) {
                res = 2
            } else if (age <= 40) {
                res = 3
            } else if (age <= 50) {
                res = 4
            } else if (age <= 60) {
                res = 5
            } else if (age <= 70) {
                res = 6
            } else if (age <= 80) {
                res = 7
            } else if (age <= 90) {
                res = 8
            } else if (age <= 100) {
                res = 9
            } else {
                res = 10
            }
            return res
        },
        async ageAnalysis(patients) {
            for (const item of patients) {
                const loc = this.ageDivide(item.age)
                this.ageChart.series[0].data[loc] += 1
            }
            this.isloaded.ageLoaded = true
        },
        async genderAnalysis(patients) {
            for (const item of patients) {
                this.genderChart.series[item.gender] += 1
            }
            this.isloaded.genderLoaded = true
        }
    },
    data() {
        return {
            isloaded: {
                ageLoaded: false,
                genderLoaded: false
            },
            ageChart: {
                chart: {
                    height: 450,
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }],
                xaxis: {
                    categories: [
                        '1-10',
                        '11-20',
                        '21-30',
                        '31-40',
                        '41-50',
                        '51-60',
                        '61-70',
                        '71-80',
                        '81-90',
                        '91-100',
                        '100+'
                    ]
                }
            },
            genderChart: {
                chart: {
                    height: 350,
                    type: 'pie'
                },
                labels: ['female', 'male'],
                series: [0, 0],
                colors: ['#ffd400', '#00d0ff'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        }
    }
}
</script>
<style scoped>
.topselector {
  display: flex;
  justify-content: flex-end;
}
</style>