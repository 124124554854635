<template>
    <div>
        <span v-for="value in diseases" :key="value">
            <span>
                <b-button pill :pressed="selected.indexOf(value) !== -1"
                    :ref="value"
                    @click="click($event, value)" @mouseenter="mouseEnter($event, value)" @mouseleave="mouseLeave($event, value)"
                    :style="{color: ch.get(value).midColor, borderColor: ch.get(value).midColor}"
                    variant="outline-primary" class="mb-3 mr-1" id="selector-button">
                    {{value}}
                </b-button>
            </span>
        </span>
    </div>
</template>

<script>
import { diseases } from '@/util/info'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'origin-disease-select',
    data () {
        return {
            diseases: diseases
        }
    },
    computed: {
        ...mapGetters('Analysis', [
            'selected'
        ]),
        ch() {
            return this.$store.state.ch
        }
    },
    methods: {
        ...mapActions('Analysis', [
            'updateRecord',
            'updateSelected',
            'changeStatus'
        ]),
        mouseEnter(e, value) {
            if (this.selected.indexOf(value) === -1) {
                e.target.style.color = '#ffffff'
                e.target.style.background = this.ch.get(value).midColor
            }
        },
        mouseLeave (e, value) {
            if (this.selected.indexOf(value) === -1) {
                e.target.style.color = this.ch.get(value).midColor
                e.target.style.background = '#ffffff'
            }
        },
        click(e, value) {
            if (this.selected.indexOf(value) === -1) {
                e.target.style.color = '#ffffff'
                e.target.style.background = this.ch.get(value).midColor
                this.updateSelected([...this.selected, value])
            } else {
                e.target.style.color = this.ch.get(value).midColor
                e.target.style.background = '#ffffff'
                this.updateSelected(this.selected.filter(a => a !== value))
            }
        }
    }
}
</script>