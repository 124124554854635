<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="5">
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img v-if="patient.gender === 2" src="../../assets/images/user/11.png" alt="profile-img"
                    class="avatar-130 img-fluid">
                  <img v-else src="../../assets/images/user/19.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">ID</h6>
                    <h4>{{$route.params.patientId}}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">Gender</h6>
                    <h4 v-if="patient.gender === 2">female</h4>
                    <h4 v-else>male</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">Age</h6>
                    <h4 v-if="patient.age != null">{{patient.age}}</h4>
                    <h4 v-else>No data</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a data-icon="T" class="icon" v-if="display === false"
                    @click="display = !display; $store.commit('setShowLine', true)">
                    <span>more</span>
                  </a>
                  <a data-icon="W" class="icon" v-else
                    @click="display = !display ; $store.commit('setShowLine', false)">
                    <span>hide</span>
                  </a>
                </div>
                <b-collapse id="collapse-0" class="mb-2" v-model="display">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">Admission time</p>
                        <h5 v-if="patient['Admission time'] == undefined">No data</h5>
                        <div v-else>
                          <h5>{{patient['Admission time']}}</h5>
                        </div>
                      </div>
                      <div class="data-block mt-3" v-if="patient['Discharge time'] !== undefined">
                        <p class="mb-0">Discharge time</p>
                        <h5>{{patient['Discharge time']}}</h5>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">Status</p>
                        <h5 v-if="patient.outcome == undefined">No data</h5>
                        <h5 v-else>{{patient.outcome ? 'Dead' : 'Alive'}}</h5>
                      </div>
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- patient card end -->
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Indicators</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>Clear selected</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4 class="card-title text-primary" v-show="patient_data_selector.current_important.length">Important
                Indicators
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.current_important.length">
              <CovidPatientDataSelector :item="patient_data_selector.current_important"
                :info="patient_data_selector.info">
              </CovidPatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.history_important.length">
              <h4 class="card-title text-primary">Historically Important Indicators (pay attention at the same time)
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.history_important.length">
              <CovidPatientDataSelector :item="patient_data_selector.history_important"
                :info="patient_data_selector.info">
              </CovidPatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.other.length">
              <div style="padding-bottom:20px;text-align:center">
                <a data-icon="T" class="icon" v-if="display1 === false" @click="display1 = !display1">
                  <span> more</span>
                </a>
                <a data-icon="W" class="icon" v-else @click="display1 = !display1">
                  <span> hide</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.other.length">
              <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                <h4 class="card-title text-primary">Other Indicators</h4>
                <CovidPatientDataSelector :item="patient_data_selector.other"></CovidPatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <CovidPatientDataChart
          v-if=" analyze !==undefined && (selectedGraph.length>= 1 || analyze.predict !== undefined)" />
      </b-col>
    </b-row>

    <!-- 相似簇内患者的统计信息 -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="bg-primary rounded pt-2 pb-2 pr-2">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0" style="font-size: 22px;margin-left: 10px;color: white;"> Similar patient information (potentially important information recommended for attention)<i
                  class="fa fa-id-card" style="margin-left: 5px;"></i>
              </h4>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <iq-card class-name='iq-card-block iq-card-stretch iq-card-height'>
          <template v-slot:headerTitle>
            <h4 class="card-title" style="display: inline">Top6 Similar patient list</h4>
            <Tooltip placement="top">
              <img src="../../assets/images/question.png" alt="guide" class="guide" />
              <span slot="content" style="white-space: normal">
                According to the condition, a list of patients most similar to the current patient selected from the database, sorted according to the similarity index from largest to smallest, and select the 6 most similar patients
              </span>
            </Tooltip>
          </template>
          <template v-slot:body>
            <div class="table-responsive" style="margin-left: 5px;">
              <table class="table mb-0 table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Age</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Admission Diagnose</th>
                    <th scope="col">Admission Time</th>
                    <th scope="col">Discharge Time</th>
                    <th scope="col">Discharge Destination</th>
                    <th scope="col">ICU Date In</th>
                    <th scope="col">ICU Date Out</th>
                    <th scope="col">ICU Days</th>
                    <th scope="col">Similar Index
                      <Tooltip placement="top">
                        <img src="../../assets/images/question.png" alt="guide" class="guide"
                          style="height:20px; margin-top: 5px;" />
                        <span slot="content" style="white-space: normal; font-weight: normal">
                          Similar Index
                        </span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(similarPatient, index) in similarPatients" :key="similarPatient.pdid"
                    :class="{'link-item-active': similarPatient.id == activeLinkId,'link-item-hover':similarPatient.pdid==hoverIndex}"
                    @click="toPatientPage(similarPatient)" @mouseover="hoverIndex = similarPatient.pdid"
                    @mouseout="hoverIndex = -1">
                    <td>{{similarPatient.age}}</td>
                    <td>{{similarPatient.gender === 1 ? 'Male': 'Female'}}</td>
                    <td>{{similarPatient.admissionDiagnose}}</td>
                    <td>{{similarPatient.admissionTime}}</td>
                    <td>{{similarPatient.dischargeTime}}</td>
                    <td>{{similarPatient.dischargeDestination}}</td>
                    <td>{{similarPatient.inICU}}</td>
                    <td>{{similarPatient.outICU}}</td>
                    <td>{{similarPatient.daysICU}}</td>
                    <!-- <td>
                      {{(similarPatient.diabetes === 1 ? '糖尿病 ' : '') +
                      (similarPatient.heart_failure === 1 ? '心脏衰竭 ' : '') +
                      (similarPatient.lung_infect === 1 ? '肺部感染 ' : '') +
                      (similarPatient.chd === 1 ? '冠心病 ' : '') +
                      (similarPatient.mi === 1 ? '心梗 ' : '') +
                      (similarPatient.ci === 1 ? '脑梗 ' : '') +
                      (similarPatient.ch === 1 ? '脑出血 ' : '') +
                      (similarPatient.amputation === 1 ? '截肢 ' : '') +
                      (similarPatient.amputation === 1 || similarPatient.ch === 1 || similarPatient.ci === 1 || similarPatient.mi === 1 || similarPatient.chd === 1 || similarPatient.lung_infect === 1 || similarPatient.heart_failure === 1 || similarPatient.diabetes === 1 ? '' : '无')
                    }}
                    </td> -->
                    <td>{{(100 - analyze.top_similarIdx[index]*100).toFixed(2)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch" :key="genderRatio">
          <template v-slot:headerTitle>
            <h4 class="card-title">Demographic Information Statistics<span style="font-size: 14px;">(Number of patients :
                <!-- {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['amount']}} -->)
                </span>
            </h4>
          </template>
          <template v-slot:body>
            <div class="iq-details">
              <span class="title text-dark">Percentage of male</span>
              <div class="percentage float-right text-primary">
                <!-- {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['maleRatio']).toFixed(2)}}<span>%</span> -->
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">Percentage of female</span>
              <div class="percentage float-right text-primary">
                <!-- {{analyze['cluster_info'] === undefined ? '':(100 * analyze['cluster_info']['femaleRatio']).toFixed(2)}}<span>%</span> -->
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">Average admission age</span>
              <div class="percentage float-right text-warning">
                <!-- {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['age'].toFixed(2)}}<span></span> -->
              </div>
            </div>
            <div class="iq-details mt-4">
              <span class="title text-dark">Average death age</span>
              <div class="percentage float-right text-success">
                <!-- {{analyze['cluster_info'] === undefined ? '':analyze['cluster_info']['deathAge'].toFixed(2)}}<span></span> -->
              </div>
            </div>
            <div style="margin-bottom: 100px;"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h4 class="card-title">Statistics of important physiological indicators<span style="font-size: 14px;">(Potential importance)</span></h4>
          </template>
          <template v-slot:body>
            <ul class="patient-progress m-0 p-0">
              <div>
                <h6 style="float: left; font-weight: bold;">Physiological feature</h6>
                <h6 style="float: right; margin-right: 20px; font-weight: bold;">Importance index
                  <Tooltip placement="top">
                    <img src="../../assets/images/question.png" alt="guide" class="guide" style="margin-top: 5px;" />
                    <span slot="content" style="white-space: normal; font-weight: normal">
                      Indicates the importance of a physiological index of similar patients. The larger the value, the more important the index (range: 0-100)
                    </span>
                  </Tooltip>
                </h6>
              </div>
              <div style="margin-bottom: -5px; clear: both;"></div>
              <div class="table-responsive mt-4">
                <ul class="doctors-lists m-0 p-0">
                  <div v-for="attn in similarPatientsAttn" :key="attn[0]" style="clear: both;">
                    <li class="d-flex mb-3 align-items-center">
                      <div class="media-support-info">
                        <h6><span :style="{color: ch.get(attn[0]).midColor}"
                            style="font-weight: bold;">{{medicalCharChinese[attn[0]]}}</span>
                        </h6>
                      </div>
                      <span class="badge badge-primary"
                        style="margin-right: 20px;">{{(attn[1] * 100).toFixed(2)}}</span>
                    </li>
                  </div>
                </ul>
              </div>
            </ul>
            <div style="margin-bottom: 50px;"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import IqCard from '../../components/xray/cards/iq-card'
  import { xray } from '@/config/pluginInit'
  import SettingsHelper from '../../util/SettingsHelper'
  import APIHelper from '@/util/APIHelper'
  import { medicalCharCovid } from '@/util/info'
  import CovidPatientDataChart from '../Charts/CovidPatientDataChart'
  import CovidPatientDataSelector from '../Charts/CovidPatientDataSelector'
  import ColorHelper from '../../util/ColorHelper'

  const body = document.getElementsByTagName('body')
  export default {
    name: 'DashboardCovid',
    components: {
      IqCard,
      CovidPatientDataChart: CovidPatientDataChart,
      CovidPatientDataSelector
    },
    inject: ['reload'],
    data() {
      return {
        bg_color: [
          'bg-warning',
          'bg-primary',
          'bg-info',
          'bg-danger',
          'bg-success',
          'bg-secondary'
        ],
        hoverIndex: -1, // 菜单hover索引
        activeLinkId: 0, // 当前激活的菜单id
        message: '默认值',
        genderRatio: 0,
        display: false,
        display1: false,
        dialog: false,
        top2: undefined,
        urlAnalyzeApi: undefined,
        settings: undefined,
        getAnalyzeDataTimer: undefined,
        getAdviceTimer: undefined,
        similarPatientsAttn: [],
        deathReason: [],
        medicalCharCovid: medicalCharCovid,
        patient_data_selector: {
          current_important: [],
          history_important: [],
          other: [],
          item: [],
          part: [0.2, 0.5, 1],
          info: {}
        },
        analyzeAdvice: undefined,
        originDiseaseChart: {
          type: 'radial-pie',
          height: 280,
          data: []
        },
        clusterInfoKey: 0
      }
    },
    created() {
      if (!this.$route.params.patientId) {
        this.$router.replace('/215')
      }
      this.urlAnalyzeApi = (new URL(window.location.href)).searchParams.get('api')
      if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
      this.settings = SettingsHelper.load()
      this.$store.commit('setComputingAdvice', { pending: true })
      Promise.all([APIHelper.getCovidPatientById(this.$route.params.patientId), APIHelper.getCovidLabById(this.$route.params.patientId), APIHelper.getCovidTimeById(this.$route.params.patientId)])
        .then(([patient, lab, time]) => {
          let groundTruthT12 = time.map(value => this.convertGroundTruth(value.label))
          this.$store.commit('setCovidGroundTruthT12', groundTruthT12)
          this.$store.commit('setCovidTime', time)
          this.$store.commit('setCovidPatient', patient)
          this.$store.commit('setCovidLab', lab)
          let covidLab = lab
          let fixlab = []
          let tmp = {}
          var i
          for (i in covidLab) {
            let tmplab = {}
            for (let idx in covidLab[i]) {
              if (covidLab[i][idx] !== 0 || !tmp.hasOwnProperty(idx)) {
                if (covidLab[i][idx] !== 0 && tmp[idx] === 0) {
                  for (let j = 0; j < i; j++) {
                    fixlab[j][idx] = covidLab[i][idx]
                  }
                }
                tmp[idx] = covidLab[i][idx]
              }
              tmplab[idx] = tmp[idx]
            }
            fixlab.push(tmplab)
          }
          this.$store.commit('setCovidFixedLab', fixlab)
        })
      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }
    },
    mounted() {
      xray.index()
      body[0].classList.add('sidebar-main-menu')
    },
    destroyed() {
      body[0].classList.remove('sidebar-main-menu')
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.covidAnalyze
      },
      covidGroundTruthT12() {
        return this.$store.state.covidGroundTruthT12
      },
      lab() {
        return this.$store.state.covidLab
      },
      patient() {
        return this.$store.state.covidPatient
      },
      analyzeData() {
        const { lab, patient } = this
        return {
          lab, patient
        }
      },
      ch() {
        return this.$store.state.ch
      },
      showLine() {
        return this.$store.state.showLine
      },
      similarPatients() {
        return this.$store.state.similarPatients
      }
    },
    watch: {
      analyzeData() {
        this.getAnalyzeData()
      },
      '$route'(to, from) { // 监听路由是否变化
        if (to.name === 'dashboard.home-covid') { // 跳转到哪个页面
          this.reload()
        }
      }
    },
    methods: {
      convertGroundTruth(value) {
        let truth = value
        if (truth <= 35) return 'Discharged in '.concat(Math.ceil(truth)).concat(truth <= 1 ? ' day' : ' days')
        else {
          truth = Math.ceil(70 - truth)
          return 'Died in '.concat(truth).concat(truth <= 1 ? ' day' : ' days')
        }
      },
      enter: function () {
        this.color = 'blue'
      },
      leave: function () {
        this.color = 'red'
      },
      getAnalyzeData() {
        clearTimeout(this.getAnalyzeDataTimer)
        this.getAnalyzeDataTimer = setTimeout(() => this.lab && APIHelper.getCovidAnalyze(
          this.lab
        ).then(result => {
          result.fixedPredict = result.predict.map((value, i) => this.patient.outcome ? 70 - value : value)
          this.$store.commit('setCovidAnalyze', result)
          if (this.top2 === undefined) {
            this.$store.commit('setOriginalAnalyze', result)
            this.getRank()
          }
        }), 500)
      },
      change(a) {
        const b = parseFloat(a)
        return b.toFixed(1)
      },
      getRank() {
        if (this.analyze.attention) {
          const lastAttention = this.analyze.attention.slice(-1)[0]
          this.patient_data_selector.item = Object.keys(lastAttention)
          let arr = []
          for (let [key, value] of Object.entries(lastAttention)) {
            if (value >= 0.22) {
              arr.push(key)
            }
          }
          let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
            return b[1] - a[1]
          })
          this.top2 = sortedLastAttention.slice(0, 5).map(e => e[0])
          let obj = {}
          let top1 = []
          let arr1 = []
          const length = this.analyze.attention.length
          this.analyze.attention.forEach((item, i) => {
            let max = 0
            Object.entries(item).forEach(([key, value]) => {
              if (value > max) {
                max = value
                top1[i] = key
              }
            })
          })
          Object.entries(obj).forEach(item => {
            if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
              arr1.push(item[0])
            }
          })
          this.patient_data_selector.current_important = arr
          this.patient_data_selector.history_important = arr1.filter(item => arr.indexOf(item) === -1)
          this.patient_data_selector.other = this.patient_data_selector.item.filter(
            item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
          )
          if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length >= 5) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.current_important[1],
              this.patient_data_selector.current_important[2],
              this.patient_data_selector.current_important[3],
              this.patient_data_selector.current_important[4]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1 &&
            this.patient_data_selector.history_important && this.patient_data_selector.history_important.length >= 1) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.history_important[0]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1) {
            this.$store.commit('setSelectedGraph', [this.patient_data_selector.current_important[0]])
          }
          this.$store.commit('setSelectedDate', [])
        }
      },
      toPatientPage(a) {
        this.$router.push('/' + a.pdid)
      }
    }
  }
</script>

<style scoped>
  .link-item-hover {
    background-color: #f5f7fa;
  }

  .link-item-active {
    color: #fff;
    background-color: #b3cae4;
  }

  .guide {
    width: 16px;
    padding-left: 2px;
    padding-bottom: 5px;
    cursor: pointer;
  }
</style>
