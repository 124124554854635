<template>
  <div class="pre-wrapper">
    <b-container fluid>
      <el-row :gutter="25">
        <el-col :span="9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Upload file</h4>
            </template>
            <template v-slot:body>
              <div class="up">
                <el-button
                  icon="el-icon-download"
                  type="primary"
                  plain
                  style="margin-bottom:15px"
                  @click="download"
                >Download sample file</el-button>
                <el-upload
                  class="upload-demo"
                  drag
                  action="#"
                  accept=".csv"
                  :on-change="handleChange"
                  :auto-upload="false"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                  <div
                    class="el-upload__tip"
                    slot="tip"
                  >only csv file
                    <p class="state">Privacy Statement : The data you uploaded will not be stored for any reason.</p>
                  </div>
                </el-upload>

                <el-button
                  type="primary"
                  @click="submitUpload"
                >Show analysis of AI </el-button>
              </div>
            </template>
          </iq-card>

          <AIObservation
            v-if="adviceOnRiskThreshold"
            :analyzeAdvice="analyzeAdvice"
            :adviceOnRiskThreshold="adviceOnRiskThreshold"
            :adviceOnRisk="adviceOnRisk"
          />

        </el-col>
        <el-col :span="14">

          <iq-card v-if="rePatientChart">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('main.general.indicators') }}</h4>
            </template>
            <template v-slot:headerAction>
              <div style="padding-right: 20px">
                <a @click="$store.commit('setSelectedGraph', [])">
                  <span>{{ $t('main.general.clearSelected') }}</span>
                </a>
              </div>
            </template>
            <template v-slot:body>
              <div class="iq-header-title">
                <h4
                  class="card-title text-primary"
                  v-show="patient_data_selector.current_important.length"
                >
                  {{ $t('main.general.importantIndicators') }}
                </h4>
              </div>
              <div
                class="iq-card-body pl-0 pr-0 pb-0"
                v-show="patient_data_selector.current_important.length"
              >
                <PatientDataSelector
                  :item="patient_data_selector.current_important"
                  :info="patient_data_selector.info"
                  :langType="getLocale()"
                >
                </PatientDataSelector>
              </div>
              <div
                class="iq-header-title"
                v-show="patient_data_selector.history_important.length"
              >
                <h4 class="card-title text-primary">
                  {{ $t('main.general.historicallyImportantIndicators') }}
                </h4>
              </div>
              <div
                class="iq-card-body pl-0 pr-0 pb-0"
                v-show="patient_data_selector.history_important.length"
              >
                <PatientDataSelector
                  :item="patient_data_selector.history_important"
                  :info="patient_data_selector.info"
                  :langType="getLocale()"
                >
                </PatientDataSelector>
              </div>
              <div
                class="iq-header-title"
                v-show="patient_data_selector.other.length"
              >
                <div style="padding-bottom:20px;text-align:center">
                  <a
                    data-icon="T"
                    class="icon"
                    v-if="display1 === false"
                    @click="display1 = !display1"
                  >
                    <span>{{ $t('main.general.more') }}</span>
                  </a>
                  <a
                    data-icon="W"
                    class="icon"
                    v-else
                    @click="display1 = !display1"
                  >
                    <span>{{ $t('main.general.hide') }}</span>
                  </a>
                </div>
                <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
              </div>
              <div
                class="iq-card-body pl-0 pr-0 pb-0"
                v-show="patient_data_selector.other.length"
              >
                <b-collapse
                  id="collapse-1"
                  class="mb-2"
                  v-model="display1"
                >
                  <h4 class="card-title text-primary">
                    {{ $t('main.general.otherIndicators') }}
                  </h4>
                  <PatientDataSelector
                    :item="patient_data_selector.other"
                    :langType="getLocale()"
                  ></PatientDataSelector>
                </b-collapse>
              </div>
            </template>
          </iq-card>
          <PatientDataChart v-if=" rePatientChart &&
              analyze !== undefined &&
              (selectedGraph.length >= 1 || analyze.predict !== undefined) " />
        </el-col>
      </el-row>
    </b-container>
  </div>
</template>
<script>
import Papa from 'papaparse'
import jschardet from 'jschardet'
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import APIHelper, { getAnalyzeAdvice } from '@/util/APIHelper'
import AIObservation from '../../components/Advice/AIObservation'
import ColorHelper from '../../util/ColorHelper'
import PatientDataChart from '../Charts/PatientDataChart'
import PatientDataSelector from '../Charts/PatientDataSelector'

export default {
  name: 'Prediction',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    IqCard,
    AIObservation,
    PatientDataChart: PatientDataChart,
    PatientDataSelector
  },
  mounted() {
    xray.index()
    this.$i18n.locale = 'en'
  },
  created() {
  },
  data() {
    return {
      analyzeAdvice: undefined,
      file: null,
      top2: undefined,
      rePatientChart: false,
      adviceOnRiskThreshold: undefined,
      adviceOnRisk: undefined,
      urlAnalyzeApi: undefined,
      lab: undefined,
      patient: undefined,
      display1: false,
      patient_data_selector: {
        current_important: [],
        history_important: [],
        other: [],
        item: [],
        part: [0.2, 0.5, 1],
        info: {}
      },
      selector_item: ['cl', 'co2', 'wbc', 'hgb', 'urea', 'ca', 'k', 'na', 'cre', 'p', 'alb', 'crp', 'glu', 'weight', 'sys', 'dia']
    }
  },
  computed: {
    modifiedLab() {
      return this.$store.state.modifiedLab
    },
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    analyze() {
      return this.$store.state.analyze
    }
  },
  methods: {
    download() {
      let downloadElement = document.createElement('a')
      downloadElement.href = '/prediction_example.csv'
      downloadElement.download = 'prediction_example.csv'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
    },
    submitUpload() {
      let _self = this
      const fileReader = new FileReader()
      if (!this.file) {
        this.$message({
          showClose: true,
          message: 'Please upload your file',
          type: 'error',
          offset: 120
        })
        return
      }
      let file = this.file.raw
      fileReader.readAsDataURL(file)
      fileReader.onload = function (evt) {
        let str = atob(evt.target.result.split(';base64,')[1])
        // 要用二进制格式
        let encoding = jschardet.detect(str)
        encoding = encoding.encoding
        if (encoding === 'windows-1252') {
          // 有时会识别错误（如UTF8的中文二字）
          encoding = 'ANSI'
        }

        // 将csv转换成二维数组
        Papa.parse(file, {
          encoding,
          complete: res => {
            // UTF8 \r\n与\n混用时有可能会出问题
            let data = res.data
            if (JSON.stringify(data[data.length - 1]) === '[""]') {
              // 去除最后的空行
              data.pop()
            }
            let array = data
            var keys = array.shift()
            var objects = array.map(function (values) {
              return keys.reduce(function (o, k, i) {
                o[k] = values[i]
                return o
              }, {})
            })
            objects.forEach(function (item) {
              item.amount = 3435.23
            })
            _self.lab = objects
            _self.getAnalyzeData()
          }
        })
      }
    },

    handleChange(file, fileList) {
      this.file = file
      if (fileList.length > 1) {
        fileList.shift()
      }
    },

    handleRemove(file, fileList) {
      this.file = null
    },

    getAnalyzeData() {
      this.patient = {
        'pdid': '1111111111abcd'
      }
      this.$store.commit('setModifiedLab', this.lab)
      this.$store.commit('setLab', this.lab)
      APIHelper.getAnalyze(
        {
          patient: this.patient,
          lab: this.lab
        },
        null

      ).then(res => {
        this.$store.commit('setAnalyze', res)
        this.$store.commit('setAnalyzeRisk', res)
        this.analyzeAdvice = getAnalyzeAdvice(
          this.lab,
          this.analyze,
          this.selector_item
        )
        const lastAttention = this.analyze.attention.slice(-1)[0]
        this.patient_data_selector.item = Object.keys(lastAttention)
        this.patient_data_selector.item.splice(13, 1)
        let arr = []
        for (let [key, value] of Object.entries(lastAttention)) {
          if (value >= 0.22) {
            arr.push(key)
          }
        }
        this.$store.commit('setOriginalAnalyze', res)
        let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
          return b[1] - a[1]
        })
        this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
        this.top2.forEach(o => {
          if (arr.indexOf(o) === -1) {
            arr.push(o)
          }
        })
        this.$store.commit('setTop2', this.top2)
        let obj = {}
        let top1 = []
        let arr1 = []
        const length = this.analyze.attention.length
        this.analyze.attention.forEach((item, i) => {
          let max = 0
          Object.entries(item).forEach(([key, value]) => {
            if (value > max) {
              max = value
              top1[i] = key
            }
          })
          // let k = 0
          Object.getOwnPropertyNames(item).forEach(key => {
            obj[key] ? (obj[key] += item[key]) : (obj[key] = item[key])
            if (item[key] >= 0.5 || key === top1[i]) {
              if (arr1.indexOf(key) === -1) {
                arr1.push(key)
                if (this.getLocale()) {
                  this.patient_data_selector.info[key] =
                    '<b>AI强烈关注此项指标时间</b>'
                } else {
                  this.patient_data_selector.info[key] =
                    '<b>This indicator is focused by AI</b>'
                }
              }
              if (i === 0 || key !== top1[i - 1]) {
                this.patient_data_selector.info[key] +=
                  '<br/>' + this.modifiedLab[i].date
                // k = i
              }
            } else {
              if (i >= 1 && key === top1[i - 1]) {
                if (i >= 2 && key === top1[i - 2]) {
                  this.patient_data_selector.info[key] +=
                    '~' + this.modifiedLab[i - 1].date
                }
              }
            }
          })
        })
        if (length >= 2) {
          this.patient_data_selector.item.forEach(key => {
            if (key === top1[length - 1]) {
              if (key === top1[length - 2]) {
                this.patient_data_selector.info[key] +=
                  '~' + this.modifiedLab[length - 1].date
              }
            }
          })
        }
        Object.entries(obj).forEach(item => {
          if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
            arr1.push(item[0])
          }
        })
        this.patient_data_selector.current_important = arr
        this.patient_data_selector.history_important = arr1.filter(
          item => arr.indexOf(item) === -1
        )
        this.patient_data_selector.other = this.patient_data_selector.item.filter(
          item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
        )
        if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length >= 2
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.current_important[1]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1 &&
          this.patient_data_selector.history_important &&
          this.patient_data_selector.history_important.length >= 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0],
            this.patient_data_selector.history_important[0]
          ])
        } else if (
          this.patient_data_selector.current_important &&
          this.patient_data_selector.current_important.length === 1
        ) {
          this.$store.commit('setSelectedGraph', [
            this.patient_data_selector.current_important[0]
          ])
        }
        this.$store.commit('setSelectedDate', [])
      })
      Promise.all([
        APIHelper.getAdviceOnRisk(
          {
            patient: this.patient,
            lab: this.lab
          },
          40
        ),
        APIHelper.getAdviceOnRisk(
          {
            patient: this.patient,
            lab: this.lab
          },
          0
        )
      ]).then(([adviceOnRiskThreshold, adviceOnRisk]) => {
        this.adviceOnRiskThreshold = adviceOnRiskThreshold
        this.adviceOnRisk = adviceOnRisk
        this.$store.commit('setAdviceOnRiskThreshold', adviceOnRiskThreshold)
        this.$store.commit('setAdviceOnRisk', adviceOnRisk)
        this.$store.commit('setComputingAdvice', { pending: false })
      })

      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }

      this.rePatientChart = true
    },
    getLocale() {
      return this.$i18n.locale === 'chi'
    }
  }
}
</script>

<style scoped>
.up {
  width: 360px;
  margin: 5px auto;
}
.el-button {
  font-size: 14px !important;
  border-radius: 4px;
  padding: 6px 10px !important;
}

.pre-wrapper {
  margin-top: -80px;
}

.state {
  background: rgb(235, 233, 233);
  padding: 7px;
  border-radius: 4px;
}
</style>
