<template>
  <div>
    <div v-if="getGraphSelf === true">
      <span v-for="value in item" :key="value">
        <span v-if="info[value]">
          <Tooltip placement="top">
            <b-button
              pill
              :pressed="selectedGraph.indexOf(value) !== -1"
              :ref="value"
              @click="click($event, value)"
              @mouseenter="mouseEnter($event, value)"
              @mouseleave="mouseLeave($event, value)"
              :style="{
                color: ch.get(value).midColor,
                borderColor: ch.get(value).midColor
              }"
              variant="outline-primary"
              class="mb-3 mr-1"
              id="selector-button"
              style="font-size:20px"
            >
              <!-- <span>123</span> -->
              {{ getValue(value) }}
            </b-button>
            <span slot="content" v-html="info[value]"></span>
          </Tooltip>
        </span>
        <span v-else>
          <b-button
            pill
            :pressed="selectedGraph.indexOf(value) !== -1"
            :ref="value"
            @click="click($event, value)"
            @mouseenter="mouseEnter($event, value)"
            @mouseleave="mouseLeave($event, value)"
            :style="{
              color: ch.get(value).midColor,
              borderColor: ch.get(value).midColor
            }"
            variant="outline-primary"
            class="mb-3 mr-1"
            id="selector-button"
            style="font-size:20px"
          >
            {{ getValue(value) }}
          </b-button>
        </span>
      </span>
    </div>
    <div v-else>
      <span v-for="value in item" :key="value">
        <span>
          <!-- <Tooltip placement="top"> -->
          <b-button
            pill
            :ref="value"
            @click="click($event, value)"
            @mouseenter="mouseEnter($event, value)"
            @mouseleave="mouseLeave($event, value)"
            :style="{
              color: '#ffffff',
              borderColor: ch.get(value).midColor
            }"
            variant="outline-primary"
            class="mb-3 mr-1"
            id="selector-button"
            style="font-size:20px"
          >
            <!-- <span>123</span> -->
            {{ getValue(value) }}
            ({{ lab[lab.length - 1][value] }})
          </b-button>
          <!-- <span
              slot="content"
              v-html="'最后一次测量值：' + lab[lab.length - 1][value]"
            ></span>
          </Tooltip> -->
        </span>
      </span>
      {{ lab[lab.length - 1][value] }}
    </div>
  </div>
</template>

<script>
import { medicalCharChinese, medicalChar } from '@/util/info'
import { getTime } from '@/util/APIHelper'

export default {
  name: 'patient-data-selector',
  props: {
    item: Array,
    langType: Boolean,
    info: {
      type: Object,
      default: () => ({})
    },
    getGraphSelf: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: [
        'outline-primary',
        'outline-secondary',
        'outline-success',
        'outline-danger',
        'outline-warning',
        'outline-info',
        'outline-dark'
      ],
      medicalCharChinese: medicalCharChinese
    }
  },
  mounted() {
    // console.log(this.$refs.co2)
    this.$forceUpdate()
  },
  computed: {
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    analyze() {
      return this.$store.state.analyze
    },
    lab() {
      return this.$store.state.lab
    },
    ch() {
      return this.$store.state.ch
    }
  },
  updated() {
    this.item.forEach(key => {
      if (this.$refs[key]) {
        if (this.selectedGraph.indexOf(key) !== -1) {
          this.$refs[key][0].style.color = '#ffffff'
          this.$refs[key][0].style.background = this.ch.get(key).midColor
        } else {
          this.$refs[key][0].style.color = this.ch.get(key).midColor
          this.$refs[key][0].style.background = '#ffffff'
        }
      }
    })
  },
  methods: {
    mouseEnter(e, value) {
      if (this.selectedGraph.indexOf(value) === -1) {
        e.target.style.color = '#ffffff'
        e.target.style.background = this.ch.get(value).midColor
      }
    },
    mouseLeave(e, value) {
      if (this.selectedGraph.indexOf(value) === -1) {
        e.target.style.color = this.ch.get(value).midColor
        e.target.style.background = '#ffffff'
      }
    },
    // getTime() {
    //   var myDate = new Date()
    //   var myYear = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
    //   var myMonth = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
    //   var myToday = myDate.getDate() // 获取当前日(1-31)
    //   // var myDay = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
    //   var myHour = myDate.getHours() // 获取当前小时数(0-23)
    //   var myMinute = myDate.getMinutes() // 获取当前分钟数(0-59)
    //   var mySecond = myDate.getSeconds() // 获取当前秒数(0-59)
    //   // var week = [
    //   //   '星期日',
    //   //   '星期一',
    //   //   '星期二',
    //   //   '星期三',
    //   //   '星期四',
    //   //   '星期五',
    //   //   '星期六'
    //   // ]
    //   let nowTime

    //   nowTime =
    //     myYear +
    //     '-' +
    //     myMonth +
    //     '-' +
    //     myToday +
    //     ' ' +
    //     myHour +
    //     ':' +
    //     myMinute +
    //     ':' +
    //     mySecond
    //   // console.log(nowTime);
    //   return nowTime
    // },
    click(e, value) {
      if (this.selectedGraph.indexOf(value) === -1 && !this.getGraphSelf) {
        let record = {}
        record.index = value
        record.time = getTime()
        record.type = 1
        console.log(record)
        this.$emit('record', JSON.stringify(record))
      }
      // console.log(value)
      if (this.getGraphSelf) {
        if (this.selectedGraph.indexOf(value) === -1) {
          // console.log(value)
          e.target.style.color = '#ffffff'
          e.target.style.background = this.ch.get(value).midColor
          this.$store.commit('setSelectedGraph', [...this.selectedGraph, value])
        } else {
          e.target.style.color = this.ch.get(value).midColor
          e.target.style.background = '#ffffff'
          this.$store.commit(
            'setSelectedGraph',
            this.selectedGraph.filter(a => a !== value)
          )
        }
      } else {
        if (this.selectedGraph.indexOf(value) !== -1) {
          // console.log(value)
          e.target.style.color = this.ch.get(value).midColor
          e.target.style.background = '#ffffff'
          this.$store.commit('setSelectedGraph', [])
        } else {
          if (this.selectedGraph.length === 0) {
            e.target.style.color = '#ffffff'
            e.target.style.background = this.ch.get(value).midColor
          } else {
            this.$refs[this.selectedGraph[0]][0].style.color = this.ch.get(
              this.selectedGraph[0]
            ).midColor
            this.$refs[this.selectedGraph[0]][0].style.background = '#ffffff'
            e.target.style.color = '#ffffff'
            e.target.style.background = this.ch.get(value).midColor
            // console.log(this.$refs[this.selectedGraph[0]][0].style.background)
            // console.log(this.selectedGraph)
          }
          // console.log(this.$refs[value][0].style.color)
          this.$store.commit('setSelectedGraph', [value])
        }
      }
    },
    getValue(value) {
      if (this.langType) {
        return medicalCharChinese[value] !== undefined
          ? medicalCharChinese[value]
          : value
      } else {
        return medicalChar[value] !== undefined ? medicalChar[value] : value
      }
    },
    getInfo() {
      return this.langType
        ? '<b>AI强烈关注此项指标时间</b>'
        : '<b>This indicator is focused by AI</b>'
    }
  }
}
</script>

<style scoped lang="less">
#selector-button {
  color: #089bab;
  border-color: #089bab;

  &:hover {
    color: #ffffff;
    background: #089bab;
    border-color: #089bab;
  }

  &:active {
    color: #ffffff;
    background: #089bab;
    border-color: #089bab;
  }
}
</style>
