<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="5">
        <!-- patient card start -->
        <b-col>
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img v-if="patient.gender !== null && patient.gender === 0" src="../../../assets/images/user/11.png" alt="profile-img"
                       class="avatar-130 img-fluid">
                  <img v-else src="../../../assets/images/user/19.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <div class="text-center mt-3">
                  <!--              <h3><b>编号：{{patient.pdid}}</b></h3>-->
                  <h6 class="text-primary">{{$t('main.DashboardCkd.patientCard.originDisease')}}</h6>
                  <h4><b>{{getLocale() ? patient.originDisease : originMedicalChartEn[patient.originDisease]}}</b></h4>
                </div>
                <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.DashboardCkd.patientCard.id')}}</h6>
                    <h4 v-if="$route.params.patientId.charAt(0) == 'a'">{{$route.params.patientId}}</h4>
                    <h4 v-else> - </h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.DashboardCkd.patientCard.gender')}}</h6>
                    <h4 v-if="patient.gender !== null && patient.gender === 0">{{$t('main.general.female')}}</h4>
                    <h4 v-else>{{$t('main.general.male')}}</h4>
                  </li>
                  <li class="text-center">
                    <h6 class="text-primary">{{$t('main.DashboardCkd.patientCard.birth')}}</h6>
                    <h4 v-if="patient.birthDate != null">{{patient.birthDate.substring(0,4)}}</h4>
                    <h4 v-else>{{$t('main.general.noData')}}</h4>
                  </li>
                </ul>
                <br />
                <div style="padding-bottom:20px;text-align:center">
                  <a data-icon="T" class="icon" v-if="display === false"
                     @click="display = !display; $store.commit('setShowLine', true)">
                    <span>{{$t('main.general.more')}}</span>
                  </a>
                  <a data-icon="W" class="icon" v-else
                     @click="display = !display ; $store.commit('setShowLine', false)">
                    <span>{{$t('main.general.hide')}}</span>
                  </a>
                </div>
                <b-collapse id="collapse-0" class="mb-2" v-model="display">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-col>
                      <div class="data-block">
                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.height')}}</p>
                        <h5 v-if="patient.height == undefined">{{$t('main.general.noData')}}</h5>
                        <div v-else>
                          <h5>{{change(patient.height)}}&nbsp;<span>cm</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.weight')}}</p>
                        <h5 v-if="patient.weight == undefined">{{$t('main.general.noData')}}</h5>
                        <div v-else>
                          <h5>{{change(patient.weight)}}&nbsp;<span>kg</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3">
                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.admissionAge')}}</p>
                        <h5 v-if="patient.age == undefined">{{$t('main.general.noData')}}</h5>
                        <div v-else>
                          <h5>{{patient.age}}&nbsp;<span>{{$t('main.general.yearsOld')}}</span></h5>
                        </div>
                      </div>
                      <div class="data-block mt-3" v-if="patient.death == 1">
                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.deadTime')}}</p>
                        <h5>{{patient.deathDate.substring(0,7)}}</h5>
                      </div>
                      <div class="data-block mt-3" v-else>
                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.status')}}</p>
                        <h5>{{$t('main.DashboardCkd.patientCard.cure')}}</h5>
                      </div>
<!--                      <div class="data-block mt-3"-->
<!--                           v-show="patient.amputation === 1 || patient.ch === 1 || patient.ci === 1 || patient.mi === 1 || patient.chd === 1 || patient.lung_infect === 1 || patient.heart_failure === 1 || patient.diabetes === 1">-->
<!--                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.basicDisease')}}</p>-->
<!--                        <h5>{{(patient.diabetes === 1 ? '糖尿病 ' : '') +-->
<!--                        (patient.heart_failure === 1 ? '心脏衰竭 ' : '') +-->
<!--                        (patient.lung_infect === 1 ? '肺部感染 ' : '') +-->
<!--                        (patient.chd === 1 ? '冠心病 ' : '') +-->
<!--                        (patient.mi === 1 ? '心梗 ' : '') +-->
<!--                        (patient.ci === 1 ? '脑梗 ' : '') +-->
<!--                        (patient.ch === 1 ? '脑出血 ' : '') +-->
<!--                        (patient.amputation === 1 ? '截肢 ' : '')}}</h5>-->
<!--                      </div>-->
<!--                      <div class="data-block mt-3" v-show="patient.death == 1">-->
<!--                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.deadCause')}}</p>-->
<!--                        <h5>{{getLocale() ? patient.deathReasonText : deathReasonEn[patient.deathReasonText]}}</h5>-->
<!--                      </div>-->
<!--                      <div class="data-block mt-3" v-show="analyze['cluster_id'] !== undefined">-->
<!--                        <p class="mb-0">{{$t('main.DashboardCkd.patientCard.cluster')}}</p>-->
<!--                        <h5><span>{{$t('main.DashboardCkd.patientCard.clusterNo1')}} </span>{{analyze.cluster_id}} <span> {{$t('main.DashboardCkd.patientCard.clusterNo2')}}</span></h5>-->
<!--                      </div>-->
                    </b-col>
                  </div>
                </b-collapse>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('main.general.indicators')}}</h4>
          </template>
          <template v-slot:headerAction>
            <div style="padding-right: 20px">
              <a @click="$store.commit('setSelectedGraph', [])">
                <span>{{$t('main.general.clearSelected')}}</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-header-title">
              <h4 class="card-title text-primary" v-show="patient_data_selector.current_important.length">{{$t('main.general.importantIndicators')}}
              </h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.current_important.length">
              <PatientDataSelector :item="patient_data_selector.current_important" :info="patient_data_selector.info" :langType="getLocale()">
              </PatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.history_important.length">
              <h4 class="card-title text-primary">{{$t('main.general.historicallyImportantIndicators')}}</h4>
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.history_important.length">
              <PatientDataSelector :item="patient_data_selector.history_important" :info="patient_data_selector.info" :langType="getLocale()">
              </PatientDataSelector>
            </div>
            <div class="iq-header-title" v-show="patient_data_selector.other.length">
              <div style="padding-bottom:20px;text-align:center">
                <a data-icon="T" class="icon" v-if="display1 === false" @click="display1 = !display1">
                  <span>{{$t('main.general.more')}}</span>
                </a>
                <a data-icon="W" class="icon" v-else @click="display1 = !display1">
                  <span>{{$t('main.general.hide')}}</span>
                </a>
              </div>
              <!--              <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
            </div>
            <div class="iq-card-body pl-0 pr-0 pb-0" v-show="patient_data_selector.other.length">
              <b-collapse id="collapse-1" class="mb-2" v-model="display1">
                <h4 class="card-title text-primary">{{$t('main.general.otherIndicators')}}</h4>
                <PatientDataSelector :item="patient_data_selector.other" :langType="getLocale()"></PatientDataSelector>
              </b-collapse>
            </div>
          </template>
        </iq-card>
        <PatientDataChart v-if=" rePatientChart && analyze !==undefined && (selectedGraph.length>= 1 || analyze.predict !== undefined)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="bg-primary rounded pt-2 pb-2 pr-2">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0" style="font-size: 22px;margin-left: 10px;color: white;"> {{$t('main.esrdProtoCare.prototypeInformation')}}<i
                class="fa fa-id-card" style="margin-left: 5px;"></i>
              </h4>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <LineChart :order="prototypeOrder"/>
      </b-col>
      <b-col lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('main.esrdProtoCare.statistics')}}</h4>
          </template>
          <template v-slot:body>
            <div class="table-responsive" style="margin-left: 5px;">
              <table class="table mb-0 table-borderless">
                <thead>
                <tr>
                  <th scope="col" style="padding: 0.25rem; text-align: center">{{$t('main.esrdProtoCare.prototypeId')}}</th>
                  <th scope="col" style="padding: 0.25rem; text-align: center">{{$t('main.esrdProtoCare.peopleNum')}}</th>
                  <th scope="col" style="padding: 0.25rem; text-align: center">{{$t('main.esrdProtoCare.deathRate')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="p in prototypeInfo" :key="p.id">
                  <td style="padding: 0.25rem; text-align: center">{{p.id}}</td>
                  <td style="padding: 0.25rem; text-align: center">{{p.patient_num}}</td>
                  <td style="padding: 0.25rem; text-align: center">{{p.rate.toFixed(2)}}</td>
                </tr>
                </tbody>
              </table>
            </div>
<!--            <div style="margin-bottom: 100px;"></div>-->
          </template>
        </iq-card>
      </b-col>
    </b-row>
<!--    <b-row>-->
<!--      <b-col lg="12">-->
<!--        <Heatmap/>-->
<!--      </b-col>-->
<!--    </b-row>-->
  </b-container>
</template>
<script>
import IqCard from '@/components/xray/cards/iq-card'
import { xray } from '@/config/pluginInit'
import SettingsHelper from '@/util/SettingsHelper'
import APIHelper from '@/util/APIHelper'
import { medicalChar, medicalCharChinese, deathReasonEn, originMedicalChartEn } from '@/util/info'
import EsrdProtoCarePatientDataChart from '@/views/Charts/Esrd/EsrdProtoCarePatientDataChart'
import PatientDataSelector from '@/views/Charts/PatientDataSelector'
import ColorHelper from '@/util/ColorHelper'
// import EsrdProtoCareHeatmap from '@/views/Charts/Esrd/EsrdProtoCareHeatmap'
import EsrdProtoCareLineChart from '@/views/Charts/Esrd/EsrdProtoCareLineChart'

const body = document.getElementsByTagName('body')
  export default {
    name: 'Dashboard4',
    components: {
      IqCard,
      PatientDataChart: EsrdProtoCarePatientDataChart,
      PatientDataSelector,
      // Heatmap: EsrdProtoCareHeatmap,
      LineChart: EsrdProtoCareLineChart
    },
    inject: ['reload'],
    data() {
      return {
        bg_color: [
          'bg-warning',
          'bg-primary',
          'bg-info',
          'bg-danger',
          'bg-success',
          'bg-secondary'
        ],
        rePatientChart: true,
        display: false,
        display1: false,
        top2: undefined,
        urlAnalyzeApi: undefined,
        settings: undefined,
        getAnalyzeDataTimer: undefined,
        medicalCharChinese: medicalCharChinese,
        deathReasonEn,
        originMedicalChartEn,
        medicalChar: medicalChar,
        patient_data_selector: {
          current_important: [],
          history_important: [],
          other: [],
          item: [],
          part: [0.2, 0.5, 1],
          info: {}
        },
        prototypeInfo: [
          // { id: 2, rate: 1.0, patient_num: 18 },
          // { id: 4, rate: 0.888888, patient_num: 18 },
          // { id: 0, rate: 0.783783, patient_num: 74 },
          // { id: 1, rate: 0.385964, patient_num: 399 },
          // { id: 5, rate: 0.225, patient_num: 40 },
          // { id: 7, rate: 0.136363, patient_num: 22 },
          // { id: 3, rate: 0.0, patient_num: 47 },
          // { id: 6, rate: 0.0, patient_num: 44 }
          // { id: 2, rate: 0.807692, patient_num: 52 },
          // { id: 7, rate: 0.674157, patient_num: 89 },
          // { id: 3, rate: 0.563318, patient_num: 229 },
          // { id: 1, rate: 0.209302, patient_num: 43 },
          // { id: 5, rate: 0.2, patient_num: 40 },
          // { id: 4, rate: 0.065934, patient_num: 91 },
          // { id: 6, rate: 0.058823, patient_num: 51 },
          // { id: 0, rate: 0.014923, patient_num: 67 }
          { id: 5, rate: 0.85, patient_num: 20 },
          { id: 3, rate: 0.6, patient_num: 130 },
          { id: 7, rate: 0.52, patient_num: 25 },
          { id: 4, rate: 0.5, patient_num: 84 },
          { id: 6, rate: 0.429655, patient_num: 116 },
          { id: 2, rate: 0.363636, patient_num: 88 },
          { id: 1, rate: 0.126984, patient_num: 126 },
          { id: 0, rate: 0.123287, patient_num: 73 }
        ],
        // prototypeOrder: [5, 4, 7, 1, 6, 3, 0, 2]
        // prototypeOrder: [0, 4, 7, 5, 2, 3, 1, 6]
        prototypeOrder: [0, 1, 2, 6, 4, 7, 3, 5]
      }
    },
    created() {
      if (!this.$route.params.patientId) {
        this.$router.replace('/esrd/protocare/215')
      }
      this.urlAnalyzeApi = (new URL(window.location.href)).searchParams.get('api')
      if (this.urlAnalyzeApi) this.urlAnalyzeApi = this.urlAnalyzeApi.split(',')
      this.settings = SettingsHelper.load()
      this.$store.commit('setComputingAdvice', { pending: true })
      Promise.all([APIHelper.getPatientById(this.$route.params.patientId), APIHelper.getLabById(this.$route.params.patientId), APIHelper.getMockData(this.$route.params.patientId)])
        .then(([patient, lab, events]) => {
          this.$store.commit('setLab', lab)
          this.$store.commit('setModifiedLab', lab)
          this.$store.commit('setPatient', patient)
        })
      if (this.$store.state.ch) {
        this.$store.commit('setCh', new ColorHelper())
      }
    },
    mounted() {
      xray.index()
      body[0].classList.add('sidebar-main-menu')
    },
    destroyed() {
      body[0].classList.remove('sidebar-main-menu')
    },
    computed: {
      selectedGraph() {
        return this.$store.state.selectedGraph
      },
      analyze() {
        return this.$store.state.analyze
      },
      modifiedLab() {
        return this.$store.state.modifiedLab
      },
      lab() {
        return this.$store.state.lab
      },
      patient() {
        return this.$store.state.patient
      },
      similarPatients() {
        return this.$store.state.similarPatients
      },
      analyzeData() {
        const { modifiedLab, patient } = this
        return {
          modifiedLab, patient
        }
      },
      ch() {
        return this.$store.state.ch
      }
    },
    watch: {
      analyzeData() {
        this.getAnalyzeData()
      },
      '$route'(to, from) { // 监听路由是否变化
        if (to.name === 'dashboard.home-4') { // 跳转到哪个页面
          this.reload()
        }
      },
      '$i18n.locale'() {
        this.reloadChild()
      }
    },
    methods: {
      reloadChild() {
        this.rePatientChart = false
        this.$nextTick(() => {
          this.rePatientChart = true
        })
      },
      getAnalyzeData() {
        clearTimeout(this.getAnalyzeDataTimer)
        this.getAnalyzeDataTimer = setTimeout(() => this.patient && this.modifiedLab && APIHelper.getEsrdProtoCareAnalyze({
          patient: this.patient,
          lab: this.modifiedLab
        }).then(result => {
          this.$store.commit('setAnalyze', result)
          if (this.top2 === undefined) {
            this.$store.commit('setOriginalAnalyze', result)
            this.getRank()
          }
        }), 500)
      },
      change(a) {
        const b = parseFloat(a)
        return b.toFixed(1)
      },
      getRank() {
        if (this.analyze.attention) {
          const lastAttention = this.analyze.attention.slice(-1)[0]
          this.patient_data_selector.item = Object.keys(lastAttention)
          let arr = []
          for (let [key, value] of Object.entries(lastAttention)) {
            if (value >= 0.22) {
              arr.push(key)
            }
          }
          let sortedLastAttention = Object.entries(lastAttention).sort((a, b) => {
            return b[1] - a[1]
          })
          this.top2 = sortedLastAttention.slice(0, 2).map(e => e[0])
          this.top2.forEach(o => {
            if (arr.indexOf(o) === -1) {
              arr.push(o)
            }
          })
          this.$store.commit('setTop2', this.top2)
          let obj = {}
          let top1 = []
          let arr1 = []
          const length = this.analyze.attention.length
          this.analyze.attention.forEach((item, i) => {
            let max = 0
            Object.entries(item).forEach(([key, value]) => {
              if (value > max) {
                max = value
                top1[i] = key
              }
            })
            // let k = 0
            Object.getOwnPropertyNames(item).forEach(key => {
              obj[key] ? (obj[key] += item[key]) : (obj[key] = item[key])
              if (key === top1[i]) {
                if (arr1.indexOf(key) === -1) {
                  arr1.push(key)
                  if (this.getLocale()) {
                    this.patient_data_selector.info[key] = '<b>AI强烈关注此项指标时间</b>'
                  } else {
                    this.patient_data_selector.info[key] = '<b>This indicator is focused by AI</b>'
                  }
                }
                if (i === 0 || key !== top1[i - 1]) {
                  this.patient_data_selector.info[key] += '<br/>' + this.modifiedLab[i].date
                  // k = i
                }
              } else {
                if (i >= 1 && key === top1[i - 1]) {
                  if (i >= 2 && key === top1[i - 2]) {
                    this.patient_data_selector.info[key] += '~' + this.modifiedLab[i - 1].date
                    /* if (k - 1 >= 0) {
                      let trend = this.lab[i][key] / this.lab[k - 1][key] - 1
                      if (trend !== 0) {
                        this.patient_data_selector.info[key] +=
                          '（' + (trend > 0 ? '升高了' + trend : '降低了' + (-trend)) + '%）'
                      }
                    } */
                  }
                }
              }
            })
          })
          if (length >= 2) {
            this.patient_data_selector.item.forEach(key => {
              if (key === top1[length - 1]) {
                if (key === top1[length - 2]) {
                  this.patient_data_selector.info[key] += '~' + this.modifiedLab[length - 1].date
                }
              }
            })
          }
          Object.entries(obj).forEach(item => {
            if (item[1] >= 0.18 * length && arr1.indexOf(item[0]) === -1) {
              arr1.push(item[0])
            }
          })
          this.patient_data_selector.current_important = arr
          this.patient_data_selector.history_important = arr1.filter(item => arr.indexOf(item) === -1)
          this.patient_data_selector.other = this.patient_data_selector.item.filter(
            item => arr.indexOf(item) === -1 && arr1.indexOf(item) === -1
          )
          if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length >= 2) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.current_important[1]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1 &&
            this.patient_data_selector.history_important && this.patient_data_selector.history_important.length >= 1) {
            this.$store.commit('setSelectedGraph', [
              this.patient_data_selector.current_important[0],
              this.patient_data_selector.history_important[0]
            ])
          } else if (this.patient_data_selector.current_important && this.patient_data_selector.current_important.length === 1) {
            this.$store.commit('setSelectedGraph', [this.patient_data_selector.current_important[0]])
          }
          this.$store.commit('setSelectedDate', [])
          // this.analyzeAdvice = getAnalyzeAdvice(this.lab, this.analyze, this.patient_data_selector.item)
        }
      },
      toPatientPage(a) {
        this.$router.push('/esrd/protocare/' + a.pdid + '?secret=yuyuyzl')
      },
      getLocale() {
        return this.$i18n.locale === 'chi'
      }
    }
  }
</script>
