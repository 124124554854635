import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index.js'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'chi',
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'chi',
  locale: localStorage.getItem('locale') || 'en',
  messages: loadLocaleMessages()
})

let langOption = {
  'chi': { title: '中文', value: 'chi', image: require('@/assets/images/small/flag-300.png') },
  'en': { title: 'English', value: 'en', image: require('@/assets/images/small/flag-01.png') },
  'fr': { title: 'Français', value: 'fr', image: require('@/assets/images/small/flag-200.png') },
  'sp': { title: 'Español', value: 'sp', image: require('@/assets/images/small/flag-sp.jpg') },
  'jp': { title: '日本語', value: 'jp', image: require('@/assets/images/small/flag-jp.png') }
}
store.commit('Setting/setLangCommit', langOption[localStorage.getItem('locale') || 'en'])
