var render = function render(){var _vm=this,_c=_vm._self._c;return _c('iq-card',{attrs:{"class-name":"iq-card-block iq-card-stretch"},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title",staticStyle:{"display":"inline","font-size":"28px"}},[(!_vm.isQues)?_c('span',[_vm._v(_vm._s(_vm.$t('main.general.riskCurve'))+" "),_c('Tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAll),expression:"isShowAll"}],attrs:{"placement":"top"}},[_c('img',{staticClass:"guide",attrs:{"src":require("../../assets/images/question.png"),"alt":"guide"}}),_c('span',{staticStyle:{"white-space":"normal"},attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.$t('main.general.riskCurve()')))])])],1):_c('span',[(!_vm.isShowAll)?_c('span',[_vm._v(" 时序图 ")]):_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('main.general.riskCurve')))])])])])]},proxy:true},{key:"headerAction",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAll),expression:"isShowAll"}],staticStyle:{"font-size":"20px"}},[(_vm.isShowAll)?_c('a',{staticStyle:{"padding-right":"30px"},on:{"click":function($event){return _vm.setShowRisk(!_vm.showRisk)}}},[_c('span',[_vm._v(_vm._s(_vm.showRisk ? _vm.$t('main.general.hideCurve') : _vm.$t('main.general.displayCurve')))])]):_vm._e(),(_vm.isShowAll)?_c('a',{staticStyle:{"padding-right":"30px"},on:{"click":function($event){return _vm.showAll(!_vm.showValue)}}},[_c('span',[_vm._v(_vm._s(_vm.showValue ? _vm.$t('main.general.hideInd') : _vm.$t('main.general.displayInd')))])]):_vm._e(),_c('a',{on:{"click":_vm.reset}},[_c('span',[_vm._v(_vm._s(_vm.$t('main.general.reset')))])])])]},proxy:true},{key:"body",fn:function(){return [_c('div',{attrs:{"id":"patient-chart","onContextMenu":e => e.preventDefault()}},[_c('div',{style:({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: 'unset'
        }),attrs:{"id":"patientDataChart"},on:{"contextmenu":function($event){$event.preventDefault();}}})]),(_vm.isQues && _vm.isShowAll)?_c('div',{staticStyle:{"font-size":"24px","color":"#A09E9E","width":"900px","margin":"10px auto"}},[_vm._v(" 纵轴额外包括了AI所评估的患者风险指数，以"),_c('span',{staticStyle:{"color":"pink"}},[_vm._v("背景浅粉色的曲线")]),_vm._v("示意，越高则越危险。特征折线图上标记有大小不一的点，"),_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("点越大")]),_c('span',{staticStyle:{"font-size":"24px"}},[_vm._v("◉")]),_vm._v("则表明AI在此刻越关注该特征，"),_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("点很小")]),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("◉")]),_vm._v("表明AI不关注该特征。当您把鼠标悬停在其上时，会有悬浮窗口显示该患者此次就诊的时间、预测风险值、特征重要性权重分配、特征检验检查值。 ")]):_vm._e(),(_vm.isQues && !_vm.isShowAll)?_c('div',{staticStyle:{"font-size":"24px","color":"#A09E9E","width":"900px","margin":"10px auto"}},[_vm._v(" 横轴为一位患者的就诊时间，纵轴为特征检验结果。每一个记录点为一次就诊。当您用鼠标点选不同特征时，该特征的时序折线图就会展示。 ")]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }