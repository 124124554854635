<template>
  <iq-card class-name="iq-card-block iq-card-stretch" body-class="iq-bg-primary rounded">

    <div class="d-flex align-items-center">
      <div class="avatar mr-3">
        <img :src="item.image" :alt="item.image" class="avatar-50 rounded">
        <span class="avatar-status">
          <i class="ri-checkbox-blank-circle-fill" :class="`text-${item.status}`"/>
        </span>
      </div>
      <div class="chat-sidebar-name">
        <h6 class="mb-0">{{ item.name }}</h6>
        <span>{{ item.role }}</span>
      </div>
    </div>
  </iq-card>
</template>
<script>
export default {
  name: 'ChatItem',
  props: {
    item: { type: Object }
  }
}
</script>
