<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('Editable.title')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="add">Add New</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover style="cursor:pointer" :items="rows" :fields="columns" foot-clone :per-page="perPage" :current-page="currentPage" @row-clicked="patientInfo">
                  <template v-slot:cell(gender)="data">
                    <span v-if="!data.item.editable">{{ data.item.gender }}</span>
                    <input type="text" v-model="data.item.gender" v-else class="form-control">
                  </template>
                  <template v-slot:cell(birthDate)="data">
                    <span v-if="!data.item.editable">{{ data.item.birthDate }}</span>
                    <input type="text" v-model="data.item.birthDate" v-else class="form-control">
                  </template>
                  <template v-slot:cell(deathDate)="data">
                    <span v-if="!data.item.editable">{{ data.item.deathDate }}</span>
                    <input type="text" v-model="data.item.deathDate" v-else class="form-control">
                  </template>
                  <template v-slot:cell(originDisease)="data">
                    <span v-if="!data.item.editable">{{ data.item.originDisease }}</span>
                    <input type="text" v-model="data.item.originDisease" v-else class="form-control">
                  </template>
                  <template v-slot:cell(deathReasonText)="data">
                    <span v-if="!data.item.editable">{{ data.item.deathReasonText }}</span>
                    <input type="text" v-model="data.item.deathReasonText" v-else class="form-control">
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row >
      <b-col cols="2">
        <b-form-select v-model="perPage" :options="options" ></b-form-select>
      </b-col>

      <b-col >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        ></b-pagination>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
// eslint-disable-next-line no-unused-vars
import APIHelper from '../../util/APIHelper'
import moment from 'moment'
export default {
  name: 'UiDataTable',
  created() {
    this.getPatients()
  },
  mounted () {
    xray.index()
  },
  methods: {
    pushIntoRows() {
      this.rows = []
      for (let i = 0; i < this.$store.state.patientsBasicDetails.length; i++) {
        let obj = this.default()
        let patient = this.$store.state.patientsBasicDetails[i]
        obj.gender = patient.gender === 0 ? '男' : '女'
        obj.birthDate = moment(patient.birthDate).format('YYYY-MM-DD')
        obj.deathDate = moment(patient.deathDate).format('YYYY-MM-DD')
        obj.originDisease = patient.originDisease
        obj.deathReasonText = patient.deathReasonText
        obj.pdid = patient.pdid
        this.rows.push(obj)
      }
    },
    getPatients() {
      if (this.$store.state.patientsBasicDetails.length === 0) {
        APIHelper.getAllPatientsBasicDetails()
          .then(patients => {
            this.$store.commit('setPatientsBasicDetails', patients)
            this.pushIntoRows()
          })
        return
      }
      this.pushIntoRows()
    },
    patientInfo(item, index, event) {
        this.$router.push('/' + item.pdid)
    },
    add () {
      let obj = this.addDefault()
      APIHelper.addPatientBasicDetails(obj).then(res => {
        // console.log(res)
        this.$store.commit('setPatientsBasicDetails', [])
        this.getPatients()
        // console.log('enddd')
      })
    },
    default () {
      return {
        id: this.rows.length,
        gender: '-',
        birthDate: '-',
        deathDate: '-',
        originDisease: '-',
        deathReason: '-',
        pdid: '-',
        editable: false
      }
    },
    addDefault() {
      return {
        name: '-',
        gender: 0,
        birthDate: '19700101',
        age: 0,
        originDisease: '-',
        diabetes: 0,
        death: 1,
        deathDate: '19700101',
        deathAge: 0,
        deathReason: 0,
        deathReasonText: '-',
        outcome: 0,
        duplicate: 0,
        height: 0,
        weight: 0,
        scr: 0,
        heart_failure: 0,
        lung_infect: 0,
        chd: 0,
        mi: 0,
        ci: 0,
        ch: 0,
        amputation: 0,
        urea: 0,
        albumin: 0,
        ca: 0,
        p: 0,
        pth: 0,
        bmi: 0,
        epi: 0,
        sbp: 0,
        dbp: 0,
        death_number: 0
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      APIHelper.getPatientBasicDetailsById(item.pdid)
        .then(patient => {
          patient.gender = item.gender === '男' ? 0 : 1
          patient.birthDate = moment(item.birthDate).format('YYYYMMDD')
          patient.deathDate = moment(item.deathDate).format('YYYYMMDD')
          patient.originDisease = item.originDisease
          patient.deathReasonText = item.deathReasonText
          APIHelper.updatePatientBasicDetailsById(item.pdid, patient)
      })
      item.editable = false
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
      APIHelper.deletePatientBasicDetailsById(item.pdid).then(res => {
        // console.log(res)
      })
    }
  },
  data () {
    return {
      options: [
        { value: 40, text: '40/页' },
        { value: 5, text: '5/页' },
        { value: 10, text: '10/页' },
        { value: 20, text: '20/页' }
      ],
      columns: [
        { label: this.$t('Editable.Gender'), key: 'gender', class: 'text-left' },
        { label: this.$t('Editable.Birth date'), key: 'birthDate', class: 'text-left' },
        { label: this.$t('Editable.Death date'), key: 'deathDate', class: 'text-left' },
        { label: this.$t('Editable.Origin disease'), key: 'originDisease', class: 'text-left' },
        { label: this.$t('Editable.Death reason'), key: 'deathReasonText', class: 'text-left' },
        { label: this.$t('Editable.Action'), key: 'action', class: 'text-center' }
      ],
      rows: [
      ],
      currentPage: 1,
      perPage: 40
    }
  }
}
</script>
