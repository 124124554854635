export const medicalChar = {
  cl: 'Chlorine',
  co2: 'CO2CP',
  wbc: 'WBC',
  hgb: 'HGB',
  urea: 'Urea',
  ca: 'Calcium',
  k: 'Potassium',
  na: 'Sodium',
  cre: 'SCR',
  p: 'PHOS',
  alb: 'Albumin',
  crp: 'hs-CRP',
  glu: 'Glucose',
  amount: 'Food intake',
  weight: 'Weight',
  sys: 'Systolic pressure',
  dia: 'Diastolic pressure',
  diabetes: 'Diabete',
  heart_failure: 'Heart failure',
  lung_infect: 'Lung infection',
  chd: 'Coronary heart disease',
  mi: 'Myocardial infarction',
  ci: 'Cerebral infarction',
  ch: 'Cerebral hemorrhage',
  amputation: 'Amputation'
}

export const covidType12 = [
  'less than 1 day',
  '1-2 days',
  '2-3 days',
  '4-5 days',
  '6-10 days',
  'more than 10 days',
  'less than 1 day',
  '1-2 days',
  '2-3 days',
  '4-5 days',
  '6-10 days',
  'more than 10 days'
]

export const medicalCharChinese = {
  cl: '血氯',
  co2: '二氧化碳结合力',
  wbc: '白细胞',
  hgb: '血红蛋白',
  urea: '尿素',
  ca: '血钙',
  k: '血钾',
  na: '血钠',
  cre: '肌酐',
  p: '血磷',
  alb: '白蛋白',
  crp: '超敏C-反应蛋白',
  glu: '血糖',
  amount: '食物摄入（含水）',
  weight: '体重',
  sys: '收缩压',
  dia: '舒张压',
  diabetes: '糖尿病',
  heart_failure: '心脏衰竭',
  lung_infect: '肺部感染',
  chd: '冠心病',
  mi: '心梗',
  ci: '脑梗',
  ch: '脑出血',
  amputation: '截肢'
}

export const diseasesChartChi = {
  '001': '其它腹膜炎相关死亡',
  '002': '多脏器功能衰竭',
  '003': '存活',
  '004': '呼吸衰竭',
  '005': '猝死',
  '006': '透析不充分',
  '007': '肺部感染',
  '008': '肿瘤死亡 原因不详',
  '009': '原因不明',
  '010': '其它消化道情况',
  '011': '急性心肌梗死',
  '012': '脑梗塞',
  '013': '其它肿瘤导致死亡',
  '014': '消化道出血死亡',
  '015': '周围血管病',
  '016': '营养不良-恶液质死亡',
  '017': '其它心源性因素',
  '018': '消化道肿瘤',
  '019': '肺癌',
  '020': '心衰',
  '021': '脓毒血症',
  '022': '糖尿病足',
  '023': '自杀',
  '024': '真菌型腹膜炎',
  '025': '肺栓塞',
  '026': '肝癌',
  '027': '肠穿孔',
  '028': '泌尿系肿瘤',
  '029': '家属放弃',
  '030': '脑出血',
  '031': '其它部位感染',
  '032': '夹层动脉瘤'
}

export const diseasesChartEn = {
  '001': 'Other peritonitis-related deaths',
  '002': 'Multiple organ failure',
  '003': 'Survival',
  '004': 'Respiratory failure',
  '005': 'Sudden death',
  '006': 'Inadequate dialysis',
  '007': 'Lung infection',
  '008': 'Tumor death Cause unknown',
  '009': 'Cause unknown',
  '010': 'Other gastrointestinal conditions',
  '011': 'Acute myocardial infarction',
  '012': 'cerebral infarction',
  '013': 'Other tumor causing death',
  '014': 'Death due to gastrointestinal bleeding',
  '015': 'Peripheral vascular disease',
  '016': 'Death from malnutrition-hypochondrium',
  '017': 'Other cardiogenic factors',
  '018': 'Gastrointestinal tumors',
  '019': 'Lung cancer',
  '020': 'heart failure',
  '021': 'sepsis',
  '022': 'Diabetic foot',
  '023': 'Suicide',
  '024': 'fungal peritonitis',
  '025': 'Pulmonary embolism',
  '026': 'Liver cancer',
  '027': 'Intestinal perforation',
  '028': 'Urinary tract tumor',
  '029': 'Family abandonment',
  '030': 'cerebral hemorrhage',
  '031': 'Other site infection',
  '032': 'Clamped aneurysm'
}

export const originMedicalChartEn = {
  慢性肾小球肾炎: 'Chronic glomerulonephritis',
  不明: 'unspecified',
  糖尿病肾病: 'Diabetic Nephropathy',
  高血压肾损害: 'Hypertensive kidney damage',
  慢性肾盂肾炎: 'Chronic pyelonephritis',
  良性动脉硬化: 'Benign arteriosclerosis',
  慢性间质性肾炎: 'Chronic interstitial nephritis',
  常染色体显性多囊肾病: 'Autosomal dominant polycystic kidney disease',
  梗阻性肾病: 'Obstructive nephropathy',
  原发性小血管炎肾损害: 'Primary small vessel vasculitis renal damage',
  高血压肾病: 'Hypertensive nephropathy',
  肾动脉硬化: 'Renal arteriosclerosis',
  缺血性肾病: 'Ischemic nephropathy',
  多发性骨髓瘤肾损害: 'Renal damage in multiple myeloma',
  多囊肾: 'Polycystic kidney',
  肾移植后肾炎: 'Post-transplant nephritis',
  系统性红斑狼疮性肾炎: 'Systemic lupus erythematosus nephritis',
  包虫病: 'Inclusion disease',
  淀粉样变性肾病: 'Amyloidosis nephropathy',
  痛风慢性间质性肾炎: 'Gout chronic interstitial nephritis',
  未知: 'unknown',
  null: 'null',
  其他: 'Other',
  过敏性紫癜性肾炎: 'Allergic purpura nephritis',
  DN: 'DN',
  IGA肾病: 'IGA nephropathy',
  局灶增生性肾小球肾炎伴肾小管间质:
    'Focal proliferative glomerulonephritis with tubulointerstitial',
  肾病综合症伴膜性增殖性IGA肾病:
    'tubulointerstitial Nephrotic syndrome with membranoproliferative IGA nephropathy ',
  肾炎: 'nephritis',
  '高血压肾损害肾小球肾炎?': 'Hypertensive renal damage glomerulonephritis?',
  心肾综合征: 'Cardiorenal syndrome',
  糖尿病肾病心衰: 'Diabetic nephropathy heart failure'
}

export const deathReasonEn = {
  腹膜透析相关性腹膜炎: 'Peritoneal dialysis-associated peritonitis',
  消化道出血心衰: 'Gastrointestinal bleeding heart failure',
  多功能衰竭: 'Multifunctional failure',
  心力衰竭: 'Heart Failure',
  猝死: 'Sudden death',
  脑出血: 'Cerebral hemorrhage',
  NULL: 'NULL',
  肺部感染: 'Lung infection',
  原因不明: 'Unknown cause',
  呼吸系统疾病: 'Respiratory disease',
  心肌梗塞: 'Myocardial infarction',
  患者长期卧床家中猝死:
    'Patient died suddenly at home after prolonged bed rest',
  '肺部感染，腹膜透析相关性腹膜炎':
    'Pulmonary infection, peritoneal dialysis-associated peritonitis',
  '脐部漏液，反复房颤EP:20/ 透析不充分于2015-5-26心脏猝死':
    'Umbilical leakage, recurrent atrial fibrillation EP:20/inadequate dialysis Sudden cardiac death on 2015-5-26',
  放弃治疗: 'Abandonment of treatment',
  消化系统疾病: 'Digestive system disease',
  脑梗塞: 'Cerebral infarction',
  '肝硬变，疝气，透析不充分': 'Hepatic steatosis, hernia, inadequate dialysis',
  肿瘤: 'Tumor',
  透析不充分营养不良: 'Dialysis insufficiency malnutrition',
  泌尿系肿瘤: 'Urological tumors',
  '心肌梗死，脑梗死': 'Myocardial infarction, cerebral infarction',
  其他感染: 'Other infections',
  肿瘤肝ca: 'Tumor liver ca',
  心绞痛心梗: 'Angina pectoris heart attack',
  透析不充分: 'Inadequate dialysis',
  呼吸衰竭: 'Respiratory failure',
  窒息: 'Asphyxia',
  肝癌: 'Liver cancer',
  不详: 'Unknown',
  心梗腹膜透析相关性腹膜炎:
    'Peritoneal dialysis-associated peritonitis in heart attack',
  高血压脑病: 'Hypertensive encephalopathy',
  多脏器衰竭老年痴呆: 'Multi-organ failure Alzheimers disease',
  消化道出血: 'Gastrointestinal bleeding',
  '肺部感染（放弃治疗': 'Pulmonary infection (abandonment of treatment)',
  '猝死（多脏器官衰竭）': 'Sudden death (multiple organ failure)',
  多脏器功能衰竭: 'Multi-organ failure',
  '心梗（在三院急诊室）':
    'Heart attack (in the emergency room of the third hospital)',
  '猝死，消化道出血可能性大':
    'Sudden death with high probability of gastrointestinal bleeding',
  肺癌: 'Lung cancer',
  心功能衰竭: 'Heart failure',
  急性心肌梗死: 'Acute myocardial infarction',
  外周血管疾病: 'Peripheral vascular disease',
  腹膜炎: 'Peritonitis',
  心梗: 'Heart attack',
  多脏器衰竭: 'Multi-organ failure',
  '腹泻？低钾？': 'Diarrhea? Hypokalemia?',
  真菌腹膜炎: 'Fungal peritonitis',
  MODS: 'MODS',
  '骨折、全身衰竭': 'Fracture, systemic failure',
  '腹膜炎、mods': 'peritonitis, mods',
  失访: 'loss of access',
  不明: 'unknown',
  感染: 'infection',
  脉管炎: 'vasculitis',
  糖尿病足: 'diabetic foot',
  跌倒后骨折卧床: 'bedridden with fractures after a fall',
  '猝死13501128099（儿子）': 'Sudden death 13501128099 (son)',
  失血性休克消化道出血胃癌:
    'Hemorrhagic shock Gastrointestinal bleeding Stomach cancer',
  肾移植术后: 'Post kidney transplantation',
  '（多器官衰竭）猝死': '(multi-organ failure) Sudden death',
  长期卧床脑梗猝死营养状况差:
    'Sudden death from long-term bedridden cerebral infarction poor nutritional status',
  多脏器功能衰竭死亡: 'Death from multiple organ failure',
  个人意愿: 'Personal wishes',
  肺炎: 'Pneumonia',
  心律失常: 'Cardiac arrhythmia',
  全身衰竭: 'Total body failure',
  '脑梗塞，急性心肌梗死': 'Cerebral infarction, acute myocardial infarction',
  脑梗肾性脑病: 'Cerebral infarction renal encephalopathy',
  心源性猝死: 'Sudden cardiac death',
  '肺部感染，脑梗塞': 'Pulmonary infection, cerebral infarction',
  '消化系统疾病（消化道出血腹膜炎脑梗死亡）':
    'Digestive system diseases (gastrointestinal bleeding peritonitis cerebral infarction death)',
  感染中毒性休克: 'Infectious toxic shock',
  真菌性腹膜炎: 'Fungal peritonitis',
  '心衰、呼衰': 'Heart failure, respiratory failure',
  脑梗: 'cerebral infarction',
  多器官衰竭: 'Multi-organ failure',
  '超滤衰竭、HD、猝死': 'Ultrafiltration failure, HD, sudden death',
  '透析不充分、猝死': 'Inadequate dialysis, sudden death',
  '难治性腹膜炎、肿瘤': 'refractory peritonitis, tumor',
  自杀: 'Suicide',
  好转拔管: 'Evacuation on improvement',
  '腹膜炎，感染性休克，败血症': 'Peritonitis, infectious shock, sepsis',
  感染性休克: 'Infectious shock',
  脑血管意外: 'cerebrovascular accident',
  肺癌死亡: 'Death from lung cancer',
  自行停止透析: 'Self-discontinuation of dialysis',
  '下肢静脉栓塞，低血压': 'Lower extremity venous embolism, hypotension',
  衰竭: 'Failure',
  全身器官衰竭: 'Total body organ failure',
  '周围血管病，糖尿病足': 'Peripheral vascular disease, diabetic foot',
  珠网膜下腔出血: 'Subretinal hemorrhage',
  糖尿病足心衰: 'Diabetic foot heart failure',
  死亡: 'Death',
  '腹膜炎、肝硬化': 'Peritonitis, cirrhosis of the liver',
  心衰: 'Heart failure',
  '全身衰竭消化道出血肿瘤？':
    'Total body failure Gastrointestinal bleeding Tumor?',
  真菌腹膜性肠梗阻: 'Fungal peritoneal intestinal obstruction',
  肺部感染脑梗: 'Pulmonary infection cerebral infarction',
  心源性猝死腹膜炎消化道肿瘤多器官衰竭不除外营养不良:
    'Sudden cardiac death peritonitis GI tract tumor multiorgan failure not excluding malnutrition',
  AMI: 'AMI',
  '死亡（低K血症家属放弃治疗）':
    'Death (hypokalemia family abandoned treatment)',
  肺炎呼衰死亡: 'Death from pneumonia with respiratory failure',
  '心功能衰竭，肺部感染': 'Heart failure with pulmonary infection',
  消化道出血多器官衰竭: 'Gastrointestinal bleeding multi-organ failure',
  肝衰竭: 'Liver failure',
  心衰低血压: 'Heart failure hypotension',
  死亡心衰: 'Death heart failure',
  脑瘤: 'Brain tumor',
  上消化道出血: 'Upper gastrointestinal bleeding',
  消化道出血低血压: 'Gastrointestinal bleeding hypotension',
  死亡重症肺炎营养不良: 'Death severe pneumonia malnutrition',
  '心梗、消化道出血、糖尿病足':
    'heart attack, gastrointestinal bleeding, diabetic foot',
  脑梗死亡: 'Death from cerebral infarction',
  肺部真菌感染: 'Pulmonary fungal infection',
  猝死死亡: 'Sudden death',
  夹层动脉瘤: 'Clamped aneurysm',
  骨折手术后栓塞: 'Embolism after fracture surgery',
  '猝死(肺炎)': 'Sudden death (pneumonia)',
  肝癌肝硬化: 'Hepatocellular carcinoma cirrhosis',
  '呼衰、心包积液': 'Respiratory failure, pericardial effusion',
  '呼吸衰竭，多脏器衰竭': 'Respiratory failure, multiple organ failure',
  腹膜炎伴合并肺部感染家属放弃治疗:
    'Peritonitis with co-infection of the lungs family abandoned treatment',
  '脑血管系统疾病（脑梗）':
    'Cerebrovascular system disease (cerebral infarction)',
  '肺部感染消化道出血，糖尿病史，死亡':
    'Pulmonary infection gastrointestinal bleeding, history of diabetes mellitus, death',
  重症肺炎呼衰: 'Severe pneumonia respiratory failure',
  '心力衰竭、外周血管疾病': 'Heart failure, peripheral vascular disease',
  '肺结核？': 'Pulmonary tuberculosis?',
  心脏猝死: 'Sudden cardiac death',
  心梗死亡: 'Death from heart attack',
  肠穿孔腹膜炎: 'Peritonitis with intestinal perforation',
  心梗脑梗死亡: 'Death from heart attack cerebral infarction',
  心脏搭桥术后伤口感染: 'Wound infection after heart bypass surgery',
  '肺部感染，呼衰': 'Pulmonary infection, respiratory failure',
  急性呼吸窘迫综合征死亡: 'Death from acute respiratory distress syndrome',
  '胃癌？腹膜炎？多脏器衰竭':
    'Stomach cancer? Peritonitis? Multiple organ failure',
  胆管梗阻: 'Bile duct obstruction',
  腹膜炎多器官功能衰竭: 'Peritonitis Multi-organ failure',
  '(肿瘤)肺癌': '(Tumor) Lung cancer',
  营养不良低血压: 'Malnutrition Hypotension',
  膀胱癌多器官衰竭: 'Bladder cancer Multi-organ failure',
  透析不充分肺炎: 'Inadequate dialysis pneumonia',
  '急性心梗，砂肺，肺部感染，陈旧心梗，冠心病':
    'Acute heart attack, sandy lung, lung infection, old heart attack, coronary artery disease',
  严重营养不良肺部感染低钾血症:
    'Severe malnutrition pulmonary infection hypokalemia',
  肺癌多器官衰竭: 'Lung cancer multi-organ failure',
  放弃治疗停透1个月:
    'Abandonment of treatment and stopping dialysis for 1 month',
  胆管癌: 'Bile duct cancer',
  房颤心梗: 'Atrial fibrillation heart attack',
  肝癌反复腹膜炎家属放弃:
    'Hepatocellular carcinoma recurrent peritonitis family gave up',
  肝癌多器官衰竭: 'Liver cancer multiple organ failure',
  '死亡/心衰呼衰': 'Death/heart failure and respiratory failure',
  腹膜炎消化道出血放弃治疗:
    'Peritonitis gastrointestinal bleeding abandonment of treatment ',
  '肺部感染，呼吸衰竭': 'Lung infection, respiratory failure',
  重症肺炎: 'Severe pneumonia',
  '心源性猝死（在家中）': 'Sudden cardiac death (at home)',
  猝死急性心梗可能性大: 'Sudden death acute heart attack likely',
  严重营养不良腹膜炎多囊肾腹水:
    'Severe malnutrition peritonitis polycystic kidney ascites',
  '脑血管系统疾病(脑出血)死亡':
    'Death from cerebrovascular system disease (cerebral hemorrhage)',
  '多脏器衰竭，急性胰腺炎肺部感染房颤':
    'Multiple organ failure, acute pancreatitis pulmonary infection atrial fibrillation',
  重症肺部感染: 'Severe pulmonary infection',
  '营养不良，回复慢腹膜炎脑血管病？':
    'Malnutrition, reply slow peritonitis cerebrovascular disease?',
  '心梗 腹膜透析相关性腹膜炎':
    'Heart attack Peritoneal dialysis-associated peritonitis'
}

export const medicalCharChallenge = {
  hr: 'HR',
  o2sat: 'O2Sat',
  Temp: 'Temp',
  sbp: 'SBP',
  map: 'MAP',
  dbp: 'DBP',
  Resp: 'Resp',
  etco2: 'EtCO2',
  baseexcess: 'BaseExcess',
  hco3: 'HCO3',
  fio2: 'FiO2',
  ph: 'pH',
  paco2: 'PaCO2',
  sao2: 'SaO2',
  ast: 'AST',
  bun: 'BUN',
  Alkalinephos: 'Alkalinephos',
  Calcium: 'Calcium',
  Chloride: 'Chloride',
  creatinine_creatinine: 'Creatinine',
  Bilirubin_direct: 'Bilirubin direct',
  Glucose: 'Glucose',
  Lactate: 'Lactate',
  Magnesium: 'Magnesium',
  Phosphate: 'Phosphate',
  Potassium: 'Potassium',
  Bilirubin_total: 'Bilirubin total',
  troponini: 'TroponinI',
  Hct: 'Hct',
  Hgb: 'Hgb',
  ptt: 'PTT',
  wbc: 'WBC',
  Fibrinogen: 'Fibrinogen',
  Platelets: 'Platelets'
}

export const medicalCharCovid = {
  'hypersensitive cardiac troponini': 'Hs-cTnI',
  hemoglobin: 'Hemoglobin',
  'serum chloride': 'Serum chloride',
  'prothrombin time': 'Prothrombin time',
  procalcitonin: 'Procalcitonin',
  'eosinophils(%)': 'Eosinophils(%)',
  'interleukin 2 receptor': 'IL-2R',
  'alkaline phosphatase': 'Alkaline phosphatase',
  albumin: 'albumin',
  'basophil(%)': 'basophil(%)',
  'interleukin 10': 'Interleukin 10',
  'total bilirubin': 'Total bilirubin',
  'platelet count': 'Platelet count',
  'monocytes(%)': 'Monocytes(%)',
  antithrombin: 'Antithrombin',
  'interleukin 8': 'Interleukin 8',
  'indirect bilirubin': 'Indirect bilirubin',
  'red blood cell distribution width': 'RDW',
  'neutrophils(%)': 'neutrophils(%)',
  'total protein': 'total protein',
  'quantification of treponema pallidum antibodies':
    'Quantification of TP antibodies',
  'prothrombin activity': 'Prothrombin activity',
  hbsag: 'HBsAg',
  'mean corpuscular volume': 'MCV',
  hematocrit: 'hematocrit',
  'white blood cell count': 'WBC',
  'tumor necrosis factorα': 'Tumor necrosis factorα',
  'mean corpuscular hemoglobin concentration': 'MCHC',
  fibrinogen: 'fibrinogen',
  'interleukin 1β': 'Interleukin 1β',
  urea: 'Urea',
  'lymphocyte count': 'lymphocyte count',
  'ph value': 'PH value',
  'red blood cell count': 'RBC',
  'eosinophil count': 'Eosinophil count',
  'corrected calcium': 'Corrected calcium',
  'serum potassium': 'Serum potassium',
  glucose: 'Glucose',
  'neutrophils count': 'Neutrophils count',
  'direct bilirubin': 'Direct bilirubin',
  'mean platelet volume': 'Mean platelet volume',
  ferritin: 'ferritin',
  'rbc distribution width sd': 'RBC distribution width SD',
  'thrombin time': 'Thrombin time',
  '(%)lymphocyte': '(%)lymphocyte',
  'hcv antibody quantification': 'HCV antibody quantification',
  'd-d dimer': 'D-D dimer',
  'total cholesterol': 'Total cholesterol',
  'aspartate aminotransferase': 'AST',
  'uric acid': 'Uric acid',
  'hco3-': 'HCO3-',
  calcium: 'Calcium',
  'amino-terminal brain natriuretic peptide precursor(nt-probnp)': 'NT-proBNP',
  'lactate dehydrogenase': 'Lactate dehydrogenase',
  'platelet large cell ratio': 'P-LCR',
  'interleukin 6': 'Interleukin 6',
  'fibrin degradation products': 'FDP',
  'monocytes count': 'monocytes count',
  'plt distribution width': 'PLT distribution width',
  globulin: 'globulin',
  'γ-glutamyl transpeptidase': 'γ-glutamyl transpeptidase',
  'international standard ratio': 'International standard ratio',
  'basophil count(#)': 'basophil count',
  '2019-ncov nucleic acid detection': 'nucleic acid detection',
  'mean corpuscular hemoglobin': 'mean corpuscular Hgb',
  'activation of partial thromboplastin time': 'aPTT',
  'hypersensitive c-reactive protein': 'HsCRP',
  'hiv antibody quantification': 'HIV antibody quantification',
  'serum sodium': 'serum sodium',
  thrombocytocrit: 'thrombocytocrit',
  esr: 'ESR',
  'glutamic-pyruvic transaminase': 'GPT',
  egfr: 'eGFR',
  creatinine: 'creatinine'
}

export const medicalCharCovidShort = {
  'hypersensitive cardiac troponini': 'Hs-cTnI',
  hemoglobin: 'Hemoglobin',
  'serum chloride': 'SC',
  'prothrombin time': 'PT',
  procalcitonin: 'Pro',
  'eosinophils(%)': 'Eos',
  'interleukin 2 receptor': 'IL-2R',
  'alkaline phosphatase': 'AP',
  albumin: 'albumin',
  'basophil(%)': 'bas',
  'interleukin 10': 'I10',
  'total bilirubin': 'TB',
  'platelet count': 'PC',
  'monocytes(%)': 'Mon',
  antithrombin: 'Antithro',
  'interleukin 8': 'I8',
  'indirect bilirubin': 'IB',
  'red blood cell distribution width': 'RDW',
  'neutrophils(%)': 'neu',
  'total protein': 'TPro',
  'quantification of treponema pallidum antibodies': 'QTPA',
  'prothrombin activity': 'PA',
  hbsag: 'HBsAg',
  'mean corpuscular volume': 'MCV',
  hematocrit: 'hem',
  'white blood cell count': 'WBC',
  'tumor necrosis factorα': 'TNF',
  'mean corpuscular hemoglobin concentration': 'MCHC',
  fibrinogen: 'fib',
  'interleukin 1β': 'I1β',
  urea: 'Urea',
  'lymphocyte count': 'LC',
  'ph value': 'PH value',
  'red blood cell count': 'RBC',
  'eosinophil count': 'EC',
  'corrected calcium': 'CC',
  'serum potassium': 'SP',
  glucose: 'Glucose',
  'neutrophils count': 'NC',
  'direct bilirubin': 'DB',
  'mean platelet volume': 'MPV',
  ferritin: 'fer',
  'rbc distribution width sd': 'RBC-dw-SD',
  'thrombin time': 'TT',
  '(%)lymphocyte': 'lym',
  'hcv antibody quantification': 'HCV',
  'd-d dimer': 'D-D dimer',
  'total cholesterol': 'TC',
  'aspartate aminotransferase': 'AST',
  'uric acid': 'Uric acid',
  'hco3-': 'HCO3-',
  calcium: 'Cal',
  'amino-terminal brain natriuretic peptide precursor(nt-probnp)': 'NT-proBNP',
  'lactate dehydrogenase': 'LD',
  'platelet large cell ratio': 'P-LCR',
  'interleukin 6': 'I6',
  'fibrin degradation products': 'FDP',
  'monocytes count': 'MC',
  'plt distribution width': 'PLT-dw',
  globulin: 'globulin',
  'γ-glutamyl transpeptidase': 'γ-glu-trans',
  'international standard ratio': 'ISR',
  'basophil count(#)': 'BC',
  '2019-ncov nucleic acid detection': 'NAD',
  'mean corpuscular hemoglobin': 'mc-Hgb',
  'activation of partial thromboplastin time': 'aPTT',
  'hypersensitive c-reactive protein': 'HsCRP',
  'hiv antibody quantification': 'HIV-aq',
  'serum sodium': 'SS',
  thrombocytocrit: 'thr',
  esr: 'ESR',
  'glutamic-pyruvic transaminase': 'GPT',
  egfr: 'eGFR',
  creatinine: 'Crea'
}

export const medicalCharChallengeShort = {
  hr: 'HR',
  o2sat: 'O2Sat',
  Temp: 'Temp',
  sbp: 'SBP',
  map: 'MAP',
  dbp: 'DBP',
  Resp: 'Resp',
  etco2: 'EtCO2',
  baseexcess: 'Base Ex',
  hco3: 'HCO3',
  fio2: 'FiO2',
  ph: 'pH',
  paco2: 'PaCO2',
  sao2: 'SaO2',
  ast: 'AST',
  bun: 'BUN',
  Alkalinephos: 'ALP',
  Calcium: 'Cal',
  Chloride: 'Cl',
  creatinine_creatinine: 'Crea',
  Bilirubin_direct: 'D-BIL',
  Glucose: 'Glu',
  Lactate: 'Lactate',
  Magnesium: 'Mg',
  Phosphate: 'PO4',
  Potassium: 'k',
  Bilirubin_total: 'TBIL',
  troponini: 'TnI',
  Hct: 'Hct',
  Hgb: 'Hgb',
  ptt: 'PTT',
  wbc: 'WBC',
  Fibrinogen: 'Fbg',
  Platelets: 'PLT'
}

export const medicalCharChineseShort = {
  cl: '血氯',
  co2: '二氧化碳' + '\n' + '结合力',
  wbc: '白细胞',
  hgb: '血红蛋白',
  urea: '尿素',
  ca: '血钙',
  k: '血钾',
  na: '血钠',
  cre: '肌酐',
  p: '血磷',
  alb: '白蛋白',
  crp: '超敏C' + '\n' + '反应蛋白',
  glu: '血糖',
  amount: '食物摄入' + '\n' + '（含水）',
  weight: '体重',
  sys: '收缩压',
  dia: '舒张压'
}

export const medicalCharLong = {
  cl: 'Blood chlorine',
  co2: 'Carbon dioxide binding power',
  wbc: 'White blood cells',
  hgb: 'Hemoglobin',
  urea: 'Urea',
  ca: 'Blood calcium',
  k: 'Blood potassium',
  na: 'Blood sodium',
  cre: 'Creatinine',
  p: 'Blood phosphorus',
  alb: 'Albumin',
  crp: 'Hypersensitive C reactive protein',
  glu: 'Blood glucose',
  amount: 'Food intake (with water)',
  weight: 'Weight',
  sys: 'Systolic blood pressure',
  dia: 'Diastolic blood pressure'
}

export const medicalCharShort = {
  cl: 'Chlorine',
  co2: 'CO2CP',
  wbc: 'WBC',
  hgb: 'HGB',
  urea: 'Urea',
  ca: 'Calcium',
  k: 'Potassium',
  na: 'Sodium',
  cre: 'SCR',
  p: 'PHOS',
  alb: 'Albumin',
  crp: 'hs-CRP',
  glu: 'Glucose',
  amount: 'Food\nintake',
  weight: 'Weight',
  sys: 'Systolic\npressure',
  dia: 'Diastolic\npressure'
}

export const medicalUnit = {
  cl: 'mmol/L',
  co2: 'mmol/L',
  wbc: '×10^9/L',
  hgb: 'g/L',
  urea: 'mmol/L',
  ca: 'mmol/L',
  k: 'mmol/L',
  na: 'mmol/L',
  cre: 'μmol/L',
  p: 'mmol/L',
  alb: 'g/L',
  crp: 'mg/L',
  glu: 'mmol/L',
  amount: 'g',
  weight: 'kg',
  sys: 'mmHg',
  dia: 'mmHg'
}

// 没有食物摄入和体重
export const medicalStandard = {
  cl: [96, 106],
  co2: [20, 29],
  wbc: [3.5, 9.5],
  hgb: [115, 150],
  urea: [3.1, 8.0],
  ca: [2.25, 2.75],
  k: [3.5, 5.5],
  na: [135, 145],
  cre: [62, 115],
  p: [1.1, 1.3],
  alb: [40, 55],
  crp: [0.5, 10],
  glu: [3.9, 6.1],
  sys: [100, 120],
  dia: [60, 80]
}

export const patientMap = {
  a1: 215,
  a2: 265,
  a3: 318,
  a4: 740,
  a5: 455,
  a6: 370,
  a7: 598,
  a8: 616,
  a9: 812,
  a10: 998,
  a11: 188,
  a12: 230,
  a13: 249,
  a14: 435,
  a15: 522,
  a16: 666,
  a17: 768,
  a18: 777,
  a19: 994,
  a20: 1035,
  a21: 1127,
  a22: 201,
  a23: 234,
  a24: 235,
  a25: 241,
  a26: 242,
  a27: 251,
  a28: 255,
  a29: 490,
  a30: 269,
  a31: 291,
  a32: 303,
  a33: 307,
  a34: 310,
  a35: 422,
  a36: 444,
  a37: 327,
  a38: 334,
  a39: 337,
  a40: 339,
  a41: 544
}

export const diseases = [
  '慢性肾小球肾炎',
  '糖尿病肾病',
  '高血压肾损害',
  '常染色体显性多囊肾病',
  '大动脉炎肾损害',
  '梗阻性肾病',
  '慢性间质性肾炎',
  '肾动脉硬化',
  '慢性肾盂肾炎',
  '良性动脉硬化',
  '原发性小血管炎肾损害',
  '高血压肾病',
  '缺血性肾病',
  '多发性骨髓瘤肾损害',
  '多囊肾',
  '肾移植后肾炎',
  '系统性红斑狼疮性肾炎',
  '包虫病',
  '淀粉样变性肾病',
  '痛风慢性间质性肾炎',
  '过敏性紫癜性肾炎',
  'DN',
  'IGA肾病',
  '局灶增生性肾小球肾炎伴肾小管间质',
  '心肾综合征',
  '不明'
]
