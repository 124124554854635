<template>
  <router-view v-if="!$route.meta.isKeepAlive && isRouterAlive" />
</template>
<script>
  import { xray } from './config/pluginInit'

  export default {
  name: 'App',
  components: {
  },
  mounted () {
    xray.mainIndex()
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
