<template>
  <div>
    <iq-card
      class-name="iq-card-block iq-card-stretch iq-card-height"
      v-if="
        (analyzeAdvice.length ||
          adviceOnRisk.valid ||
          adviceOnRiskThreshold.valid) &&
          !isQuestionnaire
      "
    >
      <template v-slot:headerTitle>
        <h4 class="card-title" style="display: inline">
          {{ $t('main.DashboardCkd.AICard.AIObserve') }}
        </h4>
        <Tooltip placement="top">
          <img
            src="../../assets/images/question.png"
            alt="guide"
            class="guide"
          />
          <span slot="content" style="white-space: normal">{{
            $t('main.DashboardCkd.AICard.tips')
          }}</span>
        </Tooltip>
      </template>
      <template v-slot:headerAction>
        <div style="padding-right: 20px">
          <a v-if="display === false" @click="display = !display">
            <span>{{ $t('main.general.more') }}</span>
          </a>
          <a v-else @click="display = !display">
            <span>{{ $t('main.general.hide') }}</span>
          </a>
        </div>
      </template>
      <template v-slot:body>
        <b-collapse id="collapse-0" class="mb-2" v-model="display">
          <p v-show="computingAdvice.pending">
            {{ $t('main.DashboardCkd.AICard.computing') }}
          </p>
          <ul v-show="!computingAdvice.pending" class="iq-timeline">
            <li>
              <div class="timeline-dots border-primary"></div>
              <a
                class="text-primary float-left mb-1"
                id="date"
                @click="
                  show([modifiedLab.length - 1, modifiedLab.length - 1], top2)
                "
              >
                {{ modifiedLab.slice(-1)[0].date.slice(0, 10) }}
              </a>
              <div class="d-inline-block w-100">
                <p style="margin-bottom: 0" v-if="originalAnalyze && lab">
                  {{ $t('main.DashboardCkd.AICard.current') }}
                  <Tooltip placement="top">
                    <b>{{ $t('main.DashboardCkd.AICard.deathRisk') }}</b>
                    <img
                      src="../../assets/images/question.png"
                      alt="guide"
                      class="guide"
                    />
                    <span slot="content" style="white-space: normal">
                      <b>{{ $t('main.DashboardCkd.AICard.deathRisk') }}</b>
                      {{ $t('main.DashboardCkd.AICard.deathRisk()') }}
                    </span>
                  </Tooltip>
                  {{ $t('main.DashboardCkd.AICard.is') }} {{ currentRisk }}
                  {{ $t('main.DashboardCkd.AICard.and') }}
                  <span
                    :style="{ color: ch.get(top2[0]).midColor }"
                    style="font-weight:bold;"
                    >{{
                      getLocale()
                        ? medicalCharChinese[top2[0]]
                        : medicalChar[top2[0]]
                    }}</span
                  >
                  ({{
                    Number(lab.slice(-1)[0][top2[0]]).toFixed(1) +
                      medicalUnit[top2[0]]
                  }}){{ $t('main.DashboardCkd.AICard.andSecond') }}
                  <span
                    :style="{ color: ch.get(top2[1]).midColor }"
                    style="font-weight:bold;"
                    >{{
                      getLocale()
                        ? medicalCharChinese[top2[1]]
                        : medicalChar[top2[1]]
                    }}</span
                  >
                  ({{
                    Number(lab.slice(-1)[0][top2[1]]).toFixed(1) +
                      medicalUnit[top2[1]]
                  }})
                  <span
                    v-if="
                      currentRisk >= 40 && prognosis.length && !isQuestionnaire
                    "
                  >
                    {{ $t('main.DashboardCkd.AICard.andConsider') }}
                    <span v-for="(value, i) in prognosis" :key="value">
                      <b>{{ getDiseaseName(value) }}</b>
                      <span v-if="i !== prognosis.length - 1">
                        、
                      </span>
                    </span>
                  </span>
                </p>
                <div
                  v-if="
                    analyzeAdvice.length &&
                      analyzeAdvice[0].merge &&
                      !isQuestionnaire
                  "
                >
                  {{ $t('main.DashboardCkd.AICard.riskFluctuated') }}
                  {{
                    getVariation(
                      100 * analyzeAdvice[0].risk_variation_absolute
                    )
                  }}{{ $t('main.DashboardCkd.AICard.to') }}
                  {{ getVariation(100 * analyzeAdvice[0].risk) }} (
                  <span
                    v-if="analyzeAdvice[0].risk_variation > 0"
                    style="color: red"
                  >
                    {{ getVariation(100 * analyzeAdvice[0].risk_variation) }}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{
                      getVariation(100 * analyzeAdvice[0].risk_variation)
                    }}%↓ </span
                  >){{ getLocale() ? '' : ' in a short period of time' }}
                  {{ $t('main.DashboardCkd.AICard.adviceAttention') }}
                  <p
                    v-for="(value, j) in analyzeAdvice[0].item"
                    :key="value + '0'"
                    style="margin-bottom: 0"
                  >
                    ◎&nbsp;
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                    {{
                      getLocale()
                        ? ''
                        : getTrendByVariation(analyzeAdvice[0].lab_variation[j])
                    }}
                    {{ $t('main.DashboardCkd.AICard.from') }}
                    {{
                      (
                        analyzeAdvice[0].lab[j] -
                        analyzeAdvice[0].lab_variation_absolute[j]
                      ).toFixed(1)
                    }}{{ medicalUnit[value] }}
                    {{ getLocale() ? '快速' : '' }}
                    {{
                      getLocale()
                        ? getTrendByVariation(analyzeAdvice[0].lab_variation[j])
                        : ''
                    }}
                    {{
                      getVariation(
                        analyzeAdvice[0].lab_variation_absolute[j],
                        1
                      )
                    }}
                    {{ $t('main.DashboardCkd.AICard.to') }}
                    {{ analyzeAdvice[0].lab[j].toFixed(1)
                    }}{{ medicalUnit[value] }} (
                    <span
                      v-if="analyzeAdvice[0].lab_variation[j] > 0"
                      style="color: red"
                    >
                      {{
                        getVariation(100 * analyzeAdvice[0].lab_variation[j])
                      }}%↑
                    </span>
                    <span v-else style="color: limegreen">
                      {{
                        getVariation(100 * analyzeAdvice[0].lab_variation[j])
                      }}%↓ </span
                    >)
                    <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '且' : ''}}-->
                  </p>
                </div>
                <p
                  style="margin-bottom: 0; margin-top: 5px;"
                  v-if="
                    adviceOnRiskThreshold.valid &&
                      adviceOnRiskThreshold.advice.curRisk <
                        adviceOnRiskThreshold.advice.oriRisk &&
                      adviceOnRiskThreshold.advice.curRisk < 40 &&
                      !isQuestionnaire
                  "
                >
                  <b
                    >{{ $t('main.DashboardCkd.AICard.AiAdvice') }}
                    <!-- {{(adviceOnRiskThresholdSub.valid == true && (adviceOnRiskThresholdSub.advice.curRisk &lt; adviceOnRiskThresholdSub.advice.oriRisk)) ? 2:1}} -->
                    {{ $t('main.DashboardCkd.AICard.AiAdvice()') }}</b
                  ><br />
                  <span
                    v-for="(advice_item, i) in adviceOnRiskThreshold.advice ==
                    undefined
                      ? null
                      : adviceOnRiskThreshold.advice.idx"
                    :key="i"
                  >
                    ◎&nbsp;
                    <span
                      :style="{ color: ch.get(advice_item.key).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[advice_item.key]
                          : medicalChar[advice_item.key]
                      }}
                    </span>
                    <span v-if="advice_item.key == 'amount'">
                      <span v-if="advice_item.direction">{{
                        $t('main.DashboardCkd.AICard.addIntake')
                      }}</span>
                      <span v-else>{{
                        $t('main.DashboardCkd.AICard.deIntake')
                      }}</span>
                    </span>
                    <span v-else-if="getLocale()">
                      {{ $t('main.DashboardCkd.AICard.from') }}
                      {{ advice_item.oriValue }}
                      {{ advice_item.direction ? '升高' : '降低' }}
                      {{ advice_item.valueDiff }}
                      {{ $t('main.DashboardCkd.AICard.to') }}
                      {{ advice_item.curValue }} (
                      <span v-if="advice_item.direction" style="color: red">
                        {{ advice_item.prop.toFixed(1) }}%↑
                      </span>
                      <span v-else style="color: limegreen">
                        {{ advice_item.prop.toFixed(1) }}%↓ </span
                      >)
                    </span>
                    <span v-else>
                      {{ advice_item.direction ? 'increased' : 'decreased' }}
                      {{ $t('main.DashboardCkd.AICard.from') }}
                      {{ advice_item.oriValue }}
                      by
                      {{ advice_item.valueDiff }}
                      {{ $t('main.DashboardCkd.AICard.to') }}
                      {{ advice_item.curValue }} (
                      <span v-if="advice_item.direction" style="color: red">
                        {{ advice_item.prop.toFixed(1) }}%↑
                      </span>
                      <span v-else style="color: limegreen">
                        {{ advice_item.prop.toFixed(1) }}%↓ </span
                      >)
                    </span>
                    <br />
                  </span>
                  {{ $t('main.DashboardCkd.AICard.riskDecrease') }}
                  {{ adviceOnRiskThreshold.advice.riskDiff }}
                  {{ $t('main.DashboardCkd.AICard.to') }}
                  {{ adviceOnRiskThreshold.advice.curRisk }} (
                  <span style="color: limegreen">
                    {{ adviceOnRiskThreshold.advice.riskProp }}%↓ </span
                  >)
                </p>
                <p
                  style="margin-bottom: 0; margin-top: 5px;"
                  v-if="
                    adviceOnRisk.valid &&
                      adviceOnRiskThreshold.advice.oriRisk > 20 &&
                      !isQuestionnaire
                  "
                >
                  <b
                    >{{ $t('main.DashboardCkd.AICard.AiAdvice') }}
                    <!-- {{(adviceOnRiskThreshold.valid == true && (adviceOnRiskThreshold.advice.curRisk &lt; adviceOnRiskThreshold.advice.oriRisk)) ? 3:2}} -->
                    {{ $t('main.DashboardCkd.AICard.AiAdvice2()') }}
                  </b>
                  <br />
                  <span
                    v-for="(advice_item, i) in adviceOnRisk.advice == undefined
                      ? null
                      : adviceOnRisk.advice.idx"
                    :key="i"
                  >
                    ◎&nbsp;
                    <span
                      :style="{ color: ch.get(advice_item.key).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[advice_item.key]
                          : medicalChar[advice_item.key]
                      }}
                    </span>
                    <span v-if="advice_item.key == 'amount'">
                      <span v-if="advice_item.direction">{{
                        $t('main.DashboardCkd.AICard.addIntake')
                      }}</span>
                      <span v-else>{{
                        $t('main.DashboardCkd.AICard.deIntake')
                      }}</span>
                    </span>
                    <span v-else-if="getLocale()">
                      {{ $t('main.DashboardCkd.AICard.from') }}
                      {{ advice_item.oriValue }}
                      {{ advice_item.direction ? '升高' : '降低' }}
                      {{ advice_item.valueDiff }}
                      {{ $t('main.DashboardCkd.AICard.to') }}
                      {{ advice_item.curValue }} (
                      <span v-if="advice_item.direction" style="color: red">
                        {{ advice_item.prop.toFixed(1) }}%↑
                      </span>
                      <span v-else style="color: limegreen">
                        {{ advice_item.prop.toFixed(1) }}%↓ </span
                      >)
                    </span>
                    <span v-else>
                      {{ advice_item.direction ? 'increased' : 'decreased' }}
                      {{ $t('main.DashboardCkd.AICard.from') }}
                      {{ advice_item.oriValue }}
                      by
                      {{ advice_item.valueDiff }}
                      {{ $t('main.DashboardCkd.AICard.to') }}
                      {{ advice_item.curValue }} (
                      <span v-if="advice_item.direction" style="color: red">
                        {{ advice_item.prop.toFixed(1) }}%↑
                      </span>
                      <span v-else style="color: limegreen">
                        {{ advice_item.prop.toFixed(1) }}%↓ </span
                      >)
                    </span>
                    <br />
                  </span>
                  {{ $t('main.DashboardCkd.AICard.riskDecrease') }}
                  {{ adviceOnRisk.advice.riskDiff }}
                  {{ $t('main.DashboardCkd.AICard.to') }}
                  {{ adviceOnRisk.advice.curRisk }} (
                  <span style="color: limegreen">
                    {{ adviceOnRisk.advice.riskProp }}%↓ </span
                  >)
                </p>
              </div>
            </li>
            <li
              v-for="(advice_item, i) in analyzeAdvice"
              :key="i"
              v-show="!isQuestionnaire"
            >
              <div
                :class="getTimelineDot(advice_item.type)"
                v-if="!advice_item.merge"
              ></div>
              <a
                class="text-primary float-left mb-1"
                id="date"
                v-if="!advice_item.merge"
                @click="show(advice_item.date_index, advice_item.item)"
              >
                {{(Number(advice_item.date.slice(0,4)) - $store.state.yearMinus) + advice_item.date.slice(4,13) + (Number(advice_item.date.slice(13,17)) - $store.state.yearMinus) + advice_item.date.slice(17)}}
              </a>
              <div class="d-inline-block w-100" v-if="!advice_item.merge">
                <div v-if="advice_item.type === 0">
                  {{ $t('main.DashboardCkd.AICard.riskFluctuated') }}
                  {{ getVariation(100 * advice_item.risk_variation_absolute) }}
                  {{ $t('main.DashboardCkd.AICard.to') }}
                  {{ getVariation(100 * advice_item.risk) }} (
                  <span
                    v-if="advice_item.risk_variation > 0"
                    style="color: red"
                  >
                    {{ getVariation(100 * advice_item.risk_variation) }}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{
                      getVariation(100 * advice_item.risk_variation)
                    }}%↓ </span
                  >){{ getLocale() ? '' : ' in a short period of time' }}
                  {{ $t('main.DashboardCkd.AICard.adviceAttention') }}
                  <p
                    v-for="(value, j) in advice_item.item"
                    :key="value + '0'"
                    style="margin-bottom: 0"
                  >
                    ◎&nbsp;
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                    {{
                      getLocale()
                        ? ''
                        : getTrendByVariation(analyzeAdvice[0].lab_variation[j])
                    }}
                    {{ $t('main.DashboardCkd.AICard.from') }}
                    {{
                      (
                        advice_item.lab[j] -
                        advice_item.lab_variation_absolute[j]
                      ).toFixed(1)
                    }}{{ medicalUnit[value] }}
                    {{ getLocale() ? '快速' : '' }}
                    {{
                      getLocale()
                        ? getTrendByVariation(advice_item.lab_variation[j])
                        : ''
                    }}
                    {{ getVariation(advice_item.lab_variation_absolute[j], 1) }}
                    {{ $t('main.DashboardCkd.AICard.to') }}
                    {{ advice_item.lab[j].toFixed(1)
                    }}{{ medicalUnit[value] }} (
                    <span
                      v-if="advice_item.lab_variation[j] > 0"
                      style="color: red"
                    >
                      {{ getVariation(100 * advice_item.lab_variation[j]) }}%↑
                    </span>
                    <span v-else style="color: limegreen">
                      {{
                        getVariation(100 * advice_item.lab_variation[j])
                      }}%↓ </span
                    >)
                    <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '且' : ''}}-->
                  </p>
                </div>
                <div v-if="advice_item.type === 1">
                  {{ $t('main.DashboardCkd.AICard.riskRise')
                  }}{{
                    getVariation(100 * advice_item.risk_variation_absolute)
                  }}
                  {{ $t('main.DashboardCkd.AICard.to') }}
                  {{ getVariation(100 * advice_item.risk) }} (
                  <span
                    v-if="advice_item.risk_variation > 0"
                    style="color: red"
                  >
                    {{ getVariation(100 * advice_item.risk_variation) }}%↑
                  </span>
                  <span v-else style="color: limegreen">
                    {{
                      getVariation(100 * advice_item.risk_variation)
                    }}%↓ </span
                  >){{ $t('main.DashboardCkd.AICard.adviceAttention') }}
                  <p
                    v-for="(value, j) in advice_item.item"
                    :key="value + '1'"
                    style="margin-bottom: 0"
                  >
                    ◎&nbsp;
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                    {{
                      getLocale()
                        ? ''
                        : getTrendByVariation(advice_item.lab_variation[j])
                    }}
                    {{ $t('main.DashboardCkd.AICard.from') }}
                    {{
                      (
                        advice_item.lab[j] -
                        advice_item.lab_variation_absolute[j]
                      ).toFixed(1)
                    }}{{ medicalUnit[value] }}
                    {{ getLocale() ? '持续' : '' }}
                    {{
                      getLocale()
                        ? getTrendByVariation(advice_item.lab_variation[j])
                        : ''
                    }}
                    {{ getVariation(advice_item.lab_variation_absolute[j], 1) }}
                    {{ $t('main.DashboardCkd.AICard.to') }}
                    {{ advice_item.lab[j].toFixed(1)
                    }}{{ medicalUnit[value] }} (
                    <span
                      v-if="advice_item.lab_variation[j] > 0"
                      style="color: red; word-spacing:0;"
                    >
                      {{ getVariation(100 * advice_item.lab_variation[j]) }}%↑
                    </span>
                    <span v-else style="color: limegreen">
                      {{
                        getVariation(100 * advice_item.lab_variation[j])
                      }}%↓ </span
                    >)
                    <!--                    {{advice_item.item.length - 2 > j ? '、' : ''}}{{advice_item.item.length - 2 === j ? '和' : ''}}-->
                  </p>
                </div>
                <div v-if="advice_item.type === 2">
                  {{ $t('main.DashboardCkd.AICard.AiContinue') }}
                  <span
                    v-for="(value, j) in advice_item.item"
                    :key="value + '2'"
                  >
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                    {{ advice_item.item.length - 2 > j ? '、' : ''
                    }}{{
                      advice_item.item.length - 2 === j
                        ? $t('main.DashboardCkd.AICard.And')
                        : ''
                    }}
                  </span>
                  {{ getLocale() ? '较高关注' : '' }}
                  {{ $t('main.DashboardCkd.AICard.advice') }}
                  <p
                    v-for="(value, j) in advice_item.item"
                    :key="value + '2_1'"
                    style="margin-bottom: 0"
                  >
                    ◎&nbsp;
                    {{ getTrend(advice_item.trend[j]) }}
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                  </p>
                </div>
                <div v-if="advice_item.type === 3">
                  {{ $t('main.DashboardCkd.AICard.inThisTime') }}
                  <span
                    v-for="(value, j) in advice_item.item"
                    :key="value + '3'"
                  >
                    <span
                      :style="{ color: ch.get(value).midColor }"
                      style="font-weight:bold"
                    >
                      {{
                        getLocale()
                          ? medicalCharChinese[value]
                          : medicalChar[value]
                      }}
                    </span>
                    {{ advice_item.item.length - 2 > j ? '、' : ''
                    }}{{
                      advice_item.item.length - 2 === j
                        ? $t('main.DashboardCkd.AICard.And')
                        : ''
                    }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </b-collapse>
      </template>
    </iq-card>
    <div v-if="isQuestionnaire">
      <div style="color:red;">AI观察结果</div>
      <b-collapse id="collapse-0" class="mb-2" v-model="display">
        <p v-show="computingAdvice.pending">
          {{ $t('main.DashboardCkd.AICard.computing') }}
        </p>
        <ul v-show="!computingAdvice.pending" class="iq-timeline">
          <li>
            <div class="timeline-dots border-primary"></div>
            <a
              class="text-primary float-left mb-1"
              id="date"
              @click="
                show([modifiedLab.length - 1, modifiedLab.length - 1], top2)
              "
            >
              {{ modifiedLab.slice(-1)[0].date.slice(0, 10) }}
            </a>
            <div class="d-inline-block w-100">
              <p style="margin-bottom: 0" v-if="originalAnalyze && lab">
                {{ $t('main.DashboardCkd.AICard.current') }}
                <Tooltip placement="top">
                  <b>{{ $t('main.DashboardCkd.AICard.deathRisk') }}</b>
                  <img
                    src="../../assets/images/question.png"
                    alt="guide"
                    class="guide"
                  />
                  <span slot="content" style="white-space: normal">
                    <b>{{ $t('main.DashboardCkd.AICard.deathRisk') }}</b>
                    {{ $t('main.DashboardCkd.AICard.deathRisk()') }}
                  </span>
                </Tooltip>
                {{ $t('main.DashboardCkd.AICard.is') }}
                {{ currentRisk }}
                {{ $t('main.DashboardCkd.AICard.and') }}
                <span
                  :style="{ color: ch.get(top2[0]).midColor }"
                  style="font-weight:bold;"
                  >{{
                    getLocale()
                      ? medicalCharChinese[top2[0]]
                      : medicalChar[top2[0]]
                  }}</span
                >
                ({{
                  Number(lab.slice(-1)[0][top2[0]]).toFixed(1) +
                    medicalUnit[top2[0]]
                }}){{ $t('main.DashboardCkd.AICard.andSecond') }}
                <span
                  :style="{ color: ch.get(top2[1]).midColor }"
                  style="font-weight:bold;"
                  >{{
                    getLocale()
                      ? medicalCharChinese[top2[1]]
                      : medicalChar[top2[1]]
                  }}</span
                >
                ({{
                  Number(lab.slice(-1)[0][top2[1]]).toFixed(1) +
                    medicalUnit[top2[1]]
                }})
              </p>
            </div>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import IqCard from '../../components/xray/cards/iq-card'
import {
  medicalCharChinese,
  medicalChar,
  medicalUnit,
  diseasesChartChi,
  diseasesChartEn
} from '@/util/info'

export default {
  name: 'AIObservation',
  components: {
    IqCard
  },
  props: {
    adviceOnRiskThreshold: { type: Object },
    adviceOnRisk: { type: Object },
    analyzeAdvice: {
      type: Array,
      default: () => []
    },
    isQuestionnaire: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: true,
      medicalCharChinese: medicalCharChinese,
      medicalChar: medicalChar,
      medicalUnit: medicalUnit,
      scrollTime: 20,
      id: 0,
      scrollSpeed: 0
    }
  },
  computed: {
    ch() {
      return this.$store.state.ch
    },
    lab() {
      return this.$store.state.lab
    },
    modifiedLab() {
      return this.$store.state.modifiedLab
    },
    computingAdvice() {
      return this.$store.state.computingAdvice
    },
    originalAnalyze() {
      return this.$store.state.originalAnalyze
    },
    top2() {
      return this.$store.state.top2
    },
    adviceOnIdx() {
      return this.$store.state.adviceOnIdx
    },
    adviceOnRiskThresholdSub() {
      return this.$store.state.adviceOnRiskThresholdSub
    },
    currentRisk() {
      return (
        this.$store.state.originalAnalyze.predict.slice(-1)[0] * 100
      ).toFixed(1)
    },
    prognosis() {
      let prognosis = []
      // console.log(this.$store.state.deathReason)
      this.$store.state.deathReason.slice(0, -1).forEach(reason => {
        if (reason[1] / this.originalAnalyze.cluster_info.amount >= 0.065) {
          prognosis.push(reason[0])
        }
      })
      if (this.$store.state.deathReason.length && !prognosis.length) {
        prognosis.push(this.$store.state.deathReason[0][0])
      }
      return prognosis
    }
  },
  methods: {
    getTimelineDot(type) {
      if (type === 0 || type === 3) {
        return 'timeline-dots border-danger'
      } else if (type === 1) {
        return 'timeline-dots border-warning'
      } else if (type === 2) {
        return 'timeline-dots border-primary'
      }
    },
    getVariation(variation, i = 1) {
      if (variation > 0) {
        return variation.toFixed(i)
      } else if (variation < 0) {
        return (-variation).toFixed(i)
      } else return '未变化'
    },
    getVariationMore(variation) {
      if (variation > 0) {
        return (100 * variation).toFixed(1) + '%'
      } else if (variation < 0) {
        return (-100 * variation).toFixed(1) + '%'
      } else return '未变化'
    },
    getTrendByVariation(variation) {
      if (variation > 0) {
        return this.getLocale() ? '上升了' : 'increased'
      } else if (variation < 0) {
        return this.getLocale() ? '下降了' : 'decreased'
      } else return '未变化' // 不应该出现
    },
    getTrend(trend) {
      if (trend === 1) {
        return this.getLocale() ? '降低' : 'decreased'
      } else if (trend === -1) {
        return this.getLocale() ? '提高' : 'increased'
      } else return this.getLocale() ? '关注' : 'attention'
    },
    getDiseaseName(value) {
      return this.getLocale() ? diseasesChartChi[value] : diseasesChartEn[value]
    },
    show(date_index, item) {
      this.$store.commit('setSelectedDate', date_index)
      this.$store.commit('setSelectedGraph', item)
      // this.scrollSpeed = this.getCurHeight() / this.scrollTime
      // this.id = setInterval(this.toStepTop, 1)
    },
    toStepTop() {
      if (document.body.scrollTop > 0) {
        document.body.scrollTop -= this.scrollSpeed
      } else if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop -= this.scrollSpeed
      }
      if (this.getCurHeight() <= 0) {
        clearInterval(this.id)
      }
    },
    getCurHeight() {
      return document.documentElement.scrollTop + document.body.scrollTop
    },
    getLocale() {
      return this.$i18n.locale === 'chi'
    }
  }
}
</script>

<style scoped lang="less">
.guide {
  width: 16px;
  padding-left: 2px;
  padding-bottom: 5px;
  cursor: pointer;
}

#date:hover {
  color: #089bab !important;
}
</style>
