<template>
  <b-container flupdid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="4">
            <iq-card>
              <template v-slot:headerTitle>
                <h2 class="card-title">按照编号搜索</h2>
              </template>
              <template v-slot:body>
                <b-input-group>
                  <b-form-input v-model="input"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="search">搜索</b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </iq-card>
          </b-col>
          <b-col md="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h2 class="card-title">按照条件筛选</h2>
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="select(0)">确定</b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="option_age" value="1">年龄</b-form-checkbox>
                    <b-input v-model="age" v-if="option_age"></b-input>
                    <b-input v-else disabled></b-input>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="option_gender" value="1">性别</b-form-checkbox>
                    <b-form-select v-if="option_gender"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="gender"
                      :value="null"
                      :options="{ '0': '男', '1': '女' }"
                      id="inline-form-custom-select-pref"
                    >
                      <template v-slot:first>
                        <option :value="null">请选择</option>
                      </template>
                    </b-form-select>
                    <b-form-select v-else
                                   disabled
                                   class="mb-2 mr-sm-2 mb-sm-0"
                    >
                    </b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="option_origin_disease" value="1">原发疾病</b-form-checkbox>
                    <b-input v-if="option_origin_disease" v-model="origin_disease"></b-input>
                    <b-input v-else disabled></b-input>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>

        <iq-card>
          <template v-slot:headerTitle>
            <h2 class="card-title">搜索结果</h2>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table id="my-table" bordered hover :items="rows" style="cursor:pointer" :fields="columns" :per-page="perPage" :current-page="currentPage">
                  <template v-slot:cell(pdid)="data">
                    <span>{{ data.item.pdid }}</span>
                  </template>
                  <template v-slot:cell(gender)="data">
                    <span>{{ data.item.gender }}</span>
                  </template>
                  <template v-slot:cell(age)="data">
                    <span>{{ data.item.age }}</span>
                  </template>
                  <template v-slot:cell(originDisease)="data">
                    <span>{{ data.item.originDisease }}</span>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-pagination v-model="currentPage"
                          :total-rows="length"
                          :per-page="perPage"
                          @change="loadData"
                          aria-controls="my-table"
                          first-number
                          last-number
                          align="center"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import APIHelper from '../../util/APIHelper'
import IqCard from '../../components/xray/cards/iq-card'

export default {
  name: 'Search',
  components: { IqCard },
  mounted () {
    xray.index()
  },
  created() {
    this.mode = 0
    this.getAllPatients()
  },
  methods: {
    select(type) {
      if (this.option_age === 1 && isNaN(this.age)) {
        alert('请输入正确的年龄！')
        return
      }
      this.rows = []
      let cnt = 0
      if (type === 0) this.currentPage = 0
      if (this.option_age === false) {
        this.age = ''
        cnt++
      }
      if (this.option_gender === false) {
        this.gender = ''
        cnt++
      }
      if (this.option_origin_disease === false) {
        this.origin_disease = ''
        cnt++
      }
      if (cnt === 3) {
        this.mode = 0
      } else {
        this.mode = 1
      }
      APIHelper.searchPatientsFromESByPage(this.age, this.gender, this.origin_disease, this.currentPage * this.perPage, this.perPage).then(response => {
        this.length = response.data.hits.total.value
        for (let i = 0; i <= this.length; i++) {
          let obj = this.default()
          let patient = response.data.hits.hits[i]
          obj.gender = patient._source.data__gender === 0 ? '男' : '女'
          obj.pdid = patient._source.data__pdid
          obj.age = patient._source.data__age
          obj.originDisease = patient._source.data__origin_disease
          this.rows.push(obj)
        }
      }).catch(function (error) {
          throw (error)
      })
    },
    search() {
      if (this.input === '') {
        this.mode = 0
        this.getAllPatients()
      } else {
        this.rows = []
        this.mode = 2
        APIHelper.searchPatientsByPdidFromES(this.input).then(response => {
          this.length = response.data.hits.total.value
          for (let i = 0; i <= this.length; i++) {
            let obj = this.default()
            let patient = response.data.hits.hits[i]
            obj.gender = patient._source.data__gender === 0 ? '男' : '女'
            obj.pdid = patient._source.data__pdid
            obj.age = patient._source.data__age
            obj.originDisease = patient._source.data__origin_disease
            this.rows.push(obj)
          }
        }).catch(function (error) {
          throw (error)
        })
      }
    },
    getAllPatients() {
      APIHelper.getAllPatientsFromESByPage(this.currentPage * this.perPage, this.perPage).then(response => {
        this.length = response.data.hits.total.value
        for (let i = 0; i <= this.length; i++) {
          let obj = this.default()
          let patient = response.data.hits.hits[i]
          obj.gender = patient._source.data__gender === 0 ? '男' : '女'
          obj.pdid = patient._source.data__pdid
          obj.age = patient._source.data__age
          obj.originDisease = patient._source.data__origin_disease
          this.rows.push(obj)
        }
      }).catch(function (error) {
        throw (error)
      })
    },
    loadData() {
      if (this.mode === 0) {
        this.getAllPatients()
      } else if (this.mode === 1) {
        this.select(1)
      }
    },
    default () {
      return {
        pdid: '',
        name: '',
        gender: '',
        tag: '',
        originDisease: 'Unknown'
      }
    }
  },
  data () {
    return {
      mode: 0,
      option_id: false,
      option_age: false,
      option_gender: false,
      option_origin_disease: false,
      input: '',
      age: '',
      gender: '',
      origin_disease: '',
      currentPage: 0,
      perPage: 20,
      columns: [
        { label: '编号', key: 'pdid', class: 'text-left' },
        { label: '性别', key: 'gender', class: 'text-left' },
        { label: '年龄', key: 'age', class: 'text-left' },
        { label: '原发疾病', key: 'originDisease', class: 'text-left' }
      ],
      rows: [],
      length: 0
    }
  }
}
</script>
