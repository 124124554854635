// 加载mockjs库
import Mock from 'mockjs'
Mock.mock('/api/data', /post|get/i, function (options) {
    let opt = JSON.parse(options.body)
    let events = []
    if (opt.pdid === 'A5') {
        events = [
            {
                'date': '2010-07-05',
                'event': 'fever',
                'describe': 'The patient suddenly had a high fever to 40 degrees Celsius at night'
            },
            {
                'date': '2010-10-05',
                'event': 'fever',
                'describe': 'The patient suddenly had a high fever to 39 degrees Celsius at night'
            },
            {
                'date': '2009-10-05',
                'event': 'shock',
                'describe': 'Sudden shock in the early morning'
            }
        ]
    }
    return events
})
