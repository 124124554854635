const defaultSettings = {
  secret: '',
  analyzeApi: [
    {
      url: 'http://47.93.42.104:10406/',
      alias: 'Adacare',
      enabled: false
    },
    {
      url: 'http://47.93.42.104:10407/',
      alias: 'Concare',
      enabled: true
    },
    {
      url: 'http://47.93.42.104:10408/',
      alias: 'LM',
      enabled: false
    },
    {
      url: 'http://47.93.42.104:10409/',
      alias: 'Stagenet',
      enabled: false
    }
  ]
}

const load = () => {
  return (localStorage.settings ? JSON.parse(localStorage.settings) : defaultSettings)
}

const save = (settings) => {
  localStorage.settings = JSON.stringify(settings)
}

const reset = () => {
  localStorage.removeItem('settings')
}

const SettingsHelper = { load, save, reset, defaultSettings }

export default SettingsHelper
