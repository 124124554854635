<template>
  <iq-card class-name="iq-card-block iq-card-stretch">
    <template v-slot:headerTitle>
      <h4
        class="card-title"
        style="display: inline;font-size:28px"
      >
        <span v-if="!isQues">{{ $t('main.general.riskCurve') }}
          <Tooltip
            placement="top"
            v-show="isShowAll"
          >
            <img
              src="../../assets/images/question.png"
              alt="guide"
              class="guide"
            />
            <span
              slot="content"
              style="white-space: normal"
            >{{
              $t('main.general.riskCurve()')
            }}</span>
          </Tooltip>
        </span>
        <span v-else>
          <span v-if="!isShowAll">
            时序图
            <!-- <div>时序图</div> -->
          </span>
          <span v-else>
            <span>{{ $t('main.general.riskCurve') }}</span>
          </span>
        </span>
        <!--                  <i class="las la-exclamation-circle" style="margin:10px" @click.stop=" dialog = true"></i>-->
      </h4>
      <!--            <template>-->
      <!--              <v-row justify="center">-->
      <!--                <v-dialog v-model="dialog" width="600px">-->
      <!--                  <v-card>-->
      <!--                    <v-card-title>-->
      <!--                      <span class="headline">如何使用</span>-->
      <!--                    </v-card-title>-->
      <!--                    <v-card-text></v-card-text>-->
      <!--                    <v-card-actions>-->
      <!--                      <v-spacer></v-spacer>-->
      <!--                      <v-btn color="green darken-1" text @click="dialog = false">关闭</v-btn>-->
      <!--                    </v-card-actions>-->
      <!--                  </v-card>-->
      <!--                </v-dialog>-->
      <!--              </v-row>-->
      <!--            </template>-->
    </template>
    <template v-slot:headerAction>
      <div
        v-show="isShowAll"
        style="font-size:20px;"
      >
        <!-- <b-dropdown
          variant="outline-danger"
          class="m-2"
          :text="target"
          style="padding-right: 30px;font-size:20px"
        >
          <b-dropdown-item @click="replaceAnalyze(0)"><span>{{ '死亡风险' }}</span></b-dropdown-item>
          <b-dropdown-item
            @click="replaceAnalyze(1)"
            v-if="!isQues"
          ><span>{{ '心脑血管合并症' }}</span></b-dropdown-item>
          <b-dropdown-item
            @click="replaceAnalyze(2)"
            v-if="!isQues"
          ><span>{{ '腹膜透析相关性腹膜炎' }}</span></b-dropdown-item>
          <b-dropdown-item
            @click="replaceAnalyze(3)"
            v-if="!isQues"
          ><span>{{ '腹膜透析外口感染' }}</span></b-dropdown-item>
          <b-dropdown-item
            @click="replaceAnalyze(4)"
            v-if="!isQues"
          ><span>{{ '其它细菌感染' }}</span></b-dropdown-item>
        </b-dropdown> -->
        <!-- <a
          @click="setShowEvents(!showEvents)"
          style="padding-right: 30px"
        >
          <span>{{
            showEvents
              ? $t('main.general.hideEvents')
              : $t('main.general.displayEvents')
          }}</span>
        </a> -->
        <a
          @click="setShowRisk(!showRisk)"
          style="padding-right: 30px"
          v-if="isShowAll"
        >
          <span>{{
            showRisk
              ? $t('main.general.hideCurve')
              : $t('main.general.displayCurve')
          }}</span>
        </a>
        <a
          @click="showAll(!showValue)"
          style="padding-right: 30px"
          v-if="isShowAll"
        >
          <span>{{
            showValue
              ? $t('main.general.hideInd')
              : $t('main.general.displayInd')
          }}</span>
        </a>
        <a @click="reset">
          <span>{{ $t('main.general.reset') }}</span>
        </a>
      </div>
    </template>
    <template v-slot:body>
      <div
        id="patient-chart"
        :onContextMenu="e => e.preventDefault()"
      >
        <!--        <input id="patient-chart-input" v-model="inputVal" @keydown.enter="enter" @keydown.esc="inputPos = undefined"-->
        <!--          @keydown.shift="keyModifier=0.1" @keydown.alt="keyModifier=0.01" @keyup.shift="keyModifier=1"-->
        <!--          @keyup.alt="keyModifier=1" @wheel="wheel"-->
        <!--          :style="inputPos?{top: inputPos[1] + 'px', left: inputPos[0] + 'px'}:{display: 'none'}" />-->
        <div
          id="patientDataChart"
          @contextmenu.prevent
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 'unset'
          }"
        ></div>
      </div>
      <div
        v-if="isQues && isShowAll"
        style="font-size:24px;color:#A09E9E;width:900px;margin:10px auto"
      >
        纵轴额外包括了AI所评估的患者风险指数，以<span style="color:pink">背景浅粉色的曲线</span>示意，越高则越危险。特征折线图上标记有大小不一的点，<span style="font-weight:700">点越大</span><span style="font-size:24px">◉</span>则表明AI在此刻越关注该特征，<span style="font-weight:700">点很小</span><span style="font-size:16px;">◉</span>表明AI不关注该特征。当您把鼠标悬停在其上时，会有悬浮窗口显示该患者此次就诊的时间、预测风险值、特征重要性权重分配、特征检验检查值。
      </div>
      <div
        style="font-size:24px;color:#A09E9E;width:900px;margin:10px auto"
        v-if="isQues && !isShowAll"
      >
        横轴为一位患者的就诊时间，纵轴为特征检验结果。每一个记录点为一次就诊。当您用鼠标点选不同特征时，该特征的时序折线图就会展示。
      </div>
      <!-- {{ lab }} -->
      <!-- {{minus}} -->
    </template>
  </iq-card>
</template>

<script>
import {
  medicalCharChinese,
  medicalChar,
  medicalCharChineseShort,
  medicalCharShort,
  medicalStandard,
  medicalUnit
} from '@/util/info'
// eslint-disable-next-line
import { xray } from '../../config/pluginInit'
export default {
  name: 'PatientDataChart',
  props: {
    isShowAll: { type: Boolean, default: true },
    showDeath: { type: Boolean, dafault: true },
    isQues: { type: Boolean, dafault: false }
  },
  data() {
    return {
      echart: undefined,
      inputPos: undefined,
      inputVal: undefined,
      keyModifier: 1,
      target: '死亡风险',
      minus: 0
      // modifiedLab: []
    }
  },

  computed: {
    data() {
      const { analyze, modifiedLab, lab, events } = this
      return {
        analyze,
        modifiedLab,
        lab,
        events
      }
    },
    selected() {
      const { selectedGraph, selectedDate } = this
      return {
        selectedGraph,
        selectedDate
      }
    },
    selectedGraph() {
      return this.$store.state.selectedGraph
    },
    selectedDate() {
      return this.$store.state.selectedDate
    },
    events() {
      return this.$store.state.events
    },
    patient() {
      return this.$store.state.patient
    },
    analyze() {
      return this.$store.state.analyze
    },
    modifiedLab() {
      let x = this.$store.state.modifiedLab
      let minus = Number(x[0].date.substring(0, 4)) - 1000
      this.$store.commit('setYearMinus', minus)
      // this.minus = minus
      for (let i = 0; i < x.length; i++) {
        x[i].date = parseInt(x[i].date.slice(0, 4)) - minus + x[i].date.slice(4)
      }
      return x
    },
    lab() {
      return this.$store.state.lab
    },
    ch() {
      return this.$store.state.ch
    },
    showValue() {
      return this.$store.state.showValue
    },
    showEvents() {
      return this.$store.state.showEvents
    },
    showLine() {
      return this.$store.state.showLine
    },
    showRisk() {
      return this.$store.state.showRisk
    },
    predict() {
      let obj = {
        type: 'line',
        name: this.$i18n.locale === 'chi' ? '风险指数' : 'Death Risk Index',
        data: this.modifiedLab.map((event, i) => [
          event.date,
          this.analyze.predict[i] * 100
        ]),
        yAxisIndex: 0,
        lineStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: '#ff0844'
              },
              {
                offset: 1,
                color: '#ffb199'
              }
            ],
            global: false
          },
          opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: '#ff0844'
              },
              {
                offset: 1,
                color: '#ffb199'
              }
            ],
            global: false
          },
          opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
        },
        symbol: 'none',
        smooth: 0.2
      }
      // if (this.patient.deathDate && this.showLine && this.showDeath) {
      // }
      if (1 || !this.selectedGraph.length) {
        if (obj.markLine) {
          obj.markLine.data.push({
            silent: true, // 鼠标悬停事件  true没有，false有
            lineStyle: {
              // 警戒线的样式  ，虚实  颜色
              type: 'dashed',
              color: '#FFB199',
              width: 2
            },
            label: {
              show: false
            },
            yAxis: 50
          })
        } else {
          obj.markLine = {
            symbol: 'none', // 去掉警戒线最后面的箭头
            silent: true,
            data: [
              {
                silent: true, // 鼠标悬停事件  true没有，false有
                lineStyle: {
                  // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: '#FFB199',
                  width: 2
                },
                label: {
                  show: false
                },
                yAxis: 50
              }
            ]
          }
        }
      }
      return obj
    },
    series() {
      return {
        series: [
          this.showRisk && this.analyze.predict && this.predict,
          ...this.selectedGraph.map((key, i) => ({
            type: 'line',
            name:
              this.$i18n.locale === 'chi'
                ? medicalCharChinese[key]
                : medicalChar[key],
            data: this.modifiedLab.map(event => [event.date, event[key]]),
            yAxisIndex: i + 1,
            symbol: (value, params) =>
              this.isShowAll === true
                ? this.analyze.attention &&
                  this.analyze.attention[params.dataIndex][key] > 0
                  ? 'circle'
                  : 'emptyCircle'
                : 'none',
            symbolSize: (value, params) =>
              this.analyze.attention
                ? this.analyze.attention[params.dataIndex][key] * 12 + 4
                : 8,
            itemStyle: {
              color: this.ch.get(key).midColor
            },
            lineStyle: {
              color: this.ch.get(key)
            },
            emphasis: {
              lineStyle: {
                width: 2
              }
            },
            markLine: this.selectedGraph.length === 1 &&
              medicalStandard[this.selectedGraph[0]] && {
              // 添加警戒线
              symbol: 'none', // 去掉警戒线最后面的箭头
              // silent: true,
              data: [
                {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: this.ch.get(this.selectedGraph[0]).midColor
                  },
                  label: {
                    position: 'end',
                    padding: 4,
                    formatter:
                      this.$i18n.locale === 'chi'
                        ? '门诊参考值下限'
                        : 'Lower limit'
                  },
                  yAxis: medicalStandard[this.selectedGraph[0]][0]
                },
                {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: this.ch.get(this.selectedGraph[0]).midColor
                  },
                  label: {
                    position: 'end',
                    padding: 4,
                    formatter:
                      this.$i18n.locale === 'chi'
                        ? '门诊参考值上限'
                        : 'Upper limit'
                  },
                  yAxis: medicalStandard[this.selectedGraph[0]][1]
                }
              ]
            }
            // markPoint: {
            //     data:modifiedLab.map((event,index)=>({
            //         coord:[event.date,event[key]],
            //         value:analyze.attention[index][key].toFixed(2)
            //     })).filter(o=>o.value>0),
            //     //data:[{coord:['2007-08-21',0],value:300}]
            // },
          })),
          this.showEvents &&
          this.events.length && {
            type: 'line',
            name: 'eventLine',
            markLine: {
              symbol: 'none', // 去掉警戒线最后面的箭头
              silent: false,
              data: this.events.map((data, i) => ({
                silent: true, // 鼠标悬停事件  true没有，false有
                lineStyle: {
                  // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: this.ch.get(data.event).midColor,
                  width: 3
                },
                itemStyle: {
                  color: this.ch.get(data.event).midColor
                },
                label: {
                  position: 'end',
                  formatter: data.event
                },
                xAxis: Number(data.date.split('-')[0]) - this.$store.state.yearMinus + '-' + data.date.split('-')[1] + '-' + data.date.split('-')[2]
              }))
            }
          },
          this.showEvents && {
            type: 'line',
            name: 'eventLine',
            markLine: {
              symbol: 'none', // 去掉警戒线最后面的箭头
              silent: false,
              data: [
                {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: '#FF6347',
                    width: 2
                  },
                  label: {
                    position: 'end',
                    formatter:
                      this.$i18n.locale === 'chi'
                        ? '死亡前一年'
                        : 'year before death'
                  },
                  // xAxis: '1007-05-14'
                  xAxis: Number(this.patient.deathDate.split('-')[0]) - 1 - this.$store.state.yearMinus + '-' + this.patient.deathDate.split('-')[1] + '-' + this.patient.deathDate.split('-')[2]
                }
              ]
            }
          },
          this.selectedDate.length && {
            type: 'line',
            name: 'markLine',
            markLine: {
              // 添加警戒线
              symbol: 'none', // 去掉警戒线最后面的箭头
              silent: true,
              data: [
                {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: '#FF4500',
                    width: 3
                  },
                  label: {
                    position: 'end',
                    formatter: this.getAiFocusArea(
                      (this.selectedDate[1] - this.selectedDate[0]) /
                      this.modifiedLab.length >=
                      0.25
                    )
                  },
                  xAxis: this.modifiedLab[this.selectedDate[0]].date
                },
                {
                  silent: false, // 鼠标悬停事件  true没有，false有
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: '#FF4500',
                    width: 3
                  },
                  label:
                    (this.selectedDate[1] - this.selectedDate[0]) /
                      this.modifiedLab.length >=
                      0.25
                      ? {
                        position: 'end',
                        formatter:
                          this.$i18n.locale === 'chi'
                            ? '←AI关注区域'
                            : '←AI Focus Area'
                      }
                      : {
                        show: false
                      },
                  xAxis: this.modifiedLab[this.selectedDate[1]].date
                }
              ]
            }
          },
          ...this.selectedGraph.map((key, i) => ({
            type: 'line',
            name: key + ' predict',
            data:
              this.analyze.predict_next_value &&
              this.analyze.predict_next_value.map((event, i) => {
                const date = this.modifiedLab[i].date.split('-')
                const predictDate = new Date(
                  parseInt(date[0]),
                  parseInt(date[1]) - 1,
                  parseInt(date[2])
                )
                predictDate.setMonth(predictDate.getMonth() + 3)
                const month = predictDate.getMonth() + 1
                const day = predictDate.getDate()
                const newDate =
                  predictDate.getFullYear() +
                  '-' +
                  (month < 10 ? '0' + month : month) +
                  '-' +
                  (day < 10 ? '0' + day : day)
                return [newDate, event[key]]
              }),
            yAxisIndex: i + 1,
            symbol: 'emptyCircle',
            symbolSize: 4,
            itemStyle: {
              color: this.ch.get(key + ' predict').midColor
            },
            lineStyle: {
              color: this.ch.get(key + ' predict')
            }
          })),
          this.analyze.stage && {
            type: 'line',
            name: 'Stage Variation',
            data: this.modifiedLab.map((event, i) => [
              event.date,
              this.analyze.stage[i]
            ]),
            yAxisIndex: this.selectedGraph.length + 2,
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#1e3c72'
                  },
                  {
                    offset: 1,
                    color: '#2a5298'
                  }
                ],
                global: false
              },
              opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#1e3c72'
                  },
                  {
                    offset: 1,
                    color: '#2a5298'
                  }
                ],
                global: false
              },
              opacity: this.selectedGraph.length === 0 ? 0.3 : 0.1
            },
            symbol: 'none',
            smooth: 0.2
          }
        ]
      }
    }
  },
  watch: {
    isShowAll: function (newvalue, oldvalue) {
      if (newvalue === false) {
        this.$store.commit('setShowRisk', false)
      } else {
        this.$store.commit('setShowRisk', true)
      }
    },
    data() {
      setTimeout(this.echart.setOption, 1000)
      // this.echart.setOption(this.series)
    },
    selected() {
      this.setChart()
    },
    showLine() {
      this.echart.setOption(this.series)
    },
    showEvents() {
      this.setChart()
    },
    showRisk() {
      this.setChart()
    },
    lab() {
      this.$store.commit('setModifiedLab', this.lab)
    }
  },
  created() {
    // for (let i = 0; i < x.length; i++) {
    //   x[i].date = parseInt(x[i].date.slice(0, 4)) - minus + x[i].date.slice(4)
    // }
    // this.modifiedLab = x
    if (this.isShowAll === false) {
      this.$store.commit('setShowRisk', false)
    }
  },
  mounted() {
    this.echart = this.$echarts.init(
      document.getElementById('patientDataChart')
    )
    this.echart.getZr().on('click', e => {
      if (!e.target) this.inputPos = undefined
    })
    this.setChart()
    window.onresize = () => {
      this.echart.resize()
    }
  },
  destroyed() {
    window.onresize = null
  },
  methods: {
    replaceAnalyze(value) {
      switch (value) {
        case 0:
          this.$store.commit('setAnalyze', this.$store.state.analyzeRisk)
          this.target = '死亡风险'
          break
        case 1:
          let obj1 = JSON.parse(JSON.stringify(this.$store.state.analyze1))
          this.$store.commit('setAnalyze', obj1)
          this.target = '心脑血管合并症'
          // console.log('click item1')
          break
        case 2:
          let obj2 = JSON.parse(JSON.stringify(this.$store.state.analyze2))
          this.$store.commit('setAnalyze', obj2)
          this.target = '腹膜透析相关性腹膜炎'
          // console.log('click item2')
          break
        case 3:
          let obj3 = JSON.parse(JSON.stringify(this.$store.state.analyze3))
          this.$store.commit('setAnalyze', obj3)
          this.target = '腹膜透析外口感染'
          // console.log('click item3')
          break
        case 4:
          let obj4 = JSON.parse(JSON.stringify(this.$store.state.analyze4))
          this.$store.commit('setAnalyze', obj4)
          this.target = '其它细菌感染'
          // console.log('click item4')
          break
      }
    },
    showAll(value) {
      this.$store.commit('setShowValue', value)
      // this.$store.commit('setSelectedGraph', [])
    },
    setShowRisk(value) {
      this.$store.commit('setShowRisk', value)
      // this.$store.commit('setSelectedGraph', [])
    },
    setShowEvents(value) {
      this.$store.commit('setShowEvents', value)
      // console.log('event: ', this.events)
      // this.$store.commit('setSelectedGraph', [])
    },
    reset() {
      this.$store.commit('setSelectedDate', [])
      this.$store.commit('setSelectedGraph', [])
    },
    enter() {
      this.selectedGraph.map(key => {
        if (this.inputVal !== '' && !isNaN(+this.inputVal)) {
          this.$store.commit(
            'setModifiedLab',
            this.modifiedLab.map(o =>
              o.date === this.event.date
                ? {
                  ...o,
                  [key]: this.inputVal
                }
                : o
            )
          )
        } else if (this.inputVal === '') {
          this.$store.commit(
            'setModifiedLab',
            this.modifiedLab.map(o =>
              o.date === this.event.date
                ? {
                  ...o,
                  [key]: this.lab[this.labIndex][key]
                }
                : o
            )
          )
        }
        this.inputPos = undefined
      })
    },
    getAiFocusArea(value) {
      if (value) {
        return this.$i18n.locale === 'chi' ? 'AI关注区域→' : 'AI focus area→'
      } else {
        return this.$i18n.locale === 'chi' ? 'AI关注区域' : 'AI focus area'
      }
    },
    wheel(e) {
      this.selectedGraph.map(key => {
        const delta = Math.round(e.deltaY) * 0.01 * this.keyModifier
        this.inputVal = parseFloat(
          (parseFloat(this.inputVal) + delta).toPrecision(12)
        )
        if (!isNaN(this.inputVal)) {
          this.$store.commit(
            'setModifiedLab',
            this.modifiedLab.map(o =>
              o.date === this.event.date
                ? {
                  ...o,
                  [key]: this.inputVal
                }
                : o
            )
          )
        }
      })
    },
    setChart() {
      this.echart.setOption(
        {
          animation: true,
          xAxis: [
            {
              type: 'time',
              show: true,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowBlur: 2
            }
          ],
          yAxis: [
            {
              name:
                this.$i18n.locale === 'chi' ? '风险指数' : 'Death Risk Index',
              type: 'value',
              show: this.showRisk && this.analyze.predict,
              min: 0,
              max: 100,
              offset: 0,
              position: 'left',
              axisLine: {
                show: true
              }
            },
            ...this.selectedGraph.map((key, i) => ({
              name:
                this.$i18n.locale === 'chi'
                  ? medicalCharChineseShort[this.selectedGraph[i]]
                  : medicalCharShort[this.selectedGraph[i]] +
                  '\n' +
                  medicalUnit[this.selectedGraph[i]],
              type: 'value',
              axisLine: {
                show: true,
                lineStyle: {
                  color: this.ch.get(key).midColor
                }
              },
              axisLabel: {
                color: '#000'
              },
              nameTextStyle: {
                color: '#000'
              },
              // 最值由正常值和指标值取最值得出
              min: medicalStandard[this.selectedGraph[i]]
                ? Math.min(
                  medicalStandard[this.selectedGraph[i]][0],
                  ...this.modifiedLab.map(event => event[key])
                )
                : Math.min(...this.modifiedLab.map(event => event[key])),
              max: medicalStandard[this.selectedGraph[i]]
                ? Math.max(
                  medicalStandard[this.selectedGraph[i]][1],
                  ...this.modifiedLab.map(event => event[key])
                )
                : Math.max(...this.modifiedLab.map(event => event[key])),
              offset: this.showRisk ? i * 48 : i <= 0 ? 0 : i * 48 - 48,
              position: this.showRisk ? 'right' : i === 0 ? 'left' : 'right'
            })),
            {
              name: '指标',
              type: 'value',
              show: this.selectedGraph.length === 0 && this.analyze.stage,
              offset: 0,
              position: 'right'
            }
          ],
          grid: {
            show: false,
            borderWidth: 0,
            backgroundColor: '#fff',
            top: 64,
            left: 48,
            right:
              96 +
              (this.selectedGraph.length > 2
                ? this.selectedGraph.length * 48 - 96
                : 0),
            bottom: 32
          },
          tooltip: {
            trigger: 'axis',
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              let x = 0 // x坐标位置
              let y = 0 // y坐标位置
              // 当前鼠标位置
              let pointX = point[0]
              let pointY = point[1]
              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];
              // 提示框大小
              let boxWidth = size.contentSize[0]
              let boxHeight = size.contentSize[1]
              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = pointX + 10
              } else {
                // 左边放的下
                x = pointX - boxWidth - 10
              }
              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5
              } else {
                // 上边放得下
                y = pointY - boxHeight
              }
              return [x, y]
            },
            formatter: params => {
              // console.log(params)
              const getCircle = echartColor => `<span style="
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: ${echartColor.midColor};
"> </span>`
              const index = params[0].dataIndex
              const valueContent = params
                .map(
                  o =>
                    `<div>${o.marker}${o.seriesName}：${Number(
                      o.value[1]
                    ).toFixed(2)}</div>`
                )
                .join('')
              let attentionContent
              let attention_tmp = JSON.parse(JSON.stringify(this.analyze.attention))
              let attention = []
              for (var item of attention_tmp) {
                let a = JSON.parse(JSON.stringify(item))
                if (a.amount === 0) delete a.amount
                else {
                  const amount_attention = a.amount
                  for (let key in a) {
                    if (a[key] > 0) {
                      a[key] = a[key] / (1 - amount_attention)
                    }
                  }
                  delete a.amount
                }
                attention.push(a)
              }

              if (this.showValue && this.isShowAll) {
                attentionContent = attention
                  ? Object.entries(attention[index])
                    .filter(o => o[1] >= 0)
                    .sort((a, b) => -a[1] + b[1])
                    .map(
                      ([k, v]) =>
                        `<div>${getCircle(this.ch.get(k))}${this.$i18n.locale === 'chi'
                          ? medicalCharChinese[k]
                          : medicalChar[k]
                        }：${(v * 100).toFixed(1)}% (${Number(
                          this.lab[index][k]
                        ).toFixed(1)})</div>`
                    )
                    .join('')
                  : undefined
              } else if (!this.showValue && this.isShowAll) {
                attentionContent = attention
                  ? Object.entries(attention[index])
                    .filter(o => o[1] > 0)
                    .sort((a, b) => -a[1] + b[1])
                    .map(
                      ([k, v]) =>
                        `<div>${getCircle(this.ch.get(k))}${this.$i18n.locale === 'chi'
                          ? medicalCharChinese[k]
                          : medicalChar[k]
                        }：${(v * 100).toFixed(1)}%</div>`
                    )
                    .join('')
                  : undefined
              } else {
                attentionContent = attention
                  ? Object.entries(attention[index])
                    .filter(o => o[1] >= 0)
                    .sort((a, b) => -a[1] + b[1])
                    .map(
                      ([k, v]) =>
                        `<div>${getCircle(this.ch.get(k))}${this.$i18n.locale === 'chi'
                          ? medicalCharChinese[k]
                          : medicalChar[k]
                        }： ${Number(this.lab[index][k]).toFixed(1)}</div>`
                    )
                    .join('')
                  : undefined
              }
              const attn =
                this.$i18n.locale === 'chi'
                  ? this.showAll === true
                    ? 'AI关注度'
                    : '动态特征重要性'
                  : this.showAll === true
                    ? 'AI Attention'
                    : 'Adaptive Feature Importance'
              let year = parseInt(params[0].value[0].slice(0, 4))
              if (this.isShowAll) {
                return `<div><div><b>${year}${params[0].value[0].slice(
                  4,
                  10
                )}</b></div>${valueContent}<div>${attentionContent
                  ? `<b>${attn}</b></div>${attentionContent}</div>`
                  : ''
                  }`
              } else {
                return `<div><div><b>${year}${params[0].value[0].slice(
                  4,
                  10
                )}</b></div><div>${attentionContent
                  ? `<b>${attn}</b></div>${attentionContent}</div>`
                  : ''
                  }`
              }
            }
          }
        },
        true
      )
      this.echart.setOption(this.series)
      this.echart.setOption({
        graphic: this.echart
          ? this.selectedGraph
            .map((key, selectedIndex) =>
              this.modifiedLab.map((event, labIndex) => ({
                type: 'circle',
                shape: { r: 8 },
                position: [
                  this.echart.convertToPixel({ xAxisIndex: 0 }, event.date),
                  this.echart.convertToPixel(
                    { yAxisIndex: selectedIndex + 1 },
                    event[key]
                  )
                ],
                draggable: true,
                invisible: true,
                z: 100,
                cursor: 'n-resize',
                onmouseup: e => {
                  if (e.event.button === 2) {
                    this.inputPos = [e.offsetX + 10, e.offsetY - 40]
                    this.inputVal = parseFloat(this.lab[labIndex][key])
                    this.event = event
                    this.labIndex = labIndex
                  }
                },
                ondrag: e => {
                  // console.log(event.date, key, this.echart.convertFromPixel({yAxisIndex: i}, e.offsetY));
                  if (this.inputPos) this.inputPos = undefined
                  this.$store.commit(
                    'setModifiedLab',
                    this.modifiedLab.map(o =>
                      o.date === event.date
                        ? {
                          ...o,
                          [key]: this.echart.convertFromPixel(
                            { yAxisIndex: selectedIndex + 1 },
                            e.offsetY
                          )
                        }
                        : o
                    )
                  )
                }
              }))
            )
            .reduce((obj, cur) => [...obj, ...cur], [])
          : undefined
      })
    }
  }
}
</script>

<style scoped lang="less">
.guide {
  width: 16px;
  padding-left: 2px;
  padding-bottom: 5px;
  cursor: pointer;
}

#patient-chart {
  /*width: 100%;*/
  /*height: 0;*/
  position: relative;
  padding-top: 40%;

  &-input {
    position: absolute;
    width: 100px;
    height: 32px;
    border: 1px solid #00000026;
    border-radius: 4px;
    z-index: 100001;
    padding: 0 8px;
  }

  &-input:focus {
    outline: none;
  }
}
</style>
