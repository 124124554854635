import axios from 'axios'

export function personalInfo(userId) {
  return axios({
    method: 'get',
    url: 'http://47.93.42.104:10408/v1/web/doctors/' + userId
  })
}

export function modifyPersonalInfo_1(userId, form) {
  return axios({
    method: 'put',
    url: 'http://47.93.42.104:10408/v1/web/doctors/' + userId,
    data: form
  })
}

export function modifyPersonalInfo_2(userId, form) {
  return axios({
    method: 'put',
    url: 'http://47.93.42.104:10408/v1/web/doctors/password/' + userId,
    data: form
  })
}
